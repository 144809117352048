import React, { useEffect, useState } from "react";
import "../../../Styles/App/HomeMB2.css";
import { Fade } from "react-awesome-reveal";
import ServicioApartado from "../../../Images/HomeMB2/ServicioApartado.png";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CUESTIONARIO, CITAS_MB } from "../../../Constants/routesConstants";
import RegisterMb2 from "../../Login/RegisterMb2";
const Section5HomeMobile = (props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const navigate = useNavigate();

  const [openSingUp, setOpenSingUp] = useState(false);
  const handleOpenSingUp = () => {
    // gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp);
  };

  const handleCloseSingUp = () => {
    setOpenSingUp(false);
  };
  return (
    <section className="ContainerSection5MBMobile">
      <img
        src={
          "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png"
        }
      />

      <div className="cardSecttion5MBMobile">
        <Fade direction="left" triggerOnce>
          <div className="section5MBMobile-1">
            <div className="contentBody">
              <span>Valua tus piezas en línea</span>
              <Button
                onClick={
                  userInfo
                    ? function () {
                        navigate(CUESTIONARIO);
                        // gtmGoTo_EmpeñaAqui_Log()
                      }
                    : function () {
                        handleOpenSingUp();
                      }
                }
                className="btnLink"
              >
                Valuar
                <img
                  src={
                    "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png "
                  }
                />
              </Button>
            </div>
            <div className="contentImg">
              <img
                src={
                  "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/aparta.png"
                }
              />
            </div>
          </div>
        </Fade>
        <Fade direction="right" triggerOnce>
          <div className="section5MBMobile-1">
            <div className="contentImg">
              <img
                src={
                  "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/aparta.png"
                }
              />
            </div>
            <div className="contentBody">
              <span>Visita nuestro Showroom</span>
              <Button
                onClick={
                  function () {
                    window.open(
                      "https://api.whatsapp.com/send/?phone=5641819020&text=Hola",
                      "_blank"
                    );
                  }
                  // userInfo
                  //   ? function () {
                  //       navigate(CITAS_MB)
                  //       // gtmGoTo_EmpeñaAqui_Log()
                  //     }
                  //   : function () {
                  //       handleOpenSingUp()
                  //     }
                }
                className="btnLink"
              >
                Generar cita
                <img
                  src={
                    "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png "
                  }
                />
              </Button>
            </div>
          </div>
        </Fade>
        {/* <div className="section5MBMobile-2"></div> */}
      </div>
      <RegisterMb2
        open={openSingUp}
        onClose={handleCloseSingUp}
        props={props}
      />
    </section>
  );
};

export default Section5HomeMobile;
