import React, { useEffect, useState } from 'react';
import requests from '../../Components/AxiosCalls/AxiosCall';
import MUIDataTable from "../../Util/MUIDataTable";
import { CANCELACIONES_LIST } from '../../Constants/ApiConstants'
import { CANCELACIONES_DETAILS } from '../../Constants/routesConstants'
import Theme from "../../Styles/Theme";
import { ThemeProvider } from '@mui/styles';
import { Chip, Grid, Button, Tooltip } from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns'
const CancelacionesView = (props) => {
    const navigate = useNavigate();
    const [registros, setRegistros] = useState([]);
    const [_RegistroId, setRegistroId] = useState();

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
    };

    const getRegistros = () => {
        props.setOpenLoadingScreen();
        requests
            .get(CANCELACIONES_LIST)
            .then((response) => {
                setRegistros(response);
                props.setCloseLoadingScreen();
                setRegistroId(null)
            })
            .catch((error) => {
                console.log("Error: " + error);
                props.setCloseLoadingScreen();
            });

    };

    useEffect(() => {
        getRegistros();
    }, []);

    const columns = [
        {
            name: "lngIdCancelDev",
            options: {
                display: "excluded",
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: "id",
            label: "#",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (rowIndex, dataIndex) => dataIndex.rowIndex + 1,
            },
        },
        {
            name: "txtFolio",
            label: "Folio",
            options: {
                columnOrder: true,
                filter: false,
            },
        }, {
            name: "txtNombre",
            label: "Cliente",
            options: {
                columnOrder: true,
                filter: false,
            },
        }, {
            name: "txtNumPedido",
            label: "No. Pedido",
            options: {
                //display: "excluded",
                columnOrder: true,
                filter: false,
            },
        }, {
            name: "fecRegistro",
            label: "Fecha",
            options: {
                //display: "excluded",
                columnOrder: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
					if (tableMeta.rowData[5] !== null) {
						return format(new Date(tableMeta.rowData[5]), 'dd/MM/yyyy')
					}
				},
            },
        },        
        {
            name: "bolTipoSolicitud",
            label: "Solicitud",
            options: {
                columnOrder: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[6] === true) {
                        return (<Chip style={{backgroundColor: '#c8e6c9', color: '#2e7d32'}} size='small' label='Cancelación'></Chip>)
                    }
                    else {
                        return (<Chip style={{ backgroundColor: '#ffccbc', color: '#7B241C' }} size='small' label='Devolución'></Chip>)
                    }                    
                }
            },
        },
        {
            name: "",
            label: "",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title='Más información' arrow aria-label='add'>
                            <Button
                                className='font-barter'
                                onClick={function () {
                                    navigate(CANCELACIONES_DETAILS + tableMeta.rowData[0])
                                }}
                            >
                                <VisibilityIcon />
                            </Button>
                        </Tooltip>

                    );
                },
            },
        },
    ];
    return (
        <ThemeProvider theme={Theme}>
            <Grid>
                <Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
                    <Grid Grid item xs={4} sm={4} md={4} lg={12} className='d-flex align-center mb-30'>
                        <Chip icon={<CancelPresentationIcon />} label='Cancelaciones' color='primary' className='back-transparent font-letter font-barter font-20' />
                    </Grid>
                </Grid>
                <Grid item xs={12} alignItems="center" elevation={12}>
                    <MUIDataTable
                        data={registros}
                        columns={columns}
                    />
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}
export default CancelacionesView