import React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
// import Accordion from '@mui/material/Accordion'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import '../Styles/App/Footer/FooterMobile.css'
import imgBarterFooter from '../Images/logo-barter-sin-fondo.png'
import fiserVIcon from '../Images/fiserVIcon.png'
import ImageIcon from '@mui/icons-material/Image'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import InboxIcon from '@mui/icons-material/Inbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  MB_TERMINOS_CONDICIONES,
  MB_AVISO_PRIVACIDAD,
  MB_FAQS,
  MB_FACTURACION,
} from '../Constants/routesConstants'
import facebook from '../Images/Icons/facebook.svg'
import instagram from '../Images/Icons/instagram.svg'
import tiktok from '../Images/Icons/tiktok.svg'
import {
  gtmPreevaluacion,
  gtmVendeUNL,
  gtmEmpenaeUNL,
  gtmGoShopyUNL,
  gtmTerminosUNL,
  gtmAvisosUNL,
  gtmGoDevolucionesUNL,
  gtmGoFaqsUNL,
  gtmGoFacebookUNL,
  gtmGoInstagramUNL,
  gtmGoTikTokUNL,
  gtmGoTo_ProcesoAutenticacion_Log,
  gtmApartaUNL,
  gtmFacturacionUNL,
} from '../Constants/GoogleTagManager/gtmConstants'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid #000000`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))
// const AccordionSummary = styled((props) => (
// 	<MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
// ))(({ theme }) => ({
// 	textTransform: 'uppercase',
// 	flexDirection: 'row',
// 	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
// 		transform: 'rotate(180deg)',
// 	},
// 	'& .MuiAccordionSummary-content': {
// 		marginLeft: theme.spacing(0),
// 	},
// }))
const FooterMobile = () => {
  const navigate = useNavigate()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const preevaluacion = () => {
    gtmPreevaluacion()
    navigate('/con-emp')
  }
  const ventasUNL = () => {
    gtmVendeUNL()
    navigate('/consigna')
  }
  const empenosUNL = () => {
    gtmEmpenaeUNL()
    navigate('/empeno')
  }
  const facturacionUNL = () => {
    gtmFacturacionUNL()
    navigate('/facturacion')
  }
  const apartarUNL = () => {
    gtmApartaUNL()
    navigate('/apartar')
  }
  return (
    <div className="ContainerFooterMobile">
      {/* <hr className="hrTop" /> */}
      {/* <img src={imgBarterFooter} /> */}
      <div className="ContainerAcordion">
        {/* ACORDION DE NUESTRA COMUNIDAD */}
        <Accordion className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography className="TitleAcordion">Servicios</Typography>
          </AccordionSummary>
          <AccordionDetails className="acordiondetails">
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  {userInfo ? (
                    <ListItemText
                      onClick={preevaluacion}
                      primary="Vende o Empeña tus artículos de lujo"
                    />
                  ) : (
                    <ListItemText onClick={ventasUNL} primary="Ventas" />
                  )}
                </ListItemButton>
              </ListItem>

              {userInfo ? null : (
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText onClick={empenosUNL} primary="Empeños" />
                  </ListItemButton>
                </ListItem>
              )}

              {userInfo ? null : (
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText onClick={apartarUNL} primary="Apartados" />
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText
                    onClick={function () {
                      gtmGoShopyUNL()
                      window.open('ttps://barterluxe.com.mx/', '_blank')
                    }}
                    primary="Tienda en línea"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        {/* ACORDION DE INFORMACIÓN */}
        <Accordion className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography className="TitleAcordion">Legales</Typography>
          </AccordionSummary>
          <AccordionDetails className="acordiondetails">
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText
                    onClick={function () {
                      // window.open(
                      //   "https://founditpreloved.com/pages/facturar",
                      //   "_blank"
                      // );
                      facturacionUNL()
                      navigate(MB_FACTURACION)
                    }}
                    primary="Facturación"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText
                    onClick={function () {
                      gtmGoFaqsUNL()
                      navigate(MB_FAQS)
                    }}
                    primary="FAQS"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText
                    onClick={function () {
                      gtmAvisosUNL()
                      navigate(MB_AVISO_PRIVACIDAD)
                    }}
                    primary="Aviso de privacidad"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText
                    onClick={function () {
                      gtmTerminosUNL()
                      navigate(MB_TERMINOS_CONDICIONES)
                    }}
                    primary="Términos y condiciones"
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText
                    onClick={function () {
                      gtmGoDevolucionesUNL()
                      window.open(
                        'https://founditpreloved.com/pages/devoluciones-y-garantias',
                        '_blank'
                      )
                    }}
                    primary="Devoluciones y garantías"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        {/* ACORDION DE CONTÁCTO */}
        <Accordion className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography className="TitleAcordion">Contacto</Typography>
          </AccordionSummary>
          <AccordionDetails className="acordiondetails">
            <List>
              <ListItem>
                <ListItemText primary="contacto@founditpreloved.com" />
              </ListItem>
              <ListItem>
                <ListItemText primary="56-4181-9020" />
                {/* <ListItemText primary='Photos' secondary='Jan 9, 2014' /> */}
              </ListItem>
              <ListItem>
                <ListItemText primary="Río Nazas #34, piso 2, Col. Cuauhtémoc CDMX" />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Lunes - Jueves"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        9h a 18h
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemText
                  primary="Viernes"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        9h a 17h
                      </Typography>
                    </React.Fragment>
                  }
                />
                {/* <ListItemText
									primary='Lunes - Viernes'
									secondary='09:00am a 18:00pm'
								/> */}
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="ContainerBottom">
        <div className="sectionbottom1">
          <div className="redesSocialess">
            <IconButton
              onClick={function () {
                gtmGoFacebookUNL()
                window.open(
                  'https://www.facebook.com/profile.php?id=61561594710798&locale=es_LA',
                  '_blank'
                )
              }}
            >
              <img src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/Facebook_1.png" />
            </IconButton>
            <IconButton
              onClick={function () {
                gtmGoInstagramUNL()
                window.open('https://www.instagram.com/foundit_preloved/?igsh=NjN2cWdkZnZpd250', '_blank')
              }}
            >
              <img src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/Instagram_1.png" />
            </IconButton>

            <IconButton
              onClick={function () {
                gtmGoTikTokUNL()
                window.open('https://www.tiktok.com/@foundit_preloved?_t=8oVpfdt9tsE&_r=1', '_blank')
              }}
            >
              <img src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/TikTok_1.png" />
            </IconButton>
          </div>
          <img src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png" />
        </div>
        <div className="sectionbottom2">
          <span>Derechos Reservados Foundit 2024</span>
        </div>
      </div>
    </div>
  )
}

export default FooterMobile
