import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { PRODUCTOS_VIEW } from '../../../Constants/routesConstants'
import { useForm } from 'react-hook-form'
import { ThemeProvider } from '@mui/styles'
import Theme from '../../../Styles/Theme'
import {
  Box,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PreviewImg from '../../../Components/PreviewImg'
import NumberFormat from 'react-number-format'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { PRODUCTOS_DETAILS_BY_SKU } from '../../../Constants/ApiConstants'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import LoginMbRenta from '../../../Components/Login/LoginMbRenta'

const RentaDetalleShopify = (props) => {
  //const { id } = useParams()
  const navigate = useNavigate()
  const [_Producto, setProducto] = useState([])
  const valores = useLocation()
  var url = valores.search
  const SKU = url.slice(5)
  useEffect(() => {
    getRegistro()
  }, [])

  const getRegistro = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PRODUCTOS_DETAILS_BY_SKU, SKU)
      .then((response) => {
        setProducto(response)
        sessionStorage.setItem('sku', JSON.stringify(SKU))
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const rechazar = () => {
    sessionStorage.removeItem('sku', JSON.stringify(SKU))
    window.location.href = 'https://founditpreloved.com/'
  }
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  //abrir modal img
  const [IdImgPreview, setIdImgPreview] = useState()
  const [openModalImgPreview, setModalImgPreview] = useState(false)
  const handleOpenImgPreview = () => {
    setModalImgPreview(!openModalImgPreview)
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="fullContainerView">
        <div className="box-consigna-pro mt3">
          <div>
            <h1>Detalle de producto</h1>
          </div>
          {/*
                        {
                            "lngIdProducto": "a0d72e9b-535c-48b1-ad83-d277413b3cd8",
                            "txtFolio": null,
                            "txtSkuReg": "CVRTNS01029133",
                            "txtProductoH": "TENIS\r\n",
                            "txtProductoM": null,
                            "intIdTipoAd": "CONSIGNA",
                            "intIdProductos": null,
                            "intIdMarca": "CARVELA",
                            "intIdMaterial": "SINTETICO",
                            "intIdTallaTamano": "TALLA MARCADA 29",
                            "intIdColor": "BLANCO",
                            "bolEstuche": false,
                            "numCantidad": 1,
                            "txtComentarios": "prueba ",
                            "dblCostoInv": 18000,
                            "dblCostoTienda": 50000,
                            "dblCostoEmpe": 0,
                            "dblCostoAhorro": -32000,
                            "dblPrecioFinal": 14400,
                            "dblDiferenciaCosto": -3600,
                            "porMargenUtilidad": -25,
                            "porReferendoEmpe": 0,
                            "dblMontoReferendo": 0,
                            "porComConsigna": 20,
                            "dblCostoEnvio": 0,
                            "dblTotalaPagar": 17280,
                            "numPeridResguardo": 0,
                            "fecIniPublicacion": "01/01/0001",
                            "numPlazoDiasPubli": 0,
                            "fecVenPublicacion": "01/01/0001",
                            "numDiasPagRef": 0,
                            "fecPierdeEmpe": "01/01/0001",
                            "fecVenta": "01/01/0001",
                            "intIdStatusPagos": "REGISTRADO",
                            "lngIdNegocio": null,
                            "intIdStatusPro": "CONSIGNA",
                            "fecIngreso": "01/01/0001",
                            "dblMontoConsigna": 2880,
                            "bolAlmacenado": false,
                            "txtUrl": "https://dl.dropbox.com/s/6ppv3bfabmkv74k/tenisprueba.jpg?dl=0",
                            "imagenesPro": null
                        }
                    */}

          <div className="box-list-consigna-pro">
            <div className="w20 relative">
              <img className="img-consigna-pro" src={_Producto.txtUrl} />
              <div className="box-view-absolute">
                <IconButton
                  className="btn-blur"
                  onClick={function () {
                    setIdImgPreview(_Producto.txtUrl)
                    handleOpenImgPreview()
                  }}
                >
                  <RemoveRedEyeIcon sx={{ m: 0, p: 0, color: '#000000' }} />
                </IconButton>
              </div>
            </div>
            <div className="flex-column-start-between w80 pl1">
              <div className="flex-column-start w100">
                <div className="flex-between">
                  <span className="p20 bold upperCase">
                    <strong>Producto: </strong>{' '}
                    {_Producto.txtProductoH === null
                      ? _Producto.txtProductoM +
                        _Producto.intIdMarca +
                        ' ' +
                        _Producto.intIdMaterial
                      : _Producto.txtProductoH +
                        _Producto.intIdMarca +
                        ' ' +
                        _Producto.intIdMaterial}
                  </span>

                  <span className="p co-grey">
                    <strong>SKU: </strong> {_Producto.txtSkuReg}
                  </span>
                </div>
                <div className="flex-around border-bottom-2 mt1 ">
                  <span className="p w33 ">
                    <strong>Marca: </strong> {_Producto.intIdMarca}
                  </span>

                  <span className="p w33 ">
                    <strong>Color: </strong> {_Producto.intIdColor}
                  </span>
                  <span className="p w33 ">
                    <strong>Material: </strong> {_Producto.intIdMaterial}
                  </span>
                </div>
                <div className="flex-around border-bottom-2 mt1">
                  <span className="p w33 ">
                    <strong>Talla: </strong>
                    {_Producto.intIdTallaTamano}
                  </span>

                  <span className="p w33 ">
                    <strong>Estuche:</strong>{' '}
                    {_Producto.bolEstuche === false ? 'NO' : 'SÍ'}
                  </span>
                  <span className="p w33">
                    <strong>Total a pagar: </strong>
                    <NumberFormat
                      value={_Producto.dblTotalaPagar}
                      displayType={'text'}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </span>
                </div>
              </div>

              <div className="flex-start">
                <Button
                  className="btn-success mr1"
                  startIcon={<CheckCircleOutlineOutlinedIcon />}
                  onClick={function () {
                    handleOpen()
                  }}
                >
                  Rentar
                </Button>
                <Button
                  className="btn-fail mr1"
                  startIcon={<CancelOutlinedIcon />}
                  onClick={rechazar}
                >
                  No me interesa
                </Button>
              </div>
            </div>
          </div>
        </div>

        <LoginMbRenta
          open={open}
          onClose={handleOpen}
          getRegistros={getRegistro}
          setOpenLoadingScreen={props.setOpenLoadingScreen}
          setCloseLoadingScreen={props.setCloseLoadingScreen}
          setOpenSnackBar={props.setOpenSnackBar}
          setMessageSnackBar={props.setMessageSnackBar}
          setTypeSnackBar={props.setTypeSnackBar}
        />
        <PreviewImg
          IdImgPreview={IdImgPreview}
          openModalImgPreview={openModalImgPreview}
          onCloseModalImgPreview={handleOpenImgPreview}
        />
      </div>
    </ThemeProvider>
  )
}
export default RentaDetalleShopify
