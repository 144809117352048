import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { EMPEÑO_PAGO_DETALLES } from '../../../Constants/ApiConstants'
import { postAbonoCreate } from '../../../Actions/MundoBarterActions'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import FormatNumber from '../../Utils/FormatNumber'
import requests from '../../AxiosCalls/AxiosAuth'
import NumberFormat from 'react-number-format';

const AmountPay = (props) => {
	const dispatch = useDispatch()

	const handleNext = (data) => {
		dispatch(postAbonoCreate(data))
		props.handleNext()
	}
	
	const [amountPay, setAmountCodi] = useState({
		monto: 0,
		saldo: props.detalles.dblSaldoActual,
		refrendo: 0,
		fee: 0,
		totalPagar: 0,
		saldoPendiente: 0,
	})
	const getData = () => {
		var data = JSON.parse(sessionStorage.getItem('pay'));
		if(data !== null){
			setAmountCodi({
				monto: data.monto === null ? '' : data.monto,
				saldo: props.detalles.dblSaldoActual,
				refrendo: data.refrendo === null ? '' : data.monto,
				fee: data.fee === null ? '' : data.fee,
				totalPagar: data.totalPagar === null ? '' : data.totalPagar,
				saldoPendiente: data.saldoPendiente === null ? '' : data.saldoPendiente,
			})
		}		
	}
	useEffect(() => {    
        getData()
    }, []);

	const handelAmountCodi = (event) => {
		let fee = event.target.value * 0.03 + 3
		let totalPagar = Number(fee) + Number(event.target.value)
		let pendientepago = Number(props.detalles.dblSaldoActual) - Number(event.target.value)

		setAmountCodi({
			...amountPay, 
			monto: event.target.value,
			fee: fee,
			totalPagar: totalPagar,
			saldoPendiente: pendientepago
		})
	}

	return (
		<>
			<p className='text-center fs30 bold'>DETALLE DE PAGO</p>
			<div className='w90 center'>
				<div className='flex'>
					<div className='w70'>
						<p>
							Ingresa el monto que desea abonar para
							<strong> {props.data.intIdProductos} {props.data.intIdMarca}</strong>
						</p>
					</div>
					<div className='flex-end w30'>
						<spam className='mr1'>$</spam>

						<TextField
							name='monto'
							type='number'
							onChange={handelAmountCodi}
							onBlur={handelAmountCodi}
							onFocus={handelAmountCodi}
							value={amountPay.monto}
							fullWidth
							required
							label='Monto'
							variant='outlined'
						/>
						<spam className='ml1'>MXN</spam>
					</div>
				</div>
				<hr />
				<div>
					<div className='flex w100 line-height-0'>
						<p className='w70'>Saldo actual</p>
						<p className='w30'>
							<NumberFormat value={props.detalles.dblSaldoActual} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'$ '} suffix={'MXN'} />
						</p>
					</div>
					<div className='flex w100'>
						<p className='w70'>Total abonar</p>
						<p className='w30'>
							<NumberFormat value={amountPay.monto} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'$ '} suffix={'MXN'} />
						</p>
					</div>
					<div className='flex w100'>
						<p className='w70'>Refrendo</p>
						<p className='w30'>
							<NumberFormat value={amountPay.refrendo} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'$ '} suffix={'MXN'} />
						</p>
					</div>

					<div className='flex w100'>
						<p className='w70'>Cargo por servicio</p>
						<p className='w30'>
							<NumberFormat value={amountPay.fee} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'$ '} suffix={'MXN'} />
						</p>
					</div>

					<div className='flex w100'>
						<p className='w70'>Total a pagar</p>
						<p className='w30'>
							<NumberFormat value={amountPay.totalPagar} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'$ '} suffix={'MXN'} />
						</p>
					</div>

					<div className='flex w100'>
						<p className='w70'>Pendiente por pagar</p>
						<p className='w30'>
							<NumberFormat value={amountPay.saldoPendiente} displayType={'text'} fixedDecimalScale={true} decimalScale={2} thousandSeparator={true} prefix={'$ '} suffix={'MXN'} />
						</p>
					</div>
				</div>
			</div>

			<div className='flex '>
				<Button
					className='btn-pay'
					disabled={amountPay.length === 0 ? true : false}
					//onClick={handleNext}
					onClick={function () {
						handleNext(amountPay)
					}}
				>
					Realizar pago
				</Button>
			</div>
		</>
	)
}

export default AmountPay
