import React, { useState } from 'react'
import "../../Styles/StylesMB.css";
import iconEco from '../../Images/MB-Consigna/ecologia-icon.png';
import RegisterMb from '../../Components/Login/RegisterMb'

const MBIntercambioView = (props) => {

	//============== OPEN REGISTRO=========================
	const [openSingUp, setOpenSingUp] = useState(false)
	const handleOpenSingUp = () => {
		setOpenSingUp(!openSingUp)
	}

	const handleCloseSingUp = () => {
		setOpenSingUp(false)
	}

	return (
		<div>
			{/* SECTION 1: BANNER + TÍTULO*/}
			<div id='banner-consigna' className='img-banner img-banner-intercambia'>
				<div><h2 className='title-banner'>INTERCAMBIA</h2></div>
			</div>
			{/* SECTION 2: ÍCONO + TEXTO*/}
			<div id='icon-txt-consigna' className='text-center w80 center pt8 pb8'>
				<img src={iconEco}/>
				<p className='txt'>Dale una segunda vida a los artículos que más amaste y únete a nuestro movimiento de moda sustentable en el cual apoyamos al planeta mientras le das un respiro a tu closet.</p>
			</div>
			{/* SECTION 3: IMAGEN + TÍTULO + DESCRIPCIÓN*/}
			<div id='desc-consigna' className='flex flex-wrap align-i-center pb8'>
				<div className='w50 img-col img-col-intercambia'></div>
				<div className='w50 text-center'>
					<div className='w80 center'>
						<h3 className='title'>NUESTRO TRUEQUE DE LUJO</h3>
						<p className='txt'>Intercambia tu artículo pre-amado favorito por alguno de nuestra selección. Así de sencillo es nuestro intercambio. Haces detox de tu closet de la manera más cool y estrenas nuevos artículos.</p>
					</div>
				</div>
			</div>
			{/* SECTION 4: CTA*/}
			<div id='cta-consigna' className='flex flex-wrap align-i-center fondo-cta'>
				<div className='w50'>
					<div className='w80 ml6' id='contenido-cta'>
						<h3 className='title-cta'>INTERCAMBIA</h3>
						<p className='txt-cta'>Regístrate o inicia sesión y comienza a vivir la nueva forma de moda sustentable</p>
						<button 
							className='btn-oscuro'
							onClick={function () {
								handleOpenSingUp()
							}}
						>COMIENZA AHORA</button>
					</div>
				</div>
				<div className='w50 img-col img-cta'></div>
			</div>
			<RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} />
		</div>
	)
}

export default MBIntercambioView
