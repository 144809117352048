import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../../Styles/Theme';
import {Chip, Card, CardContent, CardMedia, IconButton, Typography, Box } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import '../../../Styles/GeneralStyles.css';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {CLIENTES_CONSIGNAS} from '../../../Constants/routesConstants'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ConsignaTransito = () => {
    const navigate = useNavigate();

    return (
        <>
            <ThemeProvider theme={Theme}>
                <Grid>
                    <Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
                        <Grid Grid item xs={4} sm={4} md={4} lg={12} className='d-flex align-center mb-30'>
                        <Button
                                className='back-gradient-4 bord-radius-10 shadow font-letter font-light'
                                startIcon={<ArrowBackIcon />}
                                onClick={function () {
                                    navigate(CLIENTES_CONSIGNAS)
                                }}
                            >
                                Regresar
                            </Button>
                            <Chip icon={<MonetizationOnIcon />} label='Consigna / En tránsito' color='primary' className='back-transparent font-letter font-barter font-20' />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center' spacing={2} elevation={12}>
                        <Grid item xs={12}>
                        <Stack sx={{ width: '100%' }} spacing={2}>

      <Alert severity="warning">No tienes ventas en tránsito</Alert>

    </Stack>
                        </Grid>
                    </Grid>

                </Grid>
            </ThemeProvider>
        </>
    )
}

export default ConsignaTransito