import { useState } from 'react'
import {
    PAY_CREATE,
    GET_PAY_REQUEST,
    GET_PAY_FAIL,
    REFRENDO_CREATE,
    LIQUIDAR_EMP_CREATE,
    PER_SHP_CREATE,
    PER_SHP_SUCCESS,
    PER_SHP_FAIL,
    PAYMENTS_METHODS_REQUEST,
    PAYMENTS_METHODS_SUCCESS,
    PAYMENTS_METHODS_FAIL
} from '../Constants/MundoBarterConstants'

import { GET_PAYMENTS_METHODS } from '../Constants/ApiConstants'

import requests from '../Components/AxiosCalls/AxiosAuth'

export const postAbonoCreate = (data) => (dispatch, getState) => {
    try {
        dispatch({
            type: PAY_CREATE,
            payload: data
        })
        sessionStorage.setItem('pay', JSON.stringify(data))
        const stado = getState()
        return stado.pay

    } catch (error) {
        console.log(error)
    }
}

export const getAbonoState = (data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PAY_REQUEST,
        })
        var pay = JSON.parse(sessionStorage.getItem('refrendo'))
        const {
            payload: { data }
        } = getState()

    } catch (error) {
        dispatch({
            type: GET_PAY_FAIL,
            payload: 'ERROR AL OBTENER STATE PAY',
        })
    }
}
export const postRefrendpCreate = (data) => (dispatch, getState) => {
    try {
        var montoRefrenar = (data * 20) / 100;
        var fee = ((montoRefrenar * 2.12) / 100) + 3;
        var totalPagar = montoRefrenar + fee;

        const Refrendo = {
            saldoActual: 0,
            refrendo: montoRefrenar,
            fee: fee,
            totalPagar: totalPagar
        }

        dispatch({
            type: REFRENDO_CREATE,
            payload: Refrendo
        })
        sessionStorage.setItem('refrendo', JSON.stringify(Refrendo))
        const stado = getState()
        return stado.refrendo

    } catch (error) {
        console.log(error)
    }
}

export const postLiquidarEmpCreate = (data) => (dispatch, getState) => {
    try {
        var fee = ((data * 2.12) / 100) + 3;
        var totalPagar = data + fee;

        const Liquidar = {
            fee: fee,
            total: totalPagar,
            saldo: data
        }
        dispatch({
            type: LIQUIDAR_EMP_CREATE,
            payload: Liquidar
        })
        sessionStorage.setItem('liquidar', JSON.stringify(Liquidar))
        const stado = getState()
        return stado.liquidar
    } catch (error) {

        console.log(error)
    }
}

export const postInitiateCheckout = (props) => async (dispatch) => {
    try {
        const paymentMethodsResponse = dispatch(sendPostRequest(props))


        dispatch({
            type: PER_SHP_CREATE,
            payload: paymentMethodsResponse
        })

    } catch (error) {
        dispatch({
            type: PER_SHP_FAIL,
            payload: error.response && error.data ? error.data : error.message,
        })
    }

}

export const sendPostRequest = (props) => async (dispatch) => {
    props.setOpenLoadingScreen()
    dispatch({
        type: PAYMENTS_METHODS_REQUEST,
    })
    const result = await requests.post(GET_PAYMENTS_METHODS)
        .then((response) => {
            dispatch({
                type: PAYMENTS_METHODS_SUCCESS,
                payload: response,
            })
            props.setCloseLoadingScreen()
        })
        .catch((error) => {
            dispatch({
                type: PAYMENTS_METHODS_FAIL,
                payload: error.response && error.data ? error.data : error.message,
            })
            props.setTypeSnackBar('warning')
            props.setMessageAlertWarning(error.data.mensaje)
            props.setOpenAlertWarning(true)
            props.setCloseLoadingScreen()
        })

    return result
}
