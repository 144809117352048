import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { ThemeProvider } from '@mui/material/styles'
import Theme from '../../../Styles/Theme'
import {
	Chip,
	Card,
	CardContent,
	CardMedia,
	IconButton,
	Typography,
	Box,
} from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import CategoryIcon from '@mui/icons-material/Category'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import NumberFormat from 'react-number-format'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CLIENTES_CONSIGNAS_PIEZAS } from '../../../Constants/routesConstants'
import PreviewImg from '../../../Components/PreviewImg'
import Avatar from '@mui/material/Avatar'
import iconConsigna from '../../../Images/MB-Consigna/Consigna_Icon.png'

const ConsignaTransito = (props) => {
	const navigate = useNavigate()
	const [IdImgPreview, setIdImgPreview] = useState()
	const [openModalImgPreview, setModalImgPreview] = useState(false)
	const handleOpenImgPreview = () => {
		setModalImgPreview(!openModalImgPreview)
	}

	const _Consignas = [
		{
			marca: 'Balenciaga',
			material: 'Sintetico',
			fechaIngreso: '01/01/2023',
			fechaRechazo: '01/02/2023',
			explicacion:
				'Lo sentimos en esta ocasión tu articulo fue rechazado por que no paso el proceso de curaduría.',
			img: 'https://images.unsplash.com/photo-1613987876445-fcb353cd8e27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
		},
		{
			marca: 'Fosil',
			material: 'Plastico',
			fechaIngreso: '01/01/2023',
			fechaRechazo: '01/02/2023',
			explicacion:
				'Lo sentimos en esta ocasión tu articulo fue rechazado por no contar con las condiciones mínimas del estado de conservación de tu articulo.',
			img: 'https://images.unsplash.com/photo-1622434641406-a158123450f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=404&q=80',
		},
	]
	return (
		<>
			<div>
				<div className='containerViewUser'>
					<div className='flex-start w100'>
						{/* <Chip
							icon={<CategoryIcon />}
							label='Mis consignas / Rechazadas'
							color='primary'
							className='back-transparent font-letter font-barter font-20'
						/> */}
						<Chip
							sx={{ marginTop: 2 }}
							// icon={<CategoryIcon />}
							avatar={
								<Avatar
									alt='Natacha'
									src={iconConsigna}
									style={{ width: 40, height: 40 }}
								/>
							}
							label='Mis consignas / Rechazadas'
							// color='primary'
							className='back-transparent font-letter font-barter font-20'
						/>
					</div>

					<div className='box-consigna-pro mt3'>
						{_Consignas.map((row, index) => (
							<div className='box-list-consigna-pro'>
								<div className='w20 relative'>
									<img className='img-consigna-pro' src={row.img} alt='' />
									<div className='box-view-absolute'>
										<IconButton
											className='btn-blur'
											onClick={function () {
												setIdImgPreview(row.img)
												handleOpenImgPreview()
											}}
										>
											<RemoveRedEyeIcon sx={{ m: 0, p: 0, color: '#000000' }} />
										</IconButton>
									</div>
								</div>
								<div className='flex-column-start-between w80 pl1'>
									<div className='w100'>
										<div className='flex-between'>
											<span className='p20 upperCase ProductTitleMarca'>
												{row.marca} - {row.material}
												{/* {row.intIdTallaTamano} */}
												<span className='mx1'> - </span>
												<span className='p ProductTitleMarca'>Consigna</span>
											</span>

											<div>
												<span className='p co-grey'>{row.fechaIngreso}</span>
												<TrendingFlatIcon sx={{ fontSize: 20, mx: 1 }} />
												<span className='p co-grey'>{row.fechaRechazo}</span>
											</div>
										</div>

										<div className='flex-column-start'>
											<span className='p ProductTitleDescription'>
												Explicación del por que no se acepto: {row.explicacion}
											</span>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<PreviewImg
					IdImgPreview={IdImgPreview}
					openModalImgPreview={openModalImgPreview}
					onCloseModalImgPreview={handleOpenImgPreview}
				/>
			</div>
			{/* <ThemeProvider theme={Theme}>
				<Grid>
					<Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
						<Grid
							Grid
							item
							xs={4}
							sm={4}
							md={4}
							lg={12}
							className='d-flex align-center mb-30'
						>
							<Button
								className='back-gradient-4 bord-radius-10 shadow font-letter font-light'
								startIcon={<ArrowBackIcon />}
								onClick={function () {
									navigate(CLIENTES_CONSIGNAS_PIEZAS)
								}}
							>
								Regresar
							</Button>
							<Chip
								icon={<MonetizationOnIcon />}
								label='Mis consignas / Rechazada'
								color='primary'
								className='back-transparent font-letter font-barter font-20'
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						spacing={2}
						elevation={12}
					>
						<Grid item xs={4}>
							<Card sx={{ display: 'flex', height: 210 }}>
								<CardMedia
									component='img'
									sx={{ width: 151 }}
									image='https://images.unsplash.com/photo-1613987876445-fcb353cd8e27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
									alt='Live from space album cover'
								/>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<CardContent sx={{ flex: '1 0 auto' }}>
										<Typography component='div' variant='h5'>
											Balenciaga
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											SKU: RLH0125460x
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Precio: $ 25,350.00
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Ganancia est. $ 7,500.00
										</Typography>
										<Button
											className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
											onClick={function () {
												// navigate(CLIENTES_SALES_EN_VENTA)
											}}
										>
											Ofrecer descuento
										</Button>
									</CardContent>
								</Box>
							</Card>
						</Grid>

						<Grid item xs={4}>
							<Card sx={{ display: 'flex', height: 210 }}>
								<CardMedia
									component='img'
									sx={{ width: 151 }}
									image='https://images.unsplash.com/photo-1622434641406-a158123450f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=404&q=80'
									alt='Live from space album cover'
								/>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<CardContent>
										<Typography component='div' variant='h5'>
											Fosil
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											SKU: LBG2548244
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Precio: $ 15,350.00
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Ganancia est. $ 3,500.25
										</Typography>
										<Button
											className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
											onClick={function () {}}
										>
											Ofrecer descuento
										</Button>
									</CardContent>
								</Box>
							</Card>
						</Grid>
						<Grid item xs={4}></Grid>
					</Grid>
				</Grid>
			</ThemeProvider> */}
		</>
	)
}

export default ConsignaTransito
