import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import { Chip, Alert, Fab } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import '../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import HelpIcon from '@mui/icons-material/Help'
import CategoryIcon from '@mui/icons-material/Category'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { ENVIO_EVALUACION } from '../../Constants/routesConstants'
// import { CLIENTES_PERFIL_COMPLETO } from '../../../Constants/ApiConstants'

// import ConsignasHelp from './ConsignasHelp'
import Avatar from '@mui/material/Avatar'
import iconConsigna from '../../Images/MB-Consigna/Consigna_Icon.png'
import FloatinfWhatsApp from '../../Components/FloatingWhatsApp/FloatinfWhatsApp'

import imgPropuestas from '../../Images/MB-Consigna/Propuestas.jpg'
import imgEnVenta from '../../Images/MB-Consigna/ArticulosVenta.jpg'
import imgVendidas from '../../Images/MB-Consigna/Vendidas2.jpg'
import imgRechazados from '../../Images/MB-Consigna/Rechazados.jpg'
import requests from '../../Components/AxiosCalls/AxiosAuth'
import { useDispatch, useSelector } from 'react-redux'

import {
	ENVIO_DETAIL,
	ENVIOS_EN_TRANSITO,
	ENVIOS_RECIBIDOS,
} from '../../Constants/routesConstants'

const EnviosView = () => {
	const navigate = useNavigate()

	return (
		<div className='containerViewUser py4  paddingTablets'>
			{/* {_Complet === false ? (
				<Alert
					severity='warning'
					sx={{
						display: 'flex',
						justifyContent: 'center',
						fontWeight: 'bold',
						fontSize: '16px',
						mb: 1,
					}}
					action={
						<Button
							color='inherit'
							size='small'
							onClick={function(){
								gtmGoTo_Perfil_Log()
								navigate(CLIENTES_PROFILE)
							}}
						>
							Completar Perfil
						</Button>
					}
				>
					Es necesario completar tu perfil, para administrar tus ventas y tus
					empeños.
				</Alert>
			) : null} */}

			{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
			<div className='flex-start w90 pb2 pl1'>
				<div className='chip-breadcrumbs'>
					<span className='letter-breadcrumbs'>E</span>
				</div>

				<div role='presentation' className='ml2'>
					<Breadcrumbs aria-label='breadcrumb' separator='›'>
						<Link underline='hover' className='breadcrumb-url bold'>
							Mis envíos /
						</Link>
					</Breadcrumbs>
				</div>
			</div>

			<Grid container spacing={1}>
				<Grid item xs={12} md={6} lg={6} className='flex'>
					<div
						className='card'
						style={{
							backgroundImage: `url(${imgPropuestas})`,
							// backgroundImage: `url("https://images.unsplash.com/photo-1591892150204-2f872745bc4b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80")`,
						}}
					>
						<div className='card-content'>
							<h2 className='card-title HelveticaNeue'>Estatus de envíos</h2>
							<p className='card-body AvenirLight'>
								En este apartado podrás consultar el estatus del envio de tus
								piezas a evaluar y marcar como enviado cuando entregues a la
								paquetería.
							</p>
							<a
								className='buttonn AvenirLight'
								onClick={function () {
									// if (_Complet !== false) {
									//gtmGoTo_Propuestas_Log()
									navigate(ENVIO_EVALUACION)
									// } else {
									// 	props.setTypeSnackBar('warning')
									// 	props.setMessageAlertWarning(
									// 		'Debes completar tu perfil, para administrar tus ventas y tus empeños.'
									// 	)
									// 	props.setOpenAlertWarning(true)
									// }
								}}
							>
								Ver envíos
							</a>
						</div>
					</div>
					{/* <div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://images.unsplash.com/photo-1591892150204-2f872745bc4b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80'
							/>
						</div>

						<div className='card-center'>
							<p className='bold'>PROPUESTAS RECIBIDAS</p>
							<p className='w80 center text-center'>
								En este apartado podrás consultar el importe que te hemos
								propuesto por la consigna de tu artículo Preloved.
							</p>
							<div className='mt-10'>
								<Button
									className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
									onClick={function () {
										navigate(CLIENTES_CON_PROPUESTAS_VIEW)
									}}
								>
									Ver piezas
								</Button>
							</div>
						</div>
					</div> */}
				</Grid>
				<Grid item xs={12} lg={6} md={6} className='flex'>
					{/* <div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://images.unsplash.com/photo-1543163521-1bf539c55dd2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80'
							/>
						</div>

						<div className='card-center'>
							<p className='bold'>MIS ARTÍCULOS EN VENTA</p>
							<p className='w80 center text-center'>
								Aquí podrás dar seguimiento a los artículos que están ya en
								venta, podrás ver el estatus de cada articulo así como
								aplicarles un descuento. En el dado caso que tengas tu articulo
								contigo, si sales de viaje podrás notificarnos para que tu
								articulo quede en standby en nuestra plataforma.
							</p>
							<div className='mt1'>
								<Button
									className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
									onClick={function () {
										navigate(CLIENTES_CONSIGNAS_PIEZAS)
									}}
								>
									Ver articulos
								</Button>
							</div>
						</div>
					</div> */}
				</Grid>
			</Grid>
			{/* 
			<Fab
				sx={{ position: 'fixed', bottom: 100, right: 16 }}
				aria-label='Add'
				color='primary'
				onClick={function () {
					handleOpen()
				}}
			>
				<HelpIcon />
			</Fab> */}

			{/* <Fab
				sx={{ position: 'fixed', bottom: 80, right: 16 }}
				aria-label='Add'
				color='primary'
				onClick={function () {
					handleOpen()
				}}
			>
				kk
				<HelpIcon />
			</Fab> */}

			{/* <ConsignasHelp
				open={open}
				onClose={handleOpen}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/> */}
		</div>
	)
}

export default EnviosView
