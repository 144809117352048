/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
	Card,
	Grid,
	CardContent,
	CardMedia,
	CardActions,
	Button,
	Alert,
} from '@mui/material'
import { Chip } from '@mui/material'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import NumberFormat from 'react-number-format'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PreviewImg from '../../../Components/PreviewImg'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import EventIcon from '@mui/icons-material/Event'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { format } from 'date-fns'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { useSelector } from 'react-redux'
import { CITAS_MB_EMP_FORANEOS_BY_ID_CLIENTE } from '../../../Constants/ApiConstants'
import '../../../Styles/App/Apartado/Apartado.css'
import CitasEdit from '../../../Components/Preevaluaciones/Citas/CitasEdit'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Pagination from '@mui/material/Pagination'
import usePagination from '../../../Util/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'
import { CALENDAR_VIEW_CON_FOR_RMB } from '../../../Constants/routesConstants'
import { gtmGoTo_ReagendarCita_Log } from '../../../Constants/GoogleTagManager/gtmConstants'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'

const CitasEmpForaneos = (props) => {
	const [registroId, setRegistroId] = useState(null)
	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)
	const [_OcultarAgendar, setOcultarAgendar] = useState(false)
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin
	const navigate = useNavigate()

	const [_ListCitas, setListCitas] = useState([])
	const getListCitas = () => {
		props.setOpenLoadingScreen()
		requests
			.get(CITAS_MB_EMP_FORANEOS_BY_ID_CLIENTE, userInfo.intIdUsuario)
			.then((response) => {
				console.log(response)
				setListCitas(response)
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				setRegistroId(null)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getListCitas()
	}, [])

	const fechaActual = new Date()
	function diferencia(date1, date2) {
		const fechaCita = new Date(date2)
		const dia1UTC = Date.UTC(
			date1.getFullYear(),
			date1.getMonth(),
			date1.getDate()
		)
		const dia2UTC = Date.UTC(
			fechaCita.getFullYear(),
			fechaCita.getMonth(),
			fechaCita.getDate()
		)
		var dia = 1000 * 60 * 60 * 24
		var total = (dia2UTC - dia1UTC) / dia
		// setOcultarAgendar(true)
		if (total === 4 || total === 3 || total === 2 || total === 1) {
			return (
				<div className='card-media-box-citas-1'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
		if (total > 5) {
			return (
				<div className='card-media-box-citas-2'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
		if (total <= 0) {
			return (
				<div className='card-media-box-citas-3'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
	}
	function btReAgendar(date1, date2, lngIdCitas) {
		const fechaCita = new Date(date2)
		const dia1UTC = Date.UTC(
			date1.getFullYear(),
			date1.getMonth(),
			date1.getDate()
		)
		const dia2UTC = Date.UTC(
			fechaCita.getFullYear(),
			fechaCita.getMonth(),
			fechaCita.getDate()
		)
		var dia = 1000 * 60 * 60 * 24
		var total = (dia2UTC - dia1UTC) / dia
		// setOcultarAgendar(true)
		if (total === 4 || total === 3 || total === 2 || total === 1) {
			return (
				<Button
					className='btn-calendar mr1'
					startIcon={<EventIcon />}
					onClick={function () {
						setRegistroId(lngIdCitas)
						handleOpen()
						//navigate(CLIENTES_SALES + row.lngIdProducto)
					}}
				>
					Reagendar cita
				</Button>
			)
		}
		if (total > 5) {
			return (
				<Button
					className='btn-calendar mr1'
					startIcon={<EventIcon />}
					onClick={function () {
						setRegistroId(lngIdCitas)
						handleOpen()
						//navigate(CLIENTES_SALES + row.lngIdProducto)
					}}
				>
					Reagendar cita
				</Button>
			)
		}
		if (total <= 0) {
			return (
				<div></div>
				// <Button
				// 	style={{
				// 		display: _OcultarAgendar === true ? 'none' : null,
				// 	}}
				// 	className='btn-calendar mr1'
				// 	startIcon={<EventIcon />}
				// 	onClick={function () {
				// 		setRegistroId(lngIdCitas)
				// 		handleOpen()
				// 		//navigate(CLIENTES_SALES + row.lngIdProducto)
				// 	}}
				// >
				// 	Reagendar cita
				// </Button>
			)
		}
	}

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	//abrir modal img
	const [IdImgPreview, setIdImgPreview] = useState()
	const [openModalImgPreview, setModalImgPreview] = useState(false)
	const handleOpenImgPreview = () => {
		setModalImgPreview(!openModalImgPreview)
	}

	//paginacion
	let [page, setPage] = useState(1)
	const PER_PAGE = 2

	const count = Math.ceil(_ListCitas.length / PER_PAGE)
	const _DATA = usePagination(_ListCitas, PER_PAGE)

	const handleChange = (e, p) => {
		setPage(p)
		_DATA.jump(p)
	}

	return (
		<div>
			<div className='containerViewUser py4 paddingTablets' hidden={_Ocultar}>
				{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
				<div className='flex-start w90 pb2 pl1'>
					<div className='chip-breadcrumbs'>
						<span className='letter-breadcrumbs'>C</span>
					</div>

					<div role='presentation' className='ml2'>
						<Breadcrumbs aria-label='breadcrumb' separator='›'>
							<Link
								underline='hover'
								className='breadcrumb-url '
								onClick={() => {
									navigate(-1)
								}}
							>
								Citas
							</Link>
							<Link underline='hover' className='breadcrumb-url bold'>
								Mis Empeños
							</Link>
						</Breadcrumbs>
					</div>
				</div>

				{/*
                    {
                        "lngIdCitas": 82,
                        "fecCita": "2023-01-21T00:00:00",
                        "fecCitaStr": "21/01/2023",
                        "lngIdPropuestas": 10,
                        "fecRegCita": "20/01/2023",
                        "txtNombreCliente": "Giovanni Daniel Brito López",
                        "bolServicio": null,
                        "intIdTipoServicio": 2,
                        "txtTipoServicio": "Consigna",
                        "txtFolio": "BL-PRO-95",
                        "numValScore": null,
                        "txtUrlImgFrente": "https://dl.dropbox.com/s/t1zdfv841yunsnh/645262.jpg?dl=0",
                        "txtArticulo": "Zapatos",
                        "txtMarca": "COACH",
                        "txtOtraMarca": null,
                        "txtMaterial": "ALGODÓN",
                        "txtStatusCita": "Agendada"
                    }
                
                */}

				{_ListCitas.map((row, index) => (
					<div className='box-consigna-pro'>
						<div className='box-list-consigna-pro'>
							<div className='w20 relative'>
								{/* <img className='img-consigna-pro' src={row.txtUrlImgFrente} /> */}
								<div className='box-view-absolute'>
									{/* <IconButton
										className='btn-blur'
										onClick={function () {
											setIdImgPreview(row.txtUrlImgFrente)
											handleOpenImgPreview()
										}}
									>
										<RemoveRedEyeIcon
											className='RemoveEyeIconConsigna'
											sx={{ m: 0, p: 0, color: '#000000' }}
										/>
									</IconButton> */}
								</div>
							</div>
							<div className='flex-column-start-between w80 pl1'>
								<div className='flex-column-start w100'>
									<div className='flex-between'>
										<span className='titleConsignaPropuestas upperCase ProductTitleMarca'>
											{/* {row.txtArticulo} {row.txtMarca} de {row.txtMaterial} */}
											<span className='mx1'> </span>
											<span className='p titleConsignaPropuestas ProductTitleMarca'></span>
										</span>

										<span className='p textFechaConsignaPropuestas co-grey'>
											{diferencia(fechaActual, row.fecCita)}
										</span>
									</div>

									{/* <span className='p textFechaConsignaPropuestas ProductTitleDescription'>
									<strong>Cliente: &nbsp;</strong>
									{row.txtNombreCliente}
								</span> */}

									<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
										<strong>Estatus Cita: &nbsp;</strong>
										{row.txtStatusCita === null
											? 'Procesando'
											: row.txtStatusCita}
									</span>
									<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
										<strong>Hora de la cita: &nbsp;</strong>
										{row.hrCita.slice(0, 5)} hrs.
									</span>
								</div>

								<div className='flex-start mt1'>
									<Button
										style={{
											display: _OcultarAgendar === true ? 'none' : null,
										}}
										className='btn-calendar mr1'
										startIcon={<EventIcon />}
										onClick={function () {
											gtmGoTo_ReagendarCita_Log()
											navigate(
												`${CALENDAR_VIEW_CON_FOR_RMB}${row.lngCitasForaneas}`
											)
										}}
									>
										Reagendar cita
									</Button>
									<Button
										style={{
											display: _OcultarAgendar === true ? 'none' : null,
										}}
										className='btn-success mr1'
										startIcon={<VideoCameraFrontIcon />}
										onClick={function () {
											//gtmGoTo_ReagendarCita_Log()
											//navigate(`${CALENDAR_VIEW_RESCHEDULE}${row.lngIdCitas}`)
											window.open(row.txtUrlVideoLlamada, '_blank')
										}}
									>
										Ir a videollamada
									</Button>
								</div>
							</div>
						</div>
					</div>
				))}

				{/* <Pagination
					count={count}
					size='large'
					page={page}
					variant='outlined'
					shape='rounded'
					onChange={handleChange}
				/> */}

				{/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
			</div>
			<div hidden={_Ocultar2}>
				<div className='containerViewUser'>
					<Alert
						className='alert-no-data'
						icon={
							<InfoIcon fontSize='inherit' className='alert-icon-no-data' />
						}
					>
						Aún no cuentas con citas agendadas.
					</Alert>
				</div>
			</div>
			<CitasEdit
				registroId={registroId}
				getListCitas={getListCitas}
				open={open}
				onClose={handleOpen}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
				setMessageAlert={props.setMessageAlert}
				setOpenAlert={props.setOpenAlert}
				setMessageAlertWarning={props.setMessageAlertWarning}
				setOpenAlertWarning={props.setOpenAlertWarning}
			/>
			<PreviewImg
				IdImgPreview={IdImgPreview}
				openModalImgPreview={openModalImgPreview}
				onCloseModalImgPreview={handleOpenImgPreview}
			/>
		</div>
	)
}
export default CitasEmpForaneos