/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Grid from '@mui/material/Grid'
import { Chip } from '@mui/material'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

import {
	CLIENTES_RENT_WISH_LIST_PROD_VIEW,
	CLIENTES_RENT_PROD_VIEW,
} from '../../../Constants/routesConstants'

const ClientesRentasView = (props) => {
	const navigate = useNavigate()

	return (
		<div className='containerViewUser p2'>
			<Grid container spacing={1}>
				<Grid item xs={4} sm={8} md={12} lg={12}>
					<Chip
						icon={<ChangeCircleIcon />}
						label='Mis rentas'
						color='primary'
						className='back-transparent font-letter font-barter font-20'
					/>
				</Grid>
				<Grid item xs={6} lg={6} className='flex mt2'>
					<div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://antad.net/wp-content/uploads/2019/09/marcas-de-ropa-mas-valiosas-2017-retail.jpg'
							/>
						</div>

						<div className='card-center'>Mis rentas</div>
						<p className='card-text-desc'>
							Aquí podrás dar seguimiento a los articulos que has rentado
						</p>
						{/* <ul>
							<li className='card-text-desc1-5'>
								El estatus de revisión en el que se encuentra.
							</li>
							<li className='card-text-desc'>
								El precio de venta que fue asignado.
							</li>
							<li className='card-text-desc3'>
								Tu ganancia estimada por pieza.
							</li>
						</ul> */}

						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(CLIENTES_RENT_PROD_VIEW)
								}}
							>
								Ver articulos
							</Button>
						</div>
					</div>
				</Grid>
				<Grid item xs={6} lg={6} className='flex mt2'>
					<div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://images.unsplash.com/photo-1571859856639-d54ab2c18ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80'
							/>
						</div>

						<div className='card-center'>Lista de deseo</div>
						<p className='card-text-desc'>
							Aquí podrás ver los artículos que deseas rentar.
						</p>
						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(CLIENTES_RENT_WISH_LIST_PROD_VIEW)
								}}
							>
								Ver articulos
							</Button>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}
export default ClientesRentasView
