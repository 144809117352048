/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { useNavigate, useParams } from 'react-router-dom'

import {
	EMPEÑO_BY_ID,
	EMPEÑO_PAGO_DETALLES,
} from '../../../Constants/ApiConstants'

import requests from '../../AxiosCalls/AxiosCall'
import '../../../Styles/PayCodi.css'
import NumberFormat from 'react-number-format'
import AmountPayRefrendar from './PaymentComponentRefrendar/AmountPayRefrendar'
import PayRefrendar from './PaymentComponentRefrendar/PayRefrendar'
import SuccessfullRefrendar from './PaymentComponentRefrendar/SuccessfullRefrendar'

const PaymentFlowRefrendar = (props) => {
	const { id } = useParams()

	const [activeStep, setActiveStep] = useState(0)
	const [skipped, setSkipped] = useState(new Set())
	const [_Empeño, setEmpeño] = useState([])
	const [_Detalles, setDetalles] = useState([])
	let navigate = useNavigate()

	const isStepOptional = (step) => {
		return step === 1
	}

	const isStepSkipped = (step) => {
		return skipped.has(step)
	}

	const handleNext = () => {
		let newSkipped = skipped
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values())
			newSkipped.delete(activeStep)
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped(newSkipped)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleReset = () => {
		setActiveStep(0)
	}
	const getEmpeño = () => {
		props.setOpenLoadingScreen()
		requests
			.get(EMPEÑO_BY_ID, id)
			.then((response) => {
				calculo(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const [_Totales, setTotales] = useState({
		liquidacion: 0,
		regrendo: 0,
	})
	const calculo = (data) => {
		let pago = (data.dblImporteDesemp * 2.12) / 100 + 3
		let total = data.dblImporteDesemp + pago
		let re = (data.dblImporteDesemp * 20) / 100
		let pagos = (re * 2.12) / 100 + 3
		let pago2 = re + pagos
		setEmpeño(data)
		setTotales({ ..._Totales, liquidacion: total, regrendo: pago2 })
	}
	const getDetalles = () => {
		props.setOpenLoadingScreen()
		requests
			.get(EMPEÑO_PAGO_DETALLES, id)
			.then((response) => {
				console.log(response)
				setDetalles(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getEmpeño()
		getDetalles()
	}, [])

	//::::::::::::::::::::: Titulos de cada paso ::::::::::::::::::::::::::
	const steps = ['Monto ha refrendar', 'Pagar']
	//::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
	function getSteps(step, handleNext, handleBack) {
		switch (step) {
			case 0:
				return (
					<AmountPayRefrendar
						handleNext={handleNext}
						handleBack={handleBack}
						data={_Empeño}
						detalles={_Detalles}
						props={props}
					/>
				)
			case 1:
				return (
					<PayRefrendar
						handleNext={handleNext}
						handleBack={handleBack}
						data={_Empeño}
						detalles={_Detalles}
						props={props}
					/>
				)
			case 2:
				return (
					<SuccessfullRefrendar
						handleNext={handleNext}
						handleBack={handleBack}
						data={_Empeño}
						detalles={_Detalles}
					/>
				)
			default:
				throw new Error('Unknown step')
		}
	}

	//::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
	return (
		<div className='containerViewUser py4'>
			{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
			<div className='flex-start w100 pb2 pl1'>
				<div className='chip-breadcrumbs'>
					<span className='letter-breadcrumbs'>E</span>
				</div>

				<div role='presentation' className='ml2'>
					<Breadcrumbs aria-label='breadcrumb' separator='›'>
						<Link
							underline='hover'
							className='breadcrumb-url'
							onClick={() => {
								navigate(-3)
							}}
						>
							Empeño
						</Link>
						<Link
							underline='hover'
							className='breadcrumb-url '
							onClick={() => {
								navigate(-2)
							}}
						>
							Mis Refrendos
						</Link>
						<Link
							underline='hover'
							className='breadcrumb-url '
							onClick={() => {
								navigate(-1)
							}}
						>
							Realizar Pago
						</Link>
						<Link underline='hover' className='breadcrumb-url bold'>
							Refrendar
						</Link>
					</Breadcrumbs>
				</div>
			</div>

			<div className='container-one-pay'>
				<div className='box-proceso-producto-pay'>
					<div className='head-one-pay-section-1 '>
						<img className='img-proceso-producto' src={_Empeño.txtUrl} />
					</div>

					<div className='head-one-pay-section-2 '>
						<p className='title-head-one-pay'>
							{_Empeño.intIdProductos} {_Empeño.intIdMarca}
						</p>

						<div className='line-height-1'>
							<p className='subtitle-head-one-pay'>
								<strong>Saldo Total: </strong>
								<NumberFormat
									value={_Totales.liquidacion}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={'MXN'}
								/>
							</p>
							<p className='subtitle-head-one-pay'>
								<strong>Saldo pendiente: </strong>
								<NumberFormat
									value={_Totales.liquidacion}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={'MXN'}
								/>
							</p>
						</div>
					</div>
				</div>

				<Box sx={{ width: '100%', mt: 3 }}>
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
							const stepProps = {}
							const labelProps = {}

							if (isStepSkipped(index)) {
								stepProps.completed = false
							}
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							)
						})}
					</Stepper>
					{activeStep === steps.length ? (
						<div className='box-paycodi-stepper'>
							<Typography sx={{ mt: 2, mb: 1 }}>
								All steps completed - you&apos;re finished
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button onClick={handleReset}>Ver historia de pago</Button>
							</Box>
						</div>
					) : (
						<div className='box-paycodi-stepper'>
							{getSteps(activeStep, handleNext, handleBack, navigate)}

							{/* <div className='body-stepper'>Step {activeStep + 1}</div>
							<div className='footer-stepper'>
								<Button
									className='btn-stepper'
									disabled={activeStep === 0}
									onClick={handleBack}
								>
									Regresar
								</Button>
								<Button className='btn-stepper' onClick={handleNext}>
									{activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
								</Button>
							</div> */}
						</div>
					)}
				</Box>
			</div>
		</div>
	)
}

export default PaymentFlowRefrendar
