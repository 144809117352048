import React, { useEffect, useState } from 'react'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { CUESTIONARIO_DINAMICO_BY_ID } from '../../../Constants/ApiConstants'
import Theme from '../../../Styles/Theme'
import { ThemeProvider } from '@mui/styles'
import { Chip, Grid, Button, Slide, Box } from '@mui/material'
import QuizIcon from '@mui/icons-material/Quiz'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom'
import { PREVALUACIONES_DIN_VIEW } from '../../../Constants/routesConstants'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import BlCard from "../../../Util/BlCard";
import BlTitleCard from "../../../Util/BlTitleCard";
import Informacion from '../PreevaluacionMas2/Informacion'
import Articulos from '../PreevaluacionMas2/Articulos';
import PreviewImg from '../PreviewImg'
import Divider from '@mui/material/Divider';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PreevaluacionDetalles = (props) => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [_Registro, setRegistro] = useState([])
    const [_Articulos, setArticulos] = useState([])
    const [_RegistroId, setRegistroId] = useState()


    const getRegistros = () => {
        props.setOpenLoadingScreen()
        requests
            .get(CUESTIONARIO_DINAMICO_BY_ID, id)
            .then((response) => {
                setRegistro(response.datos)
                setArticulos(response.aritculos)
                props.setCloseLoadingScreen()
            })
            .catch((error) => {
                props.setCloseLoadingScreen()
            })
    }

    useEffect(() => {
        getRegistros()
    }, [])

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <ThemeProvider theme={Theme}>

            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={12} style={{ display: "flex", alignItems: "center" }}>
                        <Button
                            className='back-gradient-4 bord-radius-10 shadow font-letter font-light'
                            startIcon={<ArrowBackIosIcon />}
                            onClick={function () {
                                navigate(PREVALUACIONES_DIN_VIEW)
                            }}
                        >
                            Regresar
                        </Button>
                        {/* <Chip icon={<InventoryIcon />} label='Productos / Add' color='primary' className={classes.nombrepage}/> */}
                        <Chip icon={<QuizIcon />} label=' Preevaluaciones + 2' color='primary' className='back-transparent font-letter font-barter font-20 ml-10' />

                    </Grid>
                    <Grid item xs={12}>
                        <Informacion Registro={_Registro} />
                    </Grid>
				<Grid item xs={12}>
                <Articulos 
				 Registro={_Articulos}
				  />
                </Grid>
                {/* <Grid item xs={12} >				
				<PreviewImg 
						UrlImgFrente ={_Registro.txtUrlImgFrente}
						UrlImgAtras ={_Registro.txtUrlImgAtras}
						UrlImgIzqIzquierda ={_Registro.txtUrlImgIzqIzquierda}
						UrlImgDerecha ={_Registro.txtUrlImgDerecha}
						UrlImgDentro={_Registro.txtUrlImgDentro}
						UrlImgDetalle={_Registro.txtUrlImgDetalle}
						
						/> 				 
                </Grid> */}

                </Grid>
            </Box>

        </ThemeProvider>
    );
}

export default PreevaluacionDetalles;
