/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import Alert from '@mui/material/Alert'

import '../../Styles/App/Alert/alert.css'

const AlertWarning = (props) => {
	return (
		<div>
			<Snackbar
				open={props.openAlert}
				autoHideDuration={6000}
				onClose={props.handleCloseAlert}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert
					onClose={props.handleCloseAlert}
					className='alert-Warning'
					icon={
						<SentimentVeryDissatisfiedIcon
							fontSize='inherit'
							sx={{ fontSize: 30, color: '#f08080' }}
						/>
					}
				>
					{/* {props.MensajeTitle} */}
					{props.messageAlert}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default AlertWarning
