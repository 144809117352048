/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useNavigate, useParams } from 'react-router-dom'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import {
	EMPEÑO_BY_ID,
	EMPEÑO_PAGO_DETALLES,
	PORCENTAJE_LIST,
	PORCENTAJE_PORID,
	PRODUCTOS_DETAILS_BY_SKU,
	PRODUCTOS_DETAILS_BY_SKUP,
	GET_APA_DET_BY_ID,
	GET_PAGADO_APA,
} from '../../../../Constants/ApiConstants'

// import AmountPayLiq from '../Liquidar/PaymentComponentLiq/AmountPayLiq'
// import PayLiq from '../Liquidar/PaymentComponentLiq/PayLiq'
// import SuccessfullLiq from '../Liquidar/PaymentComponentLiq/SuccessfullLiq'
import requests from '../../../AxiosCalls/AxiosCall'
import '../../../../Styles/PayCodi.css'
import NumberFormat from 'react-number-format'
import AmountProximoPago from '../ProximosPagos/PaymentComponents/AmountProximoPago'
import CardPayProximoPago from '../ProximosPagos/PaymentComponents/CardPayProximoPago'
import PayProximoPago from '../ProximosPagos/PaymentComponents/PayProximoPago'
import { styled } from '@mui/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import Alert from '@mui/material/Alert'

import {
	TRANSEFER_OTROS_PAGOS,
	GET_LIST_PAG_APA_ID,
} from '../../../../Constants/ApiConstants'
import { CLIENTES_SECTION_PRO } from '../../../../Constants/routesConstants'

const Input = styled('input')({
	display: 'none',
})
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: 3,
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto #CBB878',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
	},
}))
const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#CBB878',
	backgroundImage:
		'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#CBB878',
	},
})
function BpCheckbox(props) {
	return (
		<Checkbox
			sx={{
				'&:hover': { bgcolor: 'transparent' },
			}}
			disableRipple
			color='default'
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			inputProps={{ 'aria-label': 'Checkbox demo' }}
			{...props}
		/>
	)
}

const PaymentFlowProximos = (props) => {
	const { id } = useParams()
	const [activeStep, setActiveStep] = useState(0)
	const [skipped, setSkipped] = useState(new Set())
	let navigate = useNavigate()
	const sku = JSON.parse(sessionStorage.getItem('sku'))
	const [_Producto, setProducto] = useState([])
	const [_Pagado, setPagado] = useState({})
	const [_OcultarFlujo, setOcultarFlujo] = useState(false)
	const [_checkEnvio, setcheckEnvio] = useState(false)
	const [_Abonos, setAbonos] = useState([])
	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)
	const [_Comprobante, setComprobante] = useState(null)
	const isStepOptional = (step) => {
		return step === 1
	}

	const isStepSkipped = (step) => {
		return skipped.has(step)
	}

	const handleNext = () => {
		let newSkipped = skipped
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values())
			newSkipped.delete(activeStep)
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped(newSkipped)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleReset = () => {
		setActiveStep(0)
	}

	const [_DataTransfer, setDataTransfer] = useState({
		LngIdApartado: '',
		DblMontoPago: 0,
		TxtUrlComprobante: '',
	})

	const getRegistro = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_APA_DET_BY_ID, id)
			.then((response) => {
				setProducto(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}
	const getPagado = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_PAGADO_APA, id)
			.then((response) => {
				setPagado(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}
	const [_Porcent, setPorcent] = useState([])
	const getPercent = () => {
		props.setOpenLoadingScreen()
		requests
			.get(PORCENTAJE_LIST)
			.then((response) => {
				setPorcent(response)
				sessionStorage.setItem('idPorc', JSON.stringify(1))

				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const handlePercent = (event) => {
		sessionStorage.setItem('idPorc', JSON.stringify(event.target.value))
		requests
			.get(PORCENTAJE_PORID + event.target.value)
			.then((response) => {
				props.setCloseLoadingScreen()
				sessionStorage.setItem('idPorc', JSON.stringify(event.target.value))
				let oper = (_Producto.dblPrecioFinal * response.dblPorApartado) / 100

				setPrecioFin(oper)
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}

	const getAbonos = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_LIST_PAG_APA_ID, id)
			.then((response) => {
				setAbonos(response)
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	const [precioFin, setPrecioFin] = useState()

	useEffect(() => {
		getRegistro()
		//getPercent()
		getPagado()
		getAbonos()
	}, [])

	//::::::::::::::::::::: Titulos de cada paso ::::::::::::::::::::::::::
	const steps = ['Monto a pagar', 'Pagar', 'Resumen de pago']
	//::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
	function getSteps(step, handleNext, handleBack) {
		switch (step) {
			case 0:
				return (
					<AmountProximoPago
						handleNext={handleNext}
						handleBack={handleBack}
						data={_Producto}
						precioFin={precioFin}
						props={props}
					/>

					// <AmountPayLiq
					// 	handleNext={handleNext}
					// 	handleBack={handleBack}
					// 	data={_Empeño}
					// 	detalles={_Detalles}
					// />
				)
			case 1:
				return (
					<PayProximoPago
						handleNext={handleNext}
						handleBack={handleBack}
						data={_Producto}
						precioFin={precioFin}
						props={props}
					/>

					// <PayLiq
					// 	handleNext={handleNext}
					// 	handleBack={handleBack}
					// 	data={_Empeño}
					// 	detalles={_Detalles}
					// />
				)
			case 2:
				return (
					<div>hola</div>
					// <SuccessfullLiq
					// 	handleNext={handleNext}
					// 	handleBack={handleBack}
					// 	data={_Empeño}
					// 	detalles={_Detalles}
					// />
				)
			default:
				throw new Error('Unknown step')
		}
	}

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	const handleComprobante = (e) => {
		setComprobante(e.target.files[0])
	}
	const adjuntarComprobante = () => {
		var dataIdPor = JSON.parse(sessionStorage.getItem('idPorc'))

		if (_Comprobante !== '' && _Comprobante !== null) {
			const formData = new FormData()
			formData.append('LngIdApartado', id === null ? '' : id)
			formData.append(
				'DblMontoPago',
				_DataTransfer.DblMontoPago === null ? '' : _DataTransfer.DblMontoPago
			)
			formData.append(
				'TxtUrlComprobante',
				_Comprobante === null ? '' : _Comprobante
			)
			props.setOpenLoadingScreen()
			requests
				.post(TRANSEFER_OTROS_PAGOS, formData)
				.then((response) => {
					props.setTypeSnackBar('success')
					props.setMessageSnackBar(response.mensaje)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
					navigate(CLIENTES_SECTION_PRO)
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageSnackBar(error.data.mensaje)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
		} else {
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar('Debes adjuntar el comprobante de pago')
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}

	const handlechecked = (event) => {
		if (event.target.checked === true) {
			setOcultarFlujo(true)
			setcheckEnvio(event.target.checked)
		} else {
			setOcultarFlujo(false)
			setcheckEnvio(event.target.checked)
		}
	}

	//::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
	return (
		<div>
			<div className='CardApartadoDetailPagos'>
				<div className='BodyCardApartadoDetail'>
					<div className='ApartadoDetailSection1'>
						<div
							className='BoxImgADetail'
							style={{
								backgroundImage: `url(${_Producto.txtUrl})`,
							}}
						></div>
					</div>
					<div className='ApartadoDetailSection2'>
						{/* ***************** */}
						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Producto:</span>{' '}
								{_Producto.txtCategoria} - {_Producto.txtMarca}
							</span>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>SKU:</span>{' '}
								{_Producto.txtSkuReg}{' '}
							</span>
						</div>
						{/* ***************** */}
						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>
									Precio Total del Artículo:
								</span>{' '}
								<NumberFormat
									value={_Producto.dblPrecioFinal}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
							</span>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Liquídalo antes del:</span>{' '}
								{_Producto.fecFinApartado}
							</span>
						</div>
						{/* ***************** */}
						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Saldo pendiente:</span>{' '}
								<NumberFormat
									value={_Producto.dblPrecioFinal - _Pagado.saldoPendiente}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
							</span>
						</div>
						<hr className='Dvividerhr' />
						<div className='container-Checkbox-ApatadoPagos'>
							<BpCheckbox checked={_checkEnvio} onChange={handlechecked} />
							<span className='bold '>
								¿Deseas realizar el pago mediante una transeferencia bancaria?
							</span>
						</div>
					</div>
				</div>
			</div>
			{_Abonos.length >= 1 ? (
				<div className='CardApartadoDetailPagos' hidden={_Ocultar}>
					<div style={{ padding: '20px 20px' }}>
						<div className='box-head-empenoDetailPay'>
							<div className='w40 empenoDetailPay-title ml1'>Folio</div>
							<div className='w20 empenoDetailPay-title text-center'>Fecha</div>
							<div className='w20 empenoDetailPay-title text-center'>
								Tipo de pago
							</div>
							<div className='w20 empenoDetailPay-title text-center'>Total</div>
						</div>

						{_Abonos.map((row, index) => (
							<div className='box-empenoDetailPay' key={index}>
								<div className='w40 empenoDetailPay-Subtitle'>
									{row.txtFolio}
								</div>
								<div className='w20 empenoDetailPay-Subtitle text-center'>
									{row.fecPagoApartado}
								</div>
								<div className='w20 empenoDetailPay-Subtitle text-center'>
									{row.tipoPago}
								</div>

								<div className='w20 empenoDetailPay-Subtitle text-center'>
									<NumberFormat
										value={row.dblMontoPago}
										displayType={'text'}
										fixedDecimalScale={true}
										decimalScale={2}
										thousandSeparator={true}
										prefix={'$ '}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className='box-proceso-producto-list' hidden={_Ocultar2}>
					<Alert severity='info'>
						Aún no cuentas con pagos realizados sobre este artículo.
					</Alert>
				</div>
			)}

			{_OcultarFlujo === false ? (
				<div className='CardApartadoDetailPagosContainerStepper'>
					<Stepper
						activeStep={activeStep}
						sx={{
							'& .Mui-active': {
								'&.MuiStepIcon-root': {
									fill: '#cbb878',
								},
								'& .MuiStepConnector-line': {
									borderColor: '#cbb878',
								},
							},

							'& .Mui-completed': {
								'&.MuiStepIcon-root': {
									color: '#cbb878',
								},
								'& .MuiStepConnector-line': {
									borderColor: '#cbb878',
								},
							},
						}}
					>
						{steps.map((label, index) => {
							const stepProps = {}
							const labelProps = {}

							if (isStepSkipped(index)) {
								stepProps.completed = false
							}
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							)
						})}
					</Stepper>
					{activeStep === steps.length ? (
						<div className='box-paycodi-stepper'>
							<Typography sx={{ mt: 2, mb: 1 }}>
								All steps completed - you&apos;re finished
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button onClick={handleReset}>Ver historia de pago</Button>
							</Box>
						</div>
					) : (
						<>{getSteps(activeStep, handleNext, handleBack, navigate)}</>
					)}
				</div>
			) : (
				<div className='CardApartadoDetailPagosContainerStepper'>
					<div className='CardApartadoDetailPagosCardStepper'>
						<div className='BodyCardApartadoDetail'>
							<div className='ApartadoDetailSection2'>
								<span className='bold text-center'>
									Adjunta el comprobante de la transeferencia realizada, te
									enviaremos un correo con la confirmación del pago.
								</span>
								<hr className='Dvividerhr' />
								{/* ***************** */}
								<div className='containerFlexBetween'>
									<span className='bold upperCase'>
										<span className='subtitlesdetail'>Banco:</span> Kuspit
									</span>
									<span className='bold upperCase'>
										<span className='subtitlesdetail'>Clabe:</span>{' '}
										653180003810159114
									</span>
									<span className='bold upperCase'>
										<span className='subtitlesdetail'>Beneficiario:</span>{' '}
										Articulos de lujo SA de CV
									</span>
								</div>
								<NumberFormat
									required
									value={_DataTransfer.DblMontoPago}
									customInput={TextField}
									thousandSeparator={true}
									fixedDecimalScale={true}
									decimalScale={2}
									prefix={'$'}
									fullWidth
									onValueChange={(values) => {
										setDataTransfer({
											..._DataTransfer,
											DblMontoPago: values.floatValue,
										})
									}}
									name='DblMontoPago'
								/>

								<Box>
									<label htmlFor='txtUrlComprobanteGuia'>
										<Input
											id='txtUrlComprobanteGuia'
											type='file'
											onChange={handleComprobante}
										/>
										<Button
											startIcon={<CloudUploadIcon />}
											variant='contained'
											className='btn-fail mr1'
											component='span'
										>
											Adjuntar comprobante pago
										</Button>
									</label>
									<Typography variant='caption' gutterBottom component='div'>
										{_Comprobante !== null ? _Comprobante.name : 'sin archivo'}
									</Typography>
								</Box>
								<Button
									className='btn-pay'
									onClick={function () {
										adjuntarComprobante()
									}}
								>
									Envíar
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
		// <div className='containerViewUser'>
		// 	<div className='w70 pt5 pb2'>
		// 		<div>
		// 			<div className='box-proceso-producto-pay'>
		// 				<div className='w20 p2'>
		// 					<img className='img-proceso-producto' src={_Producto.txtUrl} />
		// 				</div>
		// 				<div className='flex-column-start w80 line-height-0'>
		// 					<div>
		// 						<div>
		// 							<p className='title-proceso-producto'>
		// 								{_Producto.txtCategoria} - {_Producto.txtMarca}
		// 							</p>
		// 							<div className='flex-start'>
		// 								<p>
		// 									<strong>Precio Total del Artículo: </strong>
		// 									<NumberFormat
		// 										value={_Producto.dblPrecioFinal}
		// 										displayType={'text'}
		// 										fixedDecimalScale={true}
		// 										decimalScale={2}
		// 										thousandSeparator={true}
		// 										prefix={'$ '}
		// 										suffix={' MXN'}
		// 									/>
		// 								</p>
		// 								&nbsp; &bull; &nbsp;
		// 								<p className='d-contents'>
		// 									<strong>Liquídalo antes del: </strong>{' '}
		// 									{_Producto.fecFinApartado}
		// 								</p>
		// 							</div>
		// 							<div className='flex-start'>
		// 								<p>
		// 									<strong>Saldo pendiente: </strong>
		// 									<NumberFormat
		// 										value={
		// 											_Producto.dblPrecioFinal - _Pagado.saldoPendiente
		// 										}
		// 										displayType={'text'}
		// 										fixedDecimalScale={true}
		// 										decimalScale={2}
		// 										thousandSeparator={true}
		// 										prefix={'$ '}
		// 										suffix={' MXN'}
		// 									/>
		// 								</p>
		// 							</div>
		// 							<div className='flex-start'>
		// 								<p>
		// 									<strong>SKU: </strong> {_Producto.txtSkuReg}
		// 								</p>
		// 							</div>
		// 						</div>
		// 						<div className='container-Checkbox-Profile'>
		// 							<BpCheckbox checked={_checkEnvio} onChange={handlechecked} />
		// 							<p>
		// 								¿Deseas realizar el pago mediante una transeferencia
		// 								bancaria?
		// 							</p>
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 		{_Abonos.length >= 1 ? (
		// 			<div className='empenoDetailPay' hidden={_Ocultar}>
		// 				<div className='box-head-empenoDetailPay'>
		// 					<div className='w40 empenoDetailPay-title ml1'>Folio</div>
		// 					<div className='w20 empenoDetailPay-title text-center'>Fecha</div>
		// 					<div className='w20 empenoDetailPay-title text-center'>
		// 						Tipo de pago
		// 					</div>
		// 					<div className='w20 empenoDetailPay-title text-center'>Total</div>
		// 				</div>

		// 				{_Abonos.map((row, index) => (
		// 					<div className='box-empenoDetailPay' key={index}>
		// 						<div className='w40 empenoDetailPay-Subtitle'>
		// 							{row.txtFolio}
		// 						</div>
		// 						<div className='w20 empenoDetailPay-Subtitle text-center'>
		// 							{row.fecPagoApartado}
		// 						</div>
		// 						<div className='w20 empenoDetailPay-Subtitle text-center'>
		// 							{row.tipoPago}
		// 						</div>

		// 						<div className='w20 empenoDetailPay-Subtitle text-center'>
		// 							<NumberFormat
		// 								value={row.dblMontoPago}
		// 								displayType={'text'}
		// 								fixedDecimalScale={true}
		// 								decimalScale={2}
		// 								thousandSeparator={true}
		// 								prefix={'$ '}
		// 							/>
		// 						</div>
		// 					</div>
		// 				))}
		// 			</div>
		// 		) : (
		// 			<div className='box-proceso-producto-list' hidden={_Ocultar2}>
		// 				<Alert severity='info'>
		// 					Aún no cuentas con pagos realizados sobre este artículo.
		// 				</Alert>
		// 			</div>
		// 		)}

		// 		{_OcultarFlujo === false ? (
		// 			<Box sx={{ width: '100%', mt: 3 }}>
		// 				<Stepper activeStep={activeStep}>
		// 					{steps.map((label, index) => {
		// 						const stepProps = {}
		// 						const labelProps = {}

		// 						if (isStepSkipped(index)) {
		// 							stepProps.completed = false
		// 						}
		// 						return (
		// 							<Step key={label} {...stepProps}>
		// 								<StepLabel {...labelProps}>{label}</StepLabel>
		// 							</Step>
		// 						)
		// 					})}
		// 				</Stepper>
		// 				{activeStep === steps.length ? (
		// 					<div className='box-paycodi-stepper'>
		// 						<Typography sx={{ mt: 2, mb: 1 }}>
		// 							All steps completed - you&apos;re finished
		// 						</Typography>
		// 						<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
		// 							<Box sx={{ flex: '1 1 auto' }} />
		// 							<Button onClick={handleReset}>Ver historia de pago</Button>
		// 						</Box>
		// 					</div>
		// 				) : (
		// 					<div className='box-paycodi-stepper'>
		// 						{getSteps(activeStep, handleNext, handleBack, navigate)}

		// 						{/* <div className='body-stepper'>Step {activeStep + 1}</div>
		// 					<div className='footer-stepper'>
		// 						<Button
		// 							className='btn-stepper'
		// 							disabled={activeStep === 0}
		// 							onClick={handleBack}
		// 						>
		// 							Regresar
		// 						</Button>
		// 						<Button className='btn-stepper' onClick={handleNext}>
		// 							{activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
		// 						</Button>
		// 					</div> */}
		// 					</div>
		// 				)}
		// 			</Box>
		// 		) : (
		// 			<div className='box-paycodi-stepper'>
		// 				<p>
		// 					Adjunta el comprobante de la transeferencia realizada, te
		// 					enviaremos un correo con la confirmación del pago.
		// 				</p>
		// 				<p>
		// 					<strong>Banco:</strong> Kuspit
		// 				</p>
		// 				<p>
		// 					<strong>Clabe:</strong> 653180003810159114
		// 				</p>
		// 				<p>
		// 					<strong>Beneficiario:</strong> Articulos de lujo SA de CV
		// 				</p>
		// 				<p>
		// 					<div className='flex-column-start w90 line-height-0'>
		// 						<div className='flex-start w30'>
		// 							<NumberFormat
		// 								required
		// 								//label='aa'
		// 								//variant='standard'
		// 								value={_DataTransfer.DblMontoPago}
		// 								customInput={TextField}
		// 								thousandSeparator={true}
		// 								fixedDecimalScale={true}
		// 								decimalScale={2}
		// 								prefix={'$'}
		// 								fullWidth
		// 								onValueChange={(values) => {
		// 									setDataTransfer({
		// 										..._DataTransfer,
		// 										DblMontoPago: values.floatValue,
		// 									})
		// 								}}
		// 								//onBlur={handleOblur}
		// 								//size='small'
		// 								name='DblMontoPago'
		// 							/>
		// 						</div>
		// 						<div className='flex-start'>
		// 							<label htmlFor='txtUrlComprobanteGuia'>
		// 								<Input
		// 									id='txtUrlComprobanteGuia'
		// 									type='file'
		// 									onChange={handleComprobante}
		// 								/>
		// 								<Button
		// 									startIcon={<CloudUploadIcon />}
		// 									variant='contained'
		// 									className='btn-fail mr1'
		// 									component='span'
		// 								>
		// 									Adjuntar comprobante pago
		// 								</Button>
		// 							</label>
		// 							<Typography variant='caption' gutterBottom component='div'>
		// 								{_Comprobante !== null ? _Comprobante.name : 'sin archivo'}
		// 							</Typography>
		// 						</div>
		// 						<div className='flex-start'>
		// 							<Button
		// 								className='btn-calendar ml1'
		// 								// startIcon={<CloudUploadIcon />}
		// 								onClick={function () {
		// 									adjuntarComprobante()
		// 									//window.open(row.txtUrlGuia, '_blank')
		// 								}}
		// 							>
		// 								Envíar
		// 							</Button>
		// 						</div>
		// 					</div>
		// 				</p>
		// 				<Box></Box>
		// 			</div>
		// 		)}
		// 	</div>
		// </div>
	)
}

export default PaymentFlowProximos
