import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Theme from '../../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { CITA_CREATE } from '../../../Constants/ApiConstants'
import { ThemeProvider } from '@mui/styles'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@mui/material'
import '../../../Styles/Styles.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import es from 'date-fns/locale/es'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

const CitasAdd = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [_Cita, setCita] = useState({
		//lngIdCitas: 0,
		fecCita: '',
		lngIdPropuestas: 0,
		fecRegCita: '',
		hrCita: '',
		intStatusCita: 1,
	})
	const LimpiarValores = () => {
		setCita({
			..._Cita,
			fecCita: '',
			lngIdPropuestas: 0,
			fecRegCita: '',
			hrCita: '',
		})
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		_Cita.lngIdPropuestas = props.registroId
		console.log(props.registroId)

		requests
			.post(CITA_CREATE, _Cita)
			.then((response) => {
				console.log(response)
				props.setTypeSnackBar('success')
				props.setMessageAlert(response.mensaje)
				props.setOpenAlert(true)
				// props.setMessageSnackBar(response.mensaje)
				// props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
				//window.location.reload()
			})
			.catch((error) => {
				console.log(error)
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				// props.setMessageSnackBar(error.mensaje)
				// props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	const [_FecCita, setFecCita] = useState(null)
	const [_HrCita, setHrCita] = useState(null)
	const handleFecCita = (date) => {
		setFecCita(date)
		setCita({ ..._Cita, fecCita: date })
	}
	const handleHrCita = (date) => {
		var hora = date.toString()
		var horaSplice = hora.split(' ').splice(4, 1)
		// var horaS = horaSplice.toString()
		setHrCita(date)
		setCita({ ..._Cita, hrCita: horaSplice + '.0000000' })
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<Dialog
					open={props.open}
					onClose={props.onClose ? props.onClose : null}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<Box
						component='form'
						onSubmit={handleSubmit(guardar)}
						autoComplete='off'
					>
						<Box className='containerHeaderModal'>
							<DialogTitle
								id='alert-dialog-title'
								className='containerHeaderModal font-letter font-barter font-20'
							>
								Agendar cita
							</DialogTitle>
							<Box>
								<Button onClick={handleClose}>
									<CloseIcon
										fontSize='small'
										className='btn-close-icon-modal font-dark'
									/>
								</Button>
							</Box>
						</Box>
						<DialogContent className='dialog-content-modal'>
							<DialogContentText id='alert-dialog-description'>
								<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
									<DatePicker
										name='selectedFecIniPublicacion'
										label='Fecha de iniciación'
										inputFormat='dd/MM/yyyy'
										value={_FecCita}
										onChange={handleFecCita}
										renderInput={(params) => (
											<TextField
												required
												variant='standard'
												color='warning'
												fullWidth
												size='small'
												{...params}
											/>
										)}
									/>
									<TimePicker
										name='selectedHrIniPublicacion'
										label='Hora de iniciación'
										value={_HrCita}
										onChange={handleHrCita}
										renderInput={(params) => (
											<TextField
												required
												variant='standard'
												color='warning'
												fullWidth
												size='small'
												{...params}
											/>
										)}
									/>
								</LocalizationProvider>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} className='font-dark font-letter'>
								Cerrar
							</Button>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								variant='contained'
								type='submit'
								startIcon={<AddIcon />}
							>
								Agregar
							</Button>
						</DialogActions>
					</Box>
				</Dialog>
			</Dialog>
		</ThemeProvider>
	)
}

export default CitasAdd
