import React, { useState } from 'react'
import '../../Styles/StylesMB.css'
import iconEco from '../../Images/MB-Consigna/ecologia-icon.png'
import RegisterMb from '../../Components/Login/RegisterMb'

const MBPersonalShopperView = (props) => {
  //============== OPEN REGISTRO=========================
  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }

  return (
    <div>
      {/* SECTION 1: BANNER + TÍTULO*/}
      <div id="banner-consigna" className="img-banner img-banner-plan">
        <div>
          <h2 className="title-banner">PLANLUXE</h2>
          <h2 className="title-banner">
            Te financiamos la bolsa de tus sueños
          </h2>
        </div>
      </div>
      {/* SECTION 2: ÍCONO + TEXTO*/}
      <div id="icon-txt-consigna" className="text-center w80 center pt8 pb8">
        <img src={iconEco} />
        <p className="txt">
          Buscamos por ti la bolsa que tanto deseas y te la financiamos para que
          la pagues en cómodos meses.
        </p>
      </div>

      <div className="ContainerCardSectionHomePlanLuxe1">
        <div className="SectionIMG"></div>
        <div className="SectionInformation">
          <p className="title">Compra la bolsa que tanto has soñado</p>
          <p className="">
            ¿Tienes en mente esa bolsa de diseñador que siempre has deseado? Con
            PlanLuxe, hacemos que sea tuya de la forma más conveniente.
            Simplemente dinos qué bolsa buscas, ya sea nueva o PreLoved, y
            nosotros la encontramos para ti. Lo mejor de todo, te ofrecemos la
            flexibilidad de pagar en cuotas mensuales, ¡tú eliges el plazo que
            mejor te convenga!
          </p>
        </div>
      </div>
      {/* *********************** */}
      <div className="ContainerCardSectionHomePlanLuxe2">
        <div className="SectionInformation">
          <p className="title">PLANLUXE</p>
          <p className="subtitle">
            Visita nuestra tienda y mira la selección de bolsos PreLoved que
            tenemos para ti, si no encuentras el que tanto estás buscando, no te
            preocupes, lo buscamos por ti. En cualquiera de las dos modalidades,
            te lo financiamos para que pagues en cómodos meses.
          </p>
          <button
            className="btn-claro"
            onClick={function () {
              window.open(
                'https://founditpreloved.com/collections/bolsas-1',
                '_blank'
              )
            }}
          >
            VER TIENDA
          </button>
        </div>
        <div className="SectionIMG"></div>
      </div>
      {/* SECTION 3: IMAGEN + TÍTULO + DESCRIPCIÓN*/}
      {/* <div id='desc-consigna' className='flex flex-wrap align-i-center fondo-cta1'>
				<div className='w50 img-col img-col-aparta'></div>
				<div className='w50 text-center'>
					<div className='w80 center'>
						<h3 className='title ProductTitleMarca'>APARTA TU PIEZA FAVORITA Y TERMINA DE PAGARLA EN UN MES</h3>
						<div className='mt-5' style={{ textAlign: 'start' }}>
							<ol>
								<li>
									<p className='ProductTitleDescription my1'>
										Enamórate: Explora nuestra selección y elige la pieza que quieres apartar.
									</p>
								</li>
								<li>
									<p className='ProductTitleDescription my1'>
										Que no te la ganen: Aparta tu pieza pagando mínimo el 30% del valor total a manera de anticipo.
									</p>
								</li>
								<li>
									<p className='ProductTitleDescription my1'>
										Pagos diferidos: Realiza pagos quincenales, contarás con dos quincenas para terminar de pagar.
									</p>
								</li>
								<li>
									<p className='ProductTitleDescription my1'>
										Disfruta: Una vez completados los pagos, podrás acudir a nuestro ShowRoom por tu artículo, si no eres de CDMX podemos cotizarte el envío hasta tu domicilio.
									</p>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div> */}
      {/* SECTION 4: CTA*/}
      {/* <div id='cta-consigna' className='flex flex-wrap align-i-center fondo-cta'>
				<div className='w50'>
					<div className='w80 ml6' id='contenido-cta'>
						<h3 className='title-cta ProductTitleMarca'>APARTADO</h3>
						<p className='txt-cta ProductTitleDescription'>Visita nuestra tienda en línea y aparta esa pieza que tanto has deseado.</p>
						<button
							className='btn-claro'
							onClick={function () {
								window.open('https://founditpreloved.com/collections/compra', '_blank')
							}}
						>SHOP NOW</button>
					</div>
				</div>
				<div className='w50 img-col img-col-aparta2'></div>
			</div> */}
      <RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} />
    </div>
  )
}

export default MBPersonalShopperView
