import React, { useEffect, useState } from 'react'
import '../../../Styles/App/HomeMB2.css'
import { Fade } from 'react-awesome-reveal'
import ServicioApartado from '../../../Images/HomeMB2/ServicioApartado.png'

const Section4HomeWeb = (props) => {
  // var copy = document.querySelector(".logos-slide").cloneNode(true);
  // document.querySelector('.logos').appendChild(copy);
  return (
    <section className="ContainerSection4MB">
      <div className="cardSecttion4MB">
        <p>Aceptamos las siguientes marcas:</p>
        <div className="ContentMarcas">
          <span>Luxury</span>
          <div className="logos">
            <div className="logos-slide">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Alexander-McQueen.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Aquazzurra-1.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/balenciaga-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Botega+Vennrta.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Bulgari.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Cartier_logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Casadei-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Celine-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/chanel-png.webp'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Chloe-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Christian+dior.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/christian-louboutin.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Dolce__and__Gabbana.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/fendi-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/givenchy.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/golden-goose-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Gucci_logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/hermes-logo.com.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Jimmy_Choo_Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/logo_zanotti_intermedio.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Louis-Vuitton-Logo-PNG.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/michael-kors-logo-2D0B890759-seeklogo.com.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Miu-Miu-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Montblanc_logo.svg.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Moschino_logo.svg.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/prada.webp'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Roberto-Cavalli-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/salvatore-ferragamo-logo-black-and-white.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Sergio_Rossi_logo_2.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/stellamccartney.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Stuart-Weitzman-Logo-2013.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Valentino+Garavani.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/versace-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/yves-saint-laurent-logo.png'
                }
              />
            </div>
            <div className="logos-slide">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Alexander-McQueen.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Aquazzurra-1.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/balenciaga-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Botega+Vennrta.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Bulgari.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Cartier_logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Casadei-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Celine-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/chanel-png.webp'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Chloe-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Christian+dior.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/christian-louboutin.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Dolce__and__Gabbana.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/fendi-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/givenchy.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/golden-goose-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Gucci_logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/hermes-logo.com.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Jimmy_Choo_Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/logo_zanotti_intermedio.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Louis-Vuitton-Logo-PNG.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/michael-kors-logo-2D0B890759-seeklogo.com.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Miu-Miu-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Montblanc_logo.svg.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Moschino_logo.svg.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/prada.webp'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Roberto-Cavalli-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/salvatore-ferragamo-logo-black-and-white.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Sergio_Rossi_logo_2.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/stellamccartney.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Stuart-Weitzman-Logo-2013.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/Valentino+Garavani.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/versace-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Luxury+Foundit/yves-saint-laurent-logo.png'
                }
              />
            </div>
          </div>
        </div>
        <div className="ContentMarcas">
          <span>Premium</span>
          <div className="logos">
            <div className="logos-slide-premium">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/BCBGMaxAzria_Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/burberry-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Carolina-Herrera-Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/COACH-logo.webp'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Stuart-Weitzman-Logo-2013.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Tory_Burch_logo.svg.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/BCBGMaxAzria_Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/burberry-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Carolina-Herrera-Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/COACH-logo.webp'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Stuart-Weitzman-Logo-2013.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Tory_Burch_logo.svg.png'
                }
              />
            </div>
            <div className="logos-slide-premium">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/BCBGMaxAzria_Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/burberry-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Carolina-Herrera-Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/COACH-logo.webp'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Stuart-Weitzman-Logo-2013.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Tory_Burch_logo.svg.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/BCBGMaxAzria_Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/burberry-logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Carolina-Herrera-Logo.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/COACH-logo.webp'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Stuart-Weitzman-Logo-2013.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Premium+Foundit/Tory_Burch_logo.svg.png'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section4HomeWeb
