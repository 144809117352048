import axios from "axios";
import Dictionary from "./Dictionary";

const getBaseUrl = Dictionary.baseUrls.getBaseUrl;

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const tok= JSON.parse(localStorage.getItem("userInfo"));
    // Do something before request is sent
    if(tok !==null){      
    config.headers.Authorization =`Bearer ${tok.access_Token}`;
    config.headers.post['Access-Control-Allow-Origin'] = '*';
  //   config.headers= {
  //     'X-Requested-With': 'XMLHttpRequest'
  // }

  }
    return config;
  }, function (error) {
    console.log(error)
    // Do something with request error  
    return Promise.reject(error);
  });



// Add a response interceptor
axios.interceptors.response.use(function (response) {  
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    
    // Do something with response error
    return Promise.reject(error);
  });
const responseBody = (response) => response?.data;

const requests = {
    
  get: (url, id = "") =>
    axios
      .get(!id ? url : `${url}/${id}`, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
      getParam: (url, body) =>
    axios
      .get(url, body, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  getBody: (url, body) =>
    axios
      .post(url, body, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  post: (url, body, id = "") =>
    axios
      .post(!id ? url : `${url}/${id}`, body, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  put: (url, body, id = "") =>
    axios
      .put(!id ? url : `${url}/${id}`, body, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
  delete: (url, id = "") =>
    axios
      .delete(!id ? url : `${url}/${id}`, {
        baseURL: getBaseUrl,
      })
      .then(responseBody),
      Url:getBaseUrl
};

export default requests;
