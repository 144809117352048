import React from 'react'
import { makeStyles } from '@mui/styles'
import Backdrop from '@mui/material/Backdrop'
import Lottie from 'lottie-react'
import loadingCircle from '../Lottie/loadingCircle.json'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant='determinate' {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant='body2' color='#fff'>{`${Math.round(
					props.value
				)}%`}</Typography>
			</Box>
		</Box>
	)
}

const LoadingScreenProgressBar = (props) => {
	const [progress, setProgress] = React.useState(3)
    
        React.useEffect(() => {
            if(props.open === true){

            const timer = setInterval(
                () => {
                    setProgress((prevProgress) =>
                        prevProgress >= 100 ? 5 : prevProgress + 1
                    )
                },
                progress === 3 ? 1100 : 2000
            )
            return () => {
                clearInterval(timer)
            }
        }
		
	}, [props.open])
	

	return (
		<div>
			<Backdrop 	sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 9999 }} open={props.open}>

			{/* <Backdrop
				sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 9999 }}
				open={true}
			> */}
				<div className='box-LoadingScreen'>
					<div className='box-lottie-spiner'>
						<Lottie animationData={loadingCircle} loop={true} autoplay={true} />
					</div>
					{/* <p className='bold co-white '> Cargando... {props.Msj}</p> */}

					{/* --------------------------------------------------------------------------------------------------- */}

					<Box sx={{ width: '100%' }}>
						<div>
							{progress > 5 && progress < 50 ? (
								<p className='co-white bold'>
									Comprimiendo.. &nbsp; &nbsp; {progress}%
								</p>
							) : null}
							{progress > 50 && progress < 80 ? (
								<p className='co-white bold'>
									Subiendo.. &nbsp; &nbsp; {progress}%
								</p>
							) : null}
							{progress > 80 && progress < 120 ? (
								<p className='co-white bold'>
									Ya casi Terminamos.. &nbsp; &nbsp; {progress}%
								</p>
							) : null}
						</div>

						<LinearProgressWithLabel
							value={progress}
                            variant='indeterminate'
							sx={{
								background: '#fff',
								'& .MuiLinearProgress-bar': {
									backgroundColor: 'rgb(196, 137, 137)',
								},
							}}
						/>
					</Box>
				</div>
			</Backdrop>
		</div>
	)
}
export default LoadingScreenProgressBar
