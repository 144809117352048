/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import AlertConfirmActionDescount from '../../Util/Alerts/AlertConfirmActionDescount'
import requests from '../AxiosCalls/AxiosAuth'
import {
	Button,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material'

import {
	DESCUENTO_CONSIGNA_LIST,
	DESCUENTO_CONSIGNA_BY_ID,
	PRODUCTOS_GET_BY_ID,
	CLI_PRO_BY_ID,
	COLECCIONES_LIST,
	COLECCIONES_BY_ID_PRODUCTO,
	PRODUCTOS_EDIT,
	CLI_PRO_EDIT,
} from '../../Constants/ApiConstants'

import { gtm_AplicarDescuento_Log, gtm_CerrarDescuento_Log } from '../../Constants/GoogleTagManager/gtmConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const ModalConsignaDiscount = (props) => {
	

	const [_ArrayColectionsOptions, setArrayColectionsOptions] = useState([])
	const [_Colecciones, setColecciones] = useState([])
	const [_ColectionsOptions, setColectionsOptions] = useState({
		selectedValue: '',
	})
	const [_CliPro, setCliPro] = useState({
		lngIdCliPro: '',
		lngIdCliente: '',
		lngIdProducto: props.idProducto,
	})

	const [_Descuento, setDescuento] = useState({
		lngIdProducto: 0,
		dblDescuento: 0,
	})

	const [_Productos, setProductos] = useState({
		lngIdProducto: '',
		txtFolio: '',
		txtSkuReg: '',
		intIdTipoAd: '',
		intIdCategoria: '',
		intIdGenero: '',
		intIdClase: '',
		intIdProductos: '',
		intIdSubProducto: '',
		intIdProductosH: '',
		intIdSubProductoH: '',
		intIdMarca: '',
		intIdMaterial: '',
		intIdTallaTamano: '',
		intIdColor: '',
		bolEstuche: false,
		numCantidad: 0,
		txtComentarios: '',
		dblCostoInv: 0,
		dblCostoTienda: 0,
		dblCostoEmpe: 0,
		dblCostoAhorro: 0,
		dblPrecioFinal: 0,
		dblDiferenciaCosto: 0,
		porMargenUtilidad: 0,
		porReferendoEmpe: 0,
		dblMontoReferendo: 0,
		porComConsigna: 0,
		dblCostoEnvio: 0,
		dblTotalaPagar: 0,
		numPeridResguardo: 0,
		fecIniPublicacion: '',
		numPlazoDiasPubli: 0,
		fecVenPublicacion: '',
		numDiasPagRef: 0,
		fecPierdeEmpe: '',
		fecIngreso: '',
		fecVenta: '',
		intIdStatusPagos: '',
		lngIdNegocio: '',
		lngIdContrato: '',
		intIdStatusPro: '',
		dblMontoConsigna: 0,
		txtUbicacion: '',
		bolInventario: true,
		intIdMoneda: '',
		txtTamano: '',
		dblValorMarketing: 0,
		dblValorAutentificacion: 0,
		txtLocalidadProducto: '',
		dblPromocion: 0,
		intIdEstados: '',
		intIdEstado: '',
		bolIsPublic: false,
		bolAlmacenado: '',
		intIdEstadoProducto: '',
		bolCertificado: false,
		intIdFormaPagoCert: 0,
		txtCertificado: '',
		dblCertificado: 0,
		bolValidado: false,
		bolComproCertificado: false,
		imagenCertificado: '',
		txtUrlCertificado: '',
		intIdDescuentoConsigna: 0,
		dbPeso: 0,
		txtAlturaMedida: '',
		dblDescuento: 0,
		bolRenta: false,
		bolApartado: false,
		porInteresEmp: 0,
		dblImporteDesemp: 0,
		dblGananciaBruta: 0,
		dblGananciaNeta: 0,
		txtTallaTamanoMedida: '',
		porDescuentoEmp: 0,
		txtDescripcionAccesorios: 0,
		bolPeriodoConsignaExt: false,
		numPeriodoConsignaExt: 0,
		ListaImagen: [],
		IdColeccion: [],
	})

	const [_MedidasDetalles, setMedidasDetalles] = useState({
		//lngIdDescripcion: "",
		lngIdProducto: '',
		txtAlturaPlataformaZa: '', //CATEGORIA: ZAPATOS
		txtTallaZa: '', //CATEGORIA: ZAPATOS
		txtAnchoBol: '', //CATEGORIA: BOLSOS
		txtLargoBol: '', //CATEGORIA: BOLSOS
		txtProfundidadBol: '', //CATEGORIA: BOLSOS
		txtAnchoCin: '', //CATEGORIA: ACCESORIOS -> ARTICULO: CINTURON
		txtLargoCin: '', //CATEGORIA: ACCESORIOS -> ARTICULO: CINTURON
		txtAnchoCo: '', //CATEGORIA: ACCESORIOS -> ARTICULO: CORBATA
		txtLargoCo: '', //CATEGORIA: ACCESORIOS -> ARTICULO: CORBATA
		txtFormaGa: '', //CATEGORIA: ACCESORIOS -> ARTICULO: GAFAS
		txtAnchoGa: '', //CATEGORIA: ACCESORIOS -> ARTICULO: GAFAS
		txtAltoGa: '', //CATEGORIA: ACCESORIOS -> ARTICULO: GAFAS
		txtVarillaGa: '', //CATEGORIA: ACCESORIOS -> ARTICULO: GAFAS
		txtPuenteGa: '', //CATEGORIA: ACCESORIOS -> ARTICULO: GAFAS
		txtAnchoMa: '', //CATEGORIA: ACCESORIOS -> ARTICULO: MASCADAS
		txtLargoMa: '', //CATEGORIA: ACCESORIOS -> ARTICULO: MASCADAS
		bolKilatajeJo: false, //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA
		txtMedidaJo: '', //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA
		bolPiedrasJo: false, //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA
		txtSerieJo: '', //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA
		txtLeyJo: '', //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA
		bolKilatajeRe: false, //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA -> SUB ARTICULO: RELOJES
		txtMaterialRe: '', //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA -> SUB ARTICULO: RELOJES
		txtModelopulseraRe: '', //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA -> SUB ARTICULO: RELOJES
		txtTipoMaquinariaRe: '', //CATEGORIA: ACCESORIOS -> ARTICULO: JOYERIA -> SUB ARTICULO: RELOJES
	})

	const handleDescuento = (event) => {
		let descuento = 0;
		requests
			.get(DESCUENTO_CONSIGNA_BY_ID, event.target.value)
			.then((response) => {
				descuento = (_Productos.dblPrecioFinal * response.dblDescuento)/100
				setDescuento(response)
				setProductos({ ..._Productos, intIdDescuentoConsigna: event.target.value, dblDescuento:  descuento})
			})
			.catch((error) => {
				console.log(error)
			})
			setDescuento({ ..._Descuento, [event.target.name]: event.target.value })

		
	}

	const handleClose = () => {
		gtm_CerrarDescuento_Log()
		props.onCloseModalConsignaDiscount()
	}	

	const [alertInfo2, setAlertInfo2] = useState(false)
	const [titleAlertInfo2, setTitleAlertInfo2] = useState('')
	const [bodyAlertInfo2, setBodyAlertInfo2] = useState('')
	const handleCloseAlertInfo2 = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setAlertInfo2(false)
	}

	//DESCUENTO CONSIGNA
	const [_ListDescuentoCons, setListDescuentoCons] = useState([])
	const getDescuentoCons = () => {
		requests
			.get(DESCUENTO_CONSIGNA_LIST)
			.then((response) => {
				setListDescuentoCons(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	//useEffect
	useEffect(() => {
		getDescuentoCons()
		getRegistro()
		getProductoDetalles()
	}, [props.idProducto])

	const getRegistro = () => {
		if (props.idProducto !== null) {
			requests
				.get(PRODUCTOS_GET_BY_ID, props.idProducto)
				.then((response) => {
					asignarValores(response)
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const getProductoDetalles = () => {
		if (props.idProducto !== null) {
			requests
				.get('ProductosDescripcion/BuscarPorIdProducto', props.idProducto)
				.then((response) => {
					setMedidasDetalles(response)
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

	const asignarValores = (objeto) => {
		setProductos({
			..._Productos,
			lngIdProducto: objeto === null ? '' : objeto.lngIdProducto,
			txtFolio: objeto === null ? '' : objeto.txtFolio,
			txtSkuReg: objeto === null ? '' : objeto.txtSkuReg,
			intIdTipoAd: objeto === null ? '' : objeto.intIdTipoAd,
			intIdCategoria: objeto === null ? '' : objeto.intIdCategoria,
			intIdGenero: objeto === null ? '' : objeto.intIdGenero,
			intIdClase: objeto === null ? '' : objeto.intIdClase,
			intIdProductos: objeto === null ? '' : objeto.intIdProductos,
			intIdSubProducto: objeto === null ? '' : objeto.intIdSubProducto,
			intIdProductosH: objeto === null ? '' : objeto.intIdProductosH,
			intIdSubProductoH: objeto === null ? '' : objeto.intIdSubProductoH,
			intIdMarca: objeto === null ? '' : objeto.intIdMarca,
			intIdMaterial: objeto === null ? '' : objeto.intIdMaterial,
			intIdTallaTamano: objeto === null ? '' : objeto.intIdTallaTamano,
			intIdColor: objeto === null ? '' : objeto.intIdColor,
			bolEstuche: objeto === null ? '' : objeto.bolEstuche,
			numCantidad: objeto === null ? '' : objeto.numCantidad,
			txtComentarios: objeto === null ? '' : objeto.txtComentarios,
			dblCostoInv: objeto === null ? '' : objeto.dblCostoInv,
			dblCostoTienda: objeto === null ? '' : objeto.dblCostoTienda,
			dblCostoEmpe: objeto === null ? '' : objeto.dblCostoEmpe,
			dblCostoAhorro: objeto === null ? '' : objeto.dblCostoAhorro,
			dblPrecioFinal: objeto === null ? '' : objeto.dblPrecioFinal,
			dblDiferenciaCosto: objeto === null ? '' : objeto.dblDiferenciaCosto,
			porMargenUtilidad: objeto === null ? '' : objeto.porMargenUtilidad,
			porReferendoEmpe: objeto === null ? '' : objeto.porReferendoEmpe,
			dblMontoReferendo: objeto === null ? '' : objeto.dblMontoReferendo,
			porComConsigna: objeto === null ? '' : objeto.porComConsigna,
			dblCostoEnvio: objeto === null ? '' : objeto.dblCostoEnvio,
			dblTotalaPagar: objeto === null ? '' : objeto.dblTotalaPagar,
			numPeridResguardo: objeto === null ? '' : objeto.numPeridResguardo,
			fecIniPublicacion: objeto === null ? '' : objeto.fecIniPublicacion,
			numPlazoDiasPubli: objeto === null ? '' : objeto.numPlazoDiasPubli,
			fecVenPublicacion: objeto === null ? '' : objeto.fecVenPublicacion,
			numDiasPagRef: objeto === null ? '' : objeto.numDiasPagRef,
			fecPierdeEmpe: objeto === null ? '' : objeto.fecPierdeEmpe,
			fecIngreso: objeto === null ? '' : objeto.fecIngreso,
			fecVenta: objeto === null ? '' : objeto.fecVenta,
			intIdStatusPagos: objeto === null ? '' : objeto.intIdStatusPagos,
			lngIdNegocio: objeto === null ? '' : objeto.lngIdNegocio,
			lngIdContrato: objeto === null ? '' : objeto.lngIdContrato,
			intIdStatusPro: objeto === null ? '' : objeto.intIdStatusPro,
			dblMontoConsigna: objeto === null ? '' : objeto.dblMontoConsigna,
			txtUbicacion: objeto === null ? '' : objeto.txtUbicacion,
			bolInventario: objeto === null ? '' : objeto.bolInventario,
			intIdMoneda: objeto === null ? '' : objeto.intIdMoneda,
			txtTamano: objeto === null ? '' : objeto.txtTamano,
			dblValorMarketing: objeto === null ? '' : objeto.dblValorMarketing,
			dblValorAutentificacion:
				objeto === null ? '' : objeto.dblValorAutentificacion,
			txtLocalidadProducto: objeto === null ? '' : objeto.txtLocalidadProducto,
			dblPromocion: objeto === null ? '' : objeto.dblPromocion,
			intIdEstadoProducto: objeto === null ? '' : objeto.intIdEstadoProducto,
			intIdEstados: objeto === null ? '' : objeto.intIdEstados,
			intIdEstado: objeto === null ? '' : objeto.intIdEstado,
			bolIsPublic: objeto === null ? '' : objeto.bolIsPublic,
			bolAlmacenado: objeto === null ? '' : objeto.bolAlmacenado,
			bolCertificado: objeto === null ? '' : objeto.bolCertificado,
			txtCertificado: objeto === null ? '' : objeto.txtCertificado,
			dblCertificado: objeto === null ? '' : objeto.dblCertificado,
			bolValidado: objeto === null ? '' : objeto.bolValidado,
			bolComproCertificado: objeto === null ? '' : objeto.bolComproCertificado,
			txtUrlCertificado: objeto === null ? '' : objeto.txtUrlCertificado,
			intIdDescuentoConsigna:
				objeto === null ? '' : objeto.intIdDescuentoConsigna,
			dbPeso: objeto === null ? '' : objeto.dbPeso,
			txtAlturaMedida: objeto === null ? '' : objeto.txtAlturaMedida,
			dblDescuento: objeto === null ? '' : objeto.dblDescuento,
			bolRenta: objeto === null ? '' : objeto.bolRenta,
			bolApartado: objeto === null ? '' : objeto.bolApartado,
			porInteresEmp: objeto === null ? '' : objeto.porInteresEmp,
			dblImporteDesemp: objeto === null ? '' : objeto.dblImporteDesemp,
			dblGananciaBruta: objeto === null ? '' : objeto.dblGananciaBruta,
			dblGananciaNeta: objeto === null ? '' : objeto.dblGananciaNeta,
			txtTallaTamanoMedida: objeto === null ? '' : objeto.txtTallaTamanoMedida,
			porDescuentoEmp: objeto === null ? '' : objeto.porDescuentoEmp,
			txtDescripcionAccesorios:
				objeto === null ? '' : objeto.txtDescripcionAccesorios,
			bolPeriodoConsignaExt:
				objeto === null ? '' : objeto.bolPeriodoConsignaExt,
			numPeriodoConsignaExt:
				objeto === null ? '' : objeto.numPeriodoConsignaExt,
		})
		requests
			.get(CLI_PRO_BY_ID, objeto.lngIdProducto)
			.then((response) => {
				asignarValoresCliPro(response)
			})
			.catch((error) => {
				console.log(error)
			})
		requests
			.get(COLECCIONES_LIST)
			.then((response) => {
				for (var i = 0; i < response.length; i++) {
					let data = response[i]
					let object = {
						value: data.intIdColeccion,
						label: data.txtNombre,
					}

					_ArrayColectionsOptions.push(object)
				}
				requests
					.get(COLECCIONES_BY_ID_PRODUCTO, objeto.lngIdProducto)
					.then((response) => {
						for (var i = 0; i < response.length; i++) {
							let data = response[i]

							let array = _ArrayColectionsOptions.filter(
								(col) => col.value == data.intIdColeccion
							)

							let object = {
								label: array[0].label,
								value: array[0].value,
							}

							_Colecciones.push(object)
						}
						setColectionsOptions({
							..._ColectionsOptions,
							selectedValue: _Colecciones,
						})
					})
					.catch((error) => {
						console.log(error)
					})
			})
			.catch((error) => {
				console.log('Error: ' + error)
			})
	}

	const asignarValoresCliPro = (objeto) => {
		setCliPro({
			..._CliPro,
			lngIdCliPro: objeto === null ? '' : objeto.lngIdCliPro,
			lngIdCliente: objeto === null ? '' : objeto.lngIdCliente,
			lngIdProducto: objeto === null ? '' : objeto.lngIdProducto,
		})
	}

	const guardar = () => {
		props.props.setOpenLoadingScreen()
		var fechaInicio = ''
		var fechaFin = ''
		var fechaPierdeEmpeño = ''
		var fechaVenta = ''
		//FECHA INICIO
		if (_Productos.fecIniPublicacion !== '') {
			var dateInicio = new Date(_Productos.fecIniPublicacion)
			fechaInicio = dateInicio.toISOString()
		} else {
			fechaInicio = ''
		}
		//FECHA VENCE PUBLICACIÓN
		if (_Productos.fecVenPublicacion !== '') {
			var dateVen = new Date(_Productos.fecVenPublicacion)
			fechaFin = dateVen.toISOString()
		} else {
			fechaFin = ''
		}
		//FECHA PIERDE EMPEÑO
		if (_Productos.fecPierdeEmpe !== '') {
			var datePierdeEmp = new Date(_Productos.fecPierdeEmpe)
			fechaPierdeEmpeño = datePierdeEmp.toISOString()
		} else {
			fechaPierdeEmpeño = ''
		}
		//FECHA VENTA
		if (_Productos.fecVenta !== '') {
			var dateVenta = new Date(_Productos.fecVenta)
			fechaVenta = dateVenta.toISOString()
		} else {
			fechaVenta = ''
		}

		const formData = new FormData()
		formData.append(
			'lngIdProducto',
			_Productos.lngIdProducto === null ? '' : _Productos.lngIdProducto
		)
		formData.append(
			'txtFolio',
			_Productos.txtFolio === null ? '' : _Productos.txtFolio
		)
		formData.append(
			'txtSkuReg',
			_Productos.txtSkuReg === null ? '' : _Productos.txtSkuReg
		)
		formData.append(
			'intIdTipoAd',
			_Productos.intIdTipoAd === null ? '' : _Productos.intIdTipoAd
		)
		formData.append(
			'intIdCategoria',
			_Productos.intIdCategoria === null ? '' : _Productos.intIdCategoria
		)
		formData.append(
			'intIdGenero',
			_Productos.intIdGenero === null ? '' : _Productos.intIdGenero
		)
		formData.append(
			'intIdClase',
			_Productos.intIdClase === null ? '' : _Productos.intIdClase
		)
		formData.append(
			'intIdProductos',
			_Productos.intIdProductos === null ? '' : _Productos.intIdProductos
		)
		formData.append(
			'intIdSubProducto',
			_Productos.intIdSubProducto === null ? '' : _Productos.intIdSubProducto
		)
		formData.append(
			'intIdProductosH',
			_Productos.intIdProductosH === null ? '' : _Productos.intIdProductosH
		)
		formData.append(
			'intIdSubProductoH',
			_Productos.intIdSubProductoH === null ? '' : _Productos.intIdSubProductoH
		)
		formData.append(
			'intIdMarca',
			_Productos.intIdMarca === null ? '' : _Productos.intIdMarca
		)
		formData.append(
			'intIdMaterial',
			_Productos.intIdMaterial === null ? '' : _Productos.intIdMaterial
		)
		formData.append(
			'intIdTallaTamano',
			_Productos.intIdTallaTamano === null ? '' : _Productos.intIdTallaTamano
		)
		formData.append(
			'intIdColor',
			_Productos.intIdColor === null ? '' : _Productos.intIdColor
		)
		formData.append(
			'bolEstuche',
			_Productos.bolEstuche === null ? false : _Productos.bolEstuche
		)
		formData.append(
			'numCantidad',
			_Productos.numCantidad === null ? '' : _Productos.numCantidad
		)
		formData.append(
			'txtComentarios',
			_Productos.txtComentarios === null ? '' : _Productos.txtComentarios
		)
		formData.append(
			'dblCostoInv',
			_Productos.dblCostoInv === null ? '' : _Productos.dblCostoInv
		)
		formData.append(
			'dblCostoTienda',
			_Productos.dblCostoTienda === null ? '' : _Productos.dblCostoTienda
		)
		formData.append(
			'dblCostoEmpe',
			_Productos.dblCostoEmpe === null ? '' : _Productos.dblCostoEmpe
		)
		formData.append(
			'dblCostoAhorro',
			_Productos.dblCostoAhorro === null ? '' : _Productos.dblCostoAhorro
		)
		formData.append(
			'dblPrecioFinal',
			_Productos.dblPrecioFinal === null ? '' : _Productos.dblPrecioFinal
		)
		formData.append(
			'dblDiferenciaCosto',
			_Productos.dblDiferenciaCosto === null
				? ''
				: _Productos.dblDiferenciaCosto
		)
		formData.append(
			'porMargenUtilidad',
			_Productos.porMargenUtilidad === null ? '' : _Productos.porMargenUtilidad
		)
		formData.append(
			'porReferendoEmpe',
			_Productos.porReferendoEmpe === null ? '' : _Productos.porReferendoEmpe
		)
		formData.append(
			'dblMontoReferendo',
			_Productos.dblMontoReferendo === null ? '' : _Productos.dblMontoReferendo
		)
		formData.append(
			'porComConsigna',
			_Productos.porComConsigna === null ? '' : _Productos.porComConsigna
		)
		formData.append(
			'dblCostoEnvio',
			_Productos.dblCostoEnvio === null ? '' : _Productos.dblCostoEnvio
		)
		formData.append(
			'dblTotalaPagar',
			_Productos.dblTotalaPagar === null ? '' : _Productos.dblTotalaPagar
		)
		formData.append(
			'numPeridResguardo',
			_Productos.numPeridResguardo === null ? '' : _Productos.numPeridResguardo
		)
		formData.append('fecIniPublicacion', fechaInicio)
		formData.append(
			'numPlazoDiasPubli',
			_Productos.numPlazoDiasPubli === null ? '' : _Productos.numPlazoDiasPubli
		)
		formData.append('fecVenPublicacion', fechaFin)
		formData.append(
			'numDiasPagRef',
			_Productos.numDiasPagRef === null ? '' : _Productos.numDiasPagRef
		)
		formData.append('fecPierdeEmpe', fechaPierdeEmpeño)
		formData.append('fecVenta', fechaVenta)
		formData.append(
			'intIdStatusPagos',
			_Productos.intIdStatusPagos === null ? '' : _Productos.intIdStatusPagos
		)
		formData.append(
			'lngIdNegocio',
			_Productos.lngIdNegocio === null ? '' : _Productos.lngIdNegocio
		)
		formData.append(
			'lngIdContrato',
			_Productos.lngIdContrato === null ? '' : _Productos.lngIdContrato
		)
		formData.append(
			'intIdStatusPro',
			_Productos.intIdStatusPro === null ? '' : _Productos.intIdStatusPro
		)
		formData.append(
			'dblMontoConsigna',
			_Productos.dblMontoConsigna === null ? '' : _Productos.dblMontoConsigna
		)
		formData.append(
			'txtUbicacion',
			_Productos.txtUbicacion === null ? '' : _Productos.txtUbicacion
		)
		formData.append(
			'bolInventario',
			_Productos.bolInventario === null ? false : _Productos.bolInventario
		)
		formData.append(
			'intIdMoneda',
			_Productos.intIdMoneda === null ? '' : _Productos.intIdMoneda
		)
		formData.append(
			'txtTamano',
			_Productos.txtTamano === null ? '' : _Productos.txtTamano
		)
		formData.append(
			'dblValorMarketing',
			_Productos.dblValorMarketing === null ? '' : _Productos.dblValorMarketing
		)
		formData.append(
			'dblValorAutentificacion',
			_Productos.dblValorAutentificacion === null
				? ''
				: _Productos.dblValorAutentificacion
		)
		formData.append(
			'txtLocalidadProducto',
			_Productos.txtLocalidadProducto === null
				? ''
				: _Productos.txtLocalidadProducto
		)
		formData.append(
			'intIdEstados',
			_Productos.intIdEstados === null ? '' : _Productos.intIdEstados
		)
		formData.append(
			'intIdEstado',
			_Productos.intIdEstado === null ? '' : _Productos.intIdEstado
		)
		formData.append(
			'bolIsPublic',
			_Productos.bolIsPublic === null ? false : _Productos.bolIsPublic
		)
		formData.append(
			'intIdEstadoProducto',
			_Productos.intIdEstadoProducto === null
				? ''
				: _Productos.intIdEstadoProducto
		)
		formData.append(
			'txtCertificado',
			_Productos.txtCertificado === null ? '' : _Productos.txtCertificado
		)
		formData.append(
			'dblCertificado',
			_Productos.dblCertificado === null ? '' : _Productos.dblCertificado
		)
		formData.append(
			'intIdFormaPagoCert',
			_Productos.intIdFormaPagoCert === null
				? ''
				: _Productos.intIdFormaPagoCert
		)
		formData.append(
			'bolValidado',
			_Productos.bolValidado === null ? false : _Productos.bolValidado
		)
		formData.append(
			'bolAlmacenado',
			_Productos.bolAlmacenado === null ? false : _Productos.bolAlmacenado
		)
		formData.append(
			'bolCertificado',
			_Productos.bolCertificado === null ? false : _Productos.bolCertificado
		)
		formData.append(
			'bolComproCertificado',
			_Productos.bolComproCertificado === null
				? false
				: _Productos.bolComproCertificado
		)
		formData.append(
			'intIdDescuentoConsigna',
			_Productos.intIdDescuentoConsigna === null
				? ''
				: _Productos.intIdDescuentoConsigna
		)
		formData.append(
			'dbPeso',
			_Productos.dbPeso === null ? '' : _Productos.dbPeso
		)
		formData.append(
			'txtAlturaMedida',
			_Productos.txtAlturaMedida === null ? '' : _Productos.txtAlturaMedida
		)
		formData.append(
			'dblDescuento',
			_Productos.dblDescuento === null ? '' : _Productos.dblDescuento
		)
		formData.append(
			'bolRenta',
			_Productos.bolRenta === null ? '' : _Productos.bolRenta
		)
		formData.append(
			'bolApartado',
			_Productos.bolApartado === null ? '' : _Productos.bolApartado
		)
		formData.append(
			'porInteresEmp',
			_Productos.porInteresEmp === null ? '' : _Productos.porInteresEmp
		)
		formData.append(
			'dblImporteDesemp',
			_Productos.dblImporteDesemp === null ? '' : _Productos.dblImporteDesemp
		)
		formData.append(
			'dblGananciaBruta',
			_Productos.dblGananciaBruta === null ? '' : _Productos.dblGananciaBruta
		)
		formData.append(
			'dblGananciaNeta',
			_Productos.dblGananciaNeta === null ? '' : _Productos.dblGananciaNeta
		)
		formData.append(
			'porDescuentoEmp',
			_Productos.porDescuentoEmp === null ? '' : _Productos.porDescuentoEmp
		)
		formData.append(
			'txtTallaTamanoMedida',
			_Productos.txtTallaTamanoMedida === null
				? ''
				: _Productos.txtTallaTamanoMedida
		)
		formData.append(
			'txtUrlCertificado',
			_Productos.txtUrlCertificado === null ? '' : _Productos.txtUrlCertificado
		)
		formData.append(
			'bolPeriodoConsignaExt',
			_Productos.bolPeriodoConsignaExt === null
				? ''
				: _Productos.bolPeriodoConsignaExt
		)
		formData.append(
			'numPeriodoConsignaExt',
			_Productos.numPeriodoConsignaExt === null
				? ''
				: _Productos.numPeriodoConsignaExt
		)

		//MEDIDASDESCRIPCION
		formData.append(
			'MedidasDescripcion.LngIdDescripcion',
			_MedidasDetalles.lngIdDescripcion === null
				? ''
				: _MedidasDetalles.lngIdDescripcion
		)
		formData.append(
			'MedidasDescripcion.LngIdProducto',
			_MedidasDetalles.lngIdProducto === null
				? ''
				: _MedidasDetalles.lngIdProducto
		)
		formData.append(
			'MedidasDescripcion.TxtAlturaPlataformaZa',
			_MedidasDetalles.txtAlturaPlataformaZa === null
				? ''
				: _MedidasDetalles.txtAlturaPlataformaZa
		)
		formData.append(
			'MedidasDescripcion.TxtTallaZa',
			_MedidasDetalles.txtTallaZa === null ? '' : _MedidasDetalles.txtTallaZa
		)
		formData.append(
			'MedidasDescripcion.TxtAnchoBol',
			_MedidasDetalles.txtAnchoBol === null ? '' : _MedidasDetalles.txtAnchoBol
		)
		formData.append(
			'MedidasDescripcion.TxtLargoBol',
			_MedidasDetalles.txtLargoBol === null ? '' : _MedidasDetalles.txtLargoBol
		)
		formData.append(
			'MedidasDescripcion.TxtProfundidadBol',
			_MedidasDetalles.txtProfundidadBol === null
				? ''
				: _MedidasDetalles.txtProfundidadBol
		)
		formData.append(
			'MedidasDescripcion.TxtAnchoCin',
			_MedidasDetalles.txtAnchoCin === null ? '' : _MedidasDetalles.txtAnchoCin
		)
		formData.append(
			'MedidasDescripcion.TxtLargoCin',
			_MedidasDetalles.txtLargoCin === null ? '' : _MedidasDetalles.txtLargoCin
		)
		formData.append(
			'MedidasDescripcion.TxtAnchoCo',
			_MedidasDetalles.txtAnchoCo === null ? '' : _MedidasDetalles.txtAnchoCo
		)
		formData.append(
			'MedidasDescripcion.TxtLargoCo',
			_MedidasDetalles.txtLargoCo === null ? '' : _MedidasDetalles.txtLargoCo
		)
		formData.append(
			'MedidasDescripcion.TxtFormaGa',
			_MedidasDetalles.txtFormaGa === null ? '' : _MedidasDetalles.txtFormaGa
		)
		formData.append(
			'MedidasDescripcion.TxtAnchoGa',
			_MedidasDetalles.txtAnchoGa === null ? '' : _MedidasDetalles.txtAnchoGa
		)
		formData.append(
			'MedidasDescripcion.TxtAltoGa',
			_MedidasDetalles.txtAltoGa === null ? '' : _MedidasDetalles.txtAltoGa
		)
		formData.append(
			'MedidasDescripcion.TxtVarillaGa',
			_MedidasDetalles.txtVarillaGa === null
				? ''
				: _MedidasDetalles.txtVarillaGa
		)
		formData.append(
			'MedidasDescripcion.TxtPuenteGa',
			_MedidasDetalles.txtPuenteGa === null ? '' : _MedidasDetalles.txtPuenteGa
		)
		formData.append(
			'MedidasDescripcion.TxtAnchoMa',
			_MedidasDetalles.txtAnchoMa === null ? '' : _MedidasDetalles.txtAnchoMa
		)
		formData.append(
			'MedidasDescripcion.TxtLargoMa',
			_MedidasDetalles.txtLargoMa === null ? '' : _MedidasDetalles.txtLargoMa
		)
		formData.append(
			'MedidasDescripcion.BolKilatajeJo',
			_MedidasDetalles.bolKilatajeJo === null
				? false
				: _MedidasDetalles.bolKilatajeJo
		)
		formData.append(
			'MedidasDescripcion.TxtMedidaJo',
			_MedidasDetalles.txtMedidaJo === null ? '' : _MedidasDetalles.txtMedidaJo
		)
		formData.append(
			'MedidasDescripcion.BolPiedrasJo',
			_MedidasDetalles.bolPiedrasJo === null
				? false
				: _MedidasDetalles.bolPiedrasJo
		)
		formData.append(
			'MedidasDescripcion.TxtSerieJo',
			_MedidasDetalles.txtSerieJo === null ? '' : _MedidasDetalles.txtSerieJo
		)
		formData.append(
			'MedidasDescripcion.TxtLeyJo',
			_MedidasDetalles.txtLeyJo === null ? '' : _MedidasDetalles.txtLeyJo
		)
		formData.append(
			'MedidasDescripcion.BolKilatajeRe',
			_MedidasDetalles.bolKilatajeRe === null
				? false
				: _MedidasDetalles.bolKilatajeRe
		)
		formData.append(
			'MedidasDescripcion.TxtMaterialRe',
			_MedidasDetalles.txtMaterialRe === null
				? ''
				: _MedidasDetalles.txtMaterialRe
		)
		formData.append(
			'MedidasDescripcion.TxtModelopulseraRe',
			_MedidasDetalles.txtModelopulseraRe === null
				? ''
				: _MedidasDetalles.txtModelopulseraRe
		)
		formData.append(
			'MedidasDescripcion.TxtTipoMaquinariaRe',
			_MedidasDetalles.txtTipoMaquinariaRe === null
				? ''
				: _MedidasDetalles.txtTipoMaquinariaRe
		)

		requests
			.put(PRODUCTOS_EDIT, formData)
			.then((response) => {
				if (_CliPro.lngIdCliente !== '' || _CliPro.lngIdCliente !== null) {
					_CliPro.lngIdProducto = props.idProducto
					requests
						.put(CLI_PRO_EDIT, _CliPro)
						.then((response) => {
							gtm_AplicarDescuento_Log()
							props.props.setTypeSnackBar('success')
							props.props.setMessageSnackBar(response.mensaje)
							props.props.setOpenSnackBar(true)
							props.props.setCloseLoadingScreen()
						})
						.catch((error) => {
							props.props.setTypeSnackBar('warning')
							props.props.setMessageSnackBar(error.data.mensaje)
							props.props.setOpenSnackBar(true)
							props.props.setCloseLoadingScreen()
						})
				}
				props.props.setTypeSnackBar('success')
				props.props.setMessageSnackBar(response.mensaje)
				props.props.setOpenSnackBar(true)
				props.props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.props.setTypeSnackBar('warning')
				props.props.setMessageSnackBar(error.data.mensaje)
				props.props.setOpenSnackBar(true)
				props.props.setCloseLoadingScreen()
			})
	}

	return (
		<div>
			<Dialog
				fullScreen
				open={props.openModalConsignaDiscount}
				onClose={
					props.onCloseModalConsignaDiscount
						? props.onCloseModalConsignaDiscount
						: null
				}
				TransitionComponent={Transition}
				PaperProps={{
					style: {
						background: 'rgba(255, 255, 255, 0.2)',
						backdropFilter: 'blur(10px)',
					},
				}}
			>
				<div className='modal-consigna-Discount'>
					<div className='flex-between'>
						<h3 className='h3'>Otorgar Descuento</h3>
						<IconButton onClick={handleClose} className='btn-blur-close'>
							<CloseIcon sx={{ fontSize: 35 }} />
						</IconButton>
					</div>
					<div className=' '>
						<div className='column'>
							<span className='p'>
								Selecciona el descuento que desea aplicarle a tu artículo,
								recuerda que puedes eliminar este descuento cuando quieras!!
							</span>
							<div className=' mt2 flex-start'>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={12}
									spacing={1}
									container
									direction='row'
									justifyContent='center'
									alignItems='center'
								>
									<Grid item xs={12} sm={6} md={6} lg={6}>
										<FormControl color='warning' variant='standard' fullWidth>
											<InputLabel id='intIdDescuentoConsigna'>
												Descuento
											</InputLabel>
											<Select
												size='small'
												labelId='intIdDescuentoConsigna'
												id='intIdDescuentoConsigna'
												value={_Productos.intIdDescuentoConsigna}
												onChange={handleDescuento}
												inputProps={{
													name: 'intIdDescuentoConsigna',
													id: 'intIdDescuentoConsigna',
												}}
												label='Descuento'
											>
												<MenuItem value='' />
												{_ListDescuentoCons.map((object, index) => (
													<MenuItem value={object.intIdDescuentoConsigna}>
														{object.dblDescuento} {' %'}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={6} md={6} lg={6} className=' flex'>
										<Button
											className='btn-actions'
											variant='outlined'
											onClick={() => {
												setAlertInfo2(!alertInfo2)
												setTitleAlertInfo2(
													'¿Estás seguro de aplicar un descuento del ' +
														_Descuento.dblDescuento +
														' % a tu artículo? '
												)
											}}
										>
											Agregar Descuento
										</Button>
									</Grid>
								</Grid>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
			<AlertConfirmActionDescount
				openAlert={alertInfo2}
				MensajeTitle={titleAlertInfo2}
				MensajeBody={bodyAlertInfo2}
				handleCloseAlert={handleCloseAlertInfo2}
				save={guardar}
				descuento={_Descuento.dblDescuento}
				closeModal={props.onCloseModalConsignaDiscount}
			/>
		</div>
	)
}

export default ModalConsignaDiscount
