/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { ThemeProvider } from '@mui/material/styles'
import {
	Chip,
	Card,
	CardActions,
	CardContent,
	Button,
	CardMedia,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
	CLIENTES_SECTION_PRO_PAY,
	CLIENTES_SECTION_PRO_VIEW,
} from '../../../Constants/routesConstants'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import NumberFormat from 'react-number-format'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import PreviewImg from '../../../Components/PreviewImg'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import '../../../Styles/GeneralStyles'
import '../../../Styles/App/Apartado/Apartado.css'
import Theme from '../../../Styles/Theme'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { Alert } from '@mui/material'
import { createTheme, useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { GET_LIST_APA_BY_IDCLI } from '../../../Constants/ApiConstants'
import { CLIENTES_SECTION_VIEW } from './../../../Constants/routesConstants'
import { TablePagination } from '@mui/material'
import * as locales from '@mui/material/locale'

const ApartadoProceso = (props) => {
	const [locale, setLocale] = React.useState('esES')
	const navigate = useNavigate()
	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin
	const [_Ocultar, setOcultar] = useState(false)
	const [_Ocultar2, setOcultar2] = useState(false)
	const [_ListApart, setListApart] = useState([])

	const hadleApartadoClienteView = () => {
		navigate(CLIENTES_SECTION_PRO_VIEW)
	}
	const theme = useTheme()
	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales[locale]),
		[locale, theme]
	)
	const [IdImgPreview, setIdImgPreview] = useState()
	const [openModalImgPreview, setModalImgPreview] = useState(false)
	const handleOpenImgPreview = () => {
		setModalImgPreview(!openModalImgPreview)
	}

	/*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION	::::::::::::::::::::::::::::::::::::::::::::::: */
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const getList = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_LIST_APA_BY_IDCLI, userInfo.intIdUsuario)
			.then((response) => {
				console.log(response)
				setListApart(response)
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				props.setCloseLoadingScreen()
				//setIdPropuesta(null)
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getList()
	}, [])
	return (
		<di>
			<div className='containerViewUser py4 paddingTablets' hidden={_Ocultar}>
				<div className='flex-start w90 pb2 pl1'>
					<div className='chip-breadcrumbs'>
						<span className='letter-breadcrumbs'>A</span>
					</div>

					<div role='presentation' className='ml2'>
						<Breadcrumbs aria-label='breadcrumb' separator='›'>
							<Link
								underline='hover'
								className='breadcrumb-url'
								onClick={() => {
									navigate(CLIENTES_SECTION_VIEW)
								}}
							>
								Apartado
							</Link>
							<Link underline='hover' className='breadcrumb-url bold'>
								Mis Apartados
							</Link>
						</Breadcrumbs>
					</div>
				</div>
				{_ListApart.length === 0 ? null : (
					<div className='box-consigna-pro'>
						{_ListApart
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => (
								<div className='box-list-consigna-pro' key={index}>
									<div className='w20 relative'>
										<img className='img-consigna-pro' src={row.txtUrl} />
										<div className='box-view-absolute'>
											<IconButton
												className='btn-blur'
												onClick={function () {
													setIdImgPreview(row.txtUrl)
													handleOpenImgPreview()
												}}
											>
												<RemoveRedEyeIcon
													className='RemoveEyeIconConsigna'
													sx={{ m: 0, p: 0, color: '#000000' }}
												/>
											</IconButton>
										</div>
									</div>
									<div className='flex-column-start-between w80 pl1'>
										<div className='w100'>
											<div className='flex-between'>
												<span className='titleConsignaPropuestas upperCase ProductTitleMarca'>
													{row.txtCategoria} - {row.txtMarca}
													<span className='mx1'> </span>
													<span className='p titleConsignaPropuestas ProductTitleMarca'></span>
												</span>

												<span className='p textFechaConsignaPropuestas co-grey'>
													Vencimiento: {row.fecFinApartado}
												</span>
											</div>
											<span className='p textFechaConsignaPropuestas ProductTitleModelo bold'>
												Total a pagar:
												<NumberFormat
													value={row.dblPrecioFinal}
													displayType={'text'}
													fixedDecimalScale={true}
													decimalScale={2}
													thousandSeparator={true}
													prefix={'$ '}
													suffix={' MXN'}
												/>
											</span>
											<br />
											<span className='p textFechaConsignaPropuestas ProductTitleModelo bold'>
												SKU: {row.txtSkuReg}
											</span>{' '}
											<br />
											<span className='p textFechaConsignaPropuestas ProductTitleModelo bold'>
												Material: {row.txtMaterial}
											</span>
											<br />
											<span className='p textFechaConsignaPropuestas ProductTitleModelo bold'>
												Plazo: {row.txtPlazo}
											</span>											
											<br />
											<span className='p textFechaConsignaPropuestas ProductTitleModelo bold'>
												Estatus: {row.txtEstatusApartado}
											</span>
											<br />
											{row.txtEstatusApartado === 'Liquidado' ? null : (
												<Button
													className='btn-actions mr1 ProductTitleDescription'
													onClick={function () {
														navigate(
															CLIENTES_SECTION_PRO_PAY + row.lngIdApartado
														)
													}}
												>
													Realizar pago
												</Button>
											)}
										</div>
									</div>
								</div>
							))}
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'
							spacing={2}
						>
							<Grid item xl={12} className='flex'>
								<ThemeProvider theme={themeWithLocale}>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component='div'
										count={_ListApart.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</ThemeProvider>
							</Grid>
						</Grid>
					</div>
				)}
			</div>
			<div hidden={_Ocultar2}>
				<div className='containerViewUser'>
					<Alert
						className='alert-no-data'
						icon={
							<InfoIcon fontSize='inherit' className='alert-icon-no-data' />
						}
					>
						Aún no cuentas con artículos apartados.
					</Alert>
				</div>
			</div>
		</di>
	)
}

export default ApartadoProceso
