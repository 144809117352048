/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Slide from '@mui/material/Slide'
import { Dialog, Button } from '@mui/material'
import logo from '../../Images/logo-barter-sin-fondo.png'
import Lottie from 'lottie-react'
import loadingCircle from '../../Lottie/Success.json'
import { useNavigate } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const ModalAgradecimiento = (props) => {
  let navigate = useNavigate()

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          background:
            window.screen.width <= 600
              ? 'rgba(255, 255, 255, 0.2)'
              : 'rgba(255, 255, 255, 0.2)',
        },
      }}
      className={'backdropfilter'}
    >
      <div className="container-modal-Agradecimiento">
        <div className="header-modal-Agradecimiento flex-between"></div>
        <div className="container-body-modal-Agradecimiento">
          <img
            src={logo}
            className="imgBLencuesta"
            style={{ display: 'block', margin: '0 auto', marginTop: 20 }}
          />
          <div className="section-Msg-Agradecimiento">
            <p>
              Gracias por responder nuestra encuesta, en{' '}
              <span className="bold co-black">FoundIT</span> las opiniones de
              nuestros clientes son tan valiosas para seguir mejorando.
            </p>

            <div
              style={{
                width: '200px',
                margin: 'auto',
              }}
            >
              <Lottie
                animationData={loadingCircle}
                loop={false}
                autoplay={true}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="back-gradient-7 bord-radius-10  font-letter font-light mt-30"
              onClick={function () {
                navigate('/')
              }}
            >
              Aceptar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ModalAgradecimiento
