import React, { useState } from 'react'
import '../../Styles/StylesMB.css'
import iconEco from '../../Images/MB-Consigna/ecologia-icon.png'
import RegisterMb from '../../Components/Login/RegisterMb'
import Button from '@mui/material/Button'
import { gtmOpenRegisterUNL } from '../../Constants/GoogleTagManager/gtmConstants'
import '../../Styles/App/CardsSectionsHome.css'
import '../../Styles/Foundit/ventasMb.css'
import VSection1Web from '../../Components/SectionsVentas/Section1/VSection1Web'
import VSection2Web from '../../Components/SectionsVentas/Section2/VSerction2Web'
import VSection3Web from '../../Components/SectionsVentas/Section3/VSection3Web'
const MBConsignaView = (props) => {
  //============== OPEN REGISTRO=========================
  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }

  return (
    <div className="bg-white-color">
      {/* //? ******************* SECTION 1 ************************* */}
      <VSection1Web props={props} />
      {/* //? ******************* SECTION 2 ************************* */}
      <VSection2Web props={props} />
      {/* //? ******************* SECTION 3 ************************* */}
      <VSection3Web props={props} />
      <RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} />
    </div>
  )
}

export default MBConsignaView
