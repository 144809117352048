import axios from 'axios'
import requests from '../Components/AxiosCalls/AxiosCall'
import {
	USER_DETAILS_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGOUT,
	USER_REGISTER_FAIL,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS,
	USER_UPDATE_PROFILE_FAIL,
	USER_UPDATE_PROFILE_REQUEST,
	USER_UPDATE_PROFILE_SUCCESS,
	USER_DETAILS_RESET,
	USER_LIST_FAIL,
	USER_LIST_SUCCESS,
	USER_LIST_REQUEST,
	USER_DELETE_REQUEST,
	USER_DELETE_SUCCESS,
	USER_DELETE_FAIL,
	USER_UPDATE_FAIL,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_REQUEST,
	USER_LIST_RESET,
} from '../Constants/userConstants'
import { SIGNIN, CONFIRMAR_EMAIL } from './../Constants/ApiConstants'

import { gtmSendLoginUNL, gtmLogOut } from '../Constants/GoogleTagManager/gtmConstants'

import { useNavigate } from 'react-router-dom'

export const login = (sendData, props) => async (dispatch) => {
	props.props.setOpenLoadingScreen()

	dispatch({
		type: USER_LOGIN_REQUEST,
	})
	requests
		.post(SIGNIN, sendData)
		.then((response) => {
			localStorage.setItem('userInfo', JSON.stringify(response))
			gtmSendLoginUNL()
			dispatch({
				type: USER_LOGIN_SUCCESS,
				payload: response,
			})
			props.props.setCloseLoadingScreen()
		})
		.catch((error) => {
			dispatch({
				type: USER_LOGIN_FAIL,
				payload: error.response && error.data ? error.data : error.message,
			})
			props.props.setTypeSnackBar('warning')
			props.props.setMessageAlertWarning(error.data.mensaje)
			props.props.setOpenAlertWarning(true)
			props.props.setCloseLoadingScreen()
		})
}

export const loginConfirma = (sendData, props) => async (dispatch) => {	
	props.setOpenLoadingScreen()
	dispatch({
		type: USER_LOGIN_REQUEST,
	})
	requests
		.post(CONFIRMAR_EMAIL, sendData)
		.then((response) => {
			localStorage.setItem('userInfo', JSON.stringify(response))
			gtmSendLoginUNL()
			dispatch({
				type: USER_LOGIN_SUCCESS,
				payload: response,
			})
			props.setTypeSnackBar('success')
			props.setMessageAlert('Tu email ha sido confirmado con éxito.')
			props.setOpenAlert(true)
			props.setCloseLoadingScreen()
		})
		.catch((error) => {
			dispatch({
				type: USER_LOGIN_FAIL,
				payload: error.response && error.data ? error.data : error.message,
			})
			props.setTypeSnackBar('warning')
			props.setMessageAlertWarning(error.data.mensaje)
			props.setOpenAlertWarning(true)
			props.setCloseLoadingScreen()
		})
}

export const login2 = (sendData, props) => async (dispatch) => {	
	props.props.props.setOpenLoadingScreen()

	dispatch({
		type: USER_LOGIN_REQUEST,
	})
	requests
		.post(SIGNIN, sendData)
		.then((response) => {
			localStorage.setItem('userInfo', JSON.stringify(response))
			gtmSendLoginUNL()
			dispatch({
				type: USER_LOGIN_SUCCESS,
				payload: response,
			})
			props.props.props.setCloseLoadingScreen()
		})
		.catch((error) => {
			dispatch({
				type: USER_LOGIN_FAIL,
				payload: error.response && error.data ? error.data : error.message,
			})
			props.props.props.setTypeSnackBar('warning')
			props.props.props.setMessageAlertWarning(error.data.mensaje)
			props.props.props.setOpenAlertWarning(true)
			props.props.props.setCloseLoadingScreen()
		})
}

export const loginApa = (sendData, props) => async (dispatch) => {	
	props.props.setOpenLoadingScreen()
	dispatch({
		type: USER_LOGIN_REQUEST,
	})
	requests
		.post(SIGNIN, sendData)
		.then((response) => {
			localStorage.setItem('userInfo', JSON.stringify(response))
			gtmSendLoginUNL()
			dispatch({
				type: USER_LOGIN_SUCCESS,
				payload: response,
			})
			
			props.props.setCloseLoadingScreen()
		})
		.catch((error) => {
			dispatch({
				type: USER_LOGIN_FAIL,
				payload: error.response && error.data ? error.data : error.message,
			})
			props.props.setTypeSnackBar('warning')
			props.props.setMessageAlertWarning(error.data.mensaje)
			props.props.setOpenAlertWarning(true)
			props.props.setCloseLoadingScreen()
		})
}

export const logout = () => (dispatch) => {
	localStorage.removeItem('userInfo')
	localStorage.removeItem('profile')
	gtmLogOut()
	dispatch({ type: USER_LOGOUT })
	dispatch({ type: USER_DETAILS_RESET })
	dispatch({ type: USER_LIST_RESET })
}

export const register = (name, email, password) => async (dispatch) => {
	try {
		dispatch({
			type: USER_REGISTER_REQUEST,
		})

		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.post(
			`${process.env.REACT_APP_BACKEND_API}/api/users`,
			{ name, email, password },
			config
		)

		dispatch({
			type: USER_REGISTER_SUCCESS,
			payload: data,
		})

		dispatch({
			type: USER_LOGIN_SUCCESS,
			payload: data,
		})

		localStorage.setItem('userInfo', JSON.stringify(data))
	} catch (error) {
		dispatch({
			type: USER_REGISTER_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}

export const getUserDetails = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_DETAILS_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			`${process.env.REACT_APP_BACKEND_API}/api/users/${id}`,
			config
		)

		dispatch({
			type: USER_DETAILS_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
		}
		dispatch({
			type: USER_DETAILS_FAIL,
			payload: message,
		})
	}
}

export const updateUserProfile = (user) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_UPDATE_PROFILE_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(
			`${process.env.REACT_APP_BACKEND_API}/api/users/profile`,
			user,
			config
		)

		dispatch({
			type: USER_UPDATE_PROFILE_SUCCESS,
			payload: data,
		})
		dispatch({
			type: USER_LOGIN_SUCCESS,
			payload: data,
		})
		localStorage.setItem('userInfo', JSON.stringify(data))
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
		}
		dispatch({
			type: USER_UPDATE_PROFILE_FAIL,
			payload: message,
		})
	}
}

export const listUsers = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_LIST_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			`${process.env.REACT_APP_BACKEND_API}/api/users`,
			config
		)

		dispatch({
			type: USER_LIST_SUCCESS,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
		}
		dispatch({
			type: USER_LIST_FAIL,
			payload: message,
		})
	}
}

export const deleteUser = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_DELETE_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(
			`${process.env.REACT_APP_BACKEND_API}/api/users/${id}`,
			config
		)

		dispatch({ type: USER_DELETE_SUCCESS })
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
		}
		dispatch({
			type: USER_DELETE_FAIL,
			payload: message,
		})
	}
}

export const updateUser = (user) => async (dispatch, getState) => {
	try {
		dispatch({
			type: USER_UPDATE_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(
			`${process.env.REACT_APP_BACKEND_API}/api/users/${user._id}`,
			user,
			config
		)

		dispatch({ type: USER_UPDATE_SUCCESS })

		dispatch({ type: USER_DETAILS_SUCCESS, payload: data })

		dispatch({ type: USER_DETAILS_RESET })
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === 'Not authorized, token failed') {
			dispatch(logout())
		}
		dispatch({
			type: USER_UPDATE_FAIL,
			payload: message,
		})
	}
}
