import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/empeñaMB.css'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CUESTIONARIO, CITAS_MB } from '../../../Constants/routesConstants'
import RegisterMb2 from '../../Login/RegisterMb2'
const ESection1Web = (props) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const navigate = useNavigate()

  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    // gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }
  return (
    <section className="EmpeñaSection1MB">
      <div className="body">
        <div className="contentSection1MB">
          <div>
            <p className="titlesection2MB">Empeña tus</p>
            <p className="titlesection2MB">Artículos</p>
            <p className="titlesection2MB">con</p>
            <p className="titlesection2MB">Discreción</p>
          </div>
          <p className="subtitlesection2MB">
            Apreciamos la importancia de preservar tus artículos y te brindamos
            la mejor opción financiera en nuestro servicio de empeño de alta
            calidad.
          </p>
          <div className="bottomLink">
            <Button
              onClick={
                userInfo
                  ? function () {
                      navigate(CUESTIONARIO)
                      // gtmGoTo_EmpeñaAqui_Log()
                    }
                  : function () {
                      handleOpenSingUp()
                    }
              }
              className="btnLink"
            >
              Empeñar
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png '
                }
              />
            </Button>
          </div>
        </div>
        <div className="contentSection2MB"></div>
      </div>
      <RegisterMb2
        open={openSingUp}
        onClose={handleCloseSingUp}
        props={props}
      />
    </section>
  )
}

export default ESection1Web
