//USUARIOS
export const SIGNIN = 'Login/LoginMb'
export const USUARIOS_LIST = 'Users/Lista'
export const USUARIOS_LIST_CXP = 'Users/ObtenerListaCxp'
export const USUARIOS_BY_ID = 'Users/BuscarPorId'
export const USUARIOS_ADD = 'Users/Crear'
export const USUARIOS_EDIT = 'Users/Editar'
export const USUARIOS_DELETE = 'Users/Eliminar'

//MARCAS
export const MARCAS_ADD = 'Marcas/Crear'
export const MARCAS_EDIT = 'Marcas/Editar'
export const MARCAS_LIST = 'Marcas/ObtenerLista'
export const MARCAS_LIST_PREMIUM = 'Marcas/ObtenerListaPremium'
export const MARCAS_LIST_LUXURY = 'Marcas/ObtenerListaLuxury'
export const MARCAS_BY_ID = 'Marcas/ObtenerPorId'
export const MARCAS_PARAMETRO = 'Marcas/ObtenerListaParametro?nombre='

//MATERIALES
export const MATERIAL_LIST = 'Materiales/ObtenerLista'
export const MATERIAL_LIST_BY_CATEGORIA =
  'Materiales/ObtenerListaPorIdCategoria'
export const MATERIAL_ADD = 'Materiales/Crear'
export const MATERIAL_DELETE = 'Materiales/Eliminar'
export const MATERIAL_EDIT = 'Materiales/Editar'
export const MATERIAL_BY_ID = 'Materiales/ObtenerPorId'

//Tallas
export const TALLAS_LIST = 'Talla/ObtenerLista'
export const TALLAS_ADD = 'Talla/Crear'
export const TALLAS_DELETE = 'Talla/Eliminar'
export const TALLAS_EDIT = 'Talla/Editar'
export const TALLAS_BY_ID = 'Talla/ObtenerPorId'

//Colores
export const COLORES_LIST = 'Color/ObtenerLista'
export const COLORES_ADD = 'Color/Crear'
export const COLORES_DELETE = 'Color/Eliminar'
export const COLORES_EDIT = 'Color/Editar'
export const COLORES_BY_ID = 'Color/ObtenerPorId'

//Categoria
export const CATEGORIA_LIST = 'Categorias/ObtenerLista'
export const CATEGORIA_BY_ID = 'Categorias/ObtenerPorId'

//Clase
export const CLASE_LIST = 'Clase/ObtenerLista'
export const CLASE_BY_ID = 'Clase/ObtenerPorId'

//Estado
export const ESTADOS_LIST = 'Estado/ObtenerListaEstados'
export const ESTADO_BY_ID = 'Estado/ObtenerEstadoPorId'
export const CIUDADES_LIST = 'Estado/ObtenerListaCiudades'
export const CIUDADES_BY_ID = 'Estado/ObtenerCiudadPorId'

//Generos
export const GENERO_LIST = 'Generos/ObtenerLista'
export const GENERO_BY_ID = 'Generos/ObtenerPorId'

//Monedas
export const MONEDAS_LIST = 'Moneda/ObtenerLista'
export const MONEDAS_BY_ID = 'Moneda/ObtenerPorId'

//ProductosH
export const PRODUCTOS_H_LIST = 'ProductosH/ObtenerLista'
export const PRODUCTOS_H_LIST_CATEGORIA =
  'ProductosH/ObtenerListaPorIdCategoria'
export const PRODUCTOS_H_BY_ID = 'ProductosH/ObtenerPorId'

//SubproductosH
export const SUBPRODUCTOS_H_LIST = 'SubProductosH/ObtenerLista'
export const SUBPRODUCTO_H_BY_ID = 'SubProductosH/ObtenerPorIdSubProducto'

//ProductosM
export const PRODUCTOS_M_LIST = 'ProductosM/ObtenerLista'
export const PRODUCTOS_M_LIST_BY_CATEGORIA =
  'ProductosM/ObtenerListaPorIdCategoria'
export const PRODUCTOS_M_BY_ID = 'ProductosM/ObtenerPorId'

//SubproductosM
export const SUBPRODUCTOS_M_LIST = 'SubProductosM/ObtenerLista'
export const SUBPRODUCTOS_M_BY_ID = 'SubProductosM/ObtenerPorIdSubProducto'

//StatusPago
export const STATUS_PAGO_LIST = 'StatusPago/ObtenerLista'
export const STATUS_PAGO_BY_ID = 'StatusPago/ObtenerPorId'

//StatusPro
export const STATUS_PRO_LIST = 'StatusPro/ObtenerLista'
export const STATUS_PRO_BY_ID = 'StatusPro/ObtenerPorId'

//Tipo Actividad
export const TIPO_ACTIVIDAD_LIST = 'TipoActividad/ObtenerLista'
export const TIPO_ACTIVIDAD_LIST_UBI = 'TipoActividad/ObtenerListaUbicacion'
export const TIPO_ACTIVIDAD_BY_ID = 'TipoActividad/ObtenerPorId'

//BANCO
export const BANCO_LIST = 'Banco/ObtenerLista'
export const BANCO_ADD = 'Banco/Crear'
export const BANCO_EDIT = 'Banco/Editar'
export const BANCO_DELETE = 'Banco/Eliminar'
export const BANCO_BY_ID = 'Banco/ObtenerPorId'

//CLIENTES
export const CLIENTES_LIST = 'Clientes/Lista'
export const CLIENTES_ADD = 'Clientes/Crear'
export const CLIENTES_REGISTRER = 'Clientes/RegistrarClienteMB'
export const CLIENTES_REGISTRER_EDIT = 'Clientes/EditarRegistroCliente'
export const CLIENTES_EDIT = 'Clientes/Editar'
export const CLIENTES_BY_ID = 'Clientes/BuscarPorId'
export const CLIENTES_BY_ID_USER = 'Clientes/ObtenerPorIdUsuario'
export const CLIENTES_DELETE = 'Clientes/Eliminar'
export const CLIENTES_BY_PARAMS = 'Clientes/ObtenerListaParametro?nombre='
export const CLIENTES_RESET_PASSWORD = 'Clientes/ResetearContrasena'
export const CLIENTES_PERFIL_COMPLETO = 'Clientes/PerfilCompleto'

export const CLIENTES_CUENTA_BANCO = 'CuentasBancarias/BuscarPorId'

//CLIENTES - DIRECCIÓN
export const CLIENTES_DIRECCION = 'CliDirCLientes/BuscarPorId'

// Dashboard
export const TOTAL_LIST = 'Dashboard/Total'
export const TOTAL_CANTIDADES_LIST = 'Dashboard/TotalCantidades'

// Productos
export const PRODUCTOS_LIST = 'Productos/GetLista'
export const PRODUCTOS_VENDIDOS_LIST = 'Prodcutos/GetListaVendido'
export const PRODUCTOS_ADD = 'Productos/Crear'
export const PRODUCTOS_EDIT = 'Productos/Editar'
export const PRODUCTOS_GET_BY_ID = 'Productos/BuscarPorId'
export const PRODUCTOS_DETAILS = 'Productos/BuscarPorIdDetalles'
export const PRODUCTOS_DETAILS_BY_SKU = 'Productos/ObtenerDetallePorSKU'
export const PRODUCTOS_DETAILS_BY_SKUP = 'Productos/ObtenerDetallePorSKUPlus'
export const PORCENTAJE_LIST = 'MbPorApartado/ObtenerLista'
export const PORCENTAJE_PORID = 'MbPorApartado/ObtenerPorId/'

// Imagen Producto
export const IMG_PRODUCTO_ADD = 'ImagenProducto/Crear'

// Cli Pro
export const CLI_PRO_ADD = 'CliPro/Crear'
export const CLI_PRO_EDIT = 'CliPro/Editar'
export const CLI_PRO_BY_ID = 'CliProProducto/BuscarCliProPorIdPro'

// Contratos
export const CONTRATOS_LIST = 'Contratos/Lista'
export const CONTRATOS_PARAMS = 'Contratos/ObtenerListaParametro?contrato='
export const CONTRATOS_BY_CLIENT = 'Contratos/ObtenerListaCliente'

// Negocios
export const NEGOCIOS_LIST = 'Negocios/GetLista'

// Ct Producto
export const CT_PRODUCTO_LIST = 'CtProducto/Lista'

// Reportes
export const GRAFICAS = 'Dashboard/TotalCantidades'

//DEPARTAMENTOS
export const DEPARTAMENTOS_LIST = 'DepartamentoCxp/ObtenerLista'
export const DEPARTAMENTOS_ADD = 'DepartamentoCxp/Crear'
export const DEPARTAMENTOS_EDIT = 'DepartamentoCxp/Editar'
export const DEPARTAMENTOS_DELETE = 'DepartamentoCxp/Eliminar'
export const DEPARTAMENTOS_BY_ID = 'DepartamentoCxp/ObtenerPorId'

//MONEDAS CXP
export const MONEDASCXP_LIST = 'MonedaCxp/ObtenerLista'
export const MONEDASCXP_ADD = 'MonedaCxp/Crear'
export const MONEDASCXP_EDIT = 'MonedaCxp/Editar'
export const MONEDASCXP_BY_ID = 'MonedaCxp/ObtenerPorId'

//NEGOCIOS CXP
export const NEGOCIOSCXP_LIST = 'NegocioCxp/ObtenerLista'
export const NEGOCIOSCXP_ADD = 'NegocioCxp/Crear'
export const NEGOCIOSCXP_EDIT = 'NegocioCxp/Editar'
export const NEGOCIOSCXP_BY_ID = 'NegocioCxp/ObtenerPorId'
export const NEGOCIOSCXP_DELETE = 'NegocioCxp/Eliminar'

//PLAZAS
export const PLAZAS_LIST = 'PlazaCxp/ObtenerLista'
export const PLAZAS_ADD = 'PlazaCxp/Crear'
export const PLAZAS_EDIT = 'PlazaCxp/Editar'
export const PLAZAS_BY_ID = 'PlazaCxp/ObtenerPorId'
export const PLAZAS_DELETE = 'PlazaCxp/Eliminar'

//ROLES
export const ROLES_LIST = 'RolesCxp/ObtenerLista'
export const ROLES_ADD = 'RolesCxp/Crear'
export const ROLES_EDIT = 'RolesCxp/Editar'
export const ROLES_BY_ID = 'RolesCxp/ObtenerPorId'
export const ROLES_DELETE = 'RolesCxp/Eliminar'

//STATUS
export const STATUS_LIST = 'StatusCxp/ObtenerLista'
export const STATUS_ADD = 'StatusCxp/Crear'
export const STATUS_EDIT = 'StatusCxp/Editar'
export const STATUS_BY_ID = 'StatusCxp/ObtenerPorId'
export const STATUS_DELETE = 'StatusCxp/Eliminar'

//TIPOS DE DOCUMENTOS
export const TIPOS_DOCUMENTOS_LIST = 'TipoDocCxp/ObtenerLista'
export const TIPOS_DOCUMENTOS_ADD = 'TipoDocCxp/Crear'
export const TIPOS_DOCUMENTOS_EDIT = 'TipoDocCxp/Editar'
export const TIPOS_DOCUMENTOS_BY_ID = 'TipoDocCxp/ObtenerPorId'
export const TIPOS_DOCUMENTOS_DELETE = 'TipoDocCxp/Eliminar'

//TIPOS DE GASTOS
export const TIPOS_GASTOS_LIST = 'TipoGastoCxp/ObtenerLista'
export const TIPOS_GASTOS_LIST_BY_SERVICE =
  'TipoGastoCxp/ObtnerListaPorServicio'
export const TIPOS_GASTOS_ADD = 'TipoGastoCxp/Crear'
export const TIPOS_GASTOS_EDIT = 'TipoGastoCxp/Editar'
export const TIPOS_GASTOS_BY_ID = 'TipoGastoCxp/ObtenerPorId'
export const TIPOS_GASTOS_DELETE = 'TipoGastoCxp/Eliminar'

//UBICACION
export const UBICACION_LIST = 'Ubicacion/ObtenerLista'
export const UBICACION_LIST_TIPO_AD = 'Ubicacion/ObtenerListaPorTipoAd'
export const UBICACION_ADD = 'Ubicacion/Crear'
export const UBICACION_EDIT = 'Ubicacion/Editar'
export const UBICACION_BY_ID = 'Ubicacion/ObtenerPorId'

//ALMACEN
export const ALMACEN_LIST = 'Almacen/ObtenerListaVM'
export const ALMACEN_ADD = 'Almacen/Crear'
export const ALMACEN_EDIT = 'Almacen/Editar'
export const ALMACEN_BY_ID = 'Almacen/ObtenerPorId'
export const ALMACEN_DETAILS_BY_ID = 'Almacen/ObtenerVMPorId'

//STATUS ALMACEN
export const STATUS_ALMACEN = 'StatusAlm/ObtenerLista'

//TIPOS DE IVA
export const TIPOS_IVA_LIST = 'TipoIvaCxp/ObtenerLista'
export const TIPOS_IVA_ADD = 'TipoIvaCxp/Crear'
export const TIPOS_IVA_EDIT = 'TipoIvaCxp/Editar'
export const TIPOS_IVA_BY_ID = 'TipoIvaCxp/ObtenerPorId'
export const TIPOS_IVA_DELETE = 'TipoIvaCxp/Eliminar'

//CUESTIONARIO
export const CUESTIONARIO_ADD = 'AtbRegistro/Crear'
export const CUESTIONARIO_DINAMICO_ADD = 'AtbRegistroDinamico/Crear'
export const CUESTIONARIO_DINAMICO_LISTVM = 'AtbRegistroDinamico/ObtenerListaVM'
export const CUESTIONARIO_DINAMICO_BY_ID = 'AtbRegistroDinamico/ObtenerVMPorId'
export const CUESTIONARIO_DINAMICO_ADD_IMG = 'TbRegDin/Crear'
export const CUESTIONARIO_IMG_ADD = 'TbAtbRegIma/Crear'
export const CUESTIONARIO_NO_VIABLE = 'AtbRegistro/ArticuloNoViable' //Envia mail de propuesta no aceptada
export const C_ARTICULO_LIST = 'CtsCuestionario/Articulo'
export const C_ARTICULO_NO_RO_LIST = 'CtsCuestionario/ArticuloNoRopa'
export const C_PRECIO_LIST = 'CtsCuestionario/Precio'
export const C_MATERIAL_LIST = 'CtsCuestionario/Material'
export const C_TIEMPO_LIST = 'CtsCuestionario/TiempoUso'
export const C_ESTADO_LIST = 'CtsCuestionario/Estado'
export const C_DESGASTE_LIST = 'CtsCuestionario/Desgaste'
export const C_ACCESORIOS_LIST = 'CtsCuestionario/AccesorioLista'
export const C_JOYERIA01_LIST = 'CtsCuestionario/Joyeria01'
export const C_JOYERIA02_LIST = 'CtsCuestionario/Joyeria02'
export const PREEVALUACIONES_LIST = 'AtbRegistro/ObtenerListaVM'
export const PREEVALUACION_INFO = 'AtbRegistro/BuscarPorIdInfo'
export const CUESTIONARIO_DEC_CAN = 'CancelDev/Crear'
export const CANCELACIONES_LIST = 'CancelDev/ObtenerLista'
export const CANCELACIONES_INFO = 'CancelDev/BuscarPorIdInfo'

//TIPOS DE PAGOS
export const TIPOS_PAGOS_LIST = 'TipoPagoCxp/ObtenerLista'
export const TIPOS_PAGOS_ADD = 'TipoPagoCxp/Crear'
export const TIPOS_PAGOS_EDIT = 'TipoPagoCxp/Editar'
export const TIPOS_PAGOS_BY_ID = 'TipoPagoCxp/ObtenerPorId'
export const TIPOS_PAGOS_DELETE = 'TipoPagoCxp/Eliminar'

//TIPOS DE SALDOS
export const TIPOS_SALDOS_LIST = 'TipoSaldoCxp/ObtenerLista'
export const TIPOS_SALDOS_ADD = 'TipoSaldoCxp/Crear'
export const TIPOS_SALDOS_EDIT = 'TipoSaldoCxp/Editar'
export const TIPOS_SALDOS_BY_ID = 'TipoSaldoCxp/ObtenerPorId'
export const TIPOS_SALDOS_DELETE = 'TipoSaldoCxp/Eliminar'

//DETALLE
export const DETALLE_LIST = 'CxpDetalle/GetList'
export const DETALLE_ADD = 'CxpDetalle/Create'
export const DETALLE_EDIT = 'CxpDetalle/Update'
export const DETALLE_BY_ID = 'CxpDetalle/GetListById'
export const DETALLE_DELETE = 'CxpDetalle/Delete'

//SOLICITUDES
export const SOLICITUDES_LIST = 'TbSolicitudesCxp/GetList'
export const SOLICITUDES_LIST_VM = 'TbSolicitudesCxp/GetListVM'
export const SOLICITUDES_ADD = 'TbSolicitudesCxp/Create'
export const SOLICITUDES_EDIT = 'TbSolicitudesCxp/Update'
export const SOLICITUDES_BY_ID = 'TbSolicitudesCxp/GetById'
export const SOLICITUDES_DELETE = 'TbSolicitudesCxp/Delete'
export const SOLICITUDES_PRINT = 'ReportesCxp/Impreso'

//TIPO DE SERVICIOS
export const TIPOS_SERVICIOS_LIST = 'TipoServicioCxp/GetList'
export const TIPOS_SERVICIOS_ADD = 'TipoServicioCxp/Create'
export const TIPOS_SERVICIOS_EDIT = 'TipoServicioCxp/Update'
export const TIPOS_SERVICIOS_BY_ID = 'TipoServicioCxp/GetListById'
export const TIPOS_SERVICIOS_DELETE = 'TipoServicioCxp/Delete'

//ESTADO PRODUCTOS
export const EST_PRODUCTOS_LIST = 'EstadoProductos/ObtenerLista'
export const EST_PRODUCTOS_BY_ID = 'EstadoProductos/ObtenerCiudadPorId'

//COLECCIONES
export const COLECCIONES_LIST = 'Coleccion/ObtenerLista'
export const COLECCIONES_BY_ID = 'Coleccion/ObtenerCiudadPorId'
export const COLEECIONES_ADD = 'Coleccion/Crear'
export const COLECCIONES_EDIT = 'Coleccion/Editar'
export const COLECCIONES_DELETE = 'Coleccion/Eliminar'
export const COLECCIONES_BY_ID_PRODUCTO = 'Coleccion/ObtenerColeccioPorIdProd'

//CUENTAS PG
export const CUENTAPG_LIST = 'CuentaPg/GetList'
export const CUENTASPG_LIST_VM = 'CuentaPg/ObtenerListaVM'
export const CUENTAPG_BY_ID = 'CuentaPg/GetListById'
export const CUENTAPG_ADD = 'CuentaPg/Create'
export const CUENTAPG_EDIT = 'CuentaPg/Update'
export const CUENTAPG_DELETE = 'CuentaPg/Delete'

//PROVEEDORES
export const PROVEEDORES_LIST = 'TbProveedor/GetList'
export const PROVEEDORES_LIST_BY_ID = 'TbProveedor/GetListById'
export const PROVEEDORES_ADD = 'TbProveedor/Create'
export const PROVEEDORES_EDIT = 'TbProveedor/Update'
export const PROVEEDORES_DELETE = 'TbProveedor/Delete'

//CUENTAS PROVEEDOR
export const CUENTA_PROVEDOR_LIST = 'CuentasProv/GetList'
export const CUENTA_PROVEDOR_LIST_VM = 'CuentasProv/GetListVM'
export const CUENTA_PROVEDOR_LIST_BY_ID = 'CuentasProv/GetListByIdProveedor'
export const CUENTA_PROVEDOR_BY_ID = 'CuentasProv/GetById'
export const CUENTA_PROVEDOR_ADD = 'CuentasProv/Create'
export const CUENTA_PROVEDOR_EDIT = 'CuentasProv/Update'
export const CUENTA_PROVEDOR_DELETE = 'CuentasProv/Delete'

//EMPRESAS PAGADORAS
export const EMPRESA_PG_LIST = 'TbEmpresasPg/GetList'
export const EMPRESA_PG_LIST_VM = 'TbEmpresasPg/GetListVM'
export const EMPRESA_PG_BY_ID = 'TbEmpresasPg/GetListById'
export const EMPRESA_PG_ADD = 'TbEmpresasPg/Create'
export const EMPRESA_PG_EDIT = 'TbEmpresasPg/Update'
export const EMPRESA_PG_DELETE = 'TbEmpresasPg/Delete'

//SERVIICIOS - GASTOS
export const SERV_GAST_LIST = 'SerGast/ObtenerLista'
export const SERV_GAST_LIST_BY_ID = 'SerGast/ObtenerPorId'
export const SERV_GAST_BY_SERVICE = 'SerGast/ObtenerListaPorId'
export const SERV_GAST_ADD = 'SerGast/Crear'
export const SERV_GAST_EDIT = 'SerGast/Editar'

//SALDOS PROVEEDORES
export const SALDO_PROVEEDOR_LIST = 'Saldos/ObtenerLista'
export const SALDO_PROVEEDOR_LIST_VM = 'Saldos/ObtenerListaVM'
export const SALDO_PROVEEDOR_ADD = 'Saldos/Crear'
export const SALDO_PROVEEDOR_EDIT = 'Saldos/Editar'
export const SALDO_PROVEEDOR_BY_ID = 'Saldos/ObtenerPorId'

//CREDITO PROVEEDORES
export const CREDITO_PROVEEDOR_LIST = 'TbCredito/GetList'
export const CREDITO_PROVEEDOR_LIST_VM = 'TbCredito/GetListVM'
export const CREDITO_PROVEEDOR_ADD = 'TbCredito/Create'
export const CREDITO_PROVEEDOR_EDIT = 'TbCredito/Update'
export const CREDITO_PROVEEDOR_BY_ID = 'TbCredito/GetById'

//ARCHIVOS CxP
export const ARCHIVO_ADD = 'RutaArchivo/Archivos'
export const ARCHIVO_LIST_BY_ID = 'RutaArchivo/GetListById'
export const ARCHIVO_BY_ID = 'RutaArchivo/GetById/'

//REPORTES CxP
export const REPORTE_CXP_IMPRESION = 'ReportesCxp/Impreso'
export const REPORTE_CXP_SOLICITUD_EXCEL = 'ReportesCxp/ReportSolicitudExcel'
export const REPORTE_CXP_FEC_VENCE_EXCEL = 'ReportesCxp/ReporteVencimientoExcel'
export const REPORTE_CXP_FEC_PAGO_EXCEL = 'ReportesCxp/ReporteFechaPagoExcel'
export const REPORTE_CXP_FEC_PAGO_EXCEL_PARAM =
  'ReportesCxp/ReporteFechaPagoExcel?FechaIncio='
export const REPORTE_CXP_FEC_VENCE = 'ReportesCxp/ReporteVencimiento'
export const REPORTE_CXP_FEC_REGISTRO = 'ReportesCxp/ReporteFechaRegistro'
export const REPORTE_CXP_FEC_PAGO = 'ReportesCxp/ReporteFechaPago'
export const REPORTE_CXP_GRAFICAS = 'ReportesCxp/GetDashboard'
export const REPORTE_CXP_GRAFICAS_MESES = 'ReportesCxp/GraficasMeses'
export const REPORTE_CXP_DEPTOS_SOL = 'ReportesCxp/DepartCountSol'

// FILES
export const SAVE_FILE_PRODUCT = 'Files/SaveChanges'
export const GET_LIST_FILE_PRODUCT_BY_ID = 'Files/ListByIdPorduct'
export const DELETE_FILE_PRODUCT_BY_ID = 'Files/DeleteFile'

//CRM
export const GET_LIST_STATUSCRM = 'StatusCrm/ObtenerLista'
export const SEND_STATUSCRM =
  'AtbRegistro/EditarById/{LngIdRegistro}/{IntIdStatusCrm}'
export const SEND_STATUSCRM_MB =
  'AtbRegistro/EditarByIdMB/{LngIdRegistro}/{IntIdStatusCrm}'

//DESCUENTO CONSIGNA
export const DESCUENTO_CONSIGNA_LIST = 'DescuentoConsigna/ObtenerLista'
export const DESCUENTO_CONSIGNA_BY_ID = 'DescuentoConsigna/ObtenerPorId'
export const DESCUENTO_CONSIGNA_CREATE = 'DescuentoConsigna/Crear'
export const DESCUENTO_CONSIGNA_EDIT = 'DescuentoConsigna/Editar'

//PROPUESTAS MB
export const PROPUESTA_LIST = 'TbMbPropuesta/ObtenerLista'
export const PROPUESTA_LIST_BY_ID_PROP =
  'TbMbPropuesta/ObtenerListaPorIdRegistro'
export const PROPUESTA_LIST_BY_ID_PREEV =
  'TbMbPropuesta/ObtenerLPorIdPreevaluacion'
export const PROPUESTA_LIST_BY_ID_CLIENTE =
  'TbMbPropuesta/ObtenerListaEmpenoIdCliente' //EMPEÑOS
export const PROPUESTA_LIST_CONS_BY_ID_CLIENTE =
  'TbMbPropuesta/ObtenerListaConsignaIdCliente'
export const PROPUESTA_LIST_INTER_BY_ID_CLIENTE =
  'TbMbPropuesta/ObtenerListaIntercambioIdCliente'

export const PROPUESTA_CONFOR_BY_ID =
  'TbMbPropuesta/ObtenerConsignaForaneasIdProp'
export const PROPUESTA__LIST_CONFOR_BY_ID_CLIENTE =
  'TbMbPropuesta/ObtenerListaConsignaForaneasIdCliente'
export const PROPUESTA_LIST_CONFOR_DIF_ID =
  'TbMbPropuesta/ObtenerListaConsignaForaneas'

export const PROPUESTA_EMPFOR_BY_ID =
  'TbMbPropuesta/ObtenerEmpenosForaneosIdProp'
export const PROPUESTA_LIST_EMPFOR_BY_ID_CLIENTE =
  'TbMbPropuesta/ObtenerListaEmpenosForaneosIdCliente'
export const PROPUESTA_LIST_EMPFOR_DIF_ID =
  'TbMbPropuesta/ObtenerListaEmpenosForaneos'

export const PROPUESTA_BY_ID = 'TbMbPropuesta/ObtenerPorId'
export const PROPUESTA_CREATE = 'TbMbPropuesta/Crear'
export const PROPUESTA_EDIT = 'TbMbPropuesta/Editar'

//CITAS MB
export const CITA_LIST = 'TbMbCita/ObtenerLista'
export const CITA_LIST_VM = 'TbMbCita/ObtenerListaVM'
export const CITA_LIST_VM_CON_BY_ID_CLIENT =
  'TbMbCita/ObtenerListaVMConsignaPorIdCliente'
export const CITA_LIST_VM_EMP_BY_ID_CLIENT =
  'TbMbCita/ObtenerListaVMEmpenoPorIdCliente'
export const CITA_LIST_VM_DESEMP_BY_ID_CLIENT =
  'MbPagosEmpenoErp/ObtenerListaPagosPorIdCliente'
export const CITA_LIST_VM_CON_VEN_BY_ID_CLIENT =
  'Productos/ListaConsignasSinVenderIdCliente'
export const CITA_LIST_VM_INT_BY_ID_CLIENT =
  'TbMbCita/ObtenerListaVMIntercambioPorIdCliente'
export const CITA_BY_ID = 'TbMbCita/ObtenerPorId'
export const CITA_VM_BY_ID = 'TbMbCita/ObtenerVMPorId'
export const CITA_BY_ID_PROPUESTA = 'TbMbCita/ObtenerPorIdPropuesta'
export const CITA_BY_ID_PREEVALUACION = 'TbMbCita/ObtenerPorIdPreevaluacion'
export const CITA_CREATE = 'TbMbCita/Crear'
export const CITA_EDIT = 'TbMbCita/Editar'
export const CITA_DELETE = 'TbMbCita/ClienteNoLlego'
export const CITA_POST_CLIENTE_REALIZADO = 'TbMbCita/ClienteRealizado'

//EMPEÑOS CLIENTE MB
export const EMPENO_LIST_BY_ID_CLIENTE = 'Productos/ObtenerListaEmpPorIdCliente'
export const EMPEÑO_BY_ID = 'Productos/ObtenerEmpPorId'

//PAGOS EMPEÑO MB
export const EMPEÑO_PAGO_LIST = 'MbPagosEmpeno/ObtenerLista'
export const EMPEÑO_PAGO_LIST_BY_ID_EMPEÑO =
  'MbPagosEmpeno/ObtenerListaPorIdProducto'
export const EMPEÑO_PAGO_DETALLES = 'MbPagosEmpeno/DetalleEmpeño'
export const EMPEÑO_PAGO_BY_ID = 'MbPagosEmpeno/ObtenerPorId'
export const EMPEÑO_PAGO_CREATE = 'MbPagosEmpeno/Crear'
export const EMPEÑO_PAGO_EDIT = 'MbPagosEmpeno/Editar'

//CONSIGNA CLIENTE MB
export const CONSIGNA_LIST_BY_ID_CLIENTE =
  'Productos/ObtenerListaConPorIdCliente'
export const CONSIGNA_CLIENTE_BY_ID = 'Productos/ObtenerConPorId'

//CONSIGNAS RECHAZADAS
export const CONSIGNAS_RECHAZADAS_ID_CLIENTE =
  'AtbRegistro/ObtenerListaVmRechazosConsignaPorIdCliente'
export const CONSIGNAS_VENDIDAS_ID_CLIENTE =
  'Productos/ObtenerListaConVendidadPorIdCliente'

//MB TIPO DE SERVICIO
export const MB_TIPO_SERVICIO_LIST = 'MbTipoServicio/ObtenerLista'
export const MB_TIPO_SERVICIO_LIST_TEST = 'MbTipoServicio/ObtenerListaTest'
export const MB_TIPO_SERVICIO_BY_ID = 'MbTipoServicio/ObtenerPorId'
export const MB_TIPO_SERVICIO_CREATE = 'MbTipoServicio/Crear'
export const MB_TIPO_SERVICIO_EDIT = 'MbTipoServicio/Editar'

//INTERCAMBIO CLIENTE MB
export const INTERCAMBIO_LIST_BY_ID_CLIENTE =
  'Productos/ObtenerListaIntPorIdCliente'
export const INTERCAMBIO_CLIENTE_BY_ID = 'Productos/ObtenerIntPorId'

//RECHAZO DE PROPUESTA
export const RECHAZO_PREEVALUACION = 'AtbRegistro/MotivoRechazo' //PUT

//ENVIOS
//ERP
export const GUIA_ERP_LIST = 'TbGuia/ObtenerLista'
export const GUIA_ERP_LIST_VM = 'TbGuia/ObtenerListaVM'
export const GUIA_ERP_LIST_BY_ID_PROD = 'TbGuia/ObtenerListaPorIdProducto'
export const GUIA_ERP_LIST_BY_ID_CLI = 'TbGuia/ObtenerListaVMPorIdCliente'
export const GUIA_ERP_LIST_TRANSITO_BY_ID_CLI =
  'TbGuia/ObtenerListaVMEnTransitoPorIdCliente'
export const GUIA_ERP_LIST_RECIBIDO_BY_ID_CLI =
  'TbGuia/ObtenerListaVMRecibidoPorIdCliente'
export const GUIA_ERP_BY_ID = 'TbGuia/BuscarPorId'
export const GUIA_ERP_VM_BY_ID = 'TbGuia/BuscarVMPorId'
export const GUIA_ERP_CREATE = 'TbGuia/Crear'
export const GUIA_ERP_EDIT = 'TbGuia/Editar'

//MB
export const GUIA_MB_LIST = 'TbMbguia/ObtenerLista'
export const GUIA_MB_EVALUACION_LIST =
  'TbMbguia/ObtenerListaSolicitadasIdCliente'
export const GUIA_MB_BY_ID = 'TbMbguia/BuscarPorId'
export const GUIA_MB_CREATE = 'TbMbguia/Crear'
export const GUIA_MB_EDIT = 'TbMbguia/Editar'
export const GUIA_MB_MARCAR_ENVIADO = 'TbMbguia/MarcarEnviado'
export const GUIA_MB_ENVIO_COM_PAGO = 'TbMbguia/EnvioComprobantePago'

//MBConsigna
export const MB_CONSIGNA_ACT_DES_MODO_VACACIONES = 'MbConsigna/Delete'

// PAYMENTS
export const PAYMENT_CARD = 'Payments/PaymentCard'
export const PAYMENT_ID = 'Payments/PaymentID'
export const CONFIRM_PAYMENT = 'Payments/ConfirmPayment'
export const PAYMENT_SECOND = 'Payments/SecondTransaction'

//Clientes confirmation
export const CONFIRMAR_EMAIL = 'Clientes/ConfirmarMail'

//Calendar
export const CALENDAR_SCHEDULE = 'HorariosCitas/ObtenerLista'
export const CALENDAR_REGISTRIES = 'RegistroDiasDisp/ObtenerLista'
export const CALENDAR_RG_DAY_DELETE = 'RegistroDiasDisp/Eliminar'
export const CALENDAR_REGISTRY_MB = 'MbCItaDesempeno/Crear'
export const CALENDAR_EDIT_MB = 'MbCItaDesempeno/Editar'
export const CALENDAR_REGISTRY_CON_VEM_MB = 'MbCitasConVencidas/Crear'
export const CALENDAR_EDIT_CON_VEN_MB = 'MbCitasConVencidas/Editar'
export const CALENDAR_MEETING_CON_VEN_BY_ID_PROD =
  'MbCitasConVencidas/ObtenerPorIdProductoMv/'
export const CALENDAR_MEETING_BY_ID_PRODUCT_VM =
  'MbCItaDesempeno/ObtenerPorIdProductoMv/'

export const CALENDAR_CON_FOR_CREATE = 'MbCitasForaneas/Crear'
export const CALENDAR_CON_FOR_EDIT = 'MbCitasForaneas/Editar'
export const CALENDAR_CON_FOR_BY_ID = 'MbCitasForaneas/ObtenerPorIdVm/'

//CONTRATOS CLIENTESMB
export const CONTRATOS_CLIENTES_MB = 'Contratos/ObtenerListaClienteMb'
export const CONTRATOS_DOCUMENTS_MB = 'DocContrato/ObtenerListaVMPorIdCon'

//ENCUESTA DE SATISFACCION
export const ENCUESTA_SATISFACCION_ADD = 'Encuestas/Crear'

// TIPO DE SERVICIO
export const TIPO_DE_SERVICIO = 'TipoServPreReg/ObtenerLista'

// TIPO DE PIEZA
export const TIPO_DE_PIEZA = 'TipoPiezaPreReg/ObtenerLista'

// TIPO DE PIEZA
export const CREAR_CLIENTE_PREREG = 'ClientesPreReg/Crear'

//LISTADO ESTADOS DE MEXICO
export const GET_LIST_ESTADOS_MX = 'Direcciones/GetEstadosMb'

//CREAR SOLICITUD DE GUIA
export const CREATE_SOLICITUD_GUIA = 'TbMbGuia/Crear'
export const CREATE_SOLICITUD_GUIA_OTRA_PAQ = 'TbMbGuia/CrearOtraPaq'

//End points para test nuevos servicios en desarrollo
export const GET_LIST_TIPOS_SERV_DES = 'MbTipoServicio/ObtenerListaTest'
export const CREATE_FORMULARIO_TEST = 'AtbRegistro/CrearTest'

export const CITAS_MB_CON_FORANEAS_BY_ID_CLIENTE =
  'MbCitasForaneas/ObtenerListaVmPorIdCliente'
export const CITAS_MB_EMP_FORANEOS_BY_ID_CLIENTE =
  'MbCitasForaneas/ObtenerListaVmEmpForPorIdCliente'

export const GET_APA_DET_BY_ID = 'TbMbApartado/ObtenerPorIdApa'
export const GET_LIST_APA_BY_IDCLI = 'TbMbApartado/ObtenerListByIdClient'
export const GET_DET_APA_BY_IDCLIPRO = 'TbMbApartado/ObtenerPorIdProdIdCli'
export const GET_LIST_PLAZO_APA = 'MbPlazosApartado/ObtenerLista'

export const GET_LIST_PAG_APA_IDCLIPRO =
  'TbMbPagosApartado/ObtenerListaByIdProdIdCli'
export const GET_LIST_PAG_APA_ID = 'TbMbPagosApartado/ObtenerListaByIdApa'

export const GET_DATA_PROD_BY_ID = 'Productos/ObtenerDetallePorId'
export const GET_PAGADO_APA = 'TbMbApartado/GetSaldo'

export const TRASFER_PRIMER_PAGO = 'TbMbApartado/CreatePrimerTransfer'

export const TRANSEFER_OTROS_PAGOS = 'TbMbPagosApartado/CreateTransferProximos'

///PERSONAL SHOPPER
export const PERSONAL_SHOPPER_ADD = 'MbPersonalShopper/Crear'
export const PERSONAL_SHOPPER_UPDATE = 'MbPersonalShopper/Editar'
export const PERSONAL_SHOPPER_GET_LIST = 'MbPersonalShopper/ObtenerLista'
export const PERSONAL_SHOPPER_GET_BY_ID = 'MbPersonalShopper/ObtenerPorId'
export const PERSONAL_SHOPPER_GET_LIST_BY_ID_CLIENTE =
  'MbPersonalShopper/ObtenerListaByIdCliente'

///PersonalShopper
//export const PERSONAL_SHOPPER_PROP_GET_LIST = 'MbPropuestaPs/ObtenerLista'
export const PERSONAL_SHOPPER_PROP_GET_LIST_BY_ID_CLIENTE =
  'MbPropuestaPs/ObtenerListaByIdCliente'
export const PERSONAL_SHOPPER_PROP_ACEPTA =
  'MbPropuestaPs/ClienteAceptaPropuesta'
export const PERSONAL_SHOPPER_PROP_RECHAZA =
  'MbPropuestaPs/ClienteRechazaPropuesta'
export const PERSONAL_SHOPPER_PROP_BY_ID = 'MbPropuestaPs/ObtenerVMById'

//Payements
export const GET_PAYMENTS_METHODS = '/PaymentsAdyen/getPaymentMethods'

export const TRASFER_EMP_LIQUIDAR = 'MbPagosEmpeno/CrearTrasferenciaLiq'
export const TRASFER_EMP_REFRENDAR = 'MbPagosEmpeno/CrearTrasferenciaRef'
