import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/ventasMb.css'
import { Fade } from 'react-awesome-reveal'
import Button from '@mui/material/Button'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CUESTIONARIO, CITAS_MB } from '../../../Constants/routesConstants'
import RegisterMb2 from '../../Login/RegisterMb2'
const VSection3Web = (props) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const navigate = useNavigate()

  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    // gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }
  return (
    <section className="VentasSection3MB">
      <div className="cardVSection3">
        <div className="cardSection1">
          <img
            className="Logo"
            src={
              'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png'
            }
          />
          <span className="text">
            Confía en nuestro proceso y asegura que tus artículos Preloved
            reciban la atención y el valor que merecen
          </span>
          <div className="bottomLink">
            <Button
              onClick={
                userInfo
                  ? function () {
                      navigate(CUESTIONARIO)
                      // gtmGoTo_EmpeñaAqui_Log()
                    }
                  : function () {
                      handleOpenSingUp()
                    }
              }
              className="btnLink"
            >
              Vender ahora
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png '
                }
              />
            </Button>
          </div>
        </div>
        <div className="cardSection2">
          <Fade cascade damping={0.5} triggerOnce>
            <div className="contentimg">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/Bolsas.png'
                }
              />
            </div>
            <div className="contentimg">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/DSC08748.png'
                }
              />
            </div>
            <div className="contentimg">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/DSC08733.png'
                }
              />
            </div>
            <div className="contentimg">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/Bolsa+rosa.png'
                }
              />
            </div>
          </Fade>
        </div>
        <div className="cardSection2Movil">
          <Fade cascade damping={0.5} triggerOnce>
            <div className="contentimg">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/Bolsas.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/DSC08748.png'
                }
              />
            </div>
            <div className="contentimg">
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/DSC08733.png'
                }
              />
              <img
                src={
                  'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Ventas/Bolsa+rosa.png'
                }
              />
            </div>
          </Fade>
        </div>
      </div>
      <RegisterMb2
        open={openSingUp}
        onClose={handleCloseSingUp}
        props={props}
      />
    </section>
  )
}

export default VSection3Web
