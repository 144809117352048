import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TerminosPagoTar from './TerminosPagoTar'
import '../../../../../Styles/App/Apartado/ApartadoDetail.css'

const AmountPayPrimerPago = (props) => {
	const dispatch = useDispatch()
	const handleNext = (data) => {
		// dispatch(postAbonoCreate(data))
		props.handleNext()
	}

	const [openModalTerminos, setModalTerminos] = useState(false)
	const handleOpenTerminos = () => {
		setModalTerminos(!openModalTerminos)
	}

	return (
		<div className='CardApartadoDetailPagosCardStepper'>
			<div className='BodyCardApartadoDetail'>
				<div className='ApartadoDetailSection2'>
					<h2>Detalle del producto</h2>
					<hr className='Dvividerhr' />
					{/* ***************** */}
					<div className='containerFlexBetween'>
						<span className='bold upperCase'>
							<div className='containerSelectDetailPago'>
								<span className='subtitlesdetail'>Precio apartado: </span>{' '}
								<NumberFormat
									value={props.precioFin}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
							</div>
						</span>
						<span className='bold upperCase'>
							<span className='subtitlesdetail'>Total a pagar: </span>{' '}
							<div className='containerSelectDetailPago'>
								<NumberFormat
									value={props.precioFin + props.precioFin * 0.03 + 3}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
								<Button
									className='BtnTermConditions2'
									sx={{ padding: '0 !important', minWidth: '0 !important' }}
									onClick={handleOpenTerminos}
								>
									<InfoOutlinedIcon />
								</Button>
							</div>
						</span>
					</div>
					<Button
						className='btn-pay'
						onClick={function () {
							handleNext()
						}}
						disabled={props.btn}
					>
						Realizar pago
					</Button>
				</div>
			</div>
			<TerminosPagoTar
				openModalTerminos={openModalTerminos}
				onCloseModalTerminos={handleOpenTerminos}
			/>
		</div>
		// <>
		// 	<p className='text-center fs30 bold'>DETALLE DE PAGO</p>
		// 	<div className='w90 center'>
		// 		<hr />
		// 		<div>
		// 			<div className='flex w100 line-height-0'>
		// 				<p className='w70'>Precio de apartado </p>
		// 				<p className='w30'>
		// 					<NumberFormat
		// 						value={props.precioFin}
		// 						displayType={'text'}
		// 						fixedDecimalScale={true}
		// 						decimalScale={2}
		// 						thousandSeparator={true}
		// 						prefix={'$ '}
		// 						suffix={' MXN'}
		// 					/>
		// 				</p>
		// 			</div>

		// 			<div className='flex w100'>
		// 				<p className='w70'>Total a pagar</p>
		// 				<p className='w30'>
		// 					<NumberFormat
		// 						value={props.precioFin + props.precioFin * 0.03 + 3}
		// 						displayType={'text'}
		// 						fixedDecimalScale={true}
		// 						decimalScale={2}
		// 						thousandSeparator={true}
		// 						prefix={'$ '}
		// 						suffix={' MXN'}
		// 					/>
		// 					<Button
		// 						className='BtnTermConditions2 mr1'
		// 						//startIcon={<InfoOutlinedIcon />}
		// 						onClick={handleOpenTerminos}
		// 					>
		// 						<InfoOutlinedIcon />
		// 					</Button>
		// 				</p>
		// 			</div>
		// 		</div>
		// 	</div>
		// 	<div className='flex '>
		// 		<Button
		// 			className='btn-pay'
		// 			onClick={function () {
		// 				handleNext()
		// 			}}
		// 		>
		// 			Realizar pago
		// 		</Button>
		// 	</div>
		// 	<TerminosPagoTar
		// 		openModalTerminos={openModalTerminos}
		// 		onCloseModalTerminos={handleOpenTerminos}
		// 	/>
		// </>
	)
}

export default AmountPayPrimerPago
