import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { ThemeProvider } from '@mui/material/styles'
import Theme from '../../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
import { useForm } from 'react-hook-form'
import {
  CLIENTES_REGISTRER,
  CIUDADES_LIST,
  ESTADOS_LIST,
} from '../../../Constants/ApiConstants'

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Barter Luxe
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const ClientesSingUp = (props) => {
  const methods = useForm()
  const { handleSubmit } = methods
  const [_Cliente, setCliente] = useState({
    txtNombre: '',
    txtSNombre: '',
    txtAParterno: '',
    txtAMaterno: '',
    txtCelular: '',
    txtRfc: '',
    txtEmail: '',
    txtObservaciones: '',
    fecAlta: '',
    fecBaja: '',
    txtPassword: '',
    txtPasswordConfirma: '',
    bolFormulario: true,
    bolConfirmaEmail: false,
    direccion: [],
    imagen: [],
    cuentaBancaria: [],
  })

  const handleCliente = (event) => {
    setCliente({ ..._Cliente, [event.target.name]: event.target.value })
  }

  const [_Direccion, setDireccion] = useState({
    txtDireccion: '',
    txtComplemento: '',
    intIdEstado: '',
    intIdCiudad: '',
    intIdPais: 0,
    intIdTipoDir: 0,
    txtCodigoPostal: '',
  })

  const handleDireccion = (event) => {
    setDireccion({ ..._Direccion, [event.target.name]: event.target.value })
  }

  const guardar = () => {
    props.setOpenLoadingScreen()
    _Cliente.direccion.push(_Direccion)
    requests
      .post(CLIENTES_REGISTRER, _Cliente)
      .then((response) => {
        props.setTypeSnackBar('success')
        props.setMessageSnackBar(
          'Gracias por registrarte al mundo de Barterluxe. Confirma tu correo para continuar.'
        )
        props.setOpenSnackBar(true)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setTypeSnackBar('warning')
        props.setMessageSnackBar(error.data.mensaje)
        props.setOpenSnackBar(true)
        props.setCloseLoadingScreen()
        console.log(error)
      })
  }

  const [_Ocultar, setOcultar] = useState(true)
  const [_MensajeError, setMensajeError] = useState('')
  const validatePasswords = () => {
    let valido = / /
    setMensajeError('')
    if (_Cliente.txtPassword === _Cliente.txtPasswordConfirma) {
      if (_Cliente.txtPassword.match(/[A-Z]/)) {
        if (_Cliente.txtPassword.match(/\d/)) {
          if (!valido.test(_Cliente.txtPassword)) {
            if (_Cliente.txtPassword.length <= 15) {
            } else {
              setOcultar(false)
              setMensajeError(
                'La contraseña no debe ser mayor de 15 caracteres.'
              )
            }
          } else {
            setOcultar(false)
            setMensajeError('La contraseña no debe tener espacios en blanco.')
          }
        } else {
          setOcultar(false)
          setMensajeError('La contraseña debe tener al menos un número.')
        }
      } else {
        setOcultar(false)
        setMensajeError(
          'La contraseña debe tener al menos una letra mayuscula.'
        )
      }
    } else {
      setOcultar(false)
      setMensajeError('Las contraseñas no coiciden.')
    }
  }

  const [_ListEstados, setListEstados] = useState([])
  const [_ListCiudades, setListCiudades] = useState([])

  const getListEstados = () => {
    props.setOpenLoadingScreen()
    requests
      .get(ESTADOS_LIST)
      .then((response) => {
        setListEstados(response)
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }
  const handleEstado = (event) => {
    props.setOpenLoadingScreen()
    requests
      .get(CIUDADES_LIST, event.target.value)
      .then((response) => {
        setListCiudades(response)
        setDireccion({ ..._Direccion, intIdEstado: event.target.value })
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  useEffect(() => {
    getListEstados()
  }, [])

  return (
    <ThemeProvider theme={Theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" align="center">
            Regístrate y accede Foundit
          </Typography>
          <Box component="form" onSubmit={handleSubmit(guardar)} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="txtNombre"
                  required
                  fullWidth
                  id="txtNombre"
                  label="Nombre"
                  color="warning"
                  size="small"
                  value={_Cliente.txtNombre}
                  onChange={handleCliente}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="txtSNombre"
                  label="Segundo nombre"
                  name="txtSNombre"
                  color="warning"
                  size="small"
                  value={_Cliente.txtSNombre}
                  onChange={handleCliente}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="txtAParterno"
                  label="Apellido paterno"
                  name="txtAParterno"
                  color="warning"
                  size="small"
                  value={_Cliente.txtAParterno}
                  onChange={handleCliente}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="txtAMaterno"
                  label="Apellido materno"
                  name="txtAMaterno"
                  color="warning"
                  size="small"
                  value={_Cliente.txtAMaterno}
                  onChange={handleCliente}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="txtRfc"
                  label="RFC"
                  name="txtRfc"
                  color="warning"
                  size="small"
                  value={_Cliente.txtRfc}
                  onChange={handleCliente}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="txtCelular"
                  label="Celular"
                  name="txtCelular"
                  color="warning"
                  size="small"
                  value={_Cliente.txtCelular}
                  onChange={handleCliente}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="txtDireccion"
                  label="Dirección"
                  name="txtDireccion"
                  color="warning"
                  size="small"
                  value={_Direccion.txtDireccion}
                  onChange={handleDireccion}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="txtComplemento"
                  label="Complemento"
                  name="txtComplemento"
                  color="warning"
                  size="small"
                  value={_Direccion.txtComplemento}
                  onChange={handleDireccion}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  id="txtCodigoPostal"
                  label="Código postal"
                  name="txtCodigoPostal"
                  color="warning"
                  size="small"
                  value={_Direccion.txtCodigoPostal}
                  onChange={handleDireccion}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl size="small" fullWidth color="warning" required>
                  <InputLabel id="intIdEstado">Estado</InputLabel>
                  <Select
                    labelId="intIdEstado"
                    id="intIdEstado"
                    name="intIdEstado"
                    value={_Direccion.intIdEstado}
                    onChange={handleEstado}
                    label="Estado"
                    inputProps={{
                      name: 'intIdEstado',
                      id: 'intIdEstado',
                    }}
                  >
                    <MenuItem value="" />
                    {_ListEstados.map((object, index) => (
                      <MenuItem value={object.intIdEstado}>
                        {' '}
                        {object.txtEstado}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl size="small" fullWidth color="warning" required>
                  <InputLabel id="intIdCiudad">Ciudad</InputLabel>
                  <Select
                    labelId="intIdCiudad"
                    id="intIdCiudad"
                    name="intIdCiudad"
                    value={_Direccion.intIdCiudad}
                    onChange={handleDireccion}
                    label="Ciudad"
                    inputProps={{
                      name: 'intIdCiudad',
                      id: 'intIdCiudad',
                    }}
                  >
                    <MenuItem value="" />
                    {_ListCiudades.map((object, index) => (
                      <MenuItem value={object.intIdCiudad}>
                        {' '}
                        {object.txtCiudad}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="txtEmail"
                  label="Correo electrónico"
                  name="txtEmail"
                  color="warning"
                  size="small"
                  value={_Cliente.txtEmail}
                  onChange={handleCliente}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="txtPassword"
                  label="Contraseña"
                  type="password"
                  id="txtPassword"
                  color="warning"
                  size="small"
                  value={_Cliente.txtPassword}
                  onBlur={validatePasswords}
                  onChange={handleCliente}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="txtPasswordConfirma"
                  label="Confirmar contraseña"
                  type="password"
                  id="txtPasswordConfirma"
                  color="warning"
                  size="small"
                  value={_Cliente.txtPasswordConfirma}
                  onBlur={validatePasswords}
                  onChange={handleCliente}
                />
                <FormHelperText
                  id="component-error-text"
                  sx={{ color: 'red' }}
                  hidden={_Ocultar}
                >
                  {_MensajeError}
                </FormHelperText>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="warning"
            >
              Regístrate
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2" sx={{ color: '#000' }}>
                  ¿Ya tienes una cuenta? Inicia sesión
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}

export default ClientesSingUp
