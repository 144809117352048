import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import Table from '@mui/material/Table'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import TableBody from '@mui/material/TableBody'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import TodayIcon from '@mui/icons-material/Today'
import { Chip } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useNavigate, useParams } from 'react-router-dom'
import requests from '../AxiosCalls/AxiosCall'
import {
	CALENDAR_SCHEDULE,
	CALENDAR_REGISTRIES,
	CITA_CREATE,
} from '../../Constants/ApiConstants'
import { styled } from '@mui/material/styles'
import 'moment/min/locales.min'
import '../../Styles/calendario/calendario.css'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#545454',
		textTransform: 'uppercase',
		fontWeight: '600',
		fontSize: window.screen.width <= 600 ? 9 : 20,
		border: 0,
	},
	[`&.${tableCellClasses.body}`]: {
		color: '#545454',
		fontWeight: '600',
		padding: window.screen.width <= 600 ? 13 : null,
		fontSize: window.screen.width <= 600 ? 8 : 20,
		border: 0,
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}))

const CalendarScreen = (props) => {
	const today = new Date()
	const [week, setWeek] = useState([])
	const [schedule, setSchedule] = useState([])
	const [registries, setRegistry] = useState([])
	const [open, setOpen] = React.useState(false)
	const [meeting, setMeeting] = useState({
		lngIdCitas: 0,
		fecCita: '',
		lngIdPropuestas: 0,
		fecRegCita: '',
		hrCita: '01:01:00.0000000',
		intStatusCita: 0,
		lngIdRegistroDia: 0,
	})

	const [meetingDialog, setMeetingDialog] = useState({
		schedule: '',
		date: '',
	})

	const [idRegistroDia, setIdRegistroDia] = useState(0)
	const { id } = useParams()
	const navigate = useNavigate()

	const getListSchedule = () => {
		requests
			.get(CALENDAR_SCHEDULE)
			.then((response) => {
				setSchedule(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const getRegistriesCalendar = () => {
		requests
			.get(CALENDAR_REGISTRIES)
			.then((response) => {
				setRegistry(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const confirmMeeting = (id_propuesta, id_rg_day) => {
		meeting.lngIdPropuestas = id_propuesta
		meeting.lngIdRegistroDia = id_rg_day
		props.setOpenLoadingScreen()

		requests
			.post(CITA_CREATE, meeting)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageAlert(response.mensaje)
				props.setOpenAlert(true)
				handleClose()
				getRegistriesCalendar()
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})
	}

	const generateDayWeek = (end_date) => {
		let genericWeek = []
		for (let i = 6; i >= 0; i--) {
			genericWeek.push(moment(end_date).subtract(i, 'days').format('llll'))
		}
		sessionStorage.setItem('activate_week', JSON.stringify(genericWeek))
		sessionStorage.setItem('end_date', JSON.stringify(end_date))
		setWeek(genericWeek)
	}

	const controllerWeek = () => {
		let verifyDay = false
		let counter = 0
		let endDayWeek = JSON.parse(sessionStorage.getItem('end_date'))

		if (endDayWeek != null) {
			generateDayWeek(endDayWeek)
		} else {
			while (!verifyDay) {
				if (moment(today).format('dddd') === 'Saturday') {
					endDayWeek = today
					verifyDay = true
				}
				if (moment(endDayWeek).format('dddd') === 'Saturday') {
					verifyDay = true
				}
				if (!verifyDay) {
					counter++
					endDayWeek = moment(today).add(counter, 'days').format('llll')
				}
			}
			sessionStorage.setItem('end_date', JSON.stringify(endDayWeek))
			generateDayWeek(endDayWeek)
		}
	}

	useEffect(() => {
		controllerWeek()
		getListSchedule()
		getRegistriesCalendar()
	}, [])

	const renderMont = () => {
		let firstDay = JSON.parse(sessionStorage.getItem('activate_week'))[0]
		let endDay = JSON.parse(sessionStorage.getItem('activate_week'))[6]

		if (
			moment(firstDay).format('MMMM YYYY') == moment(endDay).format('MMMM YYYY')
		) {
			return (
				<Button className='BtnRenderMont' variant='text' sx={{ marginLeft: 0 }}>
					{moment(endDay).locale('es').format('MMMM YYYY')}
				</Button>
			)
		} else {
			return (
				<Button className='BtnRenderMont' variant='text' sx={{ marginLeft: 0 }}>
					{moment(firstDay).locale('es').format('MMM')} -{' '}
					{moment(endDay).locale('es').format('MMM YYYY')}
				</Button>
			)
		}
	}

	const onClickButtonToday = () => {
		sessionStorage.removeItem('end_date')
		controllerWeek()
	}
	const onClickButtonBack = () => {
		let end_date = JSON.parse(sessionStorage.getItem('end_date'))
		generateDayWeek(moment(end_date).subtract(7, 'days').format('llll'))
	}
	const onClickButtonForward = () => {
		let end_date = JSON.parse(sessionStorage.getItem('end_date'))
		generateDayWeek(moment(end_date).add(7, 'days').format('llll'))
	}

	const handleClickOpen = (element, sch) => {
		setOpen(true)
		setIdRegistroDia(element.lngIdRegistroDia)
		setMeetingDialog({
			...meetingDialog,
			schedule: sch.hrInicia,
			date: element.fecDisponible,
		})
	}

	const handleClose = () => {
		setOpen(false)
	}

	const renderElement = (schedule, day) => {
		let verifyElement = false
		let element
		registries.map((registry) => {
			if (
				registry.intIdHorarioCita == schedule.intIdHorarioCita &&
				moment(registry.fecDisponible).format('DD/MM/yyyy') ===
					moment(day).format('DD/MM/yyyy') &&
				registry.bolActivo
			) {
				verifyElement = true
				element = registry
			}
		})

		if (verifyElement) {
			if (
				moment(element.fecDisponible).format('DD/MM/yyyy') ===
				moment(today).format('DD/MM/yyyy')
			) {
				return (
					<StyledTableCell>
						<Chip
							icon={<CalendarMonthIcon className='CalendarIcon' />}
							className='btnDisponible ProductTitleDescription'
							label='Disponible'
							variant='outlined'
							onClick={() => {
								handleClickOpen(element, schedule)
							}}
						/>
					</StyledTableCell>
				)
			}

			if (moment(element.fecDisponible).unix() > moment(today).unix()) {
				return (
					<StyledTableCell>
						<Chip
							icon={<CalendarMonthIcon className='CalendarIcon' />}
							className='btnDisponible ProductTitleDescription'
							label='Disponible'
							variant='outlined'
							onClick={() => {
								handleClickOpen(element, schedule)
							}}
						/>
					</StyledTableCell>
				)
			} else {
				return <StyledTableCell></StyledTableCell>
			}
		} else {
			return <StyledTableCell></StyledTableCell>
		}
	}
	const service = sessionStorage.getItem('service')
	console.log(service)
	return JSON.parse(sessionStorage.getItem('activate_week')) != null ? (
		<>
			{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
			<div className='flex-start w90 mt6 pb2 pl1'>
				<div className='chip-breadcrumbs'>
					<span className='letter-breadcrumbs'>{service.slice(0, 1)}</span>
				</div>

				<div role='presentation' className='ml2'>
					<Breadcrumbs aria-label='breadcrumb' separator='›'>
						<Link
							underline='hover'
							className='breadcrumb-url '
							onClick={() => {
								navigate(-2)
							}}
						>
							{service}
						</Link>
						<Link
							underline='hover'
							className='breadcrumb-url'
							onClick={() => {
								navigate(-1)
							}}
						>
							Propuestas Recibidas
						</Link>
						<Link underline='hover' className='breadcrumb-url bold'>
							Agendar Cita
						</Link>
					</Breadcrumbs>
				</div>
			</div>

			<div className='TableCalendarContainer mt-40'>
				<div className='TableCalendarBody'>
					<Box className='header-calendar'>
						<Button
							className='btnHoyCalendar'
							variant='outlined'
							onClick={() => {
								onClickButtonToday()
							}}
						>
							Fecha Actual
						</Button>
						<div className='p-header-calendar'>
							<Button
								className='iconArrowCalendar'
								variant='text'
								onClick={() => {
									onClickButtonBack()
								}}
							>
								<ArrowBackIosIcon />
							</Button>
							<span>{renderMont()}</span>
							<Button
								className='iconArrowCalendar'
								variant='text'
								onClick={() => {
									onClickButtonForward()
								}}
							>
								<ArrowForwardIosIcon />
							</Button>
						</div>
					</Box>

					<div className='flex'>
						<div className='column-ahours '>
							<Table className='Table-Schedule '>
								<TableHead
									className='TableHead-Schedule'
									sx={{
										'& th': {
											height: '80px',
											padding: '0px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
										'@media(max-width: 600px)': {
											'& th': {
												height: '50px',
											},
										},
									}}
								>
									<StyledTableRow>
										<StyledTableCell>
											<span className='dayActive'>
												<TodayIcon sx={{ fontSize: 30 }} />
											</span>
										</StyledTableCell>
									</StyledTableRow>
								</TableHead>

								<TableBody>
									{schedule.map((row, index) => (
										<StyledTableRow
											sx={{
												'&:last-child td, &:last-child th': { border: 0 },
												'& td': {
													height: '80px',
												},
												'@media(max-width: 600px)': {
													'& td': {
														height: '50px',
													},
												},
											}}
										>
											<StyledTableCell>
												<span>{row.hrInicia.slice(0, 5)}</span>
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</div>

						<div className='box-schedule'>
							<Table
								className='Table-Schedule'
								sx={{ minWidth: 600, marginTop: 2 }}
								aria-label='simple table'
							>
								<TableHead
									className='TableHead-Schedule'
									sx={{
										'& th': {
											height: '80px',
											padding: '0px',
											textAlign: 'center',
										},
										'@media(max-width: 600px)': {
											'& th': {
												height: '50px',
											},
										},
									}}
								>
									<StyledTableRow>
										{JSON.parse(sessionStorage.getItem('activate_week')).map(
											(day, index) =>
												moment(day).format('L') == moment(today).format('L') ? (
													<StyledTableCell key={index}>
														<span className='dayActive'>
															{moment(day).locale('es').format('ddd DD')}
														</span>
													</StyledTableCell>
												) : (
													<StyledTableCell key={index}>
														<span>
															{moment(day).locale('es').format('ddd DD')}
														</span>
													</StyledTableCell>
												)
										)}
									</StyledTableRow>
								</TableHead>
								<TableBody>
									{schedule.map((row, index) => (
										<StyledTableRow
											sx={{
												'&:last-child td, &:last-child th': { border: 0 },
												'& td': {
													height: '80px',
													width: '100px',
												},
												'@media(max-width: 600px)': {
													'& td': {
														height: '50px',
													},
												},
											}}
										>
											{JSON.parse(sessionStorage.getItem('activate_week')).map(
												(day, index) => renderElement(row, day)
											)}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</div>
				</div>
			</div>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						¿Estás seguro de agendar tu cita para el día{' '}
						{moment(meetingDialog.date).format('DD/MM/yyyy')} a las{' '}
						{meetingDialog.schedule.slice(0, 5)} hrs?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						variant='contained'
						className='calendarBtnCancelar'
					>
						Calcelar
					</Button>
					<Button
						onClick={() => {
							confirmMeeting(id, idRegistroDia)
						}}
						variant='contained'
						className='calendarBtnConfirmar'
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	) : (
		<></>
	)
}

export default CalendarScreen
