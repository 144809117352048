/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import '../Styles/TerminosConditions/TermsConditions.css'
const PrivacyNotice = () => {
  return (
    <div className="ContainerTerms mt5">
      <div className="Container-Content-Terms shadow">
        <h2 className="text-center Title">Aviso de privacidad</h2>
        <h3 className="text-center Subtitle">Foundit</h3>

        {/* ************************SECTION 1************************* */}
        <div>
          <p className="section-Body">
            <span className="bold co-black">
              ARTÍCULOS DE LUJO, S.A. DE C.V.
            </span>{' '}
            (en lo sucesivo “<span className="bold co-black">FOUNDIT</span>
            ”), con domicilio para oír y recibir notificaciones el ubicado en
            Río Nazas número 34, Colonia Cuauhtémoc, Alcaldía Cuauhtémoc, Ciudad
            de México, C.P. 06500, correo electrónico de atención a clientes:
            contacto@founditpreloved.com. Nuestra empresa está comprometida en
            proteger su privacidad de conformidad con lo establecido en la Ley
            Federal de Protección de Datos Personales en Posesión de los
            Particulares “LFPDPPP”), su reglamento, los lineamientos del Aviso
            de Privacidad y demás disposiciones legales aplicables en la
            República Mexicana (en adelante y en conjunto, la “Legislación”).
            Además de lo dispuesto en la Legislación, FOUNDIT ha adoptado las
            mejores prácticas internacionales en el manejo y administración de
            “Datos Personales”, por lo que con el fin de informar de manera
            clara y específica que Datos Personales recabamos, los medios por
            los que se recaban, así como el tratamiento que damos a los mismos,
            siempre mediando nuestro compromiso de proteger su privacidad bajo
            los más altos estándares de ética, responsabilidad y
            profesionalismo, ponemos a su disposición el presente Aviso de
            Privacidad:
          </p>
        </div>
        {/* ************************SECTION A ************************* */}
        <div>
          <p className="section-Title">A. DATOS PERSONALES QUE RECABAMOS.</p>
          <p className="section-Body">
            Para el cumplimiento de la relación jurídica y la prestación de
            servicios que usted solicite, si usted es cliente nuestro, FOUNDIT
            podrá recabar de usted las siguientes categorías de Datos
            Personales, ya sea mediante formatos físicos o electrónicos:
            <ul>
              <li>Datos de identificación y contacto.</li>
              <li>
                Datos patrimoniales (datos bancarios para cobro, fiscales para
                facturación).
              </li>
              <li>
                Datos biométricos (huellas dactilares, huellas de voz, datos de
                imagen en fotografías y/o vídeos).
              </li>
              <li>
                Datos de geolocalización, al hacer uso de la plataforma de
                FOUNDIT.
              </li>
            </ul>
          </p>
          <p className="section-Body">
            Dentro de las categorías de Datos Patrimoniales y/o Datos
            Biométricos, al ser considerados como Datos Personales Sensibles que
            requieren especial protección, FOUNDIT dará especial tratamiento a
            los mismos y solicitará su consentimiento expreso y por escrito de
            conformidad a lo establecido por la Legislación y las finalidades
            establecidas en el presente Aviso de Privacidad.
          </p>
          <p className="section-Body">
            Usted siempre podrá elegir no informarnos sus datos personales, pero
            en general se requiere dicha información para concretar las
            relaciones jurídicas y servicios por parte de FOUNDIT.
          </p>
          <p className="section-Body">
            Si Usted elige no darnos determinada información, es posible que no
            pueda contratar nuestros servicios.
          </p>
        </div>
        {/* ************************SECTION B ************************* */}
        <div>
          <p className="section-Title">
            B. EL TRATAMIENTO DE DATOS PERSONALES POR PARTE DE FOUNDIT, TIENE
            LAS SIGUIENTES FINALIDADES.
          </p>
          <p className="section-Body">
            Para identificación, verificación y/o contacto, en cualquier tipo de
            relación jurídica o de negocios con FOUNDIT.
          </p>
          <p className="section-Body">
            Para el cumplimiento de obligaciones contractuales respecto a los
            servicios que debemos prestarle.
          </p>
          <p className="section-Body">
            Para informarle sobre cambios en los productos y/o servicios que
            comercializamos.
          </p>
          <p className="section-Body">
            Para realizar la cobranza y facturación de los servicios y/o
            productos que adquiere con nosotros, así como ejecutar procesos de
            reembolso en caso de aplicar.
          </p>
          <p className="section-Body">
            Para envío de publicidad y promociones de FOUNDIT.
          </p>
          <p className="section-Body">
            De manera adicional, utilizamos sus Datos Personales para las
            siguientes “Finalidades Secundarias” que no son necesarias para el
            cumplimiento de la relación jurídica que da origen al tratamiento de
            Datos Personales, pero que nos permiten y facilitan brindarle una
            mejor atención:
            <ul style={{ listStyle: 'none' }}>
              <li>
                - Para elaborar estudios y programas con fines de mercadotecnia
                y/o publicitarios.
              </li>
              <li>
                - Para mantenimiento de registros orientados a la prestación de
                servicios en el futuro.
              </li>
              <li>
                - Para informarle de productos, servicios, publicidad,
                promociones y boletines informativos presentes o futuros que
                pudieran resultar de su interés.
              </li>
              <li>
                - Para realizar encuestas de calidad de nuestros productos y/o
                servicios.
              </li>
            </ul>
          </p>
          <p className="section-Body">
            En caso de que no desee que sus Datos Personales se utilicen para
            alguna(s) de las Finalidades Secundarias, lo invitamos a seguir el
            procedimiento establecido en el punto “E” del presente Aviso de
            Privacidad. La negativa para el uso de sus Datos Personales para
            estas Finalidades Secundarias no podrá ser un motivo para que le
            neguemos la prestación de los servicios y/o productos que adquiere o
            contrata con FOUNDIT.
          </p>
        </div>
        {/* ************************SECTION C ************************* */}
        <div>
          <p className="section-Title">
            C. PLAZO DE CONSERVACIÓN DE SUS DATOS PERSONALES.
          </p>
          <p className="section-Body">
            FOUNDIT conservará sus Datos Personales durante el tiempo que dure
            la relación jurídica con usted, o conforme a los plazos establecidos
            en la Legislación, o hasta que usted nos solicite que los
            eliminemos, siempre y cuando la Legislación lo permita.
          </p>
        </div>
        {/* ************************SECTION D ************************* */}
        <div>
          <p className="section-Title">
            D. MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.
          </p>
          <p className="section-Body">
            Para prevenir el acceso no autorizado a sus datos personales y con
            el fin de asegurar que la información sea utilizada para los fines
            establecidos en este Aviso de Privacidad, hemos establecido diversos
            procedimientos y medidas de seguridad con la finalidad de evitar el
            uso, daño, pérdida o divulgación no autorizados de sus datos,
            permitiéndonos tratarlos debidamente. Si desea manifestar su
            negativa al uso o divulgación de Datos Personales, usted podrá
            solicitar su inscripción en nuestro “Listado de Exclusión”. Para
            solicitar su inclusión a nuestro Listado de Exclusión deberá seguir
            el proceso establecido en el punto “E” del presente Aviso de
            Privacidad.
          </p>
        </div>
        {/* ************************SECTION E ************************* */}
        <div>
          <p className="section-Title">
            E. EJERCICIO DE DERECHOS DE: (i) ACCESO, RECTIFICACIÓN, CANCELACIÓN,
            OPOSICIÓN (“Derechos de ARCO”); (ii) SOLICITUD DE REVOCACIÓN DEL
            CONSENTIMIENTO AL TRATAMIENTO DE DATOS PERSONALES; (iii)
            PORTABILIDAD DE DATOS; Y (iv) DERECHO AL OLVIDO.
          </p>
          <p className="section-Body">
            Usted tiene derecho de acceder a sus Datos Personales en nuestra
            posesión y a los detalles del tratamiento de los mismos, así como a
            rectificarlos en caso de ser inexactos o incompletos; cancelarlos
            cuando considere que no se requieren para alguna de las finalidades
            señaladas en el presente Aviso de Privacidad, cuando estén siendo
            utilizados para finalidades no consentidas o haya finalizado la
            relación contractual o de servicio, o bien, oponerse al tratamiento
            de los mismos para fines específicos.
          </p>
          <p className="section-Body">
            El mecanismo implementado para el ejercicio de dichos derechos, es a
            través de la presentación de la solicitud respectiva al correo
            electrónico <a>contacto@founditpreloved.com</a> y/o domicilio de
            nuestras oficinas mismo que fue debidamente señalado al principio
            del presente Aviso de Privacidad.
          </p>
          <p className="section-Body">
            Dicha solicitud deberá contener:
            <ol>
              <li>
                El nombre del titular y domicilio u otro medio para comunicarle
                la respuesta a su solicitud;
              </li>
              <li>
                Los documentos que acrediten la identidad o, en su caso, la
                representación legal del titular;
              </li>
              <li>
                La descripción clara y precisa de los Datos Personales respecto
                de los que se busca ejercer alguno de los derechos antes
                mencionados; y cualquier otro elemento o documento que facilite
                la localización de los Datos Personales.
              </li>
              <li>
                En el caso de solicitudes de rectificación, el titular deberá
                indicar, además de lo anterior, las modificaciones a realizarse
                y aportar la documentación que sustente su petición.
              </li>
            </ol>
          </p>
          <p className="section-Body">
            FOUNDIT le comunicará, en un plazo máximo de 20 (veinte) días
            hábiles, contados desde la fecha en que se recibió completa la
            solicitud de acceso, rectificación, cancelación u oposición, la
            determinación adoptada, a efecto de que, si resulta procedente, se
            haga efectiva dentro de los 15 (quince) días hábiles siguientes a la
            fecha en que se comunica la respuesta. Los plazos antes referidos
            podrán ser ampliados una sola vez por un periodo igual, siempre y
            cuando así lo justifiquen las circunstancias del caso.
          </p>
          <p className="section-Body">
            Es importante mencionar que no en todos los casos podremos
            satisfacer su solicitud de Derechos de ARCO, revocación del
            consentimiento, portabilidad o derecho al olvido, ya que es posible
            que por alguna obligación legal requiramos seguir conservando y/o
            dando tratamiento a sus Datos Personales de conformidad al presente
            Aviso de Privacidad. Si éste fuera el caso, lo haremos de su
            conocimiento indicando las obligaciones que correspondan y
            procederemos en su caso, al bloqueo de los Datos Personales que así
            corresponda, lo anterior durante el término que la Legislación
            obligue para su conservación y hasta que proceda su eliminación
            definitiva.
          </p>
          <p className="section-Body">
            FOUNDIT, podrá negar el ejercicio de los Derechos ARCO, en los
            siguientes supuestos:
            <ol type="a">
              <li>
                Cuando usted no sea el titular de los Datos Personales, o no
                haya acreditado fehacientemente la representación del titular;
              </li>
              <li>
                Cuando sus Datos Personales no obren en la base de datos de
                FOUNDIT;
              </li>
              <li>Cuando se lesionen los derechos de un tercero;</li>
              <li>
                Cuando exista un impedimento legal o la resolución de una
                autoridad competente que restrinja sus Derechos ARCO;
              </li>
              <li>
                En caso de cancelación, cuando los Datos Personales sean objeto
                de tratamiento para la prevención o para el diagnóstico médico o
                la gestión de servicios de salud, o;
              </li>
              <li>
                Cuando la rectificación, cancelación u oposición haya sido
                previamente realizada.
              </li>
            </ol>
          </p>
        </div>
        {/* ************************SECTION F ************************* */}
        <div>
          <p className="section-Title">F. TRANSFERENCIA DE DATOS.</p>
          <p className="section-Body">
            Sus datos personales podrán ser transferidos, compartidos y/o
            cedidos a sociedades subsidiarias, filiales, afiliadas y proveedores
            de FOUNDIT, dentro del territorio nacional e internacional para
            fines de identificación, contacto, así como para entender mejor las
            necesidades de nuestros clientes y lograr un mejor servicio. FOUNDIT
            podrá conservar el tiempo que la Ley aplicable lo permita, la
            información en su base de datos con el objeto de simplificar la
            prestación del servicio. Nos comprometemos a no transferir su
            información personal a terceros sin su consentimiento, salvo las
            excepciones previstas en el artículo 37 de la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares, así
            como a realizar esta transferencia en los términos que fija La Ley.
          </p>
        </div>
        {/* ************************SECTION G ************************* */}
        <div>
          <p className="section-Title">
            G. MEDIOS ELECTRÓNICOS, COOKIES O WEB BEACONS.
          </p>
          <p className="section-Body">
            FOUNDIT le recomienda que al navegar por el sitio web permita la
            utilización de cookies para que cumplan ciertas funciones. Las
            cookies son tipos específicos de información que un sitio web
            transmite al disco duro de la computadora u otro dispositivo del
            usuario con el fin de mantener registros. Las cookies pueden servir
            para facilitar el uso de un sitio web guardando su configuración y
            preferencias mientras el usuario navega en Internet. FOUNDIT no
            puede acceder a otra información almacenada en su dispositivo a
            través de las cookies, incluso si las mismas se descargan de nuestro
            sitio web. Las cookies no pueden cargar código malicioso de ningún
            tipo ni virus o malware, y no dañan el dispositivo del usuario.
          </p>
          <p className="section-Body">
            Los cookies también podrán ser utilizados para desplegar anuncios
            relevantes al consumidor en otros sitios que el usuario visite, los
            cuales también podrán ser removidos por el consumidor directamente
            en cada anuncio.
          </p>
        </div>
        {/* ************************SECTION H ************************* */}
        <div>
          <p className="section-Title">
            H. MEDIOS DE COMUNICACIÓN PARA CAMBIOS Y/O MODIFICACIONES AL AVISO
            DE PRIVACIDAD.
          </p>
          <p className="section-Body">
            Nos reservamos el derecho de efectuar en cualquier momento
            modificaciones o actualizaciones al presente Aviso de Privacidad,
            para la atención de novedades, políticas internas o nuevos
            requerimientos para la prestación u ofrecimiento de nuestros
            servicios. Por lo que BARTELUXE se obliga a mantener actualizado el
            presente aviso, para su consulta. Esto con el fin de que “el
            titular” se encuentre en posibilidad de ejercer sus derechos ARCO y
            de esta forma mantenerlo al tanto de cualquier modificación mediante
            aviso al último correo electrónico que nos haya proporcionado. Al
            accesar al presente sitio de internet el titular manifiesta conocer
            el actual Aviso de Privacidad y en consecuencia otorga su
            conocimiento y conformidad con su contenido, asimismo, manifiesta
            expresamente su consentimiento con los términos que en el mismo se
            señalan. En caso de que exista algún cambio, FOUNDIT lo comunicará a
            través de nuestro sitio web:
            <a href="https://founditpreloved.com/" target="_blank">
              https://founditpreloved.com/
            </a>
            .
          </p>
        </div>
        {/* ************************SECTION I ************************* */}
        <div>
          <p className="section-Title">I. JURISDICCIÓN.</p>
          <p className="section-Body">
            Este Aviso de Privacidad se rige por las leyes mexicanas y cualquier
            controversia será resuelta frente a las autoridades mexicanas
            competentes. Al aceptar este Aviso de Privacidad, usted renuncia a
            cualquier otro fuero y legislación que le pudiere corresponder por
            razón de su domicilio.
          </p>
        </div>
        {/* ************************SECTION J ************************* */}
        <div>
          <p className="section-Title">J. AUTORIDAD.</p>
          <p className="section-Body">
            Si usted considera que su derecho a la protección de DATOS
            PERSONALES ha sido lesionado por alguna conducta u omisión por parte
            de FOUNDIT, o presume alguna violación a las disposiciones previstas
            en la Legislación y demás ordenamientos aplicables, podrá interponer
            su inconformidad ante el Instituto Nacional de Transparencia, Acceso
            a la Información y Protección de Datos Personales (INAI).
          </p>
        </div>
        {/* ************************SECTION K ************************* */}
        <div>
          <p className="section-Title">K. ACEPTACIÓN DE LOS TÉRMINOS.</p>
          <p className="section-Body">
            Usted manifiesta que los Datos Personales que proporcione a FOUNDIT
            han sido obtenidos de manera libre, informada, voluntaria e
            inequívoca en términos de la “Ley” y de este Aviso, por lo que esta
            declaración de Privacidad está sujeta a los Términos de Servicio del
            sitio web de{' '}
            <a href="https://founditpreloved.com/" target="_blank">
              https://founditpreloved.com/
            </a>{' '}
            antes descrito, lo cual constituye un acuerdo legal entre el usuario
            y FOUNDIT.
          </p>
          <p className="section-Body">
            Por tanto, si el usuario utiliza los servicios del presente sitio
            web, significa que ha leído, entendido y acordado los términos antes
            expuestos y manifiesta expresamente su aceptación para que FOUNDIT
            lleve a cabo el tratamiento de sus Datos Personales en los términos
            que en el mismo se señalan.
          </p>
        </div>
        {/* ************************SECTION L ************************* */}
        <div>
          <p className="section-Title">L. VIGENCIA.</p>
          <p className="section-Body">
            Esta es la versión más reciente de nuestro Aviso de Privacidad, el
            cual entró en vigor a partir del 01 de noviembre de 2022.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyNotice
