import React, { useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Grid } from '@mui/material'

import WifiIcon from '@mui/icons-material/Wifi'
const visa = require('../../../../../Images/Logo-Visa.png')
const chip1 = require('../../../../../Images/chip1.png')

const CardPayPrimerPagoRenta = (props) => {
	const [cardFormData, setcardFormData] = useState({
		cardNumber: '',
		cardName: '',
		expiryMonth: '',
		expiryYear: '',
		ccv: '',
		loading: false,
		iframeCode: '',
		timer: 10000,
		subTimer: 20000,
		transactionId: '',
		transactionState: 'Unknown',
		paymentComplete: false,
		status: '',
		initialPaymentSubmitted: false,
	})
	const handleNameCard = (event) => {
		setcardFormData({
			...cardFormData,
			[event.target.name]: event.target.value,
		})
	}
	return (
		<div className='pay-container'>
			{/* <div className='flex my3'>
      <img src={visa} className='img-logo-payment' />
      <img src={MasterCard} className='img-logo-payment' />
      <img src={AmericanExpress} className='img-logo-payment' />
    </div> */}
			{/* Card Interactive */}
			<div className='container-Card-Payment'>
				<div className='section1'>
					<div className='flex-between w100 '>
						<img src={visa} className='img-logo-payment' alt='' />
						<div className='flex'>
							<img src={chip1} className='img-logo-chip mr1' alt='' />
							<WifiIcon className='icon-card' />
						</div>
					</div>
					<div className='flex-column line-height-0  upperCase'>
						<p>Numero de tarjeta</p>
						<p className='card-number'>{cardFormData.cardNumber}</p>
					</div>
				</div>
				<div className='section2'>
					<div className='flex-column-start line-height-0  upperCase mr1'>
						<p>CARD HOLDER</p>
						<p className='bold mt1'>{cardFormData.cardName}</p>
					</div>

					<div className='flex-between '>
						<div className='flex-between line-height-0'>
							<p className='mr1'>EXPIRA</p>
							<div className='flex upperCase mt1'>
								<p className='bold mt0'>{cardFormData.expiryMonth}</p>
								<strong>
									&nbsp; <p className='mt0'> / </p>&nbsp;
								</strong>
								<p className='bold mt0'>{cardFormData.expiryYear}</p>
							</div>
						</div>

						<div className=' flex-between mr3'>
							<div className='flex line-height-0  upperCase '>
								<p className='mr1'>CCV</p>
								<p className='bold'>{cardFormData.ccv}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Grid container spacing={1} sx={{ mt: 3, width: '80%' }}>
				<Grid item xs={12} lg={12}>
					<TextField
						keyboardType='numeric'
						maxLength={16}
						name='cardNumber'
						onChange={handleNameCard}
						value={cardFormData.cardNumber}
						variant='outlined'
						label='Numero de tarjeta'
						fullWidth
						required
						inputProps={{
							maxLength: 16,
						}}
					/>
				</Grid>

				<Grid item xs={12} lg={12}>
					<TextField
						name='cardName'
						onChange={handleNameCard}
						value={cardFormData.cardName}
						label='Titular de la tarjeta'
						required
						InputLabelProps={{ shrink: true }}
						fullWidth
						sx={{ mr: 2 }}
						type='text'
					/>
				</Grid>

				<Grid item xs={4} lg={4}>
					<TextField
						name='expiryMonth'
						onChange={handleNameCard}
						value={cardFormData.expiryMonth}
						label='Mes de vencimiento'
						required
						fullWidth
						inputProps={{
							maxLength: 2,
						}}
					/>
				</Grid>

				<Grid item xs={4} lg={4}>
					<TextField
						name='expiryYear'
						onChange={handleNameCard}
						value={cardFormData.expiryYear}
						label='Año de vencimiento'
						required
						fullWidth
						inputProps={{
							maxLength: 2,
						}}
					/>
				</Grid>

				<Grid item xs={4} lg={4}>
					<TextField
						name='ccv'
						onChange={handleNameCard}
						value={cardFormData.ccv}
						label='CVV'
						fullWidth
						required
						inputProps={{
							maxLength: 3,
						}}
					/>
				</Grid>
				<Button className='btn-pay mt2'>Realizar pago</Button>
			</Grid>
		</div>
	)
}

export default CardPayPrimerPagoRenta