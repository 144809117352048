import { React, useState, useEffect } from 'react'
import moment from 'moment/moment'
import 'moment/min/locales'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Table from '@mui/material/Table'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
	CALENDAR_SCHEDULE,
	CALENDAR_REGISTRIES,
	CALENDAR_RG_DAY_DELETE,
	CALENDAR_CON_FOR_CREATE,
} from '../../Constants/ApiConstants'
import requests from '../AxiosCalls/AxiosCall'
import TableBody from '@mui/material/TableBody'
import { useParams } from 'react-router-dom'

import { Chip } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import TodayIcon from '@mui/icons-material/Today'

//#.......       Citas foraneas         ....................
const CalendarConFor = (props) => {
	const { id } = useParams()
	const [week, setWeek] = useState([])
	const [schedule, setSchedule] = useState([])
	const [registries, setRegistry] = useState([])
	const today = new Date()
	const [open, setOpen] = useState(false)
	const [meetingDialog, setMeetingDialog] = useState({
		schedule: '',
		date: '',
	})
	const [idRegistroDia, setIdRegistroDia] = useState(0)

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.common.white,
			color: '#545454',
			textTransform: 'uppercase',
			fontWeight: '600',
			fontSize: window.screen.width <= 600 ? 9 : 20,
			border: 0,
		},
		[`&.${tableCellClasses.body}`]: {
			color: '#545454',
			fontWeight: '600',
			padding: window.screen.width <= 600 ? 13 : null,
			fontSize: window.screen.width <= 600 ? 8 : 20,
			border: 0,
		},
	}))

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}))

	useEffect(() => {
		controllerWeek()
		getListSchedule()
		getRegistriesCalendar()
	}, [])

	const handleClose = () => {
		setOpen(false)
	}

	const handleClickOpen = (element, sch) => {
		setOpen(true)
		setIdRegistroDia(element.lngIdRegistroDia)
		setMeetingDialog({
			...meetingDialog,
			schedule: sch.hrInicia,
			date: element.fecDisponible,
		})
	}

	const confirmMeeting = (id_rg_day) => {
		props.setOpenLoadingScreen()
		let element = {
			lngCitasForaneas: 0,
			lngIdGuiaMb: id,
			lngIdRegistroDia: idRegistroDia,
			intIdStatusCita: 1,
			txtUrlVideoLlamada: '',
		}
		handleClose()
		requests
			.delete(CALENDAR_RG_DAY_DELETE, idRegistroDia)
			.then((response) => {})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})

		requests
			.post(CALENDAR_CON_FOR_CREATE, element)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageAlert(response.mensaje)
				props.setOpenAlert(true)
				props.setCloseLoadingScreen()
				getRegistriesCalendar()
			})
			.catch((error) => {
				console.log(error)
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})
	}

	const getListSchedule = () => {
		requests
			.get(CALENDAR_SCHEDULE)
			.then((response) => {
				setSchedule(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}
	const getRegistriesCalendar = () => {
		requests
			.get(CALENDAR_REGISTRIES)
			.then((response) => {
				setRegistry(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const generateDayWeek = (end_date) => {
		let genericWeek = []
		for (let i = 6; i >= 0; i--) {
			genericWeek.push(moment(end_date).subtract(i, 'days').format('llll'))
		}
		sessionStorage.setItem('activate_week', JSON.stringify(genericWeek))
		sessionStorage.setItem('end_date', JSON.stringify(end_date))
		setWeek(genericWeek)
	}

	const controllerWeek = () => {
		let verifyDay = false
		let counter = 0
		let endDayWeek = JSON.parse(sessionStorage.getItem('end_date'))
		let genericToday = new Date()

		if (endDayWeek != null) {
			generateDayWeek(endDayWeek)
		} else {
			while (!verifyDay) {
				if (moment(genericToday).format('dddd') === 'Saturday') {
					endDayWeek = genericToday
					verifyDay = true
				}
				if (moment(endDayWeek).format('dddd') === 'Saturday') {
					verifyDay = true
				}
				if (!verifyDay) {
					counter++
					endDayWeek = moment(genericToday).add(counter, 'days').format('llll')
				}
			}
			sessionStorage.setItem('end_date', JSON.stringify(endDayWeek))
			generateDayWeek(endDayWeek)
		}
	}

	const onClickButtonToday = () => {
		sessionStorage.removeItem('end_date')
		controllerWeek()
	}

	const onClickButtonBack = () => {
		let end_date = JSON.parse(sessionStorage.getItem('end_date'))
		generateDayWeek(moment(end_date).subtract(7, 'days').format('llll'))
	}
	const onClickButtonForward = () => {
		let end_date = JSON.parse(sessionStorage.getItem('end_date'))
		generateDayWeek(moment(end_date).add(7, 'days').format('llll'))
	}

	const renderMont = () => {
		let data = JSON.parse(sessionStorage.getItem('activate_week'))
		if (data !== null) {
			let firstDay = JSON.parse(sessionStorage.getItem('activate_week'))[0]
			let endDay = JSON.parse(sessionStorage.getItem('activate_week'))[6]

			if (
				moment(firstDay).format('MMMM YYYY') ==
				moment(endDay).format('MMMM YYYY')
			) {
				return (
					<Button
						className='BtnRenderMont'
						variant='text'
						sx={{ marginLeft: 0 }}
					>
						{moment(endDay).locale('es').format('MMMM YYYY')}
					</Button>
				)
			} else {
				return (
					<Button
						className='BtnRenderMont'
						variant='text'
						sx={{ marginLeft: 0 }}
					>
						{moment(firstDay).locale('es').format('MMM')} -{' '}
						{moment(endDay).locale('es').format('MMM YYYY')}
					</Button>
				)
			}
		}
	}

	const renderElement = (schedule, day, index) => {
		let verifyElement = false
		let element
		registries.map((registry) => {
			if (
				registry.intIdHorarioCita == schedule.intIdHorarioCita &&
				moment(registry.fecDisponible).format('DD/MM/yyyy') ===
					moment(day).format('DD/MM/yyyy') &&
				registry.bolActivo
			) {
				verifyElement = true
				element = registry
			}
		})

		if (verifyElement) {
			if (
				moment(element.fecDisponible).format('DD/MM/yyyy') ===
				moment(today).format('DD/MM/yyyy')
			) {
				return (
					<StyledTableCell key={index}>
						<Chip
							icon={<CalendarMonthIcon className='CalendarIcon' />}
							// color='success'
							className='btnDisponible ProductTitleDescription'
							label='Disponible'
							variant='outlined'
							onClick={() => {
								handleClickOpen(element, schedule)
							}}
						/>
					</StyledTableCell>
				)
			}

			if (moment(element.fecDisponible).unix() > moment(today).unix()) {
				return (
					<StyledTableCell key={index}>
						<Chip
							icon={<CalendarMonthIcon className='CalendarIcon' />}
							className='btnDisponible ProductTitleDescription'
							label='Disponible'
							variant='outlined'
							onClick={() => {
								handleClickOpen(element, schedule)
							}}
						/>
					</StyledTableCell>
				)
			} else {
				return <StyledTableCell key={index}></StyledTableCell>
			}
		} else {
			return <StyledTableCell key={index}></StyledTableCell>
		}
	}

	return (
		<>
			<div className='TableCalendarContainer mt-40'>
				<div className='TableCalendarBody'>
					<Box className='header-calendar'>
						<Button
							className='btnHoyCalendar'
							variant='outlined'
							onClick={() => {
								onClickButtonToday()
							}}
						>
							Fecha Actual
						</Button>
						<div className='p-header-calendar'>
							<Button
								className='iconArrowCalendar'
								variant='text'
								onClick={() => {
									onClickButtonBack()
								}}
							>
								<ArrowBackIosIcon />
							</Button>
							<span>{renderMont()}</span>
							<Button
								className='iconArrowCalendar'
								variant='text'
								onClick={() => {
									onClickButtonForward()
								}}
							>
								<ArrowForwardIosIcon />
							</Button>
						</div>
					</Box>
					<div className='flex '>
						<div className='column-ahours '>
							<Table className='Table-Schedule '>
								<TableHead
									className='TableHead-Schedule'
									sx={{
										'& th': {
											height: '80px',
											padding: '0px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
										'@media(max-width: 600px)': {
											'& th': {
												height: '50px',
											},
										},
									}}
								>
									<StyledTableRow>
										{/* <StyledTableCell key='01'></StyledTableCell> */}
										{/* {JSON.parse(sessionStorage.getItem('activate_week')) !==
										null ? (
											<>
												{JSON.parse(
													sessionStorage.getItem('activate_week')
												).map((day, index) =>
													moment(day).format('L') ==
													moment(today).format('L') ? (
														<StyledTableCell key={index}>
															<span
																className='dayActive'
																style={{ fontWeight: 'bold', color: 'blue' }}
															>
																{moment(day).locale('es').format('ddd DD')}
															</span>
														</StyledTableCell>
													) : (
														<StyledTableCell key={index}>
															<span>
																{moment(day).locale('es').format('ddd DD')}
															</span>
														</StyledTableCell>
													)
												)}
											</>
										) : null} */}
										<StyledTableCell>
											<span className='dayActive'>
												<TodayIcon sx={{ fontSize: 30 }} />
											</span>
										</StyledTableCell>
									</StyledTableRow>
								</TableHead>
								<TableBody>
									{schedule.map((row, index) => (
										<StyledTableRow
											sx={{
												'&:last-child td, &:last-child th': { border: 0 },
												'& td': {
													height: '80px',
												},
												'@media(max-width: 600px)': {
													'& td': {
														height: '50px',
													},
												},
											}}
											key={index}
										>
											<StyledTableCell>
												<span>{row.hrInicia.slice(0, 5)}</span>
											</StyledTableCell>
											{/* {JSON.parse(sessionStorage.getItem('activate_week')).map(
												(day, indext) => renderElement(row, day, indext)
											)} */}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</div>
						<div className='box-schedule'>
							<Table
								className='Table-Schedule'
								sx={{ minWidth: 600, marginTop: 2 }}
								aria-label='simple table'
							>
								<TableHead
									className='TableHead-Schedule'
									sx={{
										'& th': {
											height: '80px',
											padding: '0px',
											textAlign: 'center',
										},
										'@media(max-width: 600px)': {
											'& th': {
												height: '50px',
											},
										},
									}}
								>
									<StyledTableRow>
										{JSON.parse(sessionStorage.getItem('activate_week')) !==
										null ? (
											<>
												{JSON.parse(
													sessionStorage.getItem('activate_week')
												).map((day, index) =>
													moment(day).format('L') ==
													moment(today).format('L') ? (
														<StyledTableCell key={index}>
															<span
																className='dayActive'
																style={{ fontWeight: 'bold', color: 'blue' }}
															>
																{moment(day).locale('es').format('ddd DD')}
															</span>
														</StyledTableCell>
													) : (
														<StyledTableCell key={index}>
															<span>
																{moment(day).locale('es').format('ddd DD')}
															</span>
														</StyledTableCell>
													)
												)}
											</>
										) : null}
									</StyledTableRow>
								</TableHead>

								<TableBody>
									{schedule.map((row, index) => (
										<StyledTableRow
											sx={{
												'&:last-child td, &:last-child th': { border: 0 },
												'& td': {
													height: '80px',
													width: '100px',
												},
												'@media(max-width: 600px)': {
													'& td': {
														height: '50px',
													},
												},
											}}
										>
											{JSON.parse(sessionStorage.getItem('activate_week')).map(
												(day, indext) => renderElement(row, day, indext)
											)}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</div>
				</div>
			</div>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						¿Estás seguro de agendar tu cita para el día{' '}
						{moment(meetingDialog.date).format('DD/MM/yyyy')} a las{' '}
						{meetingDialog.schedule.slice(0, 5)} hrs?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						variant='contained'
						className='calendarBtnCancelar'
					>
						Calcelar
					</Button>
					<Button
						onClick={() => {
							confirmMeeting(idRegistroDia)
						}}
						variant='contained'
						className='calendarBtnConfirmar'
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default CalendarConFor
