import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import Theme from '../../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { CITA_EDIT, CITA_BY_ID } from '../../../Constants/ApiConstants'
import { ThemeProvider } from '@mui/styles'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@mui/material'
import '../../../Styles/Styles.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import es from 'date-fns/locale/es'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

const CitasEdit = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	const [_FecCitaActual, setFecCitaActual] = useState(null)

	const handleClose = () => {
		props.onClose()
		props.getListCitas()
		LimpiarValores()
	}
	const [_Cita, setCita] = useState({
		lngIdCitas: 0,
		fecCita: '',
		lngIdPropuestas: 0,
		fecRegCita: '',
		hrCita: '',
		intStatusCita: 2,
	})
	const LimpiarValores = () => {
		setCita({
			..._Cita,
			lngIdCitas: '',
			fecCita: '',
			lngIdPropuestas: '',
			fecRegCita: '',
			hrCita: '',
		})
	}

	const guardar = () => {
		console.log(_Cita)
		props.setOpenLoadingScreen()
		requests
			.put(CITA_EDIT, _Cita)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageAlert(response.mensaje)
				props.setOpenAlert(true)
				props.setCloseLoadingScreen()
				handleClose()
				//window.location.reload()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})
	}

	const [_FecCita, setFecCita] = useState(null)
	const [_HrCita, setHrCita] = useState(null)
	const handleFecCita = (date) => {
		setFecCita(date)
		setCita({ ..._Cita, fecCita: date })
	}
	const handleHrCita = (date) => {
		var hora = date.toString()
		var horaSplice = hora.split(' ').splice(4, 1)
		var horaS = horaSplice.toString()
		setHrCita(date)
		setCita({ ..._Cita, hrCita: horaS + '.0000000' })
	}
	useEffect(() => {
		getRegistro()
	}, [props.registroId])

	const getRegistro = () => {
		if (props.registroId != null) {
			requests
				.get(CITA_BY_ID, props.registroId)
				.then((response) => {
					console.log(props.registroId)
					asignarValores(response)
				})
				.catch((error) => {
					console.log('Error: ' * error)
				})
		}
	}

	const asignarValores = (objeto) => {
		setCita({
			..._Cita,
			lngIdCitas: objeto === null ? '' : objeto.lngIdCitas,
			fecCita: objeto === null ? '' : objeto.fecCita,
			lngIdPropuestas: objeto === null ? '' : objeto.lngIdPropuestas,
			fecRegCita: objeto === null ? '' : objeto.fecRegCita,
			hrCita: objeto === null ? '' : objeto.hrCita,
		})
		setFecCitaActual(objeto.fecCita)
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<Dialog
					open={props.open}
					onClose={props.onClose ? props.onClose : null}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<Box
						component='form'
						onSubmit={handleSubmit(guardar)}
						autoComplete='off'
					>
						<Box className='containerHeaderModal'>
							<DialogTitle
								id='alert-dialog-title'
								className='containerHeaderModal font-letter font-barter font-20'
							>
								Reagendar cita
							</DialogTitle>
							<Box>
								<Button onClick={handleClose}>
									<CloseIcon
										fontSize='small'
										className='btn-close-icon-modal font-dark'
									/>
								</Button>
							</Box>
						</Box>
						<DialogContent className='dialog-content-modal'>
							<DialogContentText id='alert-dialog-description'>
								<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
									<DatePicker
										//name='selectedFecIniPublicacion'
										label='Fecha actual de la cita'
										inputFormat='dd/MM/yyyy'
										value={_FecCitaActual}
										//onChange={handleFecCita}
										disabled
										renderInput={(params) => (
											<TextField
												disabled
												variant='standard'
												color='warning'
												fullWidth
												size='small'
												{...params}
											/>
										)}
									/>
								</LocalizationProvider>
								<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
									<DatePicker
										name='selectedFecIniPublicacion'
										label='Nueva fecha'
										inputFormat='dd/MM/yyyy'
										value={_FecCita}
										onChange={handleFecCita}
										renderInput={(params) => (
											<TextField
												required
												variant='standard'
												color='warning'
												fullWidth
												size='small'
												{...params}
											/>
										)}
									/>
									<TimePicker
										name='selectedHrIniPublicacion'
										label='Hora Disponible'
										value={_HrCita}
										onChange={handleHrCita}
										renderInput={(params) => (
											<TextField
												required
												variant='standard'
												color='warning'
												fullWidth
												size='small'
												{...params}
											/>
										)}
									/>
								</LocalizationProvider>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} className='font-dark font-letter'>
								Cancelar
							</Button>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								variant='contained'
								type='submit'
								//startIcon={<AddIcon />}
							>
								Reagendar
							</Button>
						</DialogActions>
					</Box>
				</Dialog>
			</Dialog>
		</ThemeProvider>
	)
}
export default CitasEdit
