import React from 'react';
import {makeStyles} from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Lottie from 'lottie-react'
import loadingCircle from '../Lottie/loadingCircle.json'


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: 'rgba(255, 255, 255, 0.459)',
    },
}));

const LoadingScreen = (props) => {
    const classes = useStyles();
    return (
        <div>
            <Backdrop className={classes.backdrop} open={props.open} >
                <div style={{ width: '300px', margin: 'auto', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center',alignContent:'center'}}>
                    <Lottie animationData={loadingCircle} loop={true} autoplay={true} />
                    <p className="bold co-white "> Cargando... {props.Msj}</p>
                </div>
                {/* <CircularProgress color="primary" /> */}
            </Backdrop>
        </div>
    )
}
export default LoadingScreen;
