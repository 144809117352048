/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
	Card,
	Grid,
	CardContent,
	CardMedia,
	CardActions,
	Button,
	Alert,
} from '@mui/material'
import { Chip } from '@mui/material'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import NumberFormat from 'react-number-format'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PreviewImg from '../../../Components/PreviewImg'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import EventIcon from '@mui/icons-material/Event'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { format } from 'date-fns'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { useSelector } from 'react-redux'
import { CITA_LIST_VM_CON_BY_ID_CLIENT } from '../../../Constants/ApiConstants'
import '../../../Styles/App/Apartado/Apartado.css'
import CitasEdit from '../../../Components/Preevaluaciones/Citas/CitasEdit'

import Pagination from '@mui/material/Pagination'
import usePagination from '../../../Util/Pagination/Pagination'

const CitasRenta = (props) => {
	const [registroId, setRegistroId] = useState(null)
	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const [_ListCitas, setListCitas] = useState([])
	const getListCitas = () => {
		props.setOpenLoadingScreen()
		requests
			.get("", userInfo.intIdUsuario)
			.then((response) => {
				setListCitas(response)
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				setRegistroId(null)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getListCitas()
	}, [])

	const fechaActual = new Date()
	function diferencia(date1, date2) {
		const fechaCita = new Date(date2)
		const dia1UTC = Date.UTC(
			date1.getFullYear(),
			date1.getMonth(),
			date1.getDate()
		)
		const dia2UTC = Date.UTC(
			fechaCita.getFullYear(),
			fechaCita.getMonth(),
			fechaCita.getDate()
		)
		var dia = 1000 * 60 * 60 * 24
		var total = (dia2UTC - dia1UTC) / dia
		if (total === 4 || total === 3 || total === 2 || total === 1) {
			return (
				<div className='card-media-box-citas-1'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
		if (total > 5) {
			return (
				<div className='card-media-box-citas-2'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
		if (total <= 0) {
			return (
				<div className='card-media-box-citas-3'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
	}

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	//abrir modal img
	const [IdImgPreview, setIdImgPreview] = useState()
	const [openModalImgPreview, setModalImgPreview] = useState(false)
	const handleOpenImgPreview = () => {
		setModalImgPreview(!openModalImgPreview)
	}

	//paginacion
	let [page, setPage] = useState(1)
	const PER_PAGE = 2

	const count = Math.ceil(_ListCitas.length / PER_PAGE)
	
	const _DATA = usePagination(_ListCitas, PER_PAGE)

	const handleChange = (e, p) => {
		setPage(p)
		_DATA.jump(p)
	}

	return (
		<div>
			<div className='containerViewUser' hidden={_Ocultar}>
				<div className='flex-start w100'>
					<Chip
						icon={<ChangeCircleIcon />}
						label='Mis citas / Consigna'
						color='primary'
						className='back-transparent font-letter font-barter font-20'
					/>
				</div>

				{/*
                    {
                        "lngIdCitas": 82,
                        "fecCita": "2023-01-21T00:00:00",
                        "fecCitaStr": "21/01/2023",
                        "lngIdPropuestas": 10,
                        "fecRegCita": "20/01/2023",
                        "txtNombreCliente": "Giovanni Daniel Brito López",
                        "bolServicio": null,
                        "intIdTipoServicio": 2,
                        "txtTipoServicio": "Consigna",
                        "txtFolio": "BL-PRO-95",
                        "numValScore": null,
                        "txtUrlImgFrente": "https://dl.dropbox.com/s/t1zdfv841yunsnh/645262.jpg?dl=0",
                        "txtArticulo": "Zapatos",
                        "txtMarca": "COACH",
                        "txtOtraMarca": null,
                        "txtMaterial": "ALGODÓN",
                        "txtStatusCita": "Agendada"
                    }
                
                */}

				<div className='box-consigna-pro mt3'>
					{_ListCitas.map((row, index) => (
						<div className='box-list-consigna-pro'>
							<div className='w20 relative'>
								<img className='img-consigna-pro' src={row.txtUrlImgFrente} />
								<div className='box-view-absolute'>
									<IconButton
										className='btn-blur'
										onClick={function () {
											setIdImgPreview(row.txtUrlImgFrente)
											handleOpenImgPreview()
										}}
									>
										<RemoveRedEyeIcon sx={{ m: 0, p: 0, color: '#000000' }} />
									</IconButton>
								</div>
							</div>
							<div className='flex-column-start-between w80 pl1'>
								<div className='flex-column-start w100'>
									<div className='flex-between'>
										<span className='p20 bold upperCase'>
											{row.txtArticulo} {row.txtMarca} de {row.txtMaterial}
											<span className='mx1'> - </span>
											<span className='p'>{row.txtTipoServicio}</span>
										</span>

										<span className='p co-grey'>
											{diferencia(fechaActual, row.fecCita)}
										</span>
									</div>

									<span className='p '>
										<strong>Cliente: &nbsp;</strong>
										{row.txtNombreCliente}
									</span>

									<span className='p '>
										<strong>Estatus Cita: &nbsp;</strong>
										{row.txtStatusCita === null
											? 'Procesando'
											: row.txtStatusCita}
									</span>
									<span className='p '>
										<strong>Folio: &nbsp;</strong>
										{row.txtFolio}
									</span>
								</div>

								<div className='flex-start'>
									<Button
										className='btn-calendar mr1'
										startIcon={<EventIcon />}
										onClick={function () {
											setRegistroId(row.lngIdCitas)
											handleOpen()
											//navigate(CLIENTES_SALES + row.lngIdProducto)
										}}
									>
										Reagendar cita
									</Button>
								</div>
							</div>
						</div>
					))}
				</div>
				<Pagination
					count={count}
					size='large'
					page={page}
					variant='outlined'
					shape='rounded'
					onChange={handleChange}
				/>

				{/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */}
			</div>
			<div hidden={_Ocultar2}>
				<Alert
					className='alert-no-data'
					icon={<InfoIcon fontSize='inherit' className='alert-icon-no-data' />}
				>
					Aún no cuentas con citas agendadas.
				</Alert>
			</div>
			<CitasEdit
				registroId={registroId}
				getListCitas={getListCitas}
				open={open}
				onClose={handleOpen}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
				setMessageAlert={props.setMessageAlert}
				setOpenAlert={props.setOpenAlert}
				setMessageAlertWarning={props.setMessageAlertWarning}
				setOpenAlertWarning={props.setOpenAlertWarning}
			/>
			<PreviewImg
				IdImgPreview={IdImgPreview}
				openModalImgPreview={openModalImgPreview}
				onCloseModalImgPreview={handleOpenImgPreview}
			/>
		</div>
	)
}
export default CitasRenta