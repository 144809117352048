export const PAY_CREATE = 'PAY_CREATE'
export const PAY_SUCCESS = 'PAY_SUCCESS'
export const PAY_FAIL = 'PAY_FAIL'

export const GET_PAY_REQUEST = 'GET_PAY_REQUEST'
export const GET_PAY_FAIL = 'GET_PAY_FAIL'

export const REFRENDO_CREATE = 'REFRENDO_CREATE'
export const REFRENDO_SUCCESS = 'REFRENDO_SUCCESS'
export const REFRENDO_FAIL = 'REFRENDO_FAIL' 

export const LIQUIDAR_EMP_CREATE = 'LIQUIDAR_EMP_CREATE'
export const LIQUIDAR_EMP_SUCCESS = 'LIQUIDAR_EMP_SUCCESS'
export const LIQUIDAR_EMP_FAIL = 'LIQUIDAR_EMP_FAIL'

export const PER_SHP_CREATE = 'PER_SHP_CREATE'
export const PER_SHP_SUCCESS = 'PER_SHP_SUCCESS'
export const PER_SHP_FAIL = 'PER_SHP_FAIL'

export const PAYMENTS_METHODS_REQUEST = 'PAYMENTS_METHODS_REQUEST'
export const PAYMENTS_METHODS_SUCCESS = 'PAYMENTS_METHODS_SUCCESS'
export const PAYMENTS_METHODS_FAIL = 'PAYMENTS_METHODS_FAIL'
