/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import Theme from '../../../Styles/Theme'
import { ThemeProvider } from '@mui/styles'
import { Box, Button, Dialog, DialogTitle, Slide } from '@mui/material'
import '../../../Styles/Styles.css'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const EmpeñoHelp = (props) => {
	const handleClose = () => {
		props.onClose()
	}

	return (
		// <ThemeProvider theme={Theme}>
		<div>
			<Dialog
				fullScreen
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				TransitionComponent={Transition}
				PaperProps={{
					style: {
						background: 'rgba(255, 255, 255, 0.2)',
						backdropFilter: 'blur(10px)',
					},
				}}
			>
				<div className='alertHelpVideo'>
					<div className='header-modal-helpVideo flex-between'>
						<p className='text-TitleDacturasDetail co-white'>
							¿Comó realizar un empeño?
						</p>
						<IconButton
							className='btn-close-icon-alertHelp'
							aria-label='delete'
							onClick={handleClose}
						>
							<CloseIcon sx={{ color: 'black' }} />
						</IconButton>
					</div>
					<div className='container-body-modal'>
						<Paper
							sx={{
								width: '100%',
								overflow: 'hidden',
								boxShadow: 'none',
								backgroundColor: 'transparent',
								position: 'relative',
							}}
						>
							<div className='ContainerIframeHelp'>
								{/* <Skeleton
									className='responsive-iframe'
									variant='rectangular'
									animation='wave'
									height={590}
								/> */}
								<iframe
									className='responsive-iframe'
									src='https://drive.google.com/file/d/1sporfPDqZbrlOG_WlgNlil0w1zogiNMv/preview'
									allow='autoplay'
								></iframe>
							</div>
						</Paper>
					</div>
				</div>
				{/* <Box className='alertHelpVideo'>
						<Box className='flex-between mx2'>
							<DialogTitle
								id='alert-dialog-title'
								className='containerHeaderModal font-letter font-barter font-20'
							>
								¿Comó realizar un empeño?
							</DialogTitle>
							<Button
								onClick={handleClose}
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
							>
								<CloseIcon
									fontSize='small'
									className='btn-close-icon-modal font-dark'
								/>
							</Button>
						</Box>
						<iframe src="https://drive.google.com/file/d/1sporfPDqZbrlOG_WlgNlil0w1zogiNMv/preview" width='940'
							height='680' allow="autoplay"></iframe>
					</Box> */}
			</Dialog>
		</div>
		// </ThemeProvider>
	)
}

export default EmpeñoHelp
