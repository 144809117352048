import { React, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
//import ImgConfirmationPayment from '../../img/ConfirmationPayment.jpg'
import requests from "../AxiosCalls/AxiosCall"
import { PROD_APARTADO_DETAILS } from "../../Constants/routesConstants"

const Confirmation3DsApa = ({
  setMsjLoadingScreen,
  setOpenLoadingScreen,
  setCloseLoadingScreen,
  setOpenSnackBar,
  setMessageSnackBar,
  setTypeSnackBar,
}) => {
  const navigate = useNavigate()
  const { reference } = useParams()
  const IdProduct = JSON.parse(localStorage.getItem("IdProduct"))
  useEffect(() => {
    confirmPayment()
  }, [])

  const confirmPayment = () => {
    console.log("hoola")
    debugger
    setOpenLoadingScreen()
    setMsjLoadingScreen("Validando Transacción")
    const ipgTransactionId = JSON.parse(
      localStorage.getItem("ipgTransactionId")
    )

    const type3ds = JSON.parse(localStorage.getItem("3dsSinIframe"))
      ? JSON.parse(localStorage.getItem("3dsSinIframe"))
      : ""
    const HashCo = JSON.parse(localStorage.getItem("CoHas"))
      ? JSON.parse(localStorage.getItem("CoHas"))
      : ""

    const SendBody = {
      transactionId: ipgTransactionId,
      cRes: reference,
      type3ds: type3ds,
      securityCode: HashCo,
    }
    requests
      .post("/PaymentsApartado/ConfirmPayment/", SendBody)
      .then((response) => {
        localStorage.removeItem("CoHas")
        ValidPaymentTransaction()
      })
      .catch((error) => {
        debugger
        console.log(error)

        localStorage.removeItem("CoHas")
        localStorage.removeItem("ipgTransactionId")
        localStorage.removeItem("3dsSinIframe")
        setTypeSnackBar("warning")
        setMessageSnackBar(error.data.mensaje)
        setOpenSnackBar(true)
        setCloseLoadingScreen()
        navigate(PROD_APARTADO_DETAILS + IdProduct)
      })
  }
  const ValidPaymentTransaction = () => {
    debugger

    const ipgTransactionId = JSON.parse(
      localStorage.getItem("ipgTransactionId")
    )
    const SendBody = {
      transactionId: ipgTransactionId,
    }
    requests
      .post("/PaymentsApartado/SecondTransaction/", SendBody)
      .then((response) => {
        setTypeSnackBar("success")
        console.log(response)
        setMessageSnackBar(response.mensaje)

        setOpenSnackBar(true)
        setCloseLoadingScreen()

        localStorage.removeItem("ipgTransactionId")
        localStorage.removeItem("3dsSinIframe")

        navigate(PROD_APARTADO_DETAILS + IdProduct)
      })
      .catch((error) => {
        console.log(error)
        debugger

        localStorage.removeItem("ipgTransactionId")
        localStorage.removeItem("3dsSinIframe")
        setTypeSnackBar("warning")
        setMessageSnackBar(error.data.mensaje)
        setOpenSnackBar(true)
        setCloseLoadingScreen()
        navigate(PROD_APARTADO_DETAILS + IdProduct)
      })
  }

  return (
    <div style={{ width: "550px", margin: "auto" }}>
      {/* <Lottie animationData={PaymentProcess} loop={true} autoplay={true} />; */}
    </div>
  )
}
export default Confirmation3DsApa
