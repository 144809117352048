
export const StatusCRM = {
  
    Evaluacion_Virtual:{id: '18853BF5-D8CB-456B-8A9B-D36D6059DF59',order:0},
    Propuesta_Inicial:{id: '7258E679-842B-47A1-A66A-0C8AEF63B69E',order:1},
    Cita: {id:'C10D01A9-A2FC-4CBB-9F3A-1A7A5B9E8B61',order:2},
    Evaluacion_Presencial:{id: 'E91AF4A9-FC56-4112-9E93-113A48BB8EF3',order:3}, 
    Propuesta_Final:{id: 'C1AB23B5-5BA1-4467-88E6-818728E01557',order:4},
    Contrato: {id:'C5747F3D-5C55-4806-ADEB-80E438188CD3',order:5}, 
    Publicacion:{id: '4961E459-63E2-4C36-820A-94CC915511A2',order:6},
    Negociacion:{id: '86435B8D-2C4D-4235-A37C-2A27219D1B8A',order:7},
    Venta: {id:'C77FAE62-78FF-40B3-B0D5-912880A8E35A',order:8},   
    Entrega_Exitosa:{id: '2FEE6FA9-6FE0-40C1-8326-95EDA156C65C',order:9},
    Deposito_Exitoso: {id:'8F1DEC13-2691-4C54-BE32-D5AB2650C798',order:10},
    Producto_Devuelto: {id:'A0DDB901-A1D3-4D94-BE27-DB694543816C',order:11},
    Evaluacion_Fallida:{id: 'F09554BA-EAF1-46A5-9302-524D36621B75',order:12},
    No_Es_Viable: {id:'2F995852-C05D-43DD-9A47-080EF3447B02',order:13},
    NoAcepta_Propuesta:{id: 'C2AE1194-2335-4987-BB96-5897FE028365',order:14}   
  }
