/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Chip } from '@mui/material'
import PaidIcon from '@mui/icons-material/Paid'
import Button from '@mui/material/Button'
import PaymentIcon from '@mui/icons-material/Payment'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNavigate } from 'react-router-dom'
import { FLUJO_GUIAS } from '../../../Constants/routesConstants'

const PagosGuias = () => {
	const navigate = useNavigate()

	const pay = () => {
		navigate(FLUJO_GUIAS)
	}


	return (
		<div className='containerViewUser p2'>
			<div className='w100'>
				<Chip
					icon={<PaidIcon />}
					label='Pagos Extraudinarios / Pagos De Guias'
					color='primary'
					className='back-transparent font-letter font-barter font-20'
				/>
			</div>

			<div className='box-pagos-Cert'>
				<div className='flex '>
					<div className='w20 '>
						<img
							className='w100 br1  '
							src='https://images.unsplash.com/photo-1556011308-d6aedab5ed8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
						/>
					</div>

					<div className='flex-column-start-between ml1 w80'>
						<div className='flex-column-start w100'>
							<div className='flex-between mb1'>
								<span className='p'>
									<strong>Tipo De Envio:</strong> Express Domestic
								</span>
								<span className='p'>
									<strong>Numero de Guia:</strong> 9950522024
									<IconButton
										onClick={() => navigator.clipboard.writeText('9950522024')}
									>
										<ContentCopyIcon />
									</IconButton>
								</span>
							</div>

							<span className='p'>
								<strong>Direccion De Envio:</strong> Stilisimo Torre Reforma,
								Av. Paseo de la Reforma 512-Piso 34, Polanco, Colonia Juarez,
								Miguel Hidalgo, 06500 Ciudad de México, CDMX
							</span>
						</div>
						<span className='p'>
							<strong>Total a pagar:</strong>$ 250.00MXN
						</span>

						<div className='w100 flex-end'>
							<Button 
								className='btn-pay-full' 
								endIcon={<PaymentIcon />}
								onClick={() => pay()}
							>
								Pagar Guia
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PagosGuias
