/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import requests from '../../../Components/AxiosCalls/AxiosAuth'
import Button from '@mui/material/Button'
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom'
import { FLUJO_RENTAR } from '../../../Constants/routesConstants'
import { PRODUCTOS_DETAILS_BY_SKU } from '../../../Constants/ApiConstants'

import '../../../Styles/GeneralStyles'
import '../../../Styles/App/Apartado/ApartadoView.css'

const RentaPrimerProcesoProduct = (props) => {
    const sku = JSON.parse(sessionStorage.getItem('sku'))
    const [_Producto, setProducto] = useState([])

    let navigate = useNavigate()

    useEffect(() => {
        getRegistro();
    }, []);

    const getRegistro = () => {
        props.setOpenLoadingScreen()
        requests
            .get(PRODUCTOS_DETAILS_BY_SKU, sku)
            .then((response) => {
                setProducto(response)
                props.setCloseLoadingScreen()
            }).catch((error) => {
                console.log(error)
                props.setCloseLoadingScreen()
            })
    }

    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel1) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel1 : false)
    }
    const handleNavigateViewProPay = () => {
        navigate(FLUJO_RENTAR + _Producto.lngIdProducto)
    }

    return (
        <div className='containerViewUser'>
            <div className='flex-column w70 '>
                <div className='box-proceso-producto '>
                    <div className='w20 p2'>
                        <img className='img-proceso-producto' src={_Producto.txtUrl} />
                    </div>
                    <div className='flex-column-start w40 line-height-1'>
                        <div>
                            <div>
                                <p className='title-proceso-producto'>{_Producto.txtProductoH === null ? _Producto.txtProductoM : _Producto.txtProductoH} {_Producto.intIdMarca}</p>
                                <p>
                                    <strong>Réntalo con el 15%: </strong>
                                    <NumberFormat
                                        value={(_Producto.dblPrecioFinal * 15) / 100}
                                        displayType={'text'}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={'$ '}
                                        suffix={' MXN'}
                                    />
                                </p>
                                <p>
                                    <strong>Valor Total: </strong>
                                    <NumberFormat
                                        value={_Producto.dblPrecioFinal}
                                        displayType={'text'}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={'$ '}
                                        suffix={' MXN'}
                                    />
                                </p>
                            </div>

                            <p>
                                <strong>SKU: </strong> {_Producto.txtSkuReg}
                            </p>
                            <p>
                                <strong>Talla/Tamaño: </strong> {_Producto.intIdTallaTamano}
                            </p>
                            <p>
                                <strong>Color: </strong> {_Producto.intIdColor}
                            </p>
                            <p>
                                <strong>Material:</strong> {_Producto.intIdMaterial}
                            </p>
                        </div>
                    </div>
                    <div className='flex-column-start w40 line-height-0'>
                        <p className='fs30'>¡Réntalo ahora!</p>
                        {/* <div className='flex-start gap3 mb3'>
							<div>
								<p className='bold'>Fecha de apartado</p>
								<p> 19 / Diciembre /2022</p>
							</div>
							<div>
								<p className='bold'>Fecha de liquidacion</p>
								<p>19 / Enero /2022</p>
							</div>
						</div> */}
                        <Button className='btn-pay' onClick={handleNavigateViewProPay}>
                            Rentar
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RentaPrimerProcesoProduct