import React, { useState, useEffect, useRef } from 'react'

import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import logo from '../../../Images/logo-barter-sin-fondo.png'
import NumberFormat from 'react-number-format'
import Slider from 'react-slick'
import { gtm_CloseTerminos_Log } from '../../../Constants/GoogleTagManager/gtmConstants'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CpreparaTuEnvioModal = (props) => {
  const handleClose = () => {
    gtm_CloseTerminos_Log()
    props.onCloseModalImgPreview()
  }
  /*----------------------------------- */
  //creating the ref
  const customeSlider = useRef()

  // setting slider configurations
  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    onSwipe: false,
    // swipe: false,
  }

  const previous = () => {
    customeSlider.current.slickNext()
  }

  const next = () => {
    customeSlider.current.slickPrev()
  }
  return (
    <Dialog
      fullScreen
      open={props.openModalImgPreview}
      onClose={
        props.onCloseModalImgPreview ? props.onCloseModalImgPreview : null
      }
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          background:
            'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)',
        },
      }}
    >
      <div className="box-termConditions-MB">
        <div className="section-termConditions-MB-1">
          <div>
            <Slider {...settings} ref={customeSlider}>
              <div className="box-term-MB-1">
                <DialogTitle sx={{ color: '#583101' }} id="alert-dialog-title">
                  {/* {'Terminos y Condiciones'} */}
                  <img
                    src="https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png"
                    className="imgTermConditionsConsigna"
                  />
                </DialogTitle>

                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    className="text-center mt-10 upperCase font-20 f-weight-bold"
                  >
                    Prepara tu envío, te damos algunas recomendaciones.
                  </DialogContentText>

                  <DialogContentText
                    id="alert-dialog-description"
                    className="text-justify mt-10"
                  >
                    1 - Hay dos formas de conseguir tu guía de envío:
                    <ol type="a">
                      <li>
                        Compra tu guía DHL a precio preferencial con nosotros,
                        haremos el seguimiento por ti, envía Whats o llama al
                        56-4181-9020 para solicitarla.
                      </li>
                      <li>
                        Compra tu guía con la paquetería de tu elección,
                        envíanos la confirmación de tu envío al 56-4181-9020 y
                        estaremos pendientes de su llegada.
                      </li>
                    </ol>
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    className="text-justify mt-10"
                  >
                    2 - Empaqueta tu pieza de la mejor forma, te recomendamos
                    usar una caja nueva, si utilizas una usada, asegúrate de que
                    no esté desgastada o rota. Rellena la caja con algún
                    material que proteja tu pieza, puedes usar burbuja.
                    Finalmente sella bien la caja para que no se abra en el
                    camino y pega tu guía.
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    className="text-justify mt-10"
                  >
                    3 - Solicita recolección a la paquetería o bien, deja tu
                    paquete en la sucursal más cercana.
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    className="text-justify mt-10"
                  >
                    En caso de que la paquetera tenga un percance con tu envío,
                    deberás comunicarte con ellos para contar con su apoyo y
                    solución.
                  </DialogContentText>
                </DialogContent>
              </div>
            </Slider>
          </div>
        </div>
        <div className="section-termConditions-MB-2">
          <Button
            variant="outlined"
            sx={{
              color: '#583101',
              borderColor: '#583101',
              ':hover': { borderColor: '#583101' },
              margin: 'auto',
            }}
            onClick={handleClose}
            autoFocus
          >
            Aceptar
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CpreparaTuEnvioModal
