/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Grid from '@mui/material/Grid'
import { Chip } from '@mui/material'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

import {
	CLIENTES_INTERCAMBIO_PROD_VIEW,
	CLIENTES_INTERCAMBIO_PROPUESTAS_VIEW,
} from '../../../Constants/routesConstants'

const ClientesIntercambioView = (props) => {
	const navigate = useNavigate()

	return (
		<div className='containerViewUser p2'>
			<Grid container spacing={1}>
				<Grid item xs={4} sm={8} md={12} lg={12}>
					<Chip
						icon={<ChangeCircleIcon />}
						label='Mis intercambios'
						color='primary'
						className='back-transparent font-letter font-barter font-20'
					/>
				</Grid>
				<Grid item xs={6} lg={6} className='flex mt2'>
					<div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://media.istockphoto.com/id/1200693700/ja/%E3%82%B9%E3%83%88%E3%83%83%E3%82%AF%E3%83%95%E3%82%A9%E3%83%88/%E8%B5%B0%E3%82%8A%E9%96%93%E8%BF%91%E3%81%A7%E3%82%B9%E3%83%8B%E3%83%BC%E3%82%AB%E3%83%BC%E3%82%92%E7%B5%90%E3%81%B6%E5%A5%B3%E6%80%A7.jpg?s=170667a&w=0&k=20&c=4U5F0WLADZHn5nft6qPshMo9tdJx_rZpdHepkQ8_PCs='
							/>
						</div>

						<div className='card-center'>Propuestas</div>
						<p className='card-text-desc'>
							Aquí podrás dar seguimiento a las propuestas realizadas sobre tus
							artículos preevaluados.
						</p>
						{/* <ul>
							<li className='card-text-desc1-5'>
								El estatus de revisión en el que se encuentra.
							</li>
							<li className='card-text-desc'>
								El precio de venta que fue asignado.
							</li>
							<li className='card-text-desc3'>
								Tu ganancia estimada por pieza.
							</li>
						</ul> */}

						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(CLIENTES_INTERCAMBIO_PROPUESTAS_VIEW)
								}}
							>
								Ver propuestas
							</Button>
						</div>
					</div>
				</Grid>
				<Grid item xs={6} lg={6} className='flex mt2'>
					<div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://laopinion.com/wp-content/uploads/sites/3/2021/04/zapato-de-cuero-shutterstock_1412940647.jpg?quality=80&strip=all&w=640'
							/>
						</div>

						<div className='card-center'>Mis intercambios</div>
						<p className='card-text-desc'>
							Da seguimiento a tus artículos de intercambio.
						</p>
						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(CLIENTES_INTERCAMBIO_PROD_VIEW)
								}}
							>
								Ver articulos
							</Button>
						</div>
					</div>
				</Grid>
				{/* <Grid item xs={6} lg={6} className='flex mt2'>
					<div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://plus.unsplash.com/premium_photo-1661657759493-f21eb0d67e27?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
							/>
						</div>

						<div className='card-center'>En venta</div>
						<p className='card-text-desc1'>
							Aquí podrás dar seguimiento a as piezas que tienes en venta y
							revisar:
						</p>
						<ul>
							<li className='card-text-desc1-5'>Su precio de venta.</li>
							<li className='card-text-desc'> Descuento aplicado.</li>
							<li className='card-text-desc3'>Tu ganancia estimada.</li>
						</ul>

						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(CLIENTES_CONSIGNAS_EN_VENTA)
								}}
							>
								Ver piezas
							</Button>
						</div>
					</div>
				</Grid>
				<Grid item xs={6} lg={6} className='flex mt2'>
					<div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://images.unsplash.com/photo-1559563458-527698bf5295?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
							/>
						</div>

						<div className='card-center'>Vendidas</div>
						<p className='card-text-desc1'>
							Aquí podrás revisar las piezas que ya vendiste y ver:
						</p>
						<ul>
							<li className='card-text-desc1-5'>Su precio de venta.</li>
							<li className='card-text-desc'> Descuento aplicado.</li>
							<li className='card-text-desc3'>Tu ganancia estimada.</li>
							<li className='card-text-desc4'>
								Estatus de venta (vendida/venta en validación).
							</li>
						</ul>
						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(CLIENTES_CONSIGNAS_VENTAS)
								}}
							>
								Ver piezas
							</Button>
						</div>
					</div>
				</Grid> */}
			</Grid>
		</div>
	)
}
export default ClientesIntercambioView
