import { rgbToHex } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const useStylesGeneral = makeStyles((theme) => ({
	tituloboton: {
		marginTop: '30px!important',
		marginBottom: '30px!important',
	},
	tituloseccion2: {
		marginBottom: '5px!important',
		fontSize: '25px!important',
		color: '#676767',
		marginTop: '30px!important',
		fontWeight: 'bold!important',
	},
	consigna: {
		marginLeft: '20px!important',
	},
	mydatatable: {
		marginTop: '70px!important',
		marginBottom: '30px!important',
	},
	nombrepage: {
		backgroundColor: '#f8bbd0!important',
		color: '#d81b60!important',
	},
	//appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: '1px!important',
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: '20px!important',
		paddingBottom: '20px!important',
		height: 'auto',
	},
	paper: {
		padding: '1px!important',
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		width: 'auto',
	},
	paperTitle: {
		height: 'auto!important',
		borderRadius: '12px!important',
		padding: '20px!important',
		// borderTop: '8px solid #F0D006',
		borderTop: '8px solid #757D65',
		paddingBottom: '10px!important',
		// [theme.breakpoints.down('xs')]: {
		//     padding: 2,
		// },
	},
	paperBody: {
		height: 'auto',
		borderRadius: '12px!important',
		// padding: '20px!important',
		// paddingTop: '40px!important',
		paddingBottom: '40px!important',
	},
	paperCuestionaryTitle: {
		backgroundColor: '#757D65!important',
		borderRadius: '12px 12px 0px 0px!important',
		textAlign: 'center !important',
		color: '#ffffff !important',
	},
	paperCuestionaryBody: {
		padding: '20px!important',
		display: 'flex!important',
		flexDirection: 'column!important',
		justifyContent: 'center!important'
		/* padding: 20px!important;
    display: flex;
    flex-direction: column;
    align-items: center; */
	},
	btn: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '18ch',
		marginBlock: '10px!important',
		height: '2.5rem',
	},
	fab: {
		width: '3rem',
		height: '3rem',
	},
	textField: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '13rem',
		marginBlock: '10px',
	},
	textField2: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '22rem',
		marginBlock: '10px',
	},
	textField3: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '13rem',
		marginBlock: '10px',
	},
	textField4: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '5rem',
		marginBlock: '10px',
	},

	selectOption: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '13rem',
		padding: '0px!important',
		marginBottom: '2px!important',
	},
	inputTxtName: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '40rem',
		padding: '0px!important',
	},
	inputTxtEmail: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '19.5rem',
		padding: '0px!important',
	},
	inputTxtTelefono: {
		marginLeft: '1px!important',
		marginRight: '1px!important',
		width: '19.5rem',
		padding: '0px!important',
	},
	title: {
		margin: '1pxpx',
		textAlign: 'center',
		color: 'black',
	},
	root: {
		'& > *': {
			margin: '1px!important',
		},
	},
	subtitle: {
		color: 'black',
		// [theme.breakpoints.down('xs')]: {
		//     fontSize: 15,
		// },
	},
	input: {
		display: 'none',
	},
	question: {
		fontSize: '15px!important',
		verticalAlign: 'middle',
		// [theme.breakpoints.down('xs')]: {
		//     fontSize: 14,
		// },
	},
	titleQuestion: {
		fontSize: '20px!important',
	},
	bodyCuestionary: {
		padding: '10px!important',
		marginTop: '0px!important',
		marginLeft: '10px!important',
		marginRight: '10px!important',
		// [theme.breakpoints.down('xs')]: {
		//     marginRight: 0.3,
		//     marginLeft: 0.3,
		//     marginTop: 1,
		//   },
	},
	titleCuestionary: {
		padding: '10px!important',
		marginTop: '1px!important',
		marginLeft: '10px!important',
		marginRight: '10px!important',
		// [theme.breakpoints.down('xs')]: {
		//     marginRight: 0.3,
		//     marginLeft: 0.3,
		//     marginTop: 1,
		//   },
	},
	back: {
		//background: '#e2e2e2',
		flexGrow: 1,
	},
	questions: {
		height: 'auto',
		borderRadius: '12px!important',
		paddingTop: '4px!important',
		paddingBottom: '4px!important',
		paddingLeft: '6px!important',
		paddingRight: '6px!important',
		marginLeft: '20px!important',
		marginRight: '20px!important',
	},
	bodyQuestionGrey: {
		background: '#f3f3f3',
		color: '#58555b',
		padding: '1px!important',
		borderRadius: '12px!important',
		marginBottom: '2px!important',
		width: '60%',
		margin: 'auto',
		// [theme.breakpoints.down('md')]: {
		//     width: '90%',
		//   },
		// [theme.breakpoints.down('xs')]: {
		//     width: '100%',
		//     marginBottom: 1,
		//     padding: 0.5,
		//   },
	},
	bodyQuestionWhite: {
		paddingLeft: '1px!important',
		paddingRight: '1px!important',
		marginBottom: '1px!important',
		color: '#58555b',
		width: '60%',
		margin: 'auto',
		// [theme.breakpoints.down('md')]: {
		//     width: '90%',
		//     marginBottom: 0.5,
		//   },
		// [theme.breakpoints.down('xs')]: {
		//     width: '100%',
		//     paddingLeft: 0.5,
		//     paddingRight: 0.5,
		//   },
	},
	bodyQuestionWhite2: {
		paddingLeft: '1px!important',
		paddingRight: '1px!important',
		marginBottom: '1px!important',
		color: '#58555b',
		justifyContent: 'right',
	},
	numbersFormat: {
		paddingTop: '0.2px!important',
		paddingBottom: '0.2px!important',
	},
	numberFormat: {
		width: '8rem',
		marginRight: '2px!important',
		marginTop: '0.6px!important',
		marginBottom: '0.6px!important',
	},
	slider: {
		width: '80%',
	},
	dividerhr: {
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		opacity: '10',
		marginBottom: '4px!important',
		// [theme.breakpoints.down('xs')]: {
		//     marginBottom: 1,
		//   },
	},
	dividerhr2: {
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		opacity: '10',
		marginBottom: '20px!important',
		// [theme.breakpoints.down('xs')]: {
		//     marginBottom: 1,
		//   },
	},
	selectOptions: {
		marginBottom: '20px!important'
	},
}))
