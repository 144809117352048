/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/styles'
import { useSelector } from 'react-redux'
import {
	EMPEÑO_BY_ID,
	EMPEÑO_PAGO_DETALLES,
	TRASFER_EMP_REFRENDAR
} from '../../../Constants/ApiConstants'

import AmountPayRef from './PaymentComponentRef/AmountPayRef'
import PayRef from '../Refrendos/PaymentComponentRef/PayRef'
import SuccessfullRef from './PaymentComponentRef/SuccessfullRef'
import requests from '../../AxiosCalls/AxiosCall'
import '../../../Styles/PayCodi.css'
import NumberFormat from 'react-number-format'
import Checkbox from '@mui/material/Checkbox'
import '../../../Styles/App/Apartado/ApartadoDetail.css'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
const Input = styled('input')({
	display: 'none',
})
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: 3,
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto #CBB878',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
	},
}))
const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#CBB878',
	backgroundImage:
		'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#CBB878',
	},
})
function BpCheckbox(props) {
	return (
		<Checkbox
			sx={{
				'&:hover': { bgcolor: 'transparent' },
			}}
			disableRipple
			color='default'
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			inputProps={{ 'aria-label': 'Checkbox demo' }}
			{...props}
		/>
	)
}

const PaymentFlowRef = (props) => {
	const { id } = useParams()

	const [activeStep, setActiveStep] = useState(0)
	const [skipped, setSkipped] = useState(new Set())
	const [_Empeño, setEmpeño] = useState([])
	const [_Detalles, setDetalles] = useState([])
	let navigate = useNavigate()
	const [_OcultarFlujo, setOcultarFlujo] = useState(false)
	const [_checkEnvio, setcheckEnvio] = useState(false)
	const [_Comprobante, setComprobante] = useState(null)
	const [_Btn, setBtn] = useState(true)

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	const isStepOptional = (step) => {
		return step === 1
	}

	const isStepSkipped = (step) => {
		return skipped.has(step)
	}

	const handleNext = () => {
		let newSkipped = skipped
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values())
			newSkipped.delete(activeStep)
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped(newSkipped)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleReset = () => {
		setActiveStep(0)
	}
	const getEmpeño = () => {
		props.setOpenLoadingScreen()
		requests
			.get(EMPEÑO_BY_ID, id)
			.then((response) => {
				calculo(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const [_Totales, setTotales] = useState({
		liquidacion: 0,
		regrendo: 0,
	})
	const calculo = (data) => {
		//desempeño = 6000
		let pago = (data.dblImporteDesemp * 2.12) / 100 + 3//130.2
		let total = data.dblImporteDesemp + pago //6130.2
		let re = (data.dblImporteDesemp * 20) / 100 //1200
		let pagos = (re * 2.12) / 100 + 3 //28.44
		let pago2 = re + pagos //1228.44
		console.log(data);
		setEmpeño(data)
		setTotales({ ..._Totales, liquidacion: total, regrendo: pago2 })
	}
	const getDetalles = () => {
		props.setOpenLoadingScreen()
		requests
			.get(EMPEÑO_PAGO_DETALLES, id)
			.then((response) => {
				// console.log(response)
				setDetalles(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getEmpeño()
		getDetalles()
		// getRefrendo()
	}, [])

	const [_DataTransfer, setDataTransfer] = useState({
		LngIdProducto: '',
		LngIdCliente: '',
		IntIdPorcentaje: '',
		DblMontoPago: '',
		TxtUrlComprobante: '',
		IntIdPlazoApa: '',
	})

	//::::::::::::::::::::: Titulos de cada paso ::::::::::::::::::::::::::
	const steps = ['Monto a refrendar', 'Realizar pago', 'Resumen de pago']
	//::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
	function getSteps(step, handleNext, handleBack) {
		switch (step) {
			case 0:
				return (
					<AmountPayRef
						handleNext={handleNext}
						handleBack={handleBack}
						data={_Empeño}
						detalles={_Detalles}
						props={props}
					/>
				)
			case 1:
				return (
					<PayRef
						handleNext={handleNext}
						handleBack={handleBack}
						data={_Empeño}
						detalles={_Detalles}
						props={props}
					/>
				)
			case 2:
				return (
					<SuccessfullRef
						handleNext={handleNext}
						handleBack={handleBack}
						data={_Empeño}
						detalles={_Detalles}
					/>
				)
			default:
				throw new Error('Unknown step')
		}
	}


	const handlechecked = (event) => {
		if (event.target.checked === true) {
			setOcultarFlujo(true)
			setcheckEnvio(event.target.checked)
		} else {
			setOcultarFlujo(false)
			setcheckEnvio(event.target.checked)
		}
	}

	const handleComprobante = (e) => {
		setComprobante(e.target.files[0])
		setBtn(false)
	}
	const adjuntarComprobante = () => {
		//var dataIdPor = JSON.parse(sessionStorage.getItem('idPorc'))
		props.setOpenLoadingScreen()

		if (_Comprobante !== '' && _Comprobante !== null) {
			const formData = new FormData()
			formData.append('LngIdProducto', id === null ? '' : id)
			formData.append(
				'DblMontoPago',
				_Empeño.dblMontoReferendo === null ? '' : _Empeño.dblMontoReferendo
			)
			formData.append('DblTotalPagar', _Empeño.dblMontoReferendo === null ? '' : _Empeño.dblMontoReferendo)
			//formData.append('DblMontoPago', precioFin === null ? '' : precioFin)
			//formData.append('IntIdPlazoApa', _DataTransfer.IntIdPlazoApa === null ? '' : _DataTransfer.IntIdPlazoApa)

			formData.append(
				'TxtUrlComprobante',
				_Comprobante === null ? '' : _Comprobante
			)
			requests
				.post(TRASFER_EMP_REFRENDAR, formData)
				.then((response) => {
					props.setTypeSnackBar('success')
					props.setMessageSnackBar(response.mensaje)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageSnackBar(error.data.mensaje)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
		} else {
			props.setTypeSnackBar('warning')
			props.setMessageSnackBar('Debes adjuntar el comprobante de pago')
			props.setOpenSnackBar(true)
			props.setCloseLoadingScreen()
		}
	}

	//::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
	return (
		<div className='containerViewUser py4'>
			{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
			<div className='flex-start w100 pb2 pl1'>
				<div className='chip-breadcrumbs'>
					<span className='letter-breadcrumbs'>E</span>
				</div>

				<div role='presentation' className='ml2'>
					<Breadcrumbs aria-label='breadcrumb' separator='›'>
						<Link
							underline='hover'
							className='breadcrumb-url'
							onClick={() => {
								navigate(-3)
							}}
						>
							Empeño
						</Link>
						<Link
							underline='hover'
							className='breadcrumb-url '
							onClick={() => {
								navigate(-2)
							}}
						>
							Mis Empeños
						</Link>
						<Link
							underline='hover'
							className='breadcrumb-url  '
							onClick={() => {
								navigate(-1)
							}}
						>
							Realizar Pago
						</Link>
						<Link underline='hover' className='breadcrumb-url bold'>
							Refrendar Empeño
						</Link>
					</Breadcrumbs>
				</div>
			</div>

			<div className='CardApartadoDetailPagos'>
				<div className='BodyCardApartadoDetail'>
					<div className='ApartadoDetailSection1'>
						<div
							className='BoxImgADetail'
							style={{
								backgroundImage: `url(${_Empeño.txtUrl})`,
							}}
						></div>
					</div>
					<div className='ApartadoDetailSection2'>
						{/* ***************** */}
						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Producto:</span>{' '}
								{_Empeño.txtProductoH === null
									? _Empeño.txtProductoM
									: _Empeño.txtProductoH}{' '}
								{_Empeño.intIdMarca}
							</span>
							<span className='bold upperCase'>
								{/* <span className='subtitlesdetail'>SKU:</span>{' '}
								{_Empeño.txtSkuReg}{' '} */}
							</span>
						</div>
						{/* ***************** */}
						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Marca:</span>{' '}
								{_Empeño.intIdMarca}
							</span>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Color:</span>{' '}
								{_Empeño.intIdColor}
							</span>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Material:</span>{' '}
								{_Empeño.intIdMaterial}
							</span>
						</div>
						{/* ***************** */}

						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>
									Saldo Total:{' '}
								</span>{' '}
								<NumberFormat
									value={_Detalles.dblTotalEmpeño}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
							</span>
						</div>
						{/* <div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>
									Saldo pendiente:{' '}
								</span>{' '}
								<NumberFormat
									value={_Detalles.dblSaldoActual}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
							</span>
						</div> */}
						<hr className='Dvividerhr' />
						<div className='container-Checkbox-ApatadoPagos'>
							<BpCheckbox checked={_checkEnvio} onChange={handlechecked} />
							<span className='bold '>
								¿Deseas realizar el pago mediante una transeferencia bancaria?
							</span>
						</div>
					</div>
				</div>
			</div>

			{_OcultarFlujo === false ? (
				<div className='CardApartadoDetailPagosContainerStepper'>
					<Stepper
						activeStep={activeStep}
						sx={{
							'& .Mui-active': {
								'&.MuiStepIcon-root': {
									fill: '#cbb878',
								},
								'& .MuiStepConnector-line': {
									borderColor: '#cbb878',
								},
							},

							'& .Mui-completed': {
								'&.MuiStepIcon-root': {
									color: '#cbb878',
								},
								'& .MuiStepConnector-line': {
									borderColor: '#cbb878',
								},
							},
						}}
					>
						{steps.map((label, index) => {
							const stepProps = {}
							const labelProps = {}

							if (isStepSkipped(index)) {
								stepProps.completed = false
							}
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							)
						})}
					</Stepper>
					{activeStep === steps.length ? (
						<div className='box-paycodi-stepper'>
							<Typography sx={{ mt: 2, mb: 1 }}>
								All steps completed - you&apos;re finished
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button onClick={handleReset}>Ver historia de pago</Button>
							</Box>
						</div>
					) : (
						<>{getSteps(activeStep, handleNext, handleBack, navigate)}</>
					)}
				</div>
			):(
				<div className='CardApartadoDetailPagosContainerStepper'>
					<div className='CardApartadoDetailPagosCardStepper'>
						<div className='BodyCardApartadoDetail'>
							<div className='ApartadoDetailSection2'>
								<span className='bold text-center'>
									Adjunta el comprobante de la transeferencia realizada, te
									enviaremos un correo con la confirmación del pago.
								</span>
								<hr className='Dvividerhr' />
								{/* ***************** */}
								<div className='containerFlexBetween'>
									<span className='bold upperCase'>
										<span className='subtitlesdetail'>Banco:</span> Kuspit
									</span>
									<span className='bold upperCase'>
										<span className='subtitlesdetail'>Clabe:</span>{' '}
										653180003810159101
									</span>
									<span className='bold upperCase'>
										<span className='subtitlesdetail'>Beneficiario:</span>{' '}
										Articulos de lujo SA de CV
									</span>
									<span className='bold upperCase'>
										<span className='subtitlesdetail'>Monto:</span>{' '}
										<NumberFormat
											value={_Empeño.dblMontoReferendo}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={'$ '}
											suffix={' MXN'}
										/>
										
									</span>
								</div>

								<Box>
									<label htmlFor='txtUrlComprobanteGuia'>
										<Input
											id='txtUrlComprobanteGuia'
											type='file'
											onChange={handleComprobante}
										/>
										<Button
											startIcon={<CloudUploadIcon />}
											variant='contained'
											className='btn-fail mr1'
											component='span'
										>
											Adjuntar comprobante pago
										</Button>
									</label>
									<Typography variant='caption' gutterBottom component='div'>
										{_Comprobante !== null ? _Comprobante.name : 'sin archivo'}
									</Typography>
								</Box>

								<Button
									className='btn-pay'
									onClick={function () {
										adjuntarComprobante()
									}}
									disabled={_Btn}
								>
									Envíar
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default PaymentFlowRef
