/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { Button } from '@mui/material'
import { MB_CONSIGNA_ACT_DES_MODO_VACACIONES } from '../../Constants/ApiConstants'
import requests from '../../Components/AxiosCalls/AxiosCall'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import '../../Styles/App/Alert/alert.css'

const AlertConfirmAction = (props) => {
	const ModoVacaciones = (event) => {
		requests
			.delete(MB_CONSIGNA_ACT_DES_MODO_VACACIONES, props.IdMbConsigna)
			.then((response) => {
				props.handleCloseAlert()
				props.getConsignas()
			})
			.catch((error) => {
				console.log('Error: ' + error)
			})
	}
	return (
		<div>
			<Snackbar
				open={props.openAlert}
				autoHideDuration={6000}
				onClose={props.handleCloseAlert}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert
					className='alert-Warning'
					onClose={props.handleCloseAlert}
					icon={
						<WarningAmberIcon
							fontSize='inherit'
							sx={{ fontSize: 30, color: '#f08080' }}
						/>
					}
				>
					<strong>{props.MensajeTitle}</strong>
					{props.MensajeBody}

					<div className='blue'>
						<Button
							className='btn-success mr1'
							onClick={function () {
								ModoVacaciones()
							}}
						>
							Aceptar
						</Button>
						<Button
							className='btn-fail mr1'
							onClick={function () {
								props.handleCloseAlert()
								props.getConsignas()
							}}
						>
							Cancelar
						</Button>
					</div>
				</Alert>
			</Snackbar>
		</div>
	)
}

export default AlertConfirmAction
