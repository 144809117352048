import MUIDataTable from 'mui-datatables'
import React from 'react'
import Grid from '@mui/material/Grid'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material/styles'
import '../Styles/Styles.css'
import Theme from '../Styles/Theme'
export default function MyDataTable(props) {
	const theme = createTheme({
		components: {
			MUIDataTable: {
				styleOverrides: {
					root: {
						backgroundColor: '#000',
					},
					paper: {
						boxShadow: 'none',
					},
				},
			},
			MuiToolbar: {
				styleOverrides: {
					root: {
						backgroundColor: '#fff',
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						backgroundColor: '#fff',
						padding: '1px !important',
						textAlign: 'center',
					}
				},
			},
			MuiTableRow:{
				styleOverrides:{
					root: { 
						backgroundColor: '#021!important'
					},
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						//backgroundColor: '#000'
						fontWeight: 700,
						textAlign: 'center',
						margin: 'auto!important'
					}
				}
			},		
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'blue',
					},
				},
			},
			MuiTableFooter: {
				styleOverrides: {
					root: {
						'& .MuiToolbar-root': {
							backgroundColor: 'white',
						},
					},
				},
			},
		},
	})
	const options = {
		fixedHeader: true,
		jumpToPage: true,
		tableBodyHeight: window.screen.width === 1366 ? '265px' : '500px',
		//tableBodyHeight: props.data.length < 5 ? "250px" : "250px",
		filterType: 'multiselect',
		selectableRows: 'none',
		responsive: 'standard',
		grouping: 'Módulo',
		download: props.download,
		print: props.export,
		viewColumns: false,
		rowsPerPage: 12,
		textLabels: {
			body: {
				noMatch: 'Ups, no se han encontrado coincidencias.',
				toolTip: 'Ordenar',
				columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
			},
			pagination: {
				next: 'Siguiente',
				previous: 'Anterior',
				rowsPerPage: 'Registros por página:',
				displayRows: 'de',
				jumpToPage: 'Ir a página',
			},
			toolbar: {
				search: 'Buscar',
				downloadCsv: 'Descargar CSV',
				print: 'Imprimir',
				viewColumns: 'Elegir Columnas',
				filterTable: 'Filtrar Tabla',
			},
			filter: {
				all: 'Todo',
				title: 'FILTROS',
				reset: 'LIMPIAR',
			},
			viewColumns: {
				title: 'Mostrar Columnas',
				titleAria: 'Mostrar/Ocultar Columnas',
			},
			selectedRows: {
				text: 'fila(s) seleccionada(s)',
				delete: 'Borrar',
				deleteAria: 'Borrar filas seleccionadas',
			},
		},
	}
	return (
		<Grid item xs={12} elevation={12}>
			<ThemeProvider theme={theme}>
				<MUIDataTable
					title={props.title}
					data={props.data}
					columns={props.columns}
					options={options}
				/>
			</ThemeProvider>
		</Grid>
	)
}