import React, { useEffect, useState } from 'react'
import '../../../Styles/App/HomeMB2.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CUESTIONARIO, CITAS_MB } from '../../../Constants/routesConstants'
import {
  gtmOpenRegisterUNL,
  gtmGoTo_EmpeñaAqui_Log,
  gtmGoTo_MisCitas_Log,
  gtmGoTo_ProcesoAutenticacion_Log,
  gtmGoTo_Shopify_Log,
  gtmGoTo_ShopifyCompra_Log,
} from '../../../Constants/GoogleTagManager/gtmConstants'
import ServicioConsigna from '../../../Images/HomeMB2/ServicioConsigna.png'
import ServicioEmpeño from '../../../Images/HomeMB2/ServicioEmpeño.png'
import ServicioApartado from '../../../Images/HomeMB2/ServicioApartado.png'
import Button from '@mui/material/Button'
import RegisterMb2 from '../../Login/RegisterMb2'
import { Fade } from 'react-awesome-reveal'
const Section2HomeMobile = (props) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const navigate = useNavigate()

  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }
  const goShopifyCompra = () => {
    window.open(
      'https://founditpreloved.com/collections/recien-llegados',
      '_blank'
    )
  }

  return (
    <section className="ContainerSection2MBMobile">
      <span className="title">Nuestros Servicios</span>
      <div className="ContentCards">
        <Fade cascade damping={0.5} triggerOnce>
          <div className="ItemCard">
            {/* <img src={ServicioConsigna} /> */}
            <img
              src={
                'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/zapato+y+bolsa_1.png'
              }
            />
            <div className="sectionBody">
              <div className="description">
                <p>Consigna</p>
                <span>
                  Valoramos, almacenamos y gestionamos la venta de tus preciadas
                  piezas. Si te encuentras fuera de la CDMX, no te preocupes,
                  puedes enviarnos tus artículos desde cualquier rincón de
                  México.
                </span>
              </div>
              <div className="bottomLink">
                <Button
                  onClick={
                    userInfo
                      ? function () {
                          navigate(CUESTIONARIO)
                          gtmGoTo_EmpeñaAqui_Log()
                        }
                      : function () {
                          handleOpenSingUp()
                        }
                  }
                  className="btnLink"
                >
                  Vender
                  <img
                    src={
                      'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png '
                    }
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="ItemCard">
            {/* <img src={ServicioEmpeño} /> */}
            <img
              src={
                'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/empen%CC%83a.png'
              }
            />
            <div className="sectionBody">
              <div className="description">
                <p>Empeña</p>
                <span>
                  Evaluamos tu preciada pieza PreLoved para brindarte la oferta
                  más atractiva. Recibe tu dinero al instante y, con nuestra
                  insuperable tasa de interés, recupera tu artículo en un plazo
                  de 30 a 60 días.
                </span>
              </div>
              <div className="bottomLink">
                <Button
                  onClick={
                    userInfo
                      ? function () {
                          navigate(CUESTIONARIO)
                          gtmGoTo_EmpeñaAqui_Log()
                        }
                      : function () {
                          handleOpenSingUp()
                        }
                  }
                  className="btnLink"
                >
                  Empeñar
                  <img
                    src={
                      'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png '
                    }
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="ItemCard">
            {/* <img src={ServicioApartado} /> */}
            <img
              src={
                'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/aparta.png'
              }
            />

            <div className="sectionBody">
              <div className="description">
                <p>Aparta</p>
                <span>
                  Explora nuestra boutique y elige la pieza que más te enamore.
                  <span className="bold">
                    {' '}
                    Resérvala con solo el 30% de su valor{' '}
                  </span>
                  y completa el pago en cómodas cuotas quincenales durante 4
                  periodos.
                </span>
              </div>
              <div className="bottomLink">
                <Button
                  onClick={function () {
                    gtmGoTo_ShopifyCompra_Log()
                    goShopifyCompra()
                  }}
                  className="btnLink"
                >
                  Tienda virtual
                  <img
                    src={
                      'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png '
                    }
                  />
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </div>
      <RegisterMb2
        open={openSingUp}
        onClose={handleCloseSingUp}
        props={props}
      />
    </section>
  )
  //   <section className="ContainerSection2MBMobile">
  //     <span className="title">Nuestros Servicios</span>
  //     <div className="ContentCards">
  //       <Fade direction="left" triggerOnce >
  //         <div className="ItemCard">
  //           <img src={ServicioConsigna} />

  //           <div className="sectionBody">
  //             <div className="description">
  //               <p>Consigna</p>
  //               <span>
  //                 Valoramos, almacenamos y gestionamos la venta de tus preciadas
  //                 piezas. Si te encuentras fuera de la CDMX, no te preocupes,
  //                 puedes enviarnos tus artículos desde cualquier rincón de
  //                 México
  //               </span>
  //             </div>
  //             <div className="bottomLink">
  //               <Button
  //                 onClick={
  //                   userInfo
  //                     ? function () {
  //                         navigate(CUESTIONARIO);
  //                         gtmGoTo_EmpeñaAqui_Log();
  //                       }
  //                     : function () {
  //                         handleOpenSingUp();
  //                       }
  //                 }
  //                 className="btnLink"
  //               >
  //                 Vender
  //                 <img
  //                   src={
  //                     "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png "
  //                   }
  //                 />
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //       </Fade>
  //       <Fade direction="right" triggerOnce >
  //         <div className="ItemCard">
  //           <img src={ServicioEmpeño} />

  //           <div className="sectionBody">
  //             <div className="description">
  //               <p>Empeña</p>
  //               <span>
  //                 Evaluamos tu preciada pieza PreLoved para brindarte la oferta
  //                 más atractiva. Recibe tu dinero al instante y, con nuestra
  //                 insuperable tasa de interés, recupera tu artículo en un plazo
  //                 de 30 a 60 días.
  //               </span>
  //             </div>
  //             <div className="bottomLink">
  //               <Button
  //                 onClick={
  //                   userInfo
  //                     ? function () {
  //                         navigate(CUESTIONARIO);
  //                         gtmGoTo_EmpeñaAqui_Log();
  //                       }
  //                     : function () {
  //                         handleOpenSingUp();
  //                       }
  //                 }
  //                 className="btnLink"
  //               >
  //                 Empeñar
  //                 <img
  //                   src={
  //                     "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png "
  //                   }
  //                 />
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //       </Fade>
  //       <Fade direction="left" triggerOnce >
  //         <div className="ItemCard">
  //           <img src={ServicioApartado} />

  //           <div className="sectionBody">
  //             <div className="description">
  //               <p>Aparta</p>
  //               <span>
  //                 Explora nuestra boutique y elige la pieza que más te enamore.
  //                 <span className="bold">
  //                   {" "}
  //                   Resérvala con solo el 30% de su valor{" "}
  //                 </span>
  //                 y completa el pago en cómodas cuotas quincenales durante 4
  //                 periodos
  //               </span>
  //             </div>
  //             <div className="bottomLink">
  //               <Button
  //                 onClick={function () {
  //                   gtmGoTo_ShopifyCompra_Log();
  //                   goShopifyCompra();
  //                 }}
  //                 className="btnLink"
  //               >
  //                 Tienda virtual
  //                 <img
  //                   src={
  //                     "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png "
  //                   }
  //                 />
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //       </Fade>
  //     </div>
  //     <RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} />
  //   </section>
  // );
}

export default Section2HomeMobile
