import SignatureCanvas from 'react-signature-canvas'
import React from 'react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import requests from '../../AxiosCalls/AxiosCall'
import {
	Button,
	ThemeProvider,
	TextField,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	DialogActions,
	DialogContent,
	Dialog,
	DialogContentText,
	DialogTitle,
} from '@mui/material'
import Theme from '../../../Styles/Theme'
import trimCanvas from 'trim-canvas'

const ModalFirmaDesempeño = (props) => {
	const handleClose = () => {
		props.onClose()
	}

	const handleClean = () => {
		sing.clear()
	}

	function fixBinary(bin) {
		var length = bin.length
		var buf = new ArrayBuffer(length)
		var arr = new Uint8Array(buf)
		for (var i = 0; i < length; i++) {
			arr[i] = bin.charCodeAt(i)
		}
		return buf
	}

	const [_Contrato, setContrato] = React.useState({
		lngIdDocContrato: '',
		lngIdContrato: '',
		txtUrlDoc: '',
		intIdTipoDocContrato: 0,
		bolFirmado: '',
	})
	
	const getDetalleCon = () =>{
		debugger
		if(props.IdDocContrato !== undefined && props.IdDocContrato !== null ){
			requests
				.get('DocContrato/ObtenerPorId/' + props.IdDocContrato)
				.then((response) => {
					debugger
					setContrato({
						..._Contrato,
						lngIdDocContrato: response === null ? '' : response.lngIdDocContrato,
						lngIdContrato: response === null ? '' : response.lngIdContrato,
						txtUrlDoc: response === null ? '' : response.txtUrlDoc,
						intIdTipoDocContrato:
							response === null ? '' : response.intIdTipoDocContrato,
						bolFirmado: response === null ? '' : response.bolFirmado,
					})
				})
				.catch((error) => {
					debugger
					console.log(error)
				})
		}
	} 

	const [sing, setSing] = useState()

	const handleSave = () => {
		var filese = sing.getCanvas().toDataURL('image/png')

		var png = filese.split(',')[1]

		var binary = fixBinary(window.atob(png)) // <-- Usamos la fn "fixBinary"
		var the_file = new Blob([binary], { type: 'image/png' }) // <-- Sacamos el encode
		var imagen_firma = new File([the_file], 'imagen_firma.png', {
			type: 'image/png',
		})
		debugger
		const formDatas = new FormData()   
        formDatas.append('lngIdContrato', _Contrato.lngIdContrato)
        formDatas.append('LngIdDocContrato', _Contrato.lngIdDocContrato)
        formDatas.append('txtUrlDoc', imagen_firma)
        formDatas.append('BolDesempeno', true)
		
		props.setOpenLoadingScreen()
		requests
			.put('Consigna/EmpeArticulosDeLujoFirma', formDatas)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
				window.location.reload(true)
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.data.message)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
		props.onClose()
	}

	useEffect(() => {
		debugger
		getDetalleCon()
	}, [props.IdDocContrato])

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'lg'}
			>
				<SignatureCanvas
					ref={(data) => setSing(data)}
					penColor='#0000cc'
					// canvasProps={{ width: 700, height: 300, className: 'sigCanvas' }}
					canvasProps={{ width: 400, height: 300, className: 'sigCanvas' }}
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '10px',
					}}
				>
					<Button
						onClick={handleClose}
						variant='contained'
						className='btn-table-Contratos-Download'
					>
						{' '}
						Cerrar
					</Button>
					<Button
						onClick={handleClean}
						variant='contained'
						className='DialogEvaluationBtnAcep'
					>
						{' '}
						Limipiar
					</Button>
					<Button
						onClick={handleSave}
						variant='contained'
						className='btn-table-Contratos'
					>
						{' '}
						Guardar
					</Button>
				</div>
			</Dialog>
		</ThemeProvider>
	)
}

export default ModalFirmaDesempeño
