import React from 'react'
import Grid from '@mui/material/Grid'

import Footer from '../Components/Footer'
import FooterMobile from '../Components/FooterMobile'

const FooterMundoBarter = ({ children }) => {
	return (
		<Grid
			container
			sx={{ mt: 6, pt: 5 }}
			direction='row'
			justifyContent='center'
			alignItems='flex-start'
		>
			<Grid item sm={12} xs={12} md={12} lg={12} sx={{ minHeight: '50%' }}>
				{children}
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				direction='row'
				justifyContent='center'
				alignItems='center'
			>
				{window.screen.width <= 600 ? <FooterMobile /> : <Footer />}
			</Grid>
		</Grid>
	)
}

export default FooterMundoBarter
