import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../../Styles/Theme';
import {Chip, Card, CardContent, CardMedia, IconButton, Typography, Box } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import '../../../Styles/GeneralStyles.css';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {CLIENTES_CONSIGNAS} from '../../../Constants/routesConstants'

const ConsignaGuiaExpira = () => {
    const navigate = useNavigate();

    return (
        <>
            <ThemeProvider theme={Theme}>
                <Grid>
                    <Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
                        <Grid Grid item xs={4} sm={4} md={4} lg={12} className='d-flex align-center mb-30'>
                        <Button
                                className='back-gradient-4 bord-radius-10 shadow font-letter font-light'
                                startIcon={<ArrowBackIcon />}
                                onClick={function () {
                                    navigate(CLIENTES_CONSIGNAS)
                                }}
                            >
                                Regresar
                            </Button>
                            <Chip icon={<MonetizationOnIcon />} label='Consigna / Guía expirada' color='primary' className='back-transparent font-letter font-barter font-20' />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center' spacing={2} elevation={12}>
                        <Grid item xs={12}>
                            <Card sx={{ display: 'flex', height: 110 }}>                               
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5">
                                            N° de guía 78547844
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            1 pieza
                                        </Typography>                                       
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Expiró: 06/11/2022 15:05:35
                                        </Typography>
                                    </CardContent>

                                </Box>                                                                
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card sx={{ display: 'flex', height: 110 }}>
                                
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Juan Perales
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Av. Bonampack 125 Depto. 303 Torre E, Cancún, Q. Roo, Méxito
                                        </Typography>                                     
                                    </CardContent>
                                </Box>                               
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                           
                        </Grid>
                    </Grid>

                </Grid>
            </ThemeProvider>
        </>
    )
}

export default ConsignaGuiaExpira