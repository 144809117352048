/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react'

import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import logo from '../../../Images/logo-barter-sin-fondo.png'

import NumberFormat from 'react-number-format'
import Slider from 'react-slick'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSwiper } from 'swiper/react'
import { gtm_CloseTerminos_Log } from '../../../Constants/GoogleTagManager/gtmConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CpropuestaTermsConditions = (props) => {
  const swiperbtn = useSwiper()
  const handleClose = () => {
    gtm_CloseTerminos_Log()
    props.onCloseModalImgPreview()
  }
  // const settings = {
  // 	dots: true,
  // 	infinite: true,
  // 	speed: 500,
  // 	slidesToShow: 1,
  // 	slidesToScroll: 1,
  // 	autoplay: false,
  // 	autoplaySpeed: 4000,
  // }

  /*----------------------------------- */
  //creating the ref
  const customeSlider = useRef()

  // setting slider configurations
  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    onSwipe: false,
    // swipe: false,
  }

  const previous = () => {
    // customeSlider.current.slickNext()
    swiperbtn.slideNext()
  }

  const next = () => {
    // customeSlider.current.slickPrev()
    swiperbtn.slidePrev()
  }
  return (
    <Dialog
      fullScreen
      open={props.openModalImgPreview}
      onClose={
        props.onCloseModalImgPreview ? props.onCloseModalImgPreview : null
      }
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          background:
            'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)',
        },
      }}
    >
      <div className="box-termConditions-MB">
        <div className="section-termConditions-MB-1">
          <div>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log('slide change')}
            >
              <SwiperSlide>
                <div className="box-term-MB-1">
                  <DialogTitle
                    sx={{ color: '#583101' }}
                    id="alert-dialog-title"
                  >
                    <img src={logo} className="imgTermConditionsConsigna" />
                  </DialogTitle>
                  {props.service === 'Vende CDMX' ? (
                    <>
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify"
                        >
                          En FoundIt nuestros clientes son lo mas importante,
                          agradecemos tu preferencia, a continuación encontrarás
                          la cotización por tu articulo Pre-loved con los
                          detalles mas importantes para el servicio de
                          CONSIGNACIÓN.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-center mt-10 upperCase font-20 f-weight-bold"
                        >
                          {props.nameProduct.articulo} {props.nameProduct.marca}
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10 f-weight-bold font-20"
                        >
                          <ul style={{ listStyle: 'none', padding: 0 }}>
                            <li>
                              - Precio de venta en consigna:{' '}
                              <NumberFormat
                                value={props.nameProduct.precioVenta}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={'$ '}
                                suffix={' MXN'}
                              />
                              {/* value={(_Producto.dblPrecioFinal * 30) / 100} */}
                            </li>
                            <li>
                              - Comisión FoundIt: {props.nameProduct.descuento}
                              %:{' '}
                              <NumberFormat
                                value={
                                  (props.nameProduct.precioVenta *
                                    props.nameProduct.descuento) /
                                  100
                                }
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={'$ '}
                                suffix={' MXN'}
                              />
                            </li>
                            <li>
                              - Cobrarías{' '}
                              <NumberFormat
                                value={
                                  props.nameProduct.precioVenta -
                                  (props.nameProduct.precioVenta *
                                    props.nameProduct.descuento) /
                                    100
                                }
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={'$ '}
                                suffix={' MXN'}
                              />
                            </li>
                            <li>
                              - Servicio de recolección a domicilio{' '}
                              <NumberFormat
                                value={200}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={'$ '}
                                suffix={' MXN'}
                              />
                            </li>
                          </ul>
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify"
                        >
                          &#8226; Si deseas utilizar el servicio de recolección
                          a domicilio, consulta fechas y horarios con nuestro
                          servicio de atención al cliente en el teléfono y
                          WhatsApp 56-4181-9020.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          &#8226; Recuerda que el presente es un Pre-avalúo y
                          que FoundIt podrá declinar la oferta en caso de que el
                          artículo Pre loved no sea original, no coincida con la
                          información enviada o presente condiciones distintas a
                          las declaradas en tu cuestionario, si este fuera el
                          caso dicho artículo será devuelto, teniendo un costo
                          de $1,500 pesos derivado de las pruebas realizadas al
                          mismo.
                        </DialogContentText>
                        {props.nameProduct.marca === 'HERMES' ? (
                          <DialogContentText
                            id="alert-dialog-description"
                            className="text-justify mt-10"
                          >
                            &#8226; La certificación de tu artículo tiene un
                            costo de $ 2,500 + IVA.
                          </DialogContentText>
                        ) : (
                          <DialogContentText
                            id="alert-dialog-description"
                            className="text-justify mt-10"
                          >
                            &#8226; La certificación de tu artículo tiene un
                            costo de $500 + IVA.
                          </DialogContentText>
                        )}
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          &#8226; Si estas de acuerdo con la cotización y
                          condiciones te pedimos hagas una cita desde tu perfil
                          en FoundIt Members para verificar tu artículo en
                          nuestras oficinas, recuerda que debes presentarlo
                          limpio y en las mismas condiciones que se encuentra en
                          las fotografías que mandaste y debes presentarte con
                          identificación oficial y datos de cuenta bancaria
                          donde será depositado tu pago menos comisión una vez
                          que tu artículo sea vendido.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify"
                        >
                          1 - Una vez recibido y revisado tu artículo se te
                          entregará física o digitalmente un contrato con
                          términos y condiciones.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          2 - Se tomaran las fotografías mas adecuadas para que
                          tu articulo sea atractivo a la vista de los clientes.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          3 - Se publicará y se le dará el marketing necesario
                          para que se venda lo mas pronto posible.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10 co-red-open-table"
                        >
                          4 - La comisión a cobrar por FoundIt será del{' '}
                          {props.nameProduct.descuento}% sobre{' '}
                          {/* 4 - La comisión a cobrar por Barterluxe será del 25% sobre */}
                          el precio final de venta (consigna).
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          5 - El periodo máximo de consignación será de 3 meses,
                          durante este tiempo tu artículo será resguardado en
                          nuestro almacén bajo las mas estrictas normas de
                          seguridad.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          6 - En caso de querer retirar tu artículo antes de los
                          3 meses pactados se te cobrará una comisión por
                          almacenaje y trabajo de marketing.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          7 – Durante ese periodo si así lo deseas puedes
                          aplicar descuentos que van desde el 5, 10, 15 o hasta
                          20% desde tu perfil de FoundIt Members, toma en cuenta
                          que en cualquiera de los casos la comisión de FoundIt
                          será del {props.nameProduct.descuento} %.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10 co-red-open-table"
                        >
                          8 – Al termino del plazo y en caso de que no se venda
                          tu artículo podrás agendar una cita para recogerlo en
                          nuestras instalaciones.
                        </DialogContentText>
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          9 – Cuando tu artículo se haya vendido el pago menos
                          comisión te será depositado en un lapso de entre 6 y 8
                          días hábiles.
                        </DialogContentText>
                      </DialogContent>
                    </>
                  ) : (
                    <DialogContent>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify"
                      >
                        En FoundIt nuestros clientes son lo mas importante,
                        agradecemos tu preferencia, a continuación encontrarás
                        la cotización por tu articulo Pre-loved con los detalles
                        mas importantes para el servicio de CONSIGNACIÓN.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-center mt-10 upperCase font-20 f-weight-bold"
                      >
                        {props.nameProduct.articulo} {props.nameProduct.marca}
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10 f-weight-bold font-20"
                      >
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                          <li>
                            - Precio de venta en consigna:{' '}
                            <NumberFormat
                              value={props.nameProduct.precioVenta}
                              displayType={'text'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              thousandSeparator={true}
                              prefix={'$ '}
                              suffix={' MXN'}
                            />
                            {/* value={(_Producto.dblPrecioFinal * 30) / 100} */}
                          </li>
                          <li>
                            - Comisión FoundIt: {props.nameProduct.descuento}%
                          </li>
                          <li>
                            - Cobrarías{' '}
                            <NumberFormat
                              value={
                                props.nameProduct.precioVenta -
                                (props.nameProduct.precioVenta *
                                  props.nameProduct.descuento) /
                                  100
                              }
                              displayType={'text'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              thousandSeparator={true}
                              prefix={'$ '}
                              suffix={' MXN'}
                            />
                          </li>
                        </ul>
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10"
                      >
                        &#8226; Recuerda que el presente es un Pre-avalúo y que
                        FoundIt podrá declinar la oferta en caso de que el
                        artículo Pre loved no sea original, no coincida con la
                        información enviada o presente condiciones distintas a
                        las declaradas en tu cuestionario, si este fuera el caso
                        dicho artículo será devuelto, teniendo un costo de
                        $1,500 pesos derivado de las pruebas realizadas al
                        mismo.
                      </DialogContentText>
                      {props.nameProduct.marca === 'HERMES' ? (
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          &#8226; La certificación de tu artículo tiene un costo
                          de $ 2,500 + IVA.
                        </DialogContentText>
                      ) : (
                        <DialogContentText
                          id="alert-dialog-description"
                          className="text-justify mt-10"
                        >
                          &#8226; La certificación de tu artículo tiene un costo
                          de $500 + IVA.
                        </DialogContentText>
                      )}
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10"
                      >
                        &#8226; Si estas de acuerdo con la cotización y
                        condiciones te pedimos hagas una cita desde tu perfil en
                        FoundIt Members para verificar tu artículo en nuestras
                        oficinas, recuerda que debes presentarlo limpio y en las
                        mismas condiciones que se encuentra en las fotografías
                        que mandaste y debes presentarte con identificación
                        oficial y datos de cuenta bancaria donde será depositado
                        tu pago menos comisión una vez que tu artículo sea
                        vendido.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify"
                      >
                        1 - Una vez recibido y revisado tu artículo se te
                        entregará física o digitalmente un contrato con términos
                        y condiciones.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10"
                      >
                        2 - Se tomaran las fotografías mas adecuadas para que tu
                        articulo sea atractivo a la vista de los clientes.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10"
                      >
                        3 - Se publicará y se le dará el marketing necesario
                        para que se venda lo mas pronto posible.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10 co-red-open-table"
                      >
                        4 - La comisión a cobrar por FoundIt será del{' '}
                        {props.nameProduct.descuento}% sobre{' '}
                        {/* 4 - La comisión a cobrar por Barterluxe será del 25% sobre */}
                        el precio final de venta (consigna).
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10"
                      >
                        5 - El periodo máximo de consignación será de 3 meses,
                        durante este tiempo tu artículo será resguardado en
                        nuestro almacén bajo las mas estrictas normas de
                        seguridad.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10"
                      >
                        6 - En caso de querer retirar tu artículo antes de los 3
                        meses pactados se te cobrará una comisión por almacenaje
                        y trabajo de marketing.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10"
                      >
                        7 – Durante ese periodo si así lo deseas puedes aplicar
                        descuentos que van desde el 5, 10, 15 o hasta 20% desde
                        tu perfil de FoundIt Members, toma en cuenta que en
                        cualquiera de los casos la comisión de FoundIt será del{' '}
                        {props.nameProduct.descuento} %.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10 co-red-open-table"
                      >
                        8 – Al termino del plazo y en caso de que no se venda tu
                        artículo podrás agendar una cita para recogerlo en
                        nuestras instalaciones.
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        className="text-justify mt-10"
                      >
                        9 – Cuando tu artículo se haya vendido el pago menos
                        comisión te será depositado en un lapso de entre 6 y 8
                        días hábiles.
                      </DialogContentText>
                    </DialogContent>
                  )}
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>
                <div className="box-term-MB-1">
                  <DialogTitle
                    sx={{ color: '#583101' }}
                    id="alert-dialog-title"
                  >
                    {/* {'Terminos y Condiciones'} */}
              {/* <img src={logo} className="imgTermConditionsConsigna" />
                  </DialogTitle>

                  <DialogContent>
                   
                  </DialogContent>
                </div>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
        {/* <div className="section-termConditions-MB-2">
          <IconButton
            variant="outlined"
            sx={{
              color: '#583101',
              borderColor: '#583101',
              ':hover': { borderColor: '#583101' },
              margin: 'auto',
            }}
            onClick={next}
            autoFocus
          >
            <ChevronLeftIcon />
          </IconButton>

          <Button
            variant="outlined"
            sx={{
              color: '#583101',
              borderColor: '#583101',
              ':hover': { borderColor: '#583101' },
              margin: 'auto',
            }}
            onClick={handleClose}
            autoFocus
          >
            Aceptar
          </Button>

          <IconButton
            variant="outlined"
            sx={{
              color: '#583101',
              borderColor: '#583101',
              ':hover': { borderColor: '#583101' },
              margin: 'auto',
            }}
            onClick={previous}
            autoFocus
          >
            <ChevronRightIcon />
          </IconButton>
        </div> */}
      </div>
    </Dialog>
  )
}

export default CpropuestaTermsConditions
