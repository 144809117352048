import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/faqsMB.css'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordion from '@mui/material/Accordion'

import { styled } from '@mui/material/styles'

const Accordionn = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    border: '2px solid #E5E7E0',
    borderRadius: 4,
  },
  '&::before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    backgroundColor: '#757D65',
  },
}))

const AccordionSummaryy = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    color: '#fff',
  },
  '& .MuiTypography-root': {
    // color: "#000",
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
}))

const FqSectionCompras = (props) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <section className="FaqsSectionVentas">
      <div className="contentFaqs">
        <span className="titlefqs">Compras</span>
        <Accordionn
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>Realicé una compra, ¿y mi pedido?</Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Después de realizar tu compra y que tu pago haya sido
                verificado, nos pondremos en contacto contigo mediante correo
                electrónico y se te enviará una guía con un número único de
                rastreo. La paquetería que utilizamos es DHL y usualmente tarda
                de 1 a 3 días en llegar.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Todos los artículos son 100% originales?</Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Sí, todos nuestros artículos antes de ser recibidos y aceptados
                por Foundit son revisados cuidadosamente bajo un estricto
                proceso de verificación llevado a cabo por nuestros expertos.{' '}
                <br />
                Así que no te preocupes, puedes tener la confianza de que todos
                nuestros artículos son 100% originales.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Cuáles son los motivos por los que puedo devolver un artículo?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Sólo se podrá realizar una devolución dentro de las 24 a 48
                horas siguientes a la recepción del artículo y bajo las
                siguientes circunstancias:
                <ol>
                  <li>Cuando el artículo esté roto o dañado.</li>
                  <li>
                    Si el artículo tiene un defecto no mencionado o no visible
                    en la descripción, fotos o videos mostrados en nuestro sitio
                    web.
                  </li>
                </ol>
              </p>
              <p>
                La devolución será dando como opción la posibilidad de
                intercambio o bonificación con otro artículo y/o mercancía y
                sólo aplica para bolsos.
              </p>
              <p>
                El proceso deberá realizarse a través de DHL o paquetería
                correspondiente; el cliente siempre tendrá la opción de
                contratar el seguro de envío que pone a su disposición DHL o
                paquetería, el costo de devolución corre a cargo del cliente.
              </p>
              <p>
                **Recuerda que en cualquiera de estos casos el artículo debe
                conservar puestos los cinchos o etiquetas con los que fue
                embalado. **
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Cómo puedo devolver un artículo?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                El usuario deberá enviar un correo a{' '}
                <a>contacto@founditpreloved.com</a> notificando la razón de la
                posible devolución, en caso de ser aceptada el comprador debe:
                <ul>
                  <li>
                    Notificar a FOUNDIT dentro de las primeras 48 horas a partir
                    de la entrega del artículo por parte de la paquetería en el
                    domicilio proporcionado.
                  </li>
                  <li>
                    El equipo FOUNDIT enviará un correo electrónico con las
                    instrucciones para la devolución del artículo.
                  </li>
                  <li>
                    Contará el Usuario con 48 horas para enviar el artículo
                    desde que se le envíe el mail con las instrucciones.
                  </li>
                  <li>
                    Una vez que FOUNDIT reciba el artículo analizará: el estado
                    del artículo, si cuenta con las etiquetas necesarias y si
                    está en las mismas condiciones en las que se vendió.
                  </li>
                  <li>
                    Si el artículo cumple con los lineamientos de devolución, el
                    equipo FOUNDIT generará el reembolso al método de pago
                    utilizado para la compra.
                  </li>
                  <li>
                    El artículo debe de estar en el mismo estado en que fue
                    enviado, sin la posibilidad de que haya sido alterado. El
                    artículo debe de llevar todas las etiquetas con las que se
                    entregó adheridas, así como sus complementos (caja de la
                    marca, bolsa o protector, certificado, etc.) y accesorios.
                  </li>
                  <li>
                    En caso que el Usuario no cumpla con la totalidad del
                    proceso señalado, la devolución será improcedente y el
                    producto será enviado a donación.
                  </li>
                  <li>
                    En caso de recibir el paquete a domicilio o en sucursal de
                    paquetería incompleto, se pide enviar fotografías y video de
                    cómo fue recibido el paquete en donde se vea el empaque y
                    los artículos que incluía al correo
                    <a>contacto@founditpreloved.com</a> para evidenciar el caso
                    con la paquetería y poder proceder con la investigación.
                  </li>
                  <li>
                    Si el Usuario notifica a FOUNDIT el deseo de su devolución
                    después del periodo mencionado, FOUNDIT no podrá aceptar la
                    devolución.
                  </li>
                  <li>
                    FOUNDIT se reserva el derecho de no aceptar devoluciones de
                    artículos:
                  </li>
                </ul>
              </p>
              <p>
                Con etiquetas desprendidas: antes de usar tus artículos
                asegúrate de haberlas probado ya que después de quitar sus
                etiquetas y sellos no podremos aceptarlas.
              </p>
              <p>
                Artículos rotos, manchados y en diferentes condiciones de cómo
                se enviaron.
                <ul>
                  <li>
                    Sólo se aceptará la devolución si el artículo se encuentra
                    en las mismas condiciones en las que en las que se
                    compraron. De no cumplir dichas condiciones será enviado a
                    donación.
                  </li>
                </ul>
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿De qué manera puedo hacer valida mi garantía como comprador?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Es importante que, al recibir tu paquete, verifiques que cuenta
                con todas las medidas de seguridad con las que Foundit embala
                cada artículo, estás son: cincho de seguridad, el cual deben
                coincidir con nuestras bases de datos, así como empaques y cajas
                debidamente selladas. <br />
                Si recibes tu paquete y no coincide con alguna de las medidas de
                seguridad mencionadas deberás enviar un correo con evidencia
                fotográfica en donde se muestre específicamente cuál fue la o
                las medidas violadas.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Dónde están ubicados?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Nos encontramos en Río Nazas # 34 piso 2, Col. Cuauhtémoc CP
                06500 Ciudad de México.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Cuáles son las formas de pago que manejan?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Para la tienda en línea se ofrecen las siguientes opciones de
                pago: <br />
                1. Tarjetas de crédito. <br />
                2. Tarjetas de débito de principales bancos nacionales selectos.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <span></span>
      </div>
    </section>
  )
}

export default FqSectionCompras
