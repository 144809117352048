import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import {
  Card,
  CardActions,
  CardContent,
  Button,
  CardMedia,
  Alert,
  Zoom,
  Fab,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { CLIENTES_EMP_DESEMPEÑO } from '../../../Constants/routesConstants'
import { EMPENO_LIST_BY_ID_CLIENTE } from '../../../Constants/ApiConstants'
import InfoIcon from '@mui/icons-material/Info'
import HelpIcon from '@mui/icons-material/Help'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import '../../../Styles/App/Apartado/Apartado.css'
import requests from '../../../Components/AxiosCalls/AxiosCall'

import EmpeñoHelp from './EmpeñoHelp'
import iconEmpeno from '../../../Images/MB-Empena/Empeno_Icon.png'
import Avatar from '@mui/material/Avatar'
import { Chip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PreviewImg from '../../../Components/PreviewImg'
import { gtmGoTo_Liquidar_Emp_Log } from '../../../Constants/GoogleTagManager/gtmConstants'
import { TablePagination } from '@mui/material'
import * as locales from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'

const EmpeñoDesempeño = (props) => {
  const [_Empeños, setEmpeños] = useState([])
  const navigate = useNavigate()
  const theme = useTheme()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)

  const getEmpeños = () => {
    props.setOpenLoadingScreen()
    requests
      .get(EMPENO_LIST_BY_ID_CLIENTE, userInfo.intIdUsuario)
      .then((response) => {
        setEmpeños(response)
        if (response.length > 0) {
          setOcultar(false)
          setOcultar2(true)
        } else {
          setOcultar(true)
          setOcultar2(false)
        }
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log('Error: ' + error)
        props.setCloseLoadingScreen()
      })
  }
  useEffect(() => {
    getEmpeños()
  }, [])

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  const [IdImgPreview, setIdImgPreview] = useState()
  const [openModalImgPreview, setModalImgPreview] = useState(false)
  const handleOpenImgPreview = () => {
    setModalImgPreview(!openModalImgPreview)
  }
  /*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION INICIO	::::::::::::::::::::::::::::::::::::::::::::::: */
  const [locale, setLocale] = React.useState('esES')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  )
  /*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION FIN	::::::::::::::::::::::::::::::::::::::::::::::: */
  return (
    <div>
      <div className="containerViewUser py4 paddingTablets" hidden={_Ocultar}>
        {/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
        <div className="flex-start w90 pb2 pl1">
          <div className="chip-breadcrumbs">
            <span className="letter-breadcrumbs">E</span>
          </div>

          <div role="presentation" className="ml2">
            <Breadcrumbs aria-label="breadcrumb" separator="›">
              <Link
                underline="hover"
                className="breadcrumb-url"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Empeño
              </Link>
              <Link underline="hover" className="breadcrumb-url bold">
                Mis Desempeños
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        {_Empeños.length === 0 ? null : (
          <div className="box-consigna-pro">
            {_Empeños
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <div key={index} className="box-list-consigna-pro">
                  <div className="w20 relative">
                    <img className="img-consigna-pro" src={row.txtUrl} />
                    <div className="box-view-absolute">
                      <IconButton
                        className="btn-blur"
                        onClick={function () {
                          setIdImgPreview(row.txtUrl)
                          handleOpenImgPreview()
                        }}
                      >
                        <RemoveRedEyeIcon
                          className="RemoveEyeIconConsigna"
                          sx={{ m: 0, p: 0, color: '#000000' }}
                        />
                      </IconButton>
                    </div>
                  </div>

                  <div className="flex-column-start-between w80 pl1">
                    <div className="flex-column-start w100">
                      <div className="flex-between">
                        <span className="titleConsignaPropuestas upperCase ProductTitleMarca">
                          {row.intIdProductos} {row.intIdMarca}
                        </span>

                        <span className="p textFechaConsignaPropuestas co-grey">
                          {row.fecIngreso}
                        </span>
                      </div>
                      <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                        <strong>Color: &nbsp;</strong>
                        {row.intIdColor}
                      </span>
                      <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                        <strong>Material: &nbsp;</strong>
                        {row.intIdMaterial}
                      </span>
                      <span className="p textFechaConsignaPropuestas ProductTitleDescription">
                        <strong>SKU: &nbsp;</strong>
                        {row.txtSkuReg}
                      </span>
                    </div>
                    {/* <div className="flex-between w100">
                      <Button
                        className="btn-success mr1 ProductTitleDescription"
                        onClick={function () {
                          gtmGoTo_Liquidar_Emp_Log()
                          navigate(CLIENTES_EMP_DESEMPEÑO + row.lngIdProducto)
                        }}
                      >
                        Realizar pago
                      </Button>
                    </div> */}
                  </div>
                </div>
              ))}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xl={12} className="flex">
                <ThemeProvider theme={themeWithLocale}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={_Empeños.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div hidden={_Ocultar2}>
        <div className="containerViewUser">
          <Alert
            className="alert-no-data"
            icon={
              <InfoIcon fontSize="inherit" className="alert-icon-no-data" />
            }
          >
            Aún no cuentas con artículos empeñados.
          </Alert>
        </div>
      </div>
      <EmpeñoHelp
        open={open}
        onClose={handleOpen}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
      />
      <PreviewImg
        IdImgPreview={IdImgPreview}
        openModalImgPreview={openModalImgPreview}
        onCloseModalImgPreview={handleOpenImgPreview}
      />
    </div>
  )
}

export default EmpeñoDesempeño
