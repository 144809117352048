/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Button, Chip, Grid, Link, ThemeProvider, Typography } from "@mui/material";
import Theme from '../../Styles/Theme'
import trabajando from '../../Images/trabajando.svg'

const MantenimientoView = (props) => {
    return (
        <ThemeProvider theme={Theme}>
            <Grid container>
                <Grid item xs={12} elevation={12} sx={{ mt: 6 }}>
                    <div className='flex-column center'>
                        <img src={trabajando} width="600px" />
                        <Typography variant="h4" gutterBottom component="div" className='font-letter'>
                            Disculpa las molestias, estamos trabajando en esto.
                        </Typography>
                        <Typography variant="h4" gutterBottom component="div" className='font-letter'>
                            We work on this.
                        </Typography>
                    </div>

                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default MantenimientoView