import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/apartadoMB.css'
import Button from '@mui/material/Button'
import { Fade } from 'react-awesome-reveal'

const ASection3Web = (props) => {
  return (
    // <section className="ApartadoSection3MB">

    // </section>
    <section className="ApartadoSection3MB">
      <div className="cardESection3">
        <img
          className="Logo"
          src={
            'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png'
          }
        />
        <span className="text">
          Confía en nuestro proceso y asegura que tus artículos Preloved reciban
          la atención y el valor que merecen
        </span>
        <div className="bottomLink">
          <Button
            onClick={function () {
              window.open(
                'https://founditpreloved.com/collections/recien-llegados',
                '_blank'
              )
            }}
            className="btnLink"
          >
            Apartar
            <img
              src={
                'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/VectorArrowWhite.png '
              }
            />
          </Button>
        </div>
      </div>
    </section>
  )
}

export default ASection3Web
