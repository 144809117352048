//HOME
export const INICIO = '/'

//PRODUCTOS
export const PRODUCTOS_VIEW = '/productos'
export const PRODUCTOS_VENDIDOS_VIEW = '/Ventas'
export const PRODUCTOS_ADD = '/productos/Crear'
export const PRODUCTOS_EDIT = '/productos/Editar/'
export const PRODUCTOS_DETAILS_VIEW = '/productos/Detalles/'
export const PRODUCTOS_DETAILS_VIEW_VENTAS = '/productos/Detalles-ventas/'

//CITAS PREEVALIACIONES
export const CITAS_ERP_VIEW = '/Citas'
export const CITAS_ERP_VIEW_DETAILS = '/Citas-detalles/'

//Clientes
export const CLIENTES_SING_UP = '/sing-up'
export const CLIENTES_PROFILE = '/perfil-mb'
export const CLIENTES_CONFIRMA_MAIL = '/confirmation'

//APARTADO MUNDO BARTER
export const CLIENTES_SECTION = '/apartado/'
export const CLIENTES_SECTION2 = '/apartado'
export const CLIENTES_SECTION_VIEW = '/apartado-mb-view'
export const CLIENTES_SECTION_PRO = '/apartado-mb-proceso'
export const CLIENTES_SECTION_PRO_PAY = '/apartado-mb-proceso-pay/'
export const CLIENTES_SECTION_ENT = '/apartado-mb-entrega'
export const CLIENTES_SECTION_GUI = '/apartado-mb-devuelto'
export const CLIENTES_SECTION_TRA = '/apartado-mb-transito'
export const CLIENTES_SECTION_WISH = '/apartado-mb-deseo'
export const CLIENTES_SECTION_PRO_VIEW = '/apartado-mb-proceso/view'
export const CLIENTES_SECTION_FIRST_PRO_VIEW = '/apartado-mb-primer-proceso/view'

//CONSIGNA MUNDO BARTER
export const CLIENTES_CONSIGNAS_VIEW = '/consigna-mb-view'
export const CLIENTES_CONSIGNAS = '/consigna-mb/'
export const CLIENTES_CONSIGNAS_GUI = '/consigna-mb-guia'
export const CLIENTES_CONSIGNAS_TRA = '/consigna-mb-transito'
export const CLIENTES_CONSIGNAS_REC = '/consigna-mb-recibido'
export const CLIENTES_CONSIGNAS_GUIEX = '/consigna-mb-guia-ex'
export const CLIENTES_CONSIGNAS_VENTAS = '/consigna-mb-venta'
export const CLIENTES_CONSIGNAS_EN_VENTA = '/consigna-mb-en-venta'
export const CLIENTES_CONSIGNAS_RECHAZO = '/consigna-mb-rechazadas'
export const CLIENTES_CONSIGNAS_LLEGADOS = '/consigna-mb-llegados'
export const CLIENTES_CONSIGNAS_PIEZAS = '/consigna-mb-piezas'
export const CLIENTES_CON_PROPUESTAS_VIEW = '/consigna-mb-propuestas'

//EMPEÑO MUNDO BARTER
export const CLIENTES_EMP_VIEW = '/empeno-mb-view'
export const CLIENTES_EMP = '/empeno-mb/'
export const CLIENTES_EMP_REFRENDO = '/empeno-mb-refrendar/'
export const CLIENTES_EMP_DESEMPEÑO = '/empeno-mb-desempeño/'
export const CLIENTES_EMP_PRO_VIEW = '/empeno_mb_productos'
export const CLIENTES_EMP_PROPUESTAS_VIEW = '/empeno_mb_propuestas'
export const CLIENTES_EMP_REFRENDO_VIEW = '/empeno_mb_refrendo'
export const CLIENTES_EMP_DESEMPEÑO_VIEW = '/empeno_mb_desempeño'
export const CLIENTES_EMP_REALIZADOS = '/empeno-mb-realizados'
export const CLIENTES_EMP_LIQUIDADOS = '/empeno-mb-liquidados'
export const CLIENTES_EMP_DETALLE_EMPENO = '/empeno-mb-detalle'

//INTERCAMBIO MUNDO BARTER
export const CLIENTES_INTERCAMBIO_VIEW = '/intercambio-mb-view'
export const CLIENTES_INTERCAMBIO_PROPUESTAS_VIEW = '/intercambio-mb-propuesta'
export const CLIENTES_INTERCAMBIO_PROD_VIEW = '/intercambio-mb-productos'

//RENTAS MUNDO BARTER
export const CLIENTES_RENT = '/rentar/'
export const CLIENTES_RENT2 = '/rentar'
export const CLIENTES_RENT_VIEW = '/renta-mb-view'
export const CLIENTES_RENT_PROD_VIEW = '/renta-mb-productos'
export const CLIENTES_RENT_WISH_LIST_PROD_VIEW = '/renta-mb-lista-deseo'
export const CLIENTES_RENT_FIRST_PROD_VIEW = '/renta-mb-primer-proceso/view'

//PAGOS EXTRAORDINARIOS MUNDO BARTER
export const PAGOS_EXTRAUDINARIOS = '/pagos-extraudinarios-mb'

//ENVIOS MUNDO BARTER
export const ENVIO_VIEW = '/envios-view-mb'
export const ENVIO_EVALUACION = '/envios-evaluacion-mb'
export const ENVIO_DETAIL = '/envios-detalle-mb'
export const ENVIOS_EN_TRANSITO = '/envios-entransito'
export const ENVIOS_RECIBIDOS = '/envios-recibidos'

//CITAS MB
export const CITAS_MB = '/citas-mb'
export const CITAS_MB_EMPEÑO = '/citas-mb/empeno'
export const CITAS_MB_DESEMPEÑO = '/citas-mb/desempeno'
export const CITAS_MB_CON_VENCIDAS = '/citas-mb/consigna-vencidas'
export const CITAS_MB_CONSIGNA = '/citas-mb/consigna'
export const CITAS_MB_CONSIGNA_FORANEA = '/citas-mb/consigna-foranea'
export const CITAS_MB_EMPENO_FORANEO = '/citas-mb/empeno-foraneo'
export const CITAS_MB_INTERCAMBIO = '/citas-mb/intercambio'
export const CITAS_MB_RENTA = '/citas-mb/renta'

//PAGOS
export const FLUJO_PAGO = '/pago-servicios/'
export const FLUJO_REFRENDO = '/pago-refrendo/'
export const FLUJO_LIQUIDAR = '/pago-liquidar/'
export const FLUJO_APARTAR = '/pago-apartar/'
export const FLUJO_RENTAR = '/pago-rentar/'
export const FLUJO_GUIAS = '/pago-guia/'
export const FLUJO_CERTIFICADOS = '/pago-certificado/'
export const FLUJO_REFRENDAR = '/pago-refrendar/'

//Pagos Extraudinarios
export const PAGOS_EXTRAUDINARIOS_GUIAS = '/pagos-guias'
export const PAGOS_EXTRAUDINARIOS_CERTIFICADOS = '/pagos-certificados'

//APARTAR

//CUESTIONARIO
export const CUESTIONARIO = '/con-emp'
export const PREEVALIACIONES_VIEW = '/preevaluaciones'
export const PREEVALUACIONES_DETAILS = '/preevaluaciones/detalles/'
export const PREVALUACIONES_DIN_VIEW = '/preevaluaciones-din'
export const PREVALUACIONES_DIN_DETAILS = '/preevaluaciones-din/detalles/'

//ALMACEN
export const ALMACEN_VIEW = '/almacen'

//CANCELACIONES
export const CANCELACIONES = '/dev-can'
export const CANCELACIONES_VIEW = '/cancelaciones'
export const CANCELACIONES_DETAILS = '/cancelaciones/detalles'

export const CONFIRMNET_PAYMENT = '/ComfirmationPayment'
//Mundo Barter Routing
export const MB_CONSIGNA = '/consigna'
export const MB_INTERCAMBIA = '/intercambio'
export const MB_RENTA = '/renta'
export const MB_EMPENO = '/empeno'
export const MB_APARTADO = '/apartar'
export const MB_PERSONAL_SHOPPER = '/personal-shopper'

//TERMINOS Y CONDICIONES
export const MB_TERMINOS_CONDICIONES = '/terminos-condiciones'
//TERMINOS Y CONDICIONES
export const MB_AVISO_PRIVACIDAD = '/avisos-privacidad'
//FAQS
export const MB_FAQS = '/preguntas-frecuentes'
//FACTURACIÓN
export const MB_FACTURACION = '/facturacion'

//CALENDAR
export const CALENDAR_VIEW = '/calendar/'
export const CALENDAR_VIEW_RESCHEDULE = '/calendar-rsh/'
export const CALENDAR_VIEW_MB = '/calendar-mb/'
export const CALENDAR_VIEW_RMB = '/calendar-rmb/'

export const CALENDAR_VIEW_CON_VEN_MB = '/calendar-conven-mb/'
export const CALENDAR_VIEW_CON_VEN_RMB = '/calendar-conven-rmb/'

export const CALENDAR_VIEW_CON_FOR_MB = '/calendar-confor-mb/'
export const CALENDAR_VIEW_CON_FOR_RMB = '/calendar-confor-rmb/'

//TERMINOS Y CONDICIONES
export const MB_COOMING_SOON = '/Cooming-Soon'

/// ENCUESTA DE SATISFACCION
export const ENCUESTA_SATISFACCION = '/opiniones'

///ENVIO PIEZAS DHL
export const ENVIO_PIEZAS_DHL = '/envios/'
export const ENVIO_PIEZAS_EMP_DHL = '/envios-emp/'

///ENVIO PIEZAS OTRA PAQUETERIA
export const ENVIO_PIEZAS_OTRA_PAQUETERIA = '/envios-otr/'
export const ENVIO_PIEZAS_EMP_OTRA_PAQUETERIA = '/envios-otr-emp/'

///APARTADO DETAILS
export const PROD_APARTADO_DETAILS = '/apartado-mb-info/'
export const PROD_APARTADO_DETAILS2 = '/apartado-mb-info-pay/'

///PERSONAL SHOPPER 
export const PERSONAL_SHOPPER_VIEW = '/planluxe-view'
export const PERSONAL_SHOPPER_FORM = '/planluxe-form'
export const PERSONAL_SHOPPER_PROP = '/planluxe-prop'
export const PERSONAL_SHOPPER_PIEZAS = '/planluxe-piezas'

//PAGOS PERSONAL SHOPPER
export const PERSONAL_SHOPPER_PAYMENT = '/planluxe-payment/'
export const PERSONAL_SHOPPER_PAYMENT_RED = '/planluxe-payments/'