import { AppBar, Paper, Typography } from "@mui/material";

import { Box } from "@mui/system";
import PropTypes from "prop-types";

const paperPadding = 2;

const BlCard = (props) => {
    return (
        <Paper style={{ height: "100%" }}>
            <AppBar
                //color={!props.color ? "default" : props.color}
                className="bg-blue-card"
                position="static"
                variant="outlined"
                sx={{ borderRadius: "4px 4px 0px 0px" }}
            >
                <Typography variant="h6" sx={{ ml: 1, mr: 1 }} className='f-weight-bold co-second'>
                    {props.title}
                </Typography>
            </AppBar>
            <Box p={paperPadding}>{props.children}</Box>
        </Paper>
    );
};

BlCard.propTypes = {
    children: PropTypes.any,
    color: PropTypes.any,
    title: PropTypes.any,
};

export default BlCard;