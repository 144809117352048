import React, { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { Grid } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import WifiIcon from "@mui/icons-material/Wifi"
import requests from "../../../../AxiosCalls/AxiosAuth"
import { PROD_APARTADO_DETAILS } from "../../../../../Constants/routesConstants"
import {
  isNullOrEmpty,
  isErrorForm,
  rxCaracteresEsp,
} from "../../../../../Util/Validaddor"
import { useSelector } from "react-redux"

const visa = require("../../../../../Images/Logo-Visa.png")
const chip1 = require("../../../../../Images/chip1.png")

const CardPayPrimerPago = (props) => {
  let navigate = useNavigate()
  var dataIdPor = JSON.parse(sessionStorage.getItem("idPorc"))
  const { id } = useParams()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const [cardFormData, setcardFormData] = useState({
    cardNumber: "",
    cardName: "",
    expiryMonth: "",
    expiryYear: "",
    ccv: "",
    loading: false,
    iframeCode: "",
    timer: 10000,
    subTimer: 20000,
    transactionId: "",
    transactionState: "Unknown",
    paymentComplete: false,
    status: "",
    initialPaymentSubmitted: false,
  })

  // proceso de pago
  const getCardForm = () => ({
    cardNumber: { value: "", error: false },
    cardName: { value: "", error: false },
    expiryMonth: { value: "", error: false },
    expiryYear: { value: "", error: false },
    ccv: { value: "", error: false },
    //city: { value: '', error: false },
    //zip: { value: '', error: false },
  })
  const [cardForm, setCardForm] = useState(getCardForm)

  const handleNameCard = (e, value) => {
    let nombre = e.target.name
    let valor = e.target.value
    let id = e.target.id

    setCardForm({
      ...cardForm,
      [nombre]: {
        value: valor,
        error: false,
      },
    })
  }

  const handleClickSubmitPayment = () => {
    const { cardName, cardNumber, expiryMonth, expiryYear, ccv, city, zip } =
      cardForm
    const form = {
      ...cardForm,
      cardName: {
        ...cardName,
        error:
          isNullOrEmpty(cardName.value) ||
          !rxCaracteresEsp.test(cardName.value),
      },
      expiryMonth: {
        ...expiryMonth,
        error:
          isNullOrEmpty(expiryMonth.value) ||
          !rxCaracteresEsp.test(expiryMonth.value),
      },
      expiryYear: {
        ...expiryYear,
        error:
          isNullOrEmpty(expiryYear.value) ||
          !rxCaracteresEsp.test(expiryYear.value),
      },
      ccv: {
        ...ccv,
        error: isNullOrEmpty(ccv.value) || !rxCaracteresEsp.test(ccv.value),
      },
      cardNumber: {
        ...cardNumber,
        error:
          isNullOrEmpty(cardNumber.value) ||
          !rxCaracteresEsp.test(cardNumber.value),
      },
    }
    setCardForm(form)
    if (isErrorForm(form)) {
      return
    }

    const SendBody = {
      idProduct: id, //ordercomp.lngIdCarrito, // pasar el id del carrito para obtener los datos de la db y no tomar el valos de amout del local si no de la base haciendo una consulta con idCarrito,
      number: cardForm.cardNumber.value,
      month: cardForm.expiryMonth.value,
      year: cardForm.expiryYear.value,
      securityCode: cardForm.ccv.value,
      cardholderName: cardForm.cardName.value,
      amout: props.data + ((props.data * 2.12) / 100 + 3), //ordercomp.dblTotal, // asignar el precio de contexto o m ordercomp.dblTotal
      chargeService: (props.data * 2.12) / 100 + 3,
      paymentType: 2,
      fullname: cardForm.cardName.value, // definir es del usuario de la tarjeta o user del la session
      idPorApart: dataIdPor,
      idClient: userInfo.intIdUsuario,
      intIdPlazo: props.plazo,
    }
    props.props.props.setOpenLoadingScreen()
    requests
      .post("/PaymentsApartado/PaymentCard/", SendBody)
      .then((response) => {
        localStorage.setItem(
          "ipgTransactionId",
          JSON.stringify(response.data.ipgTransactionId)
        )
        localStorage.setItem("IdProduct", JSON.stringify(id))

        if (response.data.transactionStatus === "APPROVED") {
          props.props.props.setCloseLoadingScreen()
          props.props.props.setOpenSnackBar(true)
          props.props.props.setTypeSnackBar("success")
          props.props.props.setMessageSnackBar(response.mensaje)
          navigate(PROD_APARTADO_DETAILS + id)
          return
        } else {
          localStorage.setItem("HashCo", JSON.stringify(cardForm.ccv.value))
          if (
            response.data.authenticationResponse.secure3dMethod !== undefined ||
            response.data.authenticationResponse.secure3dMethod != null
          ) {
            setcardFormData({
              ...cardFormData,
              transactionId: response.data.ipgTransactionId,
              iframeCode:
                response.data.authenticationResponse.secure3dMethod.methodForm,
            })
            setInterval(() => {
              if (cardFormData.timer > 0)
                setcardFormData({
                  ...cardFormData,
                  timer: cardFormData.timer - 1000,
                })
            }, 1000)
            setTimeout(() => {
              patchTransactionAfter3DSDataIsSent(response.data)
            }, 9000)
          } else {
            localStorage.setItem(
              "3dsSinIframe",
              JSON.stringify(response.data.approvalCode)
            )
            setcardFormData({
              ...cardFormData,
              transactionId: response.data.ipgTransactionId,
            })
            let cReq = response.data.authenticationResponse.params.cReq
            let termURL = response.data.authenticationResponse.params.termURL
            let path = response.data.authenticationResponse.params.acsURL

            SubmitCreq(path, {
              creq: cReq,
              termURL: termURL,
            })
          }
        }
      })

      .catch((error) => {
        console.log(error)
        debugger
        props.props.props.setTypeSnackBar("warning")
        props.props.props.setMessageSnackBar(error.data.mensaje)
        props.props.props.setOpenSnackBar(true)
        props.props.props.setCloseLoadingScreen()
      })
  }

  const patchTransactionAfter3DSDataIsSent = (data) => {
    const SendBody = {
      idProduct: id, // pasar el id del carrito para obtener los datos de la db y no tomar el valos de amout del local si no de la base haciendo una consulta con idCarrito,
      transactionId: data.ipgTransactionId,
      securityCode: cardForm.ccv.value,
    }
    requests
      .post("/PaymentsApartado/PaymentID", SendBody)
      .then((response) => {
        // en este paso puede ser que algunos bancos aprueben la transacción
        if (response.data.transactionStatus === "APPROVED") {
          props.props.props.setCloseLoadingScreen()
          props.props.props.setOpenSnackBar(true)
          props.props.props.setTypeSnackBar("success")
          localStorage.removeItem("CoHas")
          props.props.props.setMessageSnackBar(response.mensaje)
          navigate(PROD_APARTADO_DETAILS + id)
          return
        }

        let cReq = response.data.authenticationResponse.params.cReq
        //let sessiondata =response.data.authenticationResponse.params.sessiondata
        let termURL = response.data.authenticationResponse.params.termURL
        let path = response.data.authenticationResponse.params.acsURL

        SubmitCreq(path, {
          creq: cReq,
          termURL: termURL,
        })
        props.props.props.setCloseLoadingScreen()
      })

      .catch((error) => {
        console.log(error)
        debugger
        props.props.props.setTypeSnackBar("warning")
        props.props.props.setMessageSnackBar(error.data.mensaje)
        props.props.props.setMessageSnackBar("error")
        props.props.props.setOpenSnackBar(true)
        props.props.props.setCloseLoadingScreen()
      })
  }
  // se construyec un formulario oculto para crear el proceso de validar la transacción con 3DS
  const SubmitCreq = (path, params, method = "post") => {
    const form = document.createElement("form")
    form.method = method
    form.action = path
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input")
        hiddenField.type = "hidden"
        hiddenField.name = key
        hiddenField.value = params[key]
        form.appendChild(hiddenField)
      }
    }
    document.body.appendChild(form)
    form.submit()
  }
  // El flujo continua el servidor y para luego regresar al cliente
  return (
    <div className="pay-container">
      <div className="container-Card-Payment">
        <div className="section1">
          <div className="flex-between w100 ">
            <img src={visa} className="img-logo-payment" />
            <div className="flex">
              <img src={chip1} className="img-logo-chip mr1" />
              <WifiIcon className="icon-card" />
            </div>
          </div>
          <div className="flex-column line-height-0  upperCase mt2">
            {/* <p>Número de tarjeta</p> */}

            {cardForm.cardNumber.value.length === 0 ? (
              <p className="card-number">[XXXX] [XXXX] [XXXX] [XXXX]</p>
            ) : (
              <p className="card-number">{cardForm.cardNumber.value}</p>
            )}
          </div>
        </div>
        <div className="section2">
          <div className="flex-column-start line-height-0  upperCase mr1">
            <p className="titular-card">Nombre del titular</p>
            <p className="titular-data-card">{cardForm.cardName.value}</p>
          </div>

          <div className="flex-between ">
            <div className="flex-between line-height-0">
              <p className="date-card">EXPIRA</p> &nbsp;
              <div className="flex upperCase mt1">
                <p className="date-data-card">{cardForm.expiryMonth.value}</p>
                &nbsp; <p className="bold mt0"> / </p>&nbsp;
                <p className="date-data-card">{cardForm.expiryYear.value}</p>
              </div>
            </div>

            <div className=" flex-between mr3">
              <div className="flex line-height-0  upperCase ">
                <p className="mr1">CCV</p>
                <p className="bold"> {cardForm.ccv.value}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Grid
        container
        spacing={1}
        sx={{
          mt: 3,
          width: "80%",
          "@media(max-width: 600px)": {
            width: "100%",
          },
        }}
      >
        <Grid item xs={12} lg={12}>
          <TextField
            keyboardType="numeric"
            maxLength={16}
            name="cardNumber"
            onChange={handleNameCard}
            value={cardForm.cardNumber.value}
            variant="outlined"
            label="Número de tarjeta"
            fullWidth
            required
            inputProps={{
              maxLength: 16,
            }}
            error={
              cardForm.cardNumber.error ||
              !rxCaracteresEsp.test(cardForm.cardNumber.value)
            }
            helperText={
              cardForm.cardNumber.error ? (
                <span>El número de tarjeta es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.cardNumber.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ""
              )
            }
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <TextField
            name="cardName"
            onChange={handleNameCard}
            value={cardForm.cardName.value}
            label="Titular de la tarjeta"
            required
            //InputLabelProps={{ shrink: true }}
            fullWidth
            sx={{ mr: 2 }}
            type="text"
            error={
              cardForm.cardName.error ||
              !rxCaracteresEsp.test(cardForm.cardName.value)
            }
            helperText={
              cardForm.cardName.error ? (
                <span>El nombre del titular de la tarjeta es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.cardName.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ""
              )
            }
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <TextField
            name="expiryMonth"
            onChange={handleNameCard}
            value={cardForm.expiryMonth.value}
            label="Mes de vencimiento"
            required
            fullWidth
            inputProps={{
              maxLength: 2,
            }}
            error={
              cardForm.expiryMonth.error ||
              !rxCaracteresEsp.test(cardForm.expiryMonth.value)
            }
            helperText={
              cardForm.expiryMonth.error ? (
                <span>El mes de vencimiento es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.expiryMonth.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ""
              )
            }
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <TextField
            name="expiryYear"
            onChange={handleNameCard}
            value={cardForm.expiryYear.value}
            label="Año de vencimiento"
            required
            fullWidth
            inputProps={{
              maxLength: 2,
            }}
            error={
              cardForm.expiryYear.error ||
              !rxCaracteresEsp.test(cardForm.expiryYear.value)
            }
            helperText={
              cardForm.expiryYear.error ? (
                <span>El año de vencimiento es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.expiryYear.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ""
              )
            }
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <TextField
            name="ccv"
            onChange={handleNameCard}
            value={cardForm.ccv.value}
            type={"password"}
            label="CVV"
            fullWidth
            required
            inputProps={{
              maxLength: 4,
            }}
            error={
              cardForm.ccv.error || !rxCaracteresEsp.test(cardForm.ccv.value)
            }
            helperText={
              cardForm.ccv.error ? (
                <span>El cvv es requerido</span>
              ) : !rxCaracteresEsp.test(cardForm.ccv.value) ? (
                <span>No se aceptan caracteres especiales</span>
              ) : (
                ""
              )
            }
          />
        </Grid>
        <Button className="btn-pay mt2" onClick={handleClickSubmitPayment}>
          Realizar pago
        </Button>
      </Grid>
    </div>
  )
}

export default CardPayPrimerPago
