import React, { useEffect, useState } from "react";
import "../../../Styles/Foundit/faqsMB.css";
import Button from "@mui/material/Button";

const FqSection1Web = (props) => {
  return (
    <section className="FaqsSection1MB">
      <div className="body">
        <div className="contentSection1MB">
          <p className="titlesection2MB">Preguntas Frecuentes</p>

          <p className="subtitlesection2MB">
            ¡Te damos la bienvenida a nuestro rincón de respuestas!
          </p>
          <p className="subtitlesection2MB">
            Aquí encontrarás todo lo que necesitas saber para vender, empeñar o
            apartar tus artículos Preloved con facilidad y estilo.
          </p>
        </div>
        <div className="contentSection2MB"></div>
      </div>
    </section>
  );
};

export default FqSection1Web;
