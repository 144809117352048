import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import Table from '@mui/material/Table'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import TableBody from '@mui/material/TableBody'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Chip } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useNavigate, useParams } from 'react-router-dom'
import requests from '../AxiosCalls/AxiosCall'
import {
	CALENDAR_SCHEDULE,
	CALENDAR_REGISTRIES,
	CALENDAR_MEETING_CON_VEN_BY_ID_PROD,
	CALENDAR_EDIT_CON_VEN_MB,
	CALENDAR_RG_DAY_DELETE,
} from '../../Constants/ApiConstants'
import { styled } from '@mui/material/styles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { gtmGoTo_ConfirmReagendarCitaVenVencida_Log, gtmGoTo_NoConfirmReagendarCitaVenVencida_Log } from '../../Constants/GoogleTagManager/gtmConstants'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#545454',
		textTransform: 'uppercase',
		fontWeight: '600',
		fontSize: window.screen.width <= 600 ? 9 : 20,
		border: 0,
	},
	[`&.${tableCellClasses.body}`]: {
		color: '#545454',
		fontWeight: '600',
		padding: window.screen.width <= 600 ? 13 : null,
		fontSize: window.screen.width <= 600 ? 8 : 20,
		border: 0,
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}))

const CalendarConVenRMb = (props) => {
	const today = new Date()
	const [week, setWeek] = useState([])
	const [schedule, setSchedule] = useState([])
	const [registries, setRegistry] = useState([])
	const [open, setOpen] = React.useState(false)

	const [meetingDialog, setMeetingDialog] = useState({
		schedule: '',
		date: '',
	})
	const [meeting, setMeeting] = useState({
		lngIdCitaDes: 0,
		fecRegCita: '',
		intIdStatusCita: 0,
		lngIdRegistroDia: 0,
		fecCita: '',
		hrCita: '',
		fecCitaStr: '',
		lngIdProducto: '',
		txtSkuReg: '',
		txtFolio: '',
		productoH: null,
		productoM: '',
		marca: '',
		categoria: '',
		cliente: '',
	})

	const [idRegistroDia, setIdRegistroDia] = useState(0)
	const [idRgDayCancel, setIdRgDayCancel] = useState(0)
	const { lngIdProducto } = useParams()
	const navigate = useNavigate()
	const [openDialog, setOpenDialog] = useState(false)

	const getListSchedule = () => {
		requests
			.get(CALENDAR_SCHEDULE)
			.then((response) => {
				setSchedule(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const getRegistriesCalendar = () => {
		requests
			.get(CALENDAR_REGISTRIES)
			.then((response) => {
				setRegistry(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const getMeeting = () => {
		requests
			.get(CALENDAR_MEETING_CON_VEN_BY_ID_PROD + lngIdProducto)
			.then((response) => {
				setMeeting(response)
				setIdRgDayCancel(response.lngIdRegistroCita)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const confirmMeeting = (id_rg_day) => {
		props.setOpenLoadingScreen()

		let datasource = {
			lngIdCitaDes: meeting.lngIdCitaDes,
			fecRegCita: meeting.fecRegCita,
			intIdStatusCita: 2,
			lngIdRegistroCita: id_rg_day,
			lngIdProducto: `${lngIdProducto}`,
		}
		handleClose()

		requests
			.delete(CALENDAR_RG_DAY_DELETE, id_rg_day)
			.then((response) => {})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})
		requests
			.delete(CALENDAR_RG_DAY_DELETE, idRgDayCancel)
			.then((response) => {})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})
		requests
			.put(CALENDAR_EDIT_CON_VEN_MB, datasource)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageAlert(response.mensaje)
				props.setOpenAlert(true)
				setOpenDialog(true)
				props.setCloseLoadingScreen()
				navigate(-1)
			})
			.catch((error) => {
				console.log(error)
				props.setTypeSnackBar('warning')
				props.setMessageAlertWarning(error.data.mensaje)
				props.setOpenAlertWarning(true)
				props.setCloseLoadingScreen()
			})
	}

	const generateDayWeek = (end_date) => {
		let genericWeek = []
		for (let i = 6; i >= 0; i--) {
			genericWeek.push(moment(end_date).subtract(i, 'days').format('llll'))
		}
		sessionStorage.setItem('activate_week', JSON.stringify(genericWeek))
		sessionStorage.setItem('end_date', JSON.stringify(end_date))
		setWeek(genericWeek)
	}

	const controllerWeek = () => {
		let verifyDay = false
		let counter = 0
		let endDayWeek = JSON.parse(sessionStorage.getItem('end_date'))

		if (endDayWeek != null) {
			generateDayWeek(endDayWeek)
		} else {
			while (!verifyDay) {
				if (moment(today).format('dddd') === 'Saturday') {
					endDayWeek = today
					verifyDay = true
				}
				if (moment(endDayWeek).format('dddd') === 'Saturday') {
					verifyDay = true
				}
				if (!verifyDay) {
					counter++
					endDayWeek = moment(today).add(counter, 'days').format('llll')
				}
			}
			sessionStorage.setItem('end_date', JSON.stringify(endDayWeek))
			generateDayWeek(endDayWeek)
		}
	}

	useEffect(() => {
		controllerWeek()
		getListSchedule()
		getRegistriesCalendar()
		getMeeting()
	}, [])

	const renderMont = () => {
		let firstDay = JSON.parse(sessionStorage.getItem('activate_week'))[0]
		let endDay = JSON.parse(sessionStorage.getItem('activate_week'))[6]

		if (
			moment(firstDay).format('MMMM YYYY') == moment(endDay).format('MMMM YYYY')
		) {
			return (
				<Button className='BtnRenderMont' variant='text' sx={{ marginLeft: 0 }}>
					{moment(endDay).format('MMMM YYYY')}
				</Button>
			)
		} else {
			return (
				<Button className='BtnRenderMont' variant='text' sx={{ marginLeft: 0 }}>
					{moment(firstDay).format('MMM')} - {moment(endDay).format('MMM YYYY')}
				</Button>
			)
		}
	}

	const onClickButtonToday = () => {
		sessionStorage.removeItem('end_date')
		controllerWeek()
	}
	const onClickButtonBack = () => {
		let end_date = JSON.parse(sessionStorage.getItem('end_date'))
		generateDayWeek(moment(end_date).subtract(7, 'days').format('llll'))
	}
	const onClickButtonForward = () => {
		let end_date = JSON.parse(sessionStorage.getItem('end_date'))
		generateDayWeek(moment(end_date).add(7, 'days').format('llll'))
	}

	const handleClickOpen = (element, sch) => {
		setOpen(true)
		setIdRegistroDia(element.lngIdRegistroDia)
		setMeetingDialog({
			...meetingDialog,
			schedule: sch.hrInicia,
			date: element.fecDisponible,
		})
	}

	const handleClose = () => {
		gtmGoTo_NoConfirmReagendarCitaVenVencida_Log()
		setOpen(false)
	}

	const renderElement = (schedule, day) => {
		let verifyElement = false
		let element
		registries.map((registry) => {
			if (
				registry.intIdHorarioCita == schedule.intIdHorarioCita &&
				moment(registry.fecDisponible).format('DD/MM/yyyy') ===
					moment(day).format('DD/MM/yyyy') &&
				registry.bolActivo
			) {
				verifyElement = true
				element = registry
			}
		})

		if (verifyElement) {
			if (
				moment(element.fecDisponible).format('DD/MM/yyyy') ===
				moment(today).format('DD/MM/yyyy')
			) {
				return (
					<StyledTableCell>
						<Chip
							icon={<CalendarMonthIcon className='CalendarIcon' />}
							// color='success'
							className='btnDisponible ProductTitleDescription'
							label='Disponible'
							variant='outlined'
							onClick={() => {
								handleClickOpen(element, schedule)
							}}
						/>
					</StyledTableCell>
				)
			}

			if (moment(element.fecDisponible).unix() > moment(today).unix()) {
				return (
					<StyledTableCell>
						<Chip
							icon={<CalendarMonthIcon className='CalendarIcon' />}
							className='btnDisponible ProductTitleDescription'
							label='Disponible'
							variant='outlined'
							onClick={() => {
								handleClickOpen(element, schedule)
							}}
						/>
					</StyledTableCell>
				)
			} else {
				return <StyledTableCell></StyledTableCell>
			}
		} else {
			return <StyledTableCell></StyledTableCell>
		}
	}

	return JSON.parse(sessionStorage.getItem('activate_week')) != null ? (
		<>
			<Chip
				sx={{ marginTop: 6, marginLeft: 5 }}
				avatar={<KeyboardBackspaceIcon />}
				label='Reagendar'
				className='back-transparent font-letter font-barter font-20'
				onClick={() => {
					navigate(-1)
				}}
			/>
			<div className='TableCalendarContainer mt-40'>
				<div className='TableCalendarBody'>
					<Box
						align='center'
						sx={{
							margin: 'auto',
							padding: 1,
							backgroundColor: '#000',
							borderRadius: '10px 10px 0px 0px',
						}}
					>
						<Button
							className='btnHoyCalendar'
							variant='outlined'
							onClick={() => {
								onClickButtonToday()
							}}
						>
							Fecha Actual
						</Button>
						<Button
							className='iconArrowCalendar'
							variant='text'
							onClick={() => {
								onClickButtonBack()
							}}
						>
							<ArrowBackIosIcon />
						</Button>
						<span>{renderMont()}</span>
						<Button
							className='iconArrowCalendar'
							variant='text'
							onClick={() => {
								onClickButtonForward()
							}}
						>
							<ArrowForwardIosIcon />
						</Button>
					</Box>
					<Table sx={{ minWidth: 600, marginTop: 2 }} aria-label='simple table'>
						<TableHead>
							<StyledTableRow>
								<StyledTableCell key='01'></StyledTableCell>
								{JSON.parse(sessionStorage.getItem('activate_week')).map(
									(day, index) =>
										moment(day).format('L') == moment(today).format('L') ? (
											<StyledTableCell
												key={index}
											>
												<span className='dayActive'>
													{moment(day).format('ddd DD')}
												</span>
											</StyledTableCell>
										) : (
											<StyledTableCell key={index}>
												<span>{moment(day).format('ddd DD')}</span>
											</StyledTableCell>
										)
								)}
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{schedule.map((row, index) => (
								<StyledTableRow
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<StyledTableCell>
										<span>{row.hrInicia.slice(0, 5)}</span>
									</StyledTableCell>
									{JSON.parse(sessionStorage.getItem('activate_week')).map(
										(day, index) => renderElement(row, day)
									)}
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</div>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						Cita actual: {moment(meeting.fecCita).format('DD/MM/yyyy')}{' '}
						{meeting.hrCita.slice(0, 5)}hrs<br></br>
						¿Estás seguro de agendar tu cita para el día{' '}
						{moment(meetingDialog.date).format('DD/MM/yyyy')} a las{' '}
						{meetingDialog.schedule.slice(0, 5)} hrs?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						variant='contained'
						className='calendarBtnCancelar'
					>
						Calcelar
					</Button>
					<Button
						onClick={() => {
							gtmGoTo_ConfirmReagendarCitaVenVencida_Log()
							confirmMeeting(idRegistroDia)
						}}
						variant='contained'
						className='calendarBtnConfirmar'
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</>
	) : (
		<></>
	)
}

export default CalendarConVenRMb
