import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { EMPEÑO_PAGO_DETALLES } from '../../../../Constants/ApiConstants'
import { postAbonoCreate } from '../../../../Actions/MundoBarterActions'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import NumberFormat from 'react-number-format'
import ModalCargoServicio from '../../ModalCargoServicio'

const AmountPayLiq = (props) => {
	console.log(props);
	const dispatch = useDispatch()

	const handleNext = (data) => {
		dispatch(postAbonoCreate(data))
		props.handleNext()
	}

	const [amountPay, setAmountCodi] = useState({
		fee: 0,
		totalPagar: 0,
	})
	const getData = () => {
		var data = JSON.parse(sessionStorage.getItem('liquidar'))
		if (data !== null) {
			setAmountCodi({
				...amountPay,
				fee: data.fee,
				totalPagar: data.total,
			})
		}
	}
	useEffect(() => {
		getData()
	}, [])

	const [openModalTerminos, setModalTerminos] = useState(false)
	const handleOpenTerminos = () => {
		setModalTerminos(!openModalTerminos)
	}

	return (

		<div className='CardApartadoDetailPagosCardStepper'>
			<div className='BodyCardApartadoDetail'>
				<div className='ApartadoDetailSection2'>
					<h2>Detalle de pago</h2>
					<hr className='Dvividerhr' />
					<div className='containerFlexBetween'>
						<span className='bold upperCase'>
							<div className='containerSelectDetailPago'>
								<span className='subtitlesdetail'>Total desempeño: </span>{' '}
								<NumberFormat
									value={props.detalles.dblSaldoActual === 0 ? 0 : props.detalles.dblSaldoActual}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
							</div>
						</span>
						<span className='bold upperCase'>
							<span className='subtitlesdetail'>Total a pagar: </span>{' '}
							<div className='containerSelectDetailPago'>
								<NumberFormat
									value={props.detalles.dblTotalEmpeño === 0 ? 0 : amountPay.totalPagar}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
								<Button
									className='BtnTermConditions2'
									sx={{ padding: '0 !important', minWidth: '0 !important' }}
									onClick={handleOpenTerminos}
								>
									<InfoOutlinedIcon />
								</Button>
							</div>
						</span>
					</div>
					<Button
					className='btn-pay'
					disabled={props.detalles.dblSaldoActual === 0 ? true : false}
					onClick={function () {
						handleNext(amountPay)
					}}
				>
					Realizar pago
				</Button>
				</div>
			</div>

			<ModalCargoServicio
				openModalImgPreview={openModalTerminos}
				onCloseModalImgPreview={handleOpenTerminos}
			/>
		</div>
	)
}
export default AmountPayLiq
