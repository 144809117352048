/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react'

import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import logo from '../../../Images/logo-barter-sin-fondo.png'

import NumberFormat from 'react-number-format'
import Slider from 'react-slick'
import { gtm_CloseTerminos_Log } from '../../../Constants/GoogleTagManager/gtmConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const TyCApartado = (props) => {
	const handleClose = () => {
		//gtm_CloseTerminos_Log()
		props.onCloseModalTyC()
	}
	// const settings = {
	// 	dots: true,
	// 	infinite: true,
	// 	speed: 500,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	autoplay: false,
	// 	autoplaySpeed: 4000,
	// }

	/*----------------------------------- */
	//creating the ref
	const customeSlider = useRef()

	// setting slider configurations
	var settings = {
		dots: false,
		infinite: true,
		autoplay: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		arrows: false,
		onSwipe: false,
		// swipe: false,
	}
	return (
		<Dialog
			fullScreen
			open={props.openModalTyC}
			onClose={
				props.onCloseModalTyC ? props.onCloseModalTyC : null
			}
			TransitionComponent={Transition}
			PaperProps={{
				style: {
					background:
						'linear-gradient(38deg, rgb(0 0 0 / 61%) 30%, rgb(0 0 0 / 12%) 100%)',
				},
			}}
		>
			<div className='box-termConditions-MB'>
				<div className='section-termConditions-MB-1'>
					<div>
						<Slider {...settings} ref={customeSlider}>
							<div className='box-term-MB-1'>
								<DialogTitle sx={{ color: '#583101' }} id='alert-dialog-title'>
									<img src={logo} className='imgTermConditionsConsigna' />
									REGLAS DE APARTADO
								</DialogTitle>

								<DialogContent>
									<DialogContentText
										id='alert-dialog-description'
										className='text-justify'
									>
										Aparta con el 30% del valor total de la pieza.
									</DialogContentText>
									<DialogContentText
										id='alert-dialog-description'
										className='text-justify'
									>
										Termina de pagar en 2 o hasta 4 quincenas. 
									</DialogContentText>
									<DialogContentText
										id='alert-dialog-description'
										className='text-justify'
									>
										La pieza se enviará una vez que se liquiden los pagos. 
									</DialogContentText>
									<DialogContentText
										id='alert-dialog-description'
										className='text-justify'
									>
										Podrás recoger tu pieza en ShowRoom si eres de CDMX o bien podrás cotizar tu envío hasta tu ciudad. 
									</DialogContentText>
									<DialogContentText
										id='alert-dialog-description'
										className='text-justify'
									>
										En caso de no cubrir los pagos se realizará una penalización (revisar términos y condiciones). 
									</DialogContentText>
								</DialogContent>
							</div>
							
						</Slider>
					</div>
				</div>
				<div className='section-termConditions-MB-2'>
					{/* <IconButton
						variant='outlined'
						sx={{
							color: '#583101',
							borderColor: '#583101',
							':hover': { borderColor: '#583101' },
							margin: 'auto',
						}}
						onClick={next}
						autoFocus
					>
						<ChevronLeftIcon />
					</IconButton> */}

					<Button
						variant='outlined'
						sx={{
							color: '#583101',
							borderColor: '#583101',
							':hover': { borderColor: '#583101' },
							margin: 'auto',
						}}
						onClick={handleClose}
						autoFocus
					>
						Aceptar
					</Button>

					{/* <IconButton
						variant='outlined'
						sx={{
							color: '#583101',
							borderColor: '#583101',
							':hover': { borderColor: '#583101' },
							margin: 'auto',
						}}
						onClick={previous}
						autoFocus
					>
						<ChevronRightIcon />
					</IconButton> */}
				</div>
			</div>
		</Dialog>
	)
}


export default TyCApartado