/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import requests from '../../../Components/AxiosCalls/AxiosAuth'
import Button from '@mui/material/Button'
import NumberFormat from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { FLUJO_PAGO, FLUJO_APARTAR } from '../../../Constants/routesConstants'
import {
  PORCENTAJE_LIST,
  PORCENTAJE_PORID,
  PRODUCTOS_DETAILS_BY_SKU,
  PRODUCTOS_DETAILS_BY_SKUP,
} from '../../../Constants/ApiConstants'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import '../../../Styles/GeneralStyles'
import '../../../Styles/App/Apartado/ApartadoView.css'

let imgProducto =
  'https://cdn.shopify.com/s/files/1/0448/2665/6919/products/TOTE_DIOR__2_-removebg-preview_1024x1024@2x.png?v=1659049480'

const ApartadoPrimerProcesoProduct = (props) => {
  const sku = JSON.parse(sessionStorage.getItem('sku'))
  const [_Producto, setProducto] = useState([])

  let navigate = useNavigate()

  useEffect(() => {
    getRegistro()
    getPercent()
  }, [])

  const getRegistro = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PRODUCTOS_DETAILS_BY_SKUP, sku)
      .then((response) => {
        console.log(response)

        setProducto(response)

        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const [_Porcent, setPorcent] = useState([])
  const getPercent = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PORCENTAJE_LIST)
      .then((response) => {
        setPorcent(response)

        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const [expanded, setExpanded] = useState(false)
  const [precioFin, setPrecioFin] = useState()
  const handlePercent = (event) => {
    requests
      .get(PORCENTAJE_PORID + event.target.value)
      .then((response) => {
        props.setCloseLoadingScreen()
        let oper = (_Producto.dblPrecioFinal * response.dblPorApartado) / 100
        setPrecioFin(oper)
      })
      .catch((error) => {
        console.log(error)
        props.setCloseLoadingScreen()
      })
  }

  const handleChange = (panel1) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel1 : false)
  }
  const handleNavigateViewProPay = () => {
    navigate(FLUJO_APARTAR + _Producto.lngIdProducto)
  }

  return (
    <>
      <div className="Container-Apartado-Detail">
        <div className="CardApartadoDetail">
          <div className="BodyCardApartadoDetail">
            <div className="ApartadoDetailSection1">
              <div
                className="BoxImgADetail"
                style={{
                  backgroundImage: `url(${_Producto.txtUrl})`,
                }}
              ></div>
            </div>

            <div className="ApartadoDetailSection2">
              {/* <h2>¡Apártalo desde el 30% de su valor total!</h2> */}
              <div>
                <h2 className="bold">¡Apártalo Ahora!</h2>
                <h2 className="apartalo">
                  Apártalo desde el 30% de su valor total
                </h2>
              </div>
              <hr className="Dvividerhr" />
              {/* ***************** */}
              <div className="containerFlexBetween">
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Producto:</span>{' '}
                  {_Producto.txtProductoH === null
                    ? _Producto.txtProductoM
                    : _Producto.txtProductoH}{' '}
                  {_Producto.intIdMarca}
                </span>
                <span className="bold upperCase">
                  <span className="subtitlesdetail">SKU:</span>{' '}
                  {_Producto.txtSkuReg}{' '}
                </span>
              </div>
              {/* ***************** */}
              <div className="containerFlexBetween">
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Marca:</span>{' '}
                  {_Producto.intIdMarca}
                </span>
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Color:</span>{' '}
                  {_Producto.intIdColor}
                </span>
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Material:</span>{' '}
                  {_Producto.intIdMaterial}
                </span>
              </div>
              {/* ***************** */}
              <div className="containerFlexBetween">
                {_Producto.txtAlturaPlataformaZa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">
                      Altura / Plataforma:
                    </span>{' '}
                    {_Producto.txtAlturaPlataformaZa}
                  </span>
                )}
                {_Producto.txtTallaZa === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Talla:</span>{' '}
                    {_Producto.txtTallaZa}
                  </span>
                )}
                {_Producto.txtAnchoBol === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Ancho:</span>{' '}
                    {_Producto.txtAnchoBol}
                  </span>
                )}
                {_Producto.txtLargoBol === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Largo:</span>{' '}
                    {_Producto.txtLargoBol}
                  </span>
                )}
                {_Producto.txtProfundidadBol === null ? (
                  ' '
                ) : (
                  <span className="bold upperCase">
                    <span className="subtitlesdetail">Profundidad:</span>{' '}
                    {_Producto.txtProfundidadBol}
                  </span>
                )}
                {_Producto.txtAnchoCin === null ? (
                  ' '
                ) : (
                  <strong>
                    Ancho: <p className="d-contents">{_Producto.txtAnchoCin}</p>{' '}
                  </strong>
                )}
                {_Producto.txtLargoCin === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Largo: <p className="d-contents">{_Producto.txtLargoCin}</p>{' '}
                  </strong>
                )}
                {_Producto.txtAnchoCo === null ? (
                  ' '
                ) : (
                  <strong>
                    Ancho: <p className="d-contents">{_Producto.txtAnchoCo}</p>{' '}
                  </strong>
                )}
                {_Producto.txtTallaMedidaRo === null ? (
                  ' '
                ) : (
                  <strong>
                    Talla/Tamaño:{' '}
                    <p className="d-contents">{_Producto.txtTallaMedidaRo}</p>{' '}
                  </strong>
                )}
                {_Producto.txtLargoCo === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Largo: <p className="d-contents">{_Producto.txtLargoCo}</p>{' '}
                  </strong>
                )}
                {_Producto.txtFormaGa === null ? (
                  ' '
                ) : (
                  <strong>
                    Forma: <p className="d-contents">{_Producto.txtFormaGa}</p>{' '}
                  </strong>
                )}
                {_Producto.txtAnchoGa === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Ancho: <p className="d-contents">{_Producto.txtAnchoGa}</p>{' '}
                  </strong>
                )}
                {_Producto.txtAltoGa === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Alto: <p className="d-contents">{_Producto.txtAltoGa}</p>{' '}
                  </strong>
                )}
                {_Producto.txtVarillaGa === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Varilla:{' '}
                    <p className="d-contents">{_Producto.txtVarillaGa}</p>{' '}
                  </strong>
                )}
                {_Producto.txtPuenteGa === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Puente:{' '}
                    <p className="d-contents">{_Producto.txtPuenteGa}</p>{' '}
                  </strong>
                )}
                {_Producto.txtAnchoMa === null ? (
                  ' '
                ) : (
                  <strong>
                    Ancho: <p className="d-contents">{_Producto.txtAnchoMa}</p>{' '}
                  </strong>
                )}
                {_Producto.txtLargoMa === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Largo: <p className="d-contents">{_Producto.txtLargoMa}</p>{' '}
                  </strong>
                )}
                {_Producto.bolKilatajeJo === false ? (
                  ' '
                ) : (
                  <strong>
                    Kilataje: <p className="d-contents">Si</p>{' '}
                  </strong>
                )}
                {_Producto.txtMedidaJo === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Medida Joyeria:{' '}
                    <p className="d-contents">{_Producto.txtMedidaJo}</p>{' '}
                  </strong>
                )}
                {_Producto.bolPiedrasJo === false ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Piedras: <p className="d-contents">Si</p>{' '}
                  </strong>
                )}
                {_Producto.txtSerieJo === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Serie: <p className="d-contents">{_Producto.txtSerieJo}</p>{' '}
                  </strong>
                )}
                {_Producto.txtLeyJo === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Ley: <p className="d-contents">{_Producto.txtLeyJo}</p>{' '}
                  </strong>
                )}
                {_Producto.bolKilatajeRe === false ? (
                  ' '
                ) : (
                  <strong>
                    Kilataje: <p className="d-contents">Si</p>{' '}
                  </strong>
                )}
                {_Producto.txtMaterialRe === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Material:{' '}
                    <p className="d-contents">{_Producto.txtMaterialRe}</p>{' '}
                  </strong>
                )}
                {_Producto.txtModeloPulseraRe === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Ley:{' '}
                    <p className="d-contents">{_Producto.txtModeloPulseraRe}</p>{' '}
                  </strong>
                )}
                {_Producto.txtTipoMaquinariaRe === null ? (
                  ' '
                ) : (
                  <strong className="pl1">
                    Tipo Maquinaria:{' '}
                    <p className="d-contents">
                      {_Producto.txtTipoMaquinariaRe}
                    </p>{' '}
                  </strong>
                )}
              </div>
              <hr className="Dvividerhr" />
              {/* ***************** */}

              <div className="containerFlexBetween">
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Total a pagar: </span>{' '}
                  <NumberFormat
                    value={_Producto.dblPrecioFinal}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    prefix={'$ '}
                    suffix={' MXN'}
                  />
                </span>
                <span className="bold upperCase">
                  <span className="subtitlesdetail">Apártalo con: </span>{' '}
                  <NumberFormat
                    value={(_Producto.dblPrecioFinal * 30) / 100}
                    displayType={'text'}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    thousandSeparator={true}
                    prefix={'$ '}
                    suffix={' MXN'}
                  />
                </span>
              </div>

              <Button className="btn-pay" onClick={handleNavigateViewProPay}>
                Apartar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApartadoPrimerProcesoProduct
