import React, { useEffect, useState } from 'react'
import { Button,AppBar,Paper, Box,Dialog, Toolbar,Grid,Slide,Typography } from "@mui/material";

import BlCard from "../../../Util/BlCard";
import BlLabelInfo from "../../../Util/BlLabelInfo";
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import noimg from '../../../Images/85488.png'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Articulos (props)  {
    const [_UrlImage, setUrlImage] = useState(false)
    const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const [open2, setOpen2] = useState(false);
	const handleClickOpen = () => {
		setOpen2(true);
	};
	const handleClose = () => {
        setUrlImage(false)
		setOpen2(false);
	};
  return (
    <Grid container>

    {props.Registro.map((row, index) => (
        <Grid container>
            <Grid item xs={12}>
            
        <BlCard title="Información del artículo" color={'secondary'}>
            <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
                    <BlLabelInfo label="Servicio:" inline>
                        {row.bolServicio === true ? 'Empeño' : 'Consigna'}  
                    </BlLabelInfo>                               
                </Grid>
                <Grid item xs={12} md={3}>
                    <BlLabelInfo label="Artículo:" inline>
                        {row.articulo}  
                    </BlLabelInfo>                               
                </Grid>
                
                <Grid item xs={12} md={3}>
                    <BlLabelInfo label="Marca:" inline>                       
                        {row.marca}
                    </BlLabelInfo>                               
                </Grid>
                <Grid item xs={12} md={3}>
                    <BlLabelInfo label="Otra marca:" inline>                       
                        {row.txtOtraMarca === null ? '' : row.txtOtraMarca}
                    </BlLabelInfo>                               
                </Grid>
                <Grid item xs={12} md={3}>
                    <BlLabelInfo label="Comentario:" inline>                       
                        {row.txtComentario === null ? '' : row.txtComentario}
                    </BlLabelInfo>                               
                </Grid>                
                              
            </Grid>            
        </BlCard> 
        </Grid>
        <Grid item xs={12}>
        <BlCard title="Imagenes del artículo" color={'secondary'}>               
        <Grid container spacing={1}>            
     
        <Grid item xs={12} md={2}>    
        <ImageListItem >
        <img
            src={`${row.txtUrlImg}?w=100&h=100&fit=crop&auto=format`}
            srcSet={`${row.txtUrlImg}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
            alt={"no img"}
            loading="lazy"
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; 
                currentTarget.src=noimg
              }}
        />
        <ImageListItemBar title={"Imagen 1"} subtitle={"BarteLuxe"}
            actionIcon={ <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}                           
                    onClick={function () {
                        setUrlImage(row.txtUrlImg)
                        handleClickOpen()
                    }} 
                > <VisibilityIcon /> </IconButton>                        
            }
        />
        </ImageListItem>
        </Grid>
        <Grid item xs={12} md={2}>    
        <ImageListItem >
        <img
            src={`${row.txtUrlImg2}?w=100&h=100&fit=crop&auto=format`}
            srcSet={`${row.txtUrlImg2}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
            alt={"no img"}
            loading="lazy"
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; 
                currentTarget.src=noimg
              }}
        />
        <ImageListItemBar title={"Imagen 2"} subtitle={"BarteLuxe"}
            actionIcon={ <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}                           
                    onClick={function () {
                        setUrlImage(row.txtUrlImg2)
                        handleClickOpen()
                    }} 
                > <VisibilityIcon /> </IconButton>                        
            }
        />
        </ImageListItem>
        </Grid>
        </Grid>

    </BlCard>
            </Grid>
        </Grid>
    ))}
            <Dialog
				fullScreen
				open={open2}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative' }} className='none-shadow back-white'>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon className='font-dark'/>
						</IconButton>
					</Toolbar>
				</AppBar>
				<div className='center flex'>
					<img src={_UrlImage} width="600px" height="600px"  onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src=noimg
                      }} sx={{  objectFit: 'cover'}}  />
                   
				</div>
			</Dialog>    
    </Grid>
);
}