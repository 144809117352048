import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/faqsMB.css'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'

const Accordionn = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    border: '2px solid #E5E7E0',
    borderRadius: 4,
  },
  '&::before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    backgroundColor: '#757D65',
  },
}))

const AccordionSummaryy = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    color: '#fff',
  },
  '& .MuiTypography-root': {
    // color: "#000",
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
}))

const FqSectionEmpeños = (props) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <section className="FaqsSectionVentas">
      <div className="contentFaqs">
        <span className="titlefqs">Empeños</span>
        <Accordionn
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Qué artículos puedo empeñar?</Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Podrás empeñar algunos bolsos en perfecto estado y zapatos
                nuevos. (Solo algunas marcas).
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Qué marcas aceptan?</Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Para empeño aceptamos sólo marcas Luxury como Balenciaga,
                Chanel, Dior, Gucci, Hermes, Louis Vuitton, Fendi, Dolce &
                Gabbana, Yves Saint Laurent, entre otras. (Si tienes dudas
                respecto a alguna marca en específico contáctanos en el
                siguiente correo: contacto@founditpreloved.com).
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Cuál es el proceso para empeñar mis artículos?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Cómo empeñar tus artículos <br />
                1.Accede a la plataforma: Visita www.founditmembers.com <br />
                2. Completa el formulario: Responde el formulario y sube de 5 a
                8 fotos claras de tu artículo, mostrando todos los ángulos y
                cualquier defecto.
                <br />
                3. Espera la propuesta: Recibirás una propuesta de nuestro
                valuador calificado en 24 a 48 horas.
                <br />
                4. Agrega una cita: Si aceptas la propuesta, agenda una cita en
                nuestras oficinas en Ciudad de México. <br />
                5. Valuación final y depósito: En la cita, se determinará el
                valor final y se autentificará el artículo. El depósito se
                realiza de inmediato al aceptar los artículos.
                <br />
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Cómo puedo pagar mi refrendo (intereses)?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Para poder pagar tu refrendo, tendrás que ir a tu perfil de
                Foundit Members desde ahí podrás realizar el pago
                correspondiente a tu refrendo. También puedes acudir
                directamente a nuestras instalaciones y realizar tu pago.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Cómo puedo liquidar mi empeño?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Para poder liquidar tu empeño, deberás realizar el pago por tu
                interés y el monto de préstamo desde tu perfil en el sitio web
                www.founditmembers.com y posteriormente agendar tu cita para
                recoger tú artículo en oficinas. También puedes acudir a
                realizar pago y recolección directo en nuestra oficina.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <span></span>
      </div>
    </section>
  )
}

export default FqSectionEmpeños
