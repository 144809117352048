import React, { useEffect, useState } from 'react'
import '../../../Styles/Foundit/apartadoMB.css'
import Button from '@mui/material/Button'
import { Fade } from 'react-awesome-reveal'
// import LoginMb2 from '../../Login/LoginMb2'
import RegisterMb2 from '../../Login/RegisterMb2'
import {
  gtmCloseMundoBarterUNL,
  gtmOpenLoginUNL,
  gtmCloseLoginUNL,
  gtmOpenRegisterUNL,
  gtmCloseRegisterUNL,
} from '../../../Constants/GoogleTagManager/gtmConstants'
const ASection2Web = (props) => {
  const [openModalMB, setOpenModalMB] = useState(false)
  const handleCloseModalMB = () => {
    gtmCloseMundoBarterUNL()
    setOpenModalMB(false)
  }
  //============== OPEN LOGIN =========================
  const [openLogin, setOpenLogin] = useState(false)
  const handleOpenlogin = () => {
    gtmOpenLoginUNL()
    setOpenLogin(!openLogin)
  }
  const handleCloseLogin = () => {
    gtmCloseLoginUNL()
    setOpenLogin(false)
  }

  //============== OPEN REGISTRO=========================
  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    gtmCloseRegisterUNL()
    setOpenSingUp(false)
  }
  return (
    <section className="ApartadoSection2MB">
      <div className="cardESection3">
        <p>¿Cómo funciona?</p>
        <div className="ContainerInstructions">
          <Fade cascade damping={0.6} triggerOnce>
            {/* ********************* */}
            <div className="cardinstructions">
              <div className="information">
                <span className="number">1</span>

                <span className="text">
                  Registrate en{' '}
                  <a
                    onClick={function () {
                      handleOpenSingUp()
                    }}
                  >
                    esta página
                  </a>{' '}
                  y crea un usuario
                </span>
              </div>
              <div className="contentimg"></div>
            </div>
            {/* ********************* */}
            <div className="cardinstructions">
              <div className="contentimg2"></div>
              <div className="information">
                <span className="number">2</span>

                <span className="text">
                  Ve a nuestra tienda y reserva con 30% del valor
                </span>
              </div>
            </div>
            {/* ********************* */}
            <div className="cardinstructions">
              <div className="information">
                <span className="number">3</span>

                <span className="text">
                  Realiza pagos quincenales, contarás con hasta 4 quincenas para
                  liquidar
                </span>
              </div>
              <div className="contentimg3"></div>
            </div>
            {/* ********************* */}
            <div className="cardinstructions">
              <div className="contentimg4"></div>
              <div className="information">
                <span className="number">4</span>

                <span className="text">
                  Recoge tu artículo en nuestro Showroom
                </span>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      {/* <LoginMb open={openLogin} onClose={handleCloseLogin} props={props} /> */}
      <RegisterMb2
        open={openSingUp}
        onClose={handleCloseSingUp}
        props={props}
      />
    </section>
  )
}

export default ASection2Web
