import React from 'react'
import { Grid } from "@mui/material";
import BlCard from "../../Util/BlCard";
import BlLabelInfo from "../../Util/BlLabelInfo";
export default function Informacionchecklist(props) {
    return (
        <Grid container>
            <Grid item xs={12}>
                <BlCard title="Información de la preevaluación" color={'primary'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="Tiempo de uso:" variant="h6" inline>
                                {props.Registro.txtTiempoUso}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="Estado:" variant="h6" inline>
                                {props.Registro.txtEstado}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El artículo ha sido reparado?:" variant="h6" inline>
                                {props.Registro.bolReparado === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El artículo esta roto?:" variant="h6" inline>
                                {props.Registro.bolRoto === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El artículo esta manchado?:" variant="h6" inline>
                                Interior: {props.Registro.porManchadoInt} % Exterior: {props.Registro.porManchadoExt} %
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El artículo esta rayado?:" variant="h6" inline>
                                Interior: {props.Registro.porRayadoInt} % Exterior: {props.Registro.porRayadoExt} %
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿Qué porcentaje de limpieza tiene el artículo?:" variant="h6" inline>
                                {props.Registro.porLimpieza} %
                            </BlLabelInfo>
                        </Grid>
                    </Grid>
                </BlCard>

            </Grid>
            {/* accesorio */}
            <Grid item xs={12} hidden={props._Ocultar4}>
                <BlCard title="Información del accesorio" color={'secondary'}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="Accesorio:" inline>
                                {props.Registro.txtAccesorio}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El accesorio cuenta con estuche?:" inline>
                                {props.Registro.bolEstuche === null ? 'NA' : props.Registro.bolEstuche === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El accesorio cuenta con hebillas completas?:" inline>
                                {props.Registro.bolHebillascompletas === null ? 'NA' : props.Registro.bolHebillascompletas === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El accesorio esta arrugado?:" inline>
                                {props.Registro.bolArrugado === null ? 'NA' : props.Registro.bolArrugado === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El accesorio cuenta con pedreria?:" inline>
                                {props.Registro.bolPedreria === null ? 'NA' : props.Registro.bolPedreria === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El broche del accesorio funciona correctamente?:" inline>
                                {props.Registro.bolBrochefuncional === null ? 'NA' : props.Registro.bolBrochefuncional === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El accesorio es un reloj digital?:" inline>
                                {props.Registro.bolRelojDigita === null ? 'NA' : props.Registro.bolRelojDigita === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El accesorio funciona correctamente?:" inline>
                                {props.Registro.bolFuncionando === null ? 'NA' : props.Registro.bolFuncionando === false ? 'No' : 'Si'}
                            </BlLabelInfo>
                        </Grid>

                    </Grid>
                </BlCard>
            </Grid>
            {/* Bolsa _Ocultar2*/}
            <Grid item xs={12} hidden={props._Ocultar2}>
                <BlCard title="Información de la bolsa" color={'secondary'}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿La bolsa cuenta con certificado?:" inline>
                                {props.Registro.bolCertificado === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿La bolsa cuenta con alguno de los siguientes accesorios?:" inline>
                            </BlLabelInfo>
                            <BlLabelInfo label="Bandolera:" inline>
                                {props.Registro.bolBandolera === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                            <BlLabelInfo label="Candado:" inline>
                                {props.Registro.bolCandado === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                            <BlLabelInfo label="Dust Bag:" inline>
                                {props.Registro.bolDustBag === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                            <BlLabelInfo label="Llaveros monedero:" inline>
                                {props.Registro.bolLlaveMon === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿La bolsa cuenta con los cierres completos?:" inline>
                                {props.Registro.bolCierresCompletos === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿Los cierres de la bolsa funcionan correctamente?:" inline>
                                {props.Registro.bolCierresFuncionales === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿Los herrajes de la bolsa estan brillantes?:" inline>
                                {props.Registro.bolHerrajesBrillantes === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿La bolsa cuenta con pedreria completa?:" inline>
                                {props.Registro.bolPedreriaCompleta === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿La bolsa cuenta con pedreria completa?:" inline>
                                {props.Registro.bolPedreriaCompleta === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                    </Grid>
                </BlCard>
            </Grid>
            {/* JOYERIA _Ocultar3*/}
            <Grid item xs={12} hidden={props._Ocultar3}>
                <BlCard title="Información de joyeria" color={'secondary'}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="Tipo de joyeria:" inline>
                                {props.Registro.txtJoyeria01}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="Material de la joyeria:" inline>
                                {props.Registro.txtJoyeria02}
                            </BlLabelInfo>
                        </Grid>
                    </Grid>
                </BlCard>
            </Grid>
            {/* ZAPATOS _Ocultar*/}
            <Grid item xs={12} hidden={props._Ocultar}>
                <BlCard title="Información de zapato" color={'secondary'}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El zapato cuenta con alguno de estos accesorios?:" inline>

                            </BlLabelInfo>
                            <BlLabelInfo label="Parche" inline>
                                {props.Registro.bolParche === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                            <BlLabelInfo label="Dust Bag" inline>
                                {props.Registro.bolDustBagZ === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                            <BlLabelInfo label="Repuesto de tapas" inline>
                                {props.Registro.bolRepuesto === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BlLabelInfo label="¿El zapato cuenta con la pedreria completa?:" inline>
                                {props.Registro.bolPedreria === true ? 'Si' : 'No'}
                            </BlLabelInfo>
                        </Grid>
                    </Grid>
                </BlCard>
            </Grid>
        </Grid>
    );
}