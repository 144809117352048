import axios from "axios";
import Dictionary from "./Dictionary";
//import { useDispatch, useSelector } from "react-redux";

const getBaseUrl = Dictionary.baseUrls.getBaseUrl;
const tok= JSON.parse(localStorage.getItem("userInfo"));

axios.interceptors.request.use(
    // (config) => {
    //     // const dispatch = useDispatch();

    //     // const userLogin = useSelector((state) => state.userLogin);
    //     // const { loading, error, userInfo } = userLogin;
    //   if (tok != null) {
    //     config.headers.Authorization = `Bearer ${tok.access_Token}`;
    //     return config;
    //   }
    // },
    // (error) => {
    //   return Promise.reject(error);
    // }
);

axios.interceptors.response.use(undefined, (error) => {
    if (error.message === "Network Error" && !error.response) {
        throw error.response;
    }
    const { status, headers } = error?.response;

    if (
        status === 401 &&
        headers["www-authenticate"]?.includes("The token expired")
    ) {
        window.sessionStorage.removeItem("token");
        return;
    }
    if (
        status === 401 &&
        headers["www-authenticate"]?.includes("invalid_token")
    ) {
        window.sessionStorage.removeItem("token");
        return;
    }
    if (status === 500) {
        console.log(error.response);
    }

    if (!headers["www-authenticate"]?.includes("The token expired"))
        throw error.response;
});

const responseBody = (response) => response?.data;

const requests = {
    get: (url, id = "") =>
        axios
            .get(!id ? url : `${url}/${id}`, {
                baseURL: getBaseUrl,
            })
            .then(responseBody),
    getBody: (url, body) =>
        axios
            .post(url, body, {
                baseURL: getBaseUrl,
            })
            .then(responseBody),
    post: (url, body, id = "") =>
        axios
            .post(!id ? url : `${url}/${id}`, body, {
                baseURL: getBaseUrl,
            })
            .then(responseBody),
    put: (url, body, id = "") =>
        axios
            .put(!id ? url : `${url}/${id}`, body, {
                baseURL: getBaseUrl,
            })
            .then(responseBody),
    delete: (url, id = "") =>
        axios
            .delete(!id ? url : `${url}/${id}`, {
                baseURL: getBaseUrl,
            })
            .then(responseBody),
    Url: getBaseUrl
};

export default requests;
