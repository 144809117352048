import {
    PAY_CREATE,
    PAY_SUCCESS,
    PAY_FAIL,
    REFRENDO_CREATE,
    REFRENDO_SUCCESS,
    REFRENDO_FAIL,
    PER_SHP_CREATE,
    PER_SHP_SUCCESS,
    PER_SHP_FAIL
} from '../../Constants/MundoBarterConstants'

const dataInicial = {
    monto: 0,
    saldo: 0,
    refrendo: 0,
    fee: 0,
    totalPagar: 0,
    saldoPendiente: 0
}

const refrendoInicial = {
    saldoActual: 0,
    refrendo: 0,
    fee: 0,
    totalPagar: 0
}

const paymentState = {
    error: "",
    session: null,
    orderRef: null,
    paymentDataStoreRes: null,
    config: {
        storePaymentMethod: true,
        paymentMethodsConfiguration: {
            ideal: {
                showImage: true,
            },
            card: {
                hasHolderName: true,
                holderNameRequired: true,
                name: "Credit or debit card",
                amount: {
                    value: 10000, // 100€ in minor units
                    currency: "EUR",
                },
                installmentOptions: {
                    visa: {
                        // Shows 1, 2, and 3 as the numbers of monthly installments that the shopper can choose.
                        values: [1, 2, 3],
                        // Shows regular and revolving as plans shoppers can choose.
                        plans: ['regular']
                    },
                    mc: {
                        // Shows 1 and 2 as the numbers of monthly installments that the shopper can choose.
                        values: [1, 2],
                        // Shows regular and revolving as plans that the shopper can choose.
                        plans: ['regular']
                    }
                },
                // Shows payment amount per installment.
                showInstallmentAmounts: true
            },
        },
        locale: "es_MX",
        showPayButton: true,
        clientKey: "test_7QLRJPHCGBDJDLB2MROZWLKGLIT7RU7N",
        environment: "test",
    },
}

export const abonoCreate = (state = { pay: { dataInicial } }, action) => {
    switch (action.type) {
        case PAY_CREATE:
            return { ...state, loading: false }
        case PAY_SUCCESS:
            return { loading: false, pay: action.payload }
        case PAY_FAIL:
            return { loading: false, pay: action.payload }
        default:
            return state
    }
}

export const refrendoCreate = (state = { refrendoInicial: { refrendoInicial } }, action) => {
    switch (action.type) {
        case REFRENDO_CREATE:
            return { ...state, loading: false }
        case REFRENDO_SUCCESS:
            return { loading: false, pay: action.payload }
        case REFRENDO_FAIL:
            return { loading: false, pay: action.payload }
        default:
            return state
    }
}

export const initiateCheckout = (state = { payment: { paymentState } }, action) => {
    switch (action.type) {
        case REFRENDO_CREATE:
            return { ...state, loading: false }
        case REFRENDO_SUCCESS:
            return { loading: false, pay: action.payload }
        case REFRENDO_FAIL:
            return { loading: false, pay: action.payload }
        default:
            return state
    }
}