import React, { useEffect, useState } from "react";
import "../../../Styles/App/HomeMB2.css";
import { Fade } from "react-awesome-reveal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ServicioApartado from "../../../Images/HomeMB2/ServicioApartado.png";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#CB8944",
    color: "#fff",
    // textTransform: "uppercase",
    fontWeight: "500",
    fontSize: window.screen.width <= 600 ? 12 : null,
    border: 0,
    paddingTop: 5,
    paddingBottom: 7,
    // borderRadius: '20px',
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#545454",
    // padding: window.screen.width <= 600 ? 13 : null,
    fontSize: window.screen.width <= 600 ? 11 : null,
    border: 0,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const Section3HomeWeb = (props) => {
  function createData(name, calories, fat) {
    return { name, calories, fat };
  }
  const rows = [
    createData("-", "$ 10,000.00 MXN", "70%"),
    createData("$ 10,001.00 MXN", "$ 20,000.00 MXN", "75%"),
    createData("$ 20,001.00 MXN", "$ 35,000.00 MXN", "80%"),
    createData("$ 35,001.00 MXN", "$ 100,000.00 MXN", "82%"),
    createData("$ 100,001.00 MXN", "+", "85%"),
  ];
  const columns = [
    {
      id: "CostoMinimo",
      label: "Costo Mínimo",
      radius: "0px 0px 0px 0px",
    },
    {
      id: "CostoMaximo",
      label: "Costo Máximo",
    },
    {
      id: "GananciaFinal",
      label: "Ganancia financiera",
      radius: "0px 0px 0px 0px",
    },
  ];
  return (
    <section className="ContainerSection3MB">
      <Fade direction="left" triggerOnce >
        {window.screen.width <= 600 ? (
          <p>Evaluamos el potencial de tu pieza</p>
        ) : null}
        <div className="CardSection3MB">
          <div className="section1-3MB">
            <img
              src={
                "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/DSC01229.png"
              }
              // src={ServicioApartado}
            />
          </div>
          <div className="section2-3MB">
            {window.screen.width <= 600 ? null : (
              <p>Evaluamos el potencial de tu pieza</p>
            )}

            <span>
              Nuestro experto evaluador te enviará una oferta personalizada para
              tu pieza, basada en su estado, demanda en el mercado, marca,
              modelo y material.
            </span>
            {window.screen.width <= 600 ? (
              <div></div>
            ) : (
              <div className="CardTableCalcula">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            // style={{ borderRadius: column.radius }}
                            className="headStyletxt"
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell className="headStyletxt">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell className="headStyletxt">
                            {row.calories}
                          </StyledTableCell>
                          <StyledTableCell className="headStyletxt">
                            {row.fat}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        </div>
        {window.screen.width <= 600 ? (
          <div className="CardTableCalcula">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        // style={{ borderRadius: column.radius }}
                        className="headStyletxt"
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell className="headStyletxt">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell className="headStyletxt">
                        {row.calories}
                      </StyledTableCell>
                      <StyledTableCell className="headStyletxt">
                        {row.fat}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div></div>
        )}
      </Fade>
    </section>
  );
};

export default Section3HomeWeb;
