/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import {
  Card,
  CardActions,
  CardContent,
  Button,
  CardMedia,
  Alert,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  PROPUESTA_LIST_BY_ID_CLIENTE,
  SEND_STATUSCRM_MB,
} from '../../../Constants/ApiConstants'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

import CitasAdd from '../../../Components/Preevaluaciones/Citas/CitasAdd'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import '../../../Styles/App/Apartado/Apartado.css'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import NumberFormat from 'react-number-format'
import InfoIcon from '@mui/icons-material/Info'
import { Chip } from '@mui/material'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import PreviewImg from '../../../Components/PreviewImg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import IconButton from '@mui/material/IconButton'
import CategoryIcon from '@mui/icons-material/Category'
import AlertInfo from '../../../Util/Alerts/AlertInfo'
import EmpeñosTerminosCondiciones from './EspeñosTerminosCondiciones'
import CpreparaTuEnvioModal from '../Consignas/CpreparaTuEnvioModal'
import Avatar from '@mui/material/Avatar'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import iconEmpeno from '../../../Images/MB-Empena/Empeno_Icon.png'
import { useNavigate } from 'react-router-dom'
import {
  CALENDAR_VIEW,
  CLIENTES_EMP_VIEW,
  ENVIO_PIEZAS_EMP_DHL,
  ENVIO_PIEZAS_EMP_OTRA_PAQUETERIA,
} from '../../../Constants/routesConstants'

import {
  gtmGoTo_AceptarPropuesta_Emp_Log,
  gtmGoTo_RechazaPropuesta_Emp_Log,
  gtmGoTo_NuevaCita_Log,
  gtm_OpenTerminosEmp_Log,
} from '../../../Constants/GoogleTagManager/gtmConstants'
import { TablePagination } from '@mui/material'
import * as locales from '@mui/material/locale'
import { createTheme, useTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'

const EmpeñoPropuestas = (props) => {
  const [_Propuestas, setPropuestas] = useState([])
  const [_IdPropuesta, setIdPropuesta] = useState()
  const [_Ocultar, setOcultar] = useState(true)
  const [_Ocultar2, setOcultar2] = useState(true)
  const [alertInfo, setAlertInfo] = useState(false)
  const [titleAlertInfo, setTitleAlertInfo] = useState('')
  const [bodyAlertInfo, setBodyAlertInfo] = useState('')
  const [nameProduct, setNameProduct] = useState([])
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const navigate = useNavigate()

  const handleCloseAlertInfo = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlertInfo(false)
  }
  const getPropuestas = () => {
    props.setOpenLoadingScreen()
    requests
      .get(PROPUESTA_LIST_BY_ID_CLIENTE, userInfo.intIdUsuario)
      .then((response) => {
        console.log(response)
        setPropuestas(response)
        if (response.length > 0) {
          setOcultar(false)
          setOcultar2(true)
        } else {
          setOcultar(true)
          setOcultar2(false)
        }
        props.setCloseLoadingScreen()
        setIdPropuesta(null)
      })
      .catch((error) => {
        console.log('Error: ' + error)
        props.setCloseLoadingScreen()
      })
  }

  useEffect(() => {
    getPropuestas()
  }, [])

  const handleChangeSendStatusRechazado = (idRegistro) => {
    props.setOpenLoadingScreen()
    var url = SEND_STATUSCRM_MB.replace('{LngIdRegistro}', idRegistro).replace(
      '{IntIdStatusCrm}',
      'c2ae1194-2335-4987-bb96-5897fe028365'
    )
    requests
      .get(url)
      .then((response) => {
        props.setTypeSnackBar('success')
        //rechazo
        props.setMessageAlertWarning(
          'Lamentamos que hayas tomado esta decisión, esperamos vuelvas pronto a Foundit. Te recordamos que tenemos disponibles para ti los siguientes servicios: Consigna y Empeño.'
        )
        props.setOpenAlertWarning(true)
        // window.location.reload()
        gtmGoTo_RechazaPropuesta_Emp_Log()
        getPropuestas()
        props.setCloseLoadingScreen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }
  const handleChangeSendStatusCita = (idRegistro) => {
    props.setOpenLoadingScreen()
    var url = SEND_STATUSCRM_MB.replace('{LngIdRegistro}', idRegistro).replace(
      '{IntIdStatusCrm}',
      'c10d01a9-a2fc-4cbb-9f3a-1a7a5b9e8b61'
    )
    requests
      .get(url)
      .then((response) => {
        props.setTypeSnackBar('success')
        //Exito
        gtmGoTo_AceptarPropuesta_Emp_Log()
        props.setMessageAlert(response.mensaje)
        props.setOpenAlert(true)
        props.setCloseLoadingScreen()
        getPropuestas()
        // handleOpen()
      })
      .catch((error) => {
        props.setCloseLoadingScreen()
      })
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  const openCalendar = (idPropuestas) => {
    gtmGoTo_NuevaCita_Log()
    navigate(`${CALENDAR_VIEW}${idPropuestas}`)
  }

  //abrir modal img
  const [IdImgPreview, setIdImgPreview] = useState()
  const [openModalImgPreview, setModalImgPreview] = useState(false)
  const handleOpenImgPreview = () => {
    setModalImgPreview(!openModalImgPreview)
  }

  //abrir modal Terms y Condiciones

  const [openModalTerminos, setModalTerminos] = useState(false)
  const handleOpenTerminos = () => {
    gtm_OpenTerminosEmp_Log()
    setModalTerminos(!openModalTerminos)
  }

  const [openModalPreparaEnvios, setModalPreparaEnvios] = useState(false)
  const handleOpenPreparaEnvio = () => {
    //gtm_OpenTerminos_Log()
    setModalPreparaEnvios(!openModalPreparaEnvios)
  }

  /*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION INICIO	::::::::::::::::::::::::::::::::::::::::::::::: */
  const [locale, setLocale] = React.useState('esES')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const theme = useTheme()
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  )
  /*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION FIN	::::::::::::::::::::::::::::::::::::::::::::::: */
  return (
    <div>
      <div className="containerViewUser py4 paddingTablets" hidden={_Ocultar}>
        {/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
        <div className="flex-start w90 pb2 pl1">
          <div className="chip-breadcrumbs">
            <span className="letter-breadcrumbs">E</span>
          </div>

          <div role="presentation" className="ml2">
            <Breadcrumbs aria-label="breadcrumb" separator="›">
              <Link
                underline="hover"
                className="breadcrumb-url "
                onClick={() => {
                  navigate(-1)
                }}
              >
                Empeño
              </Link>
              <Link underline="hover" className="breadcrumb-url bold">
                Propuestas Recibidas
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        {_Propuestas.length === 0 ? null : (
          <div className="box-consigna-pro">
            {_Propuestas
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <div className="box-list-consigna-pro">
                  <div className="w20 relative">
                    <img
                      className="img-consigna-pro"
                      src={row.txtUrlImgFrente}
                    />
                    <div className="box-view-absolute">
                      <IconButton
                        className="btn-blur"
                        onClick={function () {
                          setIdImgPreview(row.txtUrlImgFrente)
                          handleOpenImgPreview()
                        }}
                      >
                        <RemoveRedEyeIcon
                          className="RemoveEyeIconConsigna"
                          sx={{ m: 0, p: 0, color: '#000000' }}
                        />
                      </IconButton>
                    </div>
                  </div>
                  <div className="flex-column-start-between w80 pl1">
                    <div className="w100">
                      <div className="flex-between">
                        <span className="titleConsignaPropuestas upperCase ProductTitleMarca">
                          {row.txtArticulo} {row.txtMarca} {row.txtOtraMarca}
                          <span className="mx1"> </span>
                          <span className="p titleConsignaPropuestas ProductTitleMarca">
                            {/* {row.txtTipoServicio} */}
                          </span>
                        </span>

                        <span className="p textFechaConsignaPropuestas co-grey ">
                          {row.fecPropuestas}
                        </span>
                      </div>

                      <span className="p textFechaConsignaPropuestas ProductTitleModelo bold">
                        <NumberFormat
                          value={row.dblMontoPropuesta}
                          displayType={'text'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          thousandSeparator={true}
                          prefix={'$ '}
                          suffix={'MXN'}
                        />
                      </span>
                    </div>
                    {row.txtTipoServicio === 'Empeña desde otro estado' ? (
                      <>
                        <div className="flex-between w100">
                          <div
                            className={
                              window.screen.width <= 600 ? 'auto' : 'auto'
                            }
                            style={{ width: '100%' }}
                          >
                            {row.bolUpdateMb === true &&
                            row.bolAprobacion === true ? (
                              <>
                                <Button
                                  className="btn-actions mr1 ProductTitleDescription mt1"
                                  startIcon={<LocalShippingIcon />}
                                  onClick={function () {
                                    setIdPropuesta(row.lngIdPropuestas)
                                    //openCalendar(row.lngIdPropuestas)
                                    navigate(
                                      ENVIO_PIEZAS_EMP_OTRA_PAQUETERIA +
                                        row.lngIdPropuestas
                                    )
                                  }}
                                >
                                  Enviar por mi cuenta
                                </Button>
                                <Button
                                  className="btn-success mr1 ProductTitleDescription mt1"
                                  startIcon={<LocalShippingIcon />}
                                  onClick={function () {
                                    setIdPropuesta(row.lngIdPropuestas)
                                    navigate(
                                      ENVIO_PIEZAS_EMP_DHL + row.lngIdPropuestas
                                    )
                                  }}
                                >
                                  Solicitar guía de envío
                                </Button>

                                <div className="textFechaConsignaPropuestas">
                                  {window.screen.width <= 600 ? (
                                    <div className="flex-columnn-end">
                                      <IconButton
                                        onClick={() => {
                                          // calcula(
                                          // 	row.dblMontoPropuesta,
                                          // 	row.txtArticulo,
                                          // 	row.txtMarca
                                          // )
                                          handleOpenPreparaEnvio()
                                        }}
                                      >
                                        <LocalShippingIcon
                                          sx={{ color: '#757D65' }}
                                        />
                                      </IconButton>
                                    </div>
                                  ) : (
                                    <div className="flex-columnn-end">
                                      <div className="flex-row">
                                        <Button
                                          className="BtnTermConditions"
                                          sx={{
                                            color: '#5d5b5b',
                                            fontWeight: 'bold',
                                          }}
                                          onClick={() => {
                                            // calcula(
                                            // 	row.dblMontoPropuesta,
                                            // 	row.txtArticulo,
                                            // 	row.txtMarca
                                            // )
                                            handleOpenPreparaEnvio()
                                          }}
                                          variant="text"
                                          endIcon={
                                            <LocalShippingIcon
                                              sx={{ color: '#757D65' }}
                                            />
                                          }
                                        >
                                          ¿Cómo preparar mi envío?
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : row.bolUpdateMb === true &&
                              row.bolAprobacion === false ? (
                              <p>Has rechazado esta propuesta</p>
                            ) : row.bolUpdateMb === false &&
                              row.bolAprobacion === false ? (
                              <>
                                <Button
                                  className="btn-success mr1 ProductTitleDescription"
                                  startIcon={<CheckCircleOutlineOutlinedIcon />}
                                  onClick={function () {
                                    setIdPropuesta(row.lngIdPropuestas)
                                    //setTipoServicio(row.txtTipoServicio)
                                    handleChangeSendStatusCita(
                                      row.lngIdRegistro
                                    )
                                  }}
                                >
                                  Aceptar
                                </Button>
                                <Button
                                  className="btn-fail mr1 ProductTitleDescription"
                                  startIcon={<CancelOutlinedIcon />}
                                  onClick={function () {
                                    handleChangeSendStatusRechazado(
                                      row.lngIdRegistro
                                    )
                                  }}
                                >
                                  Rechazar
                                </Button>
                                <div className="textFechaConsignaPropuestas">
                                  {window.screen.width <= 600 ? (
                                    <div className="flex-columnn-end">
                                      <IconButton
                                        onClick={() => {
                                          // calcula(
                                          // 	row.dblMontoPropuesta,
                                          // 	row.txtArticulo,
                                          // 	row.txtMarca
                                          // )
                                          handleOpenTerminos()
                                        }}
                                      >
                                        <InfoOutlinedIcon
                                          sx={{ color: '#757D65' }}
                                        />
                                      </IconButton>
                                    </div>
                                  ) : (
                                    <div className="flex-columnn-end">
                                      <div className="flex-row">
                                        <Button
                                          className="BtnTermConditions"
                                          sx={{
                                            color: '#5d5b5b',
                                            fontWeight: 'bold',
                                          }}
                                          onClick={() => {
                                            handleOpenTerminos()
                                            setNameProduct({
                                              articulo: row.txtArticulo,
                                              marca: row.txtMarca,
                                              precioVenta:
                                                row.dblMontoPropuesta,
                                            })
                                          }}
                                          variant="text"
                                          endIcon={
                                            <InfoOutlinedIcon
                                              sx={{ color: '#757D65' }}
                                            />
                                          }
                                        >
                                          Ver propuesta a detalle
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex-between w100">
                          <div>
                            {row.bolUpdateMb === false ? (
                              <>
                                <Button
                                  className="btn-success mr1 ProductTitleDescription"
                                  startIcon={<CheckCircleOutlineOutlinedIcon />}
                                  onClick={function () {
                                    setIdPropuesta(row.lngIdPropuestas)

                                    handleChangeSendStatusCita(
                                      row.lngIdRegistro
                                    )
                                  }}
                                >
                                  Aceptar
                                </Button>
                                <Button
                                  className="btn-fail mr1 ProductTitleDescription"
                                  startIcon={<CancelOutlinedIcon />}
                                  onClick={function () {
                                    handleChangeSendStatusRechazado(
                                      row.lngIdRegistro
                                    )
                                  }}
                                >
                                  Rechazar
                                </Button>
                              </>
                            ) : row.bolAprobacion === false ? (
                              <>
                                <span className="p ProductTitleDescription textFechaConsignaPropuestas">
                                  Has rechazado esta propuesta
                                </span>
                              </>
                            ) : null}

                            {row.bolCitaAgendada === false ||
                            row.bolCitaAgendada === null ? (
                              <Button
                                style={{
                                  display:
                                    row.bolAprobacion === true ? null : 'none',
                                }}
                                className="btn-calendar mr1 ProductTitleDescription"
                                startIcon={<EventOutlinedIcon />}
                                onClick={function () {
                                  sessionStorage.setItem('service', 'Empeños')
                                  setIdPropuesta(row.lngIdPropuestas)
                                  openCalendar(row.lngIdPropuestas)
                                }}
                              >
                                Agendar cita
                              </Button>
                            ) : (
                              <Button
                                style={{
                                  display: 'none',
                                }}
                                className="btn-calendar mr1"
                                startIcon={<EventOutlinedIcon />}
                              >
                                Reagendar cita
                              </Button>
                            )}
                          </div>

                          <div className="textFechaConsignaPropuestas">
                            {window.screen.width <= 600 ? (
                              <IconButton
                                aria-label="Info"
                                onClick={() => {
                                  handleOpenTerminos()
                                  setNameProduct({
                                    articulo: row.txtArticulo,
                                    marca: row.txtMarca,
                                    precioVenta: row.dblMontoPropuesta,
                                  })
                                }}
                              >
                                <InfoOutlinedIcon sx={{ color: '#757D65' }} />
                              </IconButton>
                            ) : (
                              <Button
                                className="BtnTermConditions"
                                sx={{ color: '#5d5b5b', fontWeight: 'bold' }}
                                onClick={() => {
                                  handleOpenTerminos()
                                  setNameProduct({
                                    articulo: row.txtArticulo,
                                    marca: row.txtMarca,
                                    precioVenta: row.dblMontoPropuesta,
                                  })
                                }}
                                variant="text"
                                endIcon={
                                  <InfoOutlinedIcon sx={{ color: '#757D65' }} />
                                }
                              >
                                Ver propuesta a detalle
                              </Button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xl={12} className="flex">
                <ThemeProvider theme={themeWithLocale}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={_Propuestas.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div hidden={_Ocultar2}>
        <div className="containerViewUser">
          <Alert
            className="alert-no-data"
            icon={
              <InfoIcon fontSize="inherit" className="alert-icon-no-data" />
            }
          >
            Aún no cuentas con propuestas, sobre tus articulos.
          </Alert>
        </div>
      </div>
      <CitasAdd
        registroId={_IdPropuesta}
        open={open}
        onClose={handleOpen}
        getRegistros={getPropuestas}
        setOpenLoadingScreen={props.setOpenLoadingScreen}
        setCloseLoadingScreen={props.setCloseLoadingScreen}
        setOpenSnackBar={props.setOpenSnackBar}
        setMessageSnackBar={props.setMessageSnackBar}
        setTypeSnackBar={props.setTypeSnackBar}
        setMessageAlert={props.setMessageAlert}
        setOpenAlert={props.setOpenAlert}
        setMessageAlertWarning={props.setMessageAlertWarning}
        setOpenAlertWarning={props.setOpenAlertWarning}
      />
      <PreviewImg
        IdImgPreview={IdImgPreview}
        openModalImgPreview={openModalImgPreview}
        onCloseModalImgPreview={handleOpenImgPreview}
      />
      <AlertInfo
        openAlert={alertInfo}
        MensajeTitle={titleAlertInfo}
        MensajeBody={bodyAlertInfo}
        handleCloseAlert={handleCloseAlertInfo}
      />
      <EmpeñosTerminosCondiciones
        openModalImgPreview={openModalTerminos}
        onCloseModalImgPreview={handleOpenTerminos}
        nameProduct={nameProduct}
      />
      <CpreparaTuEnvioModal
        openModalImgPreview={openModalPreparaEnvios}
        onCloseModalImgPreview={handleOpenPreparaEnvio}
        nameProduct={nameProduct}
      />
    </div>
  )
}

export default EmpeñoPropuestas
