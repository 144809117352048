export const isNullOrEmpty = (value) => !value || value === "";

export const isErrorForm = (form) => {
    const errorValues = Object.values(form).map((x) => x.error);
    return errorValues.some((x) => x);
};

export const rxCorreo = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

export const rxCaracteresEsp = /^[^$%&|<>[\]{}°¬~^`¨¡?=/!'"]*$/;

export const IsValidId = (Uniqueidentifier) => {
    //^(?!.*00000000)[0-9A-Fa-f\-]{36}$
    const regex = /^(?!.*00000000)[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/gm;
    return regex.test(Uniqueidentifier);
};
export const hasSpecialCharacter = (value) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /[!"`#%&,: ;<>=@{ }~\$\(\)\*\+\/\\\?\[\]\^\|]+/;
    return regex.test(value);
};
export const NumberFormat = (numStr) => {
    if (numStr === '') return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    }).format(numStr);
  };