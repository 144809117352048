import React, { useEffect, useState } from 'react'
import { Collapse, Grid,Button } from "@mui/material";
import { PROPUESTA_LIST_BY_ID_PREEV, CITA_BY_ID_PREEVALUACION,CITA_DELETE} from '../../../Constants/ApiConstants'
import BlCard from "../../../Util/BlCard";
import BlLabelInfo from "../../../Util/BlLabelInfo";
import requests from '../../AxiosCalls/AxiosCall';
import NumberFormat from 'react-number-format'


export default function CitasDetails(props) {
    const [open, setOpen] = React.useState(true);
    const [_Registro, setRegistro] = useState([])
    const [_RegistroProp, setRegistroProp] = useState([])
    const [_ocultarCita, setOcultarCita] = useState(true)
    const [_ocultarProp, setOcultarProp] = useState(true)
    const [_lngIdCitas, setLngIdCitas    ] = useState()
    const [_EstatusCita, setEstatusCita] = useState('')

    const getDataCita = () => {
        if (props.idRegistro !== null) {
            requests
                .get(CITA_BY_ID_PREEVALUACION, props.idRegistro)
                .then((response) => {
                    setEstatusCita(response.txtStatusCita)
                    setLngIdCitas(response.lngIdCitas) 
                    localStorage.setItem('lngIdCitas',response.lngIdCitas )
                    setRegistro(response)
                    if (response !== "") {
                        setOcultarCita(false)
                    }

                })
                .catch((error) => {
                    props.setCloseLoadingScreen()
                })
        }
    }
    const getDataPropuesta = () => {
        if (props.idRegistro !== null) {
            requests
                .get(PROPUESTA_LIST_BY_ID_PREEV, props.idRegistro)
                .then((response) => {
                   
                    setRegistroProp(response)
                    if (response !== "") {
                        setOcultarProp(false)
                    }
                })
                .catch((error) => {
                    props.setCloseLoadingScreen()
                })
        }
    }
    useEffect(() => {
        getDataPropuesta()
        getDataCita()
    }, [])

    const BtnNoAsistio = () =>{
   props.Props.setOpenLoadingScreen()
   requests
   .delete(CITA_DELETE, _lngIdCitas)
   .then((response) => {
props.Props.setTypeSnackBar('success')
props.Props.setOpenSnackBar(true)
props.Props.setMessageSnackBar(response.mensaje )
props.Props.setCloseLoadingScreen()
   })
   .catch((error) => {
    props.Props.setCloseLoadingScreen()
   })

      
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Collapse in={open}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} hidden={_ocultarProp}>
                            <BlCard title="Propuesta" color={'secondary'}>
                                <BlLabelInfo label="Fecha envió propuesta:" inline>
                                    {_RegistroProp.fecPropuestas}
                                </BlLabelInfo>
                                <BlLabelInfo label="Monto propuesto:" inline>
                                    <NumberFormat
                                        value={_RegistroProp.dblMontoPropuesta}
                                        displayType={'text'}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={'$ '}
                                    //suffix={' MXN'}
                                    />
                                </BlLabelInfo>
                                <BlLabelInfo label="Estatus:" inline>
                                    {_RegistroProp.bolUpdateMb === true ? (
                                        <>
                                            {_RegistroProp.bolAprobacion === true ? 'Aceptado' : 'Rechazado'}
                                        </>
                                    ) : (
                                        <>
                                            Sin confirmación
                                        </>
                                    )}

                                </BlLabelInfo>
                   
                            </BlCard>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} hidden={_ocultarCita}>
                            <BlCard title="Cita" color={'secondary'}>
                                <BlLabelInfo label="Fecha Registro:" inline>
                                    {_Registro.fecRegCita}
                                </BlLabelInfo>
                                <BlLabelInfo label="Fecha Cita:" inline>
                                    {_Registro.fecCita}
                                </BlLabelInfo>
                                <BlLabelInfo label="Hora Cita:" inline>
                                    {_Registro.hrCita + ' hrs'}
                                </BlLabelInfo>
                                <BlLabelInfo label="Estatus Cita:" inline>
                                    {_Registro.txtStatusCita}
                                </BlLabelInfo>
                                <Button	style={{
									display: props.StatuActualPosion === 2 && _Registro.intStatusCita === 1 || _Registro.intStatusCita === 2 ? null : 'none',
								}}
									onClick={BtnNoAsistio}
									size='small'
									variant='contained'
									color='error'
								>
									El cliente no asistió
                              
								</Button>
                            </BlCard>
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
}