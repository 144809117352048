/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNavigate } from 'react-router-dom'
import { FLUJO_PAGO } from '../../../Constants/routesConstants'

import '../../../Styles/GeneralStyles'
import '../../../Styles/App/Apartado/ApartadoView.css'

let imgProducto =
	'https://cdn.shopify.com/s/files/1/0448/2665/6919/products/TOTE_DIOR__2_-removebg-preview_1024x1024@2x.png?v=1659049480'

const ApartadoProcesoProduct = (props) => {
	let navigate = useNavigate()

	const [expanded, setExpanded] = useState(false)

	const handleChange = (panel1) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel1 : false)
	}
	const handleNavigateViewProPay = () => {
		navigate(FLUJO_PAGO)
	}

	return (
		<div className='containerViewUser'>
			<div className='flex-column w70 '>
				<div className='box-proceso-producto '>
					<div className='w20 p2'>
						<img className='img-proceso-producto' src={imgProducto} />
					</div>
					<div className='flex-column-start w40 line-height-1'>
						<div>
							<div>
								<p className='title-proceso-producto'>TBolso Book Tote Bag</p>
								<p>
									<strong>Apartado con 15%:</strong> $ 8,100.00 MXN
								</p>
								<p>
									<strong>Valor Total:</strong>$ 54,000.00 MXN
								</p>
							</div>

							<p className='mt5'>
								<strong>Saldo Actual:</strong> $ 45,900 MXN
							</p>
						</div>
					</div>
					<div className='flex-column-start w40 line-height-0'>
						<p className='fs30'>Fecha limite de pago</p>
						<div className='flex-start gap3 mb3'>
							<div>
								<p className='bold'>Fecha de apartado</p>
								<p> 19 / Diciembre /2022</p>
							</div>
							<div>
								<p className='bold'>Fecha de liquidacion</p>
								<p>19 / Enero /2022</p>
							</div>
						</div>
						<Button className='btn-pay' onClick={handleNavigateViewProPay}>
							Abonar
						</Button>
					</div>
				</div>

				<div className='box-proceso-producto-list'>
					<div className='flex w95 ml2'>
						<div className='w25 text-center bold fs16 '>Abono #</div>
						<div className='w25 text-center bold fs16 '>Fecha</div>
						<div className='w25 text-center bold fs16 '>Concepto</div>
						<div className='w25 text-center bold fs16 '>Valor total</div>
						<div className='w25 text-center bold fs16 '>Abonos</div>
						<div className='w25 text-center bold fs16 '>Debes</div>
						<div className='w25 text-center bold fs16 '>Estado</div>
					</div>
					<div>
						<Accordion
							expanded={expanded === 'panel1'}
							onChange={handleChange('panel1')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls='panel1bh-content'
								id='panel1bh-header'
							>
								<div className='w25 text-center fs16 '>1</div>
								<div className='w25 text-center fs16 '>19 / Dic / 2022</div>
								<div className='w25 text-center fs16 '>Enganche</div>
								<div className='w25 text-center fs16 '>$ 54,000.00</div>
								<div className='w25 text-center fs16 '>$ 8,100.00</div>
								<div className='w25 text-center fs16 '>$ 45,800.00</div>
								<div className='w25 text-center fs16 '>
									<Button className='success'>Exitoso</Button>
									{/* <Button className='success'>Pagado</Button> */}
								</div>
							</AccordionSummary>

							<AccordionDetails>
								<div className='flex-column-end pb2 fs12'>
									<div className='flex w42 ml2 border-bottom-2 mb1'>
										<div className='w60  bold fs16 '>Concepto</div>
										<div className='w40  bold fs16 '>Total</div>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Valor del producto</p>
										<p className='w40'>$ 54,000.00</p>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Enganche</p>
										<p className='w40'>$ 8,100.00</p>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Cargo por servicios</p>
										<p className='w40'>$ 248.00</p>
									</div>

									{/* <div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Interes</p>
										<p className='w40'>$ 1,728.00</p>
									</div> */}

									{/* <div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>IVA</p>
										<p className='w40'>$ 13,608.00</p>
									</div> */}

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Total</p>
										<p className='w40'>$ 8,353.00</p>
									</div>

									<div className='flex w42 ml2 border-bottom-2 mb1 line-height-0'>
										<p className='w60'>Saldo pendiente</p>
										<p className='w40'>$ 45,900.00</p>
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ApartadoProcesoProduct
