/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import {
	CLIENTES_SECTION_PRO,
	CLIENTES_SECTION_VIEW,
} from '../../../Constants/routesConstants'
import { useDispatch, useSelector } from 'react-redux'
import { postLiquidarEmpCreate } from '../../../Actions/MundoBarterActions'

import {
	// EMPEÑO_BY_ID,
	// EMPEÑO_PAGO_LIST_BY_ID_EMPEÑO,
	GET_DET_APA_BY_IDCLIPRO,
	GET_LIST_PAG_APA_IDCLIPRO,
	GET_LIST_PAG_APA_ID,
	GET_APA_DET_BY_ID,
	GET_PAGADO_APA,
} from '../../../Constants/ApiConstants'

import requests from '../../../Components/AxiosCalls/AxiosCall'
import '../../../Styles/App/Empeño/EmpeñoView.css'
import NumberFormat from 'react-number-format'

const ClienteApartadoDetails2 = (props) => {
	const { id } = useParams()
	let navigate = useNavigate()
	const dispatch = useDispatch()
	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	const [_Parametrs, setParameters] = useState({
		idProd: id,
		idClient: userInfo.intIdUsuario,
	})

	const [_DataInfo, setDataInfo] = useState({})
	const [_Empeño, setEmpeño] = useState({})
	const [_Saldo, setSaldo] = useState({})
	const [_Abonos, setAbonos] = useState([])
	const [_OcultarFecha, setOcultarFecha] = useState(false)
	const [expanded, setExpanded] = useState(false)

	let date = new Date()
	let day = `${date.getDate()}`.padStart(2, '0')
	let month = `${date.getMonth() + 1}`.padStart(2, '0')
	let year = date.getFullYear()
	let fecActual = `${day}/${month}/${year}`

	const handleChange = (panel1) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel1 : false)
	}
	const getInfo = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_APA_DET_BY_ID, id)
			.then((response) => {
				setDataInfo(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const getSaldo = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_PAGADO_APA, id)
			.then((response) => {
				setSaldo(response)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	const [_Totales, setTotales] = useState({
		liquidacion: 0,
		regrendo: 0,
	})
	const calculo = (data) => {
		let pago = (data.dblCostoEmpe * 2.12) / 100 + 3
		let total = data.dblCostoEmpe + pago
		let re = (data.dblImporteDesemp * 20) / 100
		let pagos = (re * 2.12) / 100 + 3
		let pago2 = re + pagos
		setEmpeño(data)
		setTotales({ ..._Totales, liquidacion: total, regrendo: pago2 })
	}
	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)
	const getAbonos = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_LIST_PAG_APA_ID, id)
			.then((response) => {
				setAbonos(response)
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getInfo()
		getAbonos()
		getSaldo()
	}, [])

	return (
		<div className='containerViewUser py4 paddingTablets'>
			{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
			<div className='flex-start w90 pb2 pl1'>
				<div className='chip-breadcrumbs'>
					<span className='letter-breadcrumbs'>A</span>
				</div>

				<div role='presentation' className='ml2'>
					<Breadcrumbs aria-label='breadcrumb' separator='›'>
						<Link
							underline='hover'
							className='breadcrumb-url'
							onClick={() => {
								navigate(CLIENTES_SECTION_VIEW)
							}}
						>
							Apartado
						</Link>
						<Link
							underline='hover'
							className='breadcrumb-url '
							onClick={() => {
								navigate(CLIENTES_SECTION_PRO)
							}}
						>
							Mis Apartados
						</Link>
						<Link underline='hover' className='breadcrumb-url bold'>
							Detalle de pago
						</Link>
					</Breadcrumbs>
				</div>
			</div>
			<div className='CardApartadoDetailPagosCardStepper'>
				<div className='BodyCardApartadoDetail'>
					<div className='ApartadoDetailSection1'>
						<div
							className='BoxImgADetail'
							style={{
								backgroundImage: `url(${_DataInfo.txtUrl})`,
							}}
						></div>
					</div>
					<div className='ApartadoDetailSection2'>
						<h2>Gracias por realizar tu pago</h2>
						<hr className='Dvividerhr' />
						{/* ***************** */}
						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Producto:</span>{' '}
								{_DataInfo.txtCategoria} - {_DataInfo.txtMarca}
							</span>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>SKU:</span>{' '}
								{_DataInfo.txtSkuReg}
							</span>
						</div>
						{/* ***************** */}
						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Total a pagar:</span>{' '}
								<NumberFormat
									value={_DataInfo.dblPrecioFinal}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={'MXN'}
								/>
							</span>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Liquida antes del:</span>{' '}
								{_DataInfo.fecFinApartado}
							</span>
						</div>
						{/* ***************** */}
						<div className='containerFlexBetween'>
							<span className='bold upperCase'>
								<span className='subtitlesdetail'>Saldo pendiente:</span>{' '}
								<NumberFormat
									value={_DataInfo.dblPrecioFinal - _Saldo.saldoPendiente}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={'MXN'}
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
			{/* <div className='body-empeño'>
				<div className='head-empeño flex-between p3'>
					<div className='img-refrendo-Desk w20'>
						<img className='img-EmpeñoView' src={_DataInfo.txtUrl} />
					</div>

					<div className='refrendo-section3-pay'>
						<p className='title-section3-pay'>Total a pagar</p>
						<p className='subtitle-section3-pay'>
							<NumberFormat
								value={_DataInfo.dblPrecioFinal}
								displayType={'text'}
								fixedDecimalScale={true}
								decimalScale={2}
								thousandSeparator={true}
								prefix={'$ '}
								suffix={'MXN'}
							/>
						</p>
						<p className='title-section3-pay'>Saldo pendiente</p>
						<p className='subtitle-section3-pay'>
							<NumberFormat
								value={_DataInfo.dblPrecioFinal - _Saldo.saldoPendiente}
								displayType={'text'}
								fixedDecimalScale={true}
								decimalScale={2}
								thousandSeparator={true}
								prefix={'$ '}
								suffix={'MXN'}
							/>
						</p>
						<p>
							{_DataInfo.txtCategoria} - {_DataInfo.txtMarca}
						</p>
						<p>SKU: {_DataInfo.txtSkuReg}</p>
						<p className='title-section3-pay'>Gracias por realizar tu pago</p>
					</div>

					<div className='refrendo-img-desk-section2'>
						<img className='img-EmpeñoView' src={_DataInfo.txtUrl} />
					</div>

					<div className='refrendo-section1-pay'>
						<p className='title-ref-pay'>Liquida antes del: </p>
						<p className='subtitle-ref-pay'>{_DataInfo.fecFinApartado}</p>
					</div>
				</div>
			</div> */}
		</div>
	)
}

export default ClienteApartadoDetails2
