import React from 'react'
import Button from '@mui/material/Button'

const Successfull = (props) => {
	const handleNext = () => {
		props.handleNext()
	}

	const handleBack = () => {
		props.handleBack()
	}

	return (
		<div>
			<div>Resumen de compra</div>
			<div className='flex-between'>
				<Button onClick={handleBack}>Atras</Button>
				<Button onClick={handleNext}>siguiente</Button>
			</div>
		</div>
	)
}

export default Successfull
