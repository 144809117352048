import React, { useEffect, useState } from 'react'
import requests from '../../Components/AxiosCalls/AxiosCall'
import {
	PREEVALUACION_INFO,
	GET_LIST_STATUSCRM,
	SEND_STATUSCRM,
	PROPUESTA_LIST_BY_ID_PROP,
	CUESTIONARIO_NO_VIABLE,
	CITA_POST_CLIENTE_REALIZADO,
} from '../../Constants/ApiConstants'
import Theme from '../../Styles/Theme'
import { ThemeProvider } from '@mui/styles'
import { Chip, Grid, Button, Box } from '@mui/material'
import QuizIcon from '@mui/icons-material/Quiz'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useNavigate, useParams } from 'react-router-dom'
import { PREEVALIACIONES_VIEW } from '../../Constants/routesConstants'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import BlCard from '../../Util/BlCard'
import InformacionGeneral from './InformacionGeneral'
import Informacionchecklist from './Informacionchecklist'
import CitasDetails from './Citas/CitasDetails'
import PreviewImg from './PreviewImg'
import { StatusCRM } from '../../Util/Enums'
import PropuestaAdd from '../Preevaluaciones/Propuestas/PropuestaAdd'
import MotivoRechazo from './Rechazo/MotivoRechazo'


const ProcesoEvaluacion = (props) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [_Registro, setRegistro] = useState([])
	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)
	const [_Ocultar3, setOcultar3] = useState(true)
	const [_Ocultar4, setOcultar4] = useState(true)
	const [listStatusCrm, setListStatusCrm] = useState([])
	const [statuActual, setStatusActual] = useState('')
	const [statuActualPosion, setStatusActualPosion] = useState('')

	const getRegistros = () => {
		props.setOpenLoadingScreen()
		requests
			.get(PREEVALUACION_INFO, id)
			.then((response) => {
				console.log(response)
				setRegistro(response)
				setStatusActual(response.intIdStatusCrm)
				handleChangePosionStatusCrm(response.intIdStatusCrm.toUpperCase())
				//ZAPATOS
				if (response.intIdArticulo === 1) {
					setOcultar(false)
				}
				//BOLSAS
				if (response.intIdArticulo === 2) {
					setOcultar2(false)
				} //JOYERIA
				if (response.intIdArticulo === 3) {
					setOcultar3(false)
				} //ACCESORIOS
				if (response.intIdArticulo === 4) {
					setOcultar4(false)
				}

				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
		getStatuspro()
	}

	const getStatuspro = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GET_LIST_STATUSCRM)
			.then((response) => {
				setListStatusCrm(response.data)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getRegistros()
	}, [])

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	const [open2, setOpen2] = useState(false)
	const handleOpen2 = () => {
		setOpen2(!open2)
	}

	const [_IdNoViable, setIdNoViable] = useState({
		id: 0,
	})
	const handleChangeStatusCrm = (event) => {
		setStatusActual(event.target.value)
		handleChangePosionStatusCrm(event.target.value.toUpperCase())

		//SI ESTADO CRM ES PROPUESTA INICIAL
		if (event.target.value === '7258e679-842b-47a1-a66a-0c8aef63b69e') {
			props.setOpenLoadingScreen()
			requests
				.get(PROPUESTA_LIST_BY_ID_PROP, id)
				.then((response) => {
					if (response.length > 0) {
						///
					} else {
						handleOpen()
					}
					props.setCloseLoadingScreen()
				})
				.catch((error) => {
					props.setCloseLoadingScreen()
				})
		}
		//SI ESTADO CRM ES NO VIABLE
		if (event.target.value === '2f995852-c05d-43dd-9a47-080ef3447b02') {
			_IdNoViable.id = id
			props.setOpenLoadingScreen()
			requests
				.post(CUESTIONARIO_NO_VIABLE, _IdNoViable)
				.then((response) => {
					props.setTypeSnackBar('success')
					props.setMessageSnackBar(response.mensaje)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
					handleOpen2()
				})
				.catch((error) => {
					props.setTypeSnackBar('warning')
					props.setMessageSnackBar(error.mensaje)
					props.setOpenSnackBar(true)
					props.setCloseLoadingScreen()
				})
		}
	}

	const handleChangePosionStatusCrm = (valor) => {
		if (StatusCRM.Evaluacion_Virtual.id === valor) {
			setStatusActualPosion(StatusCRM.Evaluacion_Virtual.order)
		}
		if (StatusCRM.Propuesta_Inicial.id === valor) {
			setStatusActualPosion(StatusCRM.Propuesta_Inicial.order)
		}
		if (StatusCRM.Cita.id === valor) {
			setStatusActualPosion(StatusCRM.Cita.order)
		}
		// if (StatusCRM.Cita.id === valor) {
		// 	setStatusActualPosion(StatusCRM.Cita.order)
		// }
		if (StatusCRM.Evaluacion_Presencial.id === valor) {
			setStatusActualPosion(StatusCRM.Evaluacion_Presencial.order)
			// getRegistros()
		}
		if (StatusCRM.Propuesta_Final.id === valor) {
			setStatusActualPosion(StatusCRM.Propuesta_Final.order)
		}
		if (StatusCRM.Contrato.id === valor) {
			setStatusActualPosion(StatusCRM.Contrato.order)
		}
		if (StatusCRM.Publicacion.id === valor) {
			setStatusActualPosion(StatusCRM.Publicacion.order)
		}
		if (StatusCRM.Negociacion.id === valor) {
			setStatusActualPosion(StatusCRM.Negociacion.order)
		}
		if (StatusCRM.Venta.id === valor) {
			setStatusActualPosion(StatusCRM.Venta.order)
		}
		if (StatusCRM.Entrega_Exitosa.id === valor) {
			setStatusActualPosion(StatusCRM.Entrega_Exitosa.order)
		}
		if (StatusCRM.Deposito_Exitoso.id === valor) {
			setStatusActualPosion(StatusCRM.Deposito_Exitoso.order)
		}
		if (StatusCRM.Producto_Devuelto.id === valor) {
			setStatusActualPosion(StatusCRM.Producto_Devuelto.order)
		}
		if (StatusCRM.Evaluacion_Fallida.id === valor) {
			setStatusActualPosion(StatusCRM.Evaluacion_Fallida.order)
		}
		if (StatusCRM.No_Es_Viable.id === valor) {
			setStatusActualPosion(StatusCRM.No_Es_Viable.order)
		}
		if (StatusCRM.NoAcepta_Propuesta.id === valor) {
			setStatusActualPosion(StatusCRM.NoAcepta_Propuesta.order)
		}
		handleChangeSendStatus(valor)
	}

	const handleChangeSendStatus = (valor) => {
		//SI ESTADO CRM ES EVALUACIÓN PRESENCIAL

		var url = SEND_STATUSCRM.replace('{LngIdRegistro}', id).replace(
			'{IntIdStatusCrm}',
			valor
		)
		requests
			.get(url)
			.then((response) => {
				props.setCloseLoadingScreen()

				if (valor.toLowerCase() === 'e91af4a9-fc56-4112-9e93-113a48bb8ef3') {
					const id = localStorage.getItem('lngIdCitas')
					requests
						.delete(CITA_POST_CLIENTE_REALIZADO, id)
						.then((response) => {
							console.log(response)
						})
						.catch((error) => {
							console.log(error)
						})
				}
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	const steps = [
		'Evaluación virtual',
		'Propuesta Inicial',
		'Cita',
		'Evaluacion Presencial',
		'Propuesta Final',
		'Contrato',
		'Publicación',
		'Negociación',
		'Venta',
		'Entrega Exitosa',
		'Producto Devuelto',
		'Evaluacion Fallida',
		'No es Viable',
		'No Acepta Propuesta',
	]

	return (
		<ThemeProvider theme={Theme}>
			<Box p={2}>
				<Grid container spacing={2}>
					<Grid
						item
						xs={4}
						sm={4}
						md={4}
						lg={12}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<Button
							className='back-gradient-4 bord-radius-10 shadow font-letter font-light'
							startIcon={<ArrowBackIosIcon />}
							onClick={function () {
								navigate(PREEVALIACIONES_VIEW)
							}}
						>
							Regresar
						</Button>
						{/* <Chip icon={<InventoryIcon />} label='Productos / Add' color='primary' className={classes.nombrepage}/> */}
						<Chip
							icon={<QuizIcon />}
							label=' Preevaluaciones'
							color='primary'
							className='back-transparent font-letter font-barter font-20 ml-10'
						/>
					</Grid>
					<Grid item xs={12}>
						<InformacionGeneral Registro={_Registro} />
					</Grid>
					<Grid item xs={12}>
						<BlCard title='Seguimiento de Negociación' color={'primary'}>
							<Grid item xs={12} sm={12} md={6} lg={2} sx={{ m: 2 }}>
								<FormControl
									color='primary'
									variant='standard'
									size='small'
									fullWidth
								>
									<InputLabel id='lngIdStatusCrm'>Estado Crm</InputLabel>
									<Select
										fullWidth
										labelId='lngIdStatusCrm'
										id='lngIdStatusCrm'
										value={statuActual}
										onChange={handleChangeStatusCrm}
										inputProps={{
											name: 'lngIdStatusCrm',
											id: 'lngIdStatusCrm',
										}}
										label='Estatus Crm'
									>
										<MenuItem value='' />
										{listStatusCrm.map((object, index) => (
											<MenuItem value={object.lngIdStatusCrm}>
												{object.txtNombre}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Box sx={{ width: '100%' }}>
								<Stepper activeStep={statuActualPosion} alternativeLabel>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel>{label}</StepLabel>
										</Step>
									))}
								</Stepper>
							</Box>
						</BlCard>
					</Grid>

					<Grid item xs={12}>
						<CitasDetails
							Registro={_Registro}
							idRegistro={id}
							StatuActualPosion={statuActualPosion}
							Props={props}
						/>
					</Grid>

					<Grid item xs={12}>
						<Informacionchecklist
							Registro={_Registro}
							_Ocultar={_Ocultar}
							_Ocultar2={_Ocultar2}
							_Ocultar3={_Ocultar3}
							_Ocultar4={_Ocultar4}
						/>
					</Grid>
					<Grid item xs={12}>
						<PreviewImg
							UrlImgFrente={_Registro.txtUrlImgFrente}
							UrlImgAtras={_Registro.txtUrlImgAtras}
							UrlImgIzqIzquierda={_Registro.txtUrlImgIzqIzquierda}
							UrlImgDerecha={_Registro.txtUrlImgDerecha}
							UrlImgDentro={_Registro.txtUrlImgDentro}
							UrlImgDetalle={_Registro.txtUrlImgDetalle}
						/>
					</Grid>
				</Grid>
			</Box>
			<PropuestaAdd
				open={open}
				idRegistro={id}
				folio={_Registro.txtFolio}
				onClose={handleOpen}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
			<MotivoRechazo
				open={open2}
				idRegistro={id}
				folio={_Registro.txtFolio}
				onClose={handleOpen2}
				getRegistros={getRegistros}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
		</ThemeProvider>
	)
}

export default ProcesoEvaluacion
