import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MCWMQMF',
}
TagManager.initialize(tagManagerArgs)

////================== USARIOS NO LOGUEADOS ==================

//=_=_=_=_=_=_=_=_ PREEVALUACION =_=_=_=_=_=_=_=_
export const gtmPreevaluacion = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPreevaluacion',
      page: {
        path: '/con-emp',
        title: 'Formulario Preevaluacion',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ VENDE =_=_=_=_=_=_=_=_
export const gtmVendeUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisVentas',
      page: {
        path: '/consigna',
        title: 'Mis Ventas UNL',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ EMPEÑA =_=_=_=_=_=_=_=_
export const gtmEmpenaeUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisEmpenos',
      page: {
        path: '/empeno',
        title: 'Mis Empeños UNL',
      },
    },
  })
}
//=_=_=_=_=_=_=_=_ APARTAR =_=_=_=_=_=_=_=_
export const gtmApartaUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisApartados',
      page: {
        path: '/aparta',
        title: 'Mis Empeños UNL',
      },
    },
  })
}
//=_=_=_=_=_=_=_=_ APARTAR =_=_=_=_=_=_=_=_
export const gtmFacturacionUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewFacturacion',
      page: {
        path: '/facturacion',
        title: 'Facturacion UNL',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ TIENDA SHOPY =_=_=_=_=_=_=_=_
export const gtmGoShopyUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewShopify',
      page: {
        path: '/https://founditpreloved.com/',
        title: 'Redirect Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ TERMINNOS y CONDICIONES =_=_=_=_=_=_=_=_
export const gtmTerminosUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewTerminos',
      page: {
        path: '/terminos-condiciones',
        title: 'Terminos y condiciones',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ AVISO PRIVACIDAD =_=_=_=_=_=_=_=_
export const gtmAvisosUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewAvisoPriv',
      page: {
        path: '/avisos-privacidad',
        title: 'Aviso de privacidad',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ DEVOLUCIONES Y GARANTIAS SHOPY =_=_=_=_=_=_=_=_
export const gtmGoDevolucionesUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewDevolucionesShopify',
      page: {
        path: '/https://founditpreloved.com/pages/devoluciones-y-garantias',
        title: 'Redirect Devoluciones y garantias',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ FAQS SHOPY =_=_=_=_=_=_=_=_
export const gtmGoFaqsUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewFaqsShopify',
      page: {
        path: '/preguntas-frecuentes',
        title: 'FAQs',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ FACEBOOK =_=_=_=_=_=_=_=_
export const gtmGoFacebookUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewFacebook',
      page: {
        path: '/https://www.facebook.com/people/Barter-Luxe-MX/100064095422771/',
        title: 'Redirect Facebook',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ INSTAGRAM =_=_=_=_=_=_=_=_
export const gtmGoInstagramUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewInstagram',
      page: {
        path: '/https://www.instagram.com/barterluxe_/',
        title: 'Redirect Instagram',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ TIK TOK =_=_=_=_=_=_=_=_
export const gtmGoTikTokUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewTikTok',
      page: {
        path: '/https://www.tiktok.com/@barterluxe_',
        title: 'Redirect Tik Tok',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ HOME =_=_=_=_=_=_=_=_

export const gtmPageViewHomeUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageView',
      page: {
        path: '/home',
        title: 'MundoBarter Home',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CONOCER MAS =_=_=_=_=_=_=_=_

export const gtmGoConocerMasUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectConocerMasShopy',
      page: {
        path: 'https://founditpreloved.com/pages/nuestra-comunidad#tab-1-nuestra-comunidad',
        title: 'Nuestra comunidad Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ BOLSAS =_=_=_=_=_=_=_=_

export const gtmGoBolsasUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectBolsasShopy',
      page: {
        path: 'https://founditpreloved.com/collections/bolsas-1',
        title: 'Bolsas Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ZAPATOS =_=_=_=_=_=_=_=_

export const gtmGoZapatosUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectZapatosShopy',
      page: {
        path: 'https://founditpreloved.com/collections/zapatos-mujer',
        title: 'Zapatos Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ACCESORIOS =_=_=_=_=_=_=_=_

export const gtmGoAccesoriosUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectAccesoriosShopy',
      page: {
        path: 'https://founditpreloved.com/collections/accesorios',
        title: 'Accesorios Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ DISEÑADORES PREMIUM =_=_=_=_=_=_=_=_

export const gtmGoDisenadoresPremiunUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectDiseñadoresPremShopy',
      page: {
        path: 'https://founditpreloved.com/collections/disenadores#tab-premium',
        title: 'Diseñadores Premium Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ DISEÑADORES LUXURY =_=_=_=_=_=_=_=_

export const gtmGoDisenadoresLuxuryUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectDiseñadoresLuxuShopy',
      page: {
        path: 'https://founditpreloved.com/collections/disenadores#tab-luxury',
        title: 'Diseñadores Luxury Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MUJER =_=_=_=_=_=_=_=_

export const gtmGoMujerUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectMujerShopy',
      page: {
        path: 'https://founditpreloved.com/collections/mujer-2',
        title: 'Mujer Shopify',
      },
    },
  })
}
//=_=_=_=_=_=_=_=_ HOMBRE =_=_=_=_=_=_=_=_

export const gtmGoHombreUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectHombreShopy',
      page: {
        path: 'https://founditpreloved.com/collections/hombre-2',
        title: 'Hombre Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ LO MAS NUEVO =_=_=_=_=_=_=_=_

export const gtmGoLoMasNuevoUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectLoMasNuevoShopy',
      page: {
        path: 'https://founditpreloved.com/collections/new-arrivals',
        title: 'Lo más nuevo Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CERTIFICADOS =_=_=_=_=_=_=_=_

export const gtmGoCertificadosUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'redirectProcesoAuthShopy',
      page: {
        path: 'https://founditpreloved.com/pages/proceso-de-autentificacion',
        title: 'Proceso auth Shopify',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS EMPEÑOS =_=_=_=_=_=_=_=_
export const gtmMisEmpenosUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisEmpenos',
      page: {
        path: '/empeno',
        title: 'Mis Empenos UNL',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS VENTAS =_=_=_=_=_=_=_=_
export const GtmMisVentasUNL = () => {
  //const Inizialize = useContext(Context);
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisVentas',
      page: {
        path: '/consigna',
        title: 'Mis Ventas UNL',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ INFORMACION MUNDO BARTER =_=_=_=_=_=_=_=_

export const gtmOpenMundoBarterUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewOpenInfoMundoBarter',
      page: {
        path: '/InfoMundoBarter',
        title: 'Abrir MundoBarter Info',
      },
    },
  })
}

export const gtmCloseMundoBarterUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewCloseInfoMundoBarter',
      page: {
        path: '/InfoMundoBarter',
        title: 'Close MundoBarter Info',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ LOGIN =_=_=_=_=_=_=_=_

export const gtmOpenLoginUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewLogin',
      page: {
        path: '/Login',
        title: 'Abrir Login',
      },
    },
  })
}

export const gtmCloseLoginUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewLogin',
      page: {
        path: '/Login',
        title: 'Cerrar Login',
      },
    },
  })
}

export const gtmSendLoginUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewHomeMB',
      page: {
        path: '/Login',
        title: 'Send Login',
      },
    },
  })
}

export const gtmLogOut = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewHome',
      page: {
        path: '/home/',
        title: 'Log out',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ RESET PASSWORD =_=_=_=_=_=_=_=_

export const gtmOpenResetPassUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewLogin',
      page: {
        path: '/ResetPass',
        title: 'Abrir Resetear Contraseña',
      },
    },
  })
}

export const gtmCloseResetPassUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewResetPass',
      page: {
        path: '/ResetPass',
        title: 'Cerrar Resetear Contraseña',
      },
    },
  })
}

export const gtmSendResetPassUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewResetPass',
      page: {
        path: '/ResetPass',
        title: 'Send Resetear Contraseña',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ REGISTER =_=_=_=_=_=_=_=_

export const gtmOpenRegisterUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewRegister',
      page: {
        path: '/SingUp',
        title: 'Abrir Registro cliente',
      },
    },
  })
}

export const gtmCloseRegisterUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewRegister',
      page: {
        path: '/SingUp',
        title: 'Cerrar Registro cliente',
      },
    },
  })
}

export const gtmSendRegisterUNL = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sendRegister',
      page: {
        path: '/SingUp',
        title: 'Envio Registro cliente',
      },
    },
  })
}

////================== USARIOS LOGUEADOS ==================

//=_=_=_=_=_=_=_=_ CITAS VENTAS  =_=_=_=_=_=_=_=_
export const gtmGoTo_CitasVentas_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewCitasConsigna',
      page: {
        path: '/citas-mb/consigna',
        title: 'Citas consigna',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CITAS EMPEÑOS  =_=_=_=_=_=_=_=_
export const gtmGoTo_CitasEmpenos_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewCitasEmpenos',
      page: {
        path: '/citas-mb/empeno',
        title: 'Citas empeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CITAS DESEMPEÑOS  =_=_=_=_=_=_=_=_
export const gtmGoTo_CitasDesempeños_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewCitasDesempeno',
      page: {
        path: '/citas-mb/desempeno',
        title: 'Citas desempeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CITAS VENTAS VENCIDAS =_=_=_=_=_=_=_=_
export const gtmGoTo_CitasVentasVencidad_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewCitasVentasVencidas',
      page: {
        path: '/citas-mb/consigna-vencidas',
        title: 'Citas ventas vencidas',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ REAGENDAR CITA VENTA =_=_=_=_=_=_=_=_
export const gtmGoTo_ReagendarCita_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewReagendarCitaVentas',
      page: {
        path: '/calendar-rsh/',
        title: 'Reagendar cita ventas',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CONFIRMAR CITA VENTA =_=_=_=_=_=_=_=_
export const gtmGoTo_ConfirmReagendarCita_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmReagendarCitaVentas',
      page: {
        path: '/calendar-rsh/',
        title: 'Confirmar Reagendar cita ventas',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ NO CONFIRMAR CITA VENTA =_=_=_=_=_=_=_=_
export const gtmGoTo_NoConfirmReagendarCita_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmReagendarCitaVentas',
      page: {
        path: '/calendar-rsh/',
        title: 'No confirma Reagendar cita ventas',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ AGENDAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_AgendarCitaDes_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewAgendarCitaDes',
      page: {
        path: '/calendar-mb/',
        title: 'Agendar cita desempeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ REAGENDAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_ReagendarCitaDes_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewReagendarCitadES',
      page: {
        path: '/calendar-rmb/',
        title: 'Reagendar cita desempeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CONFIRMAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_ConfirmarAgendarCitaDes_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmAgendarCitaDes',
      page: {
        path: '/calendar-sh/',
        title: 'Confirmar agendar cita desempeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ NO CONFIRMAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_NoConfirmAgendarCitaDes_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmAgendarCitaDes',
      page: {
        path: '/calendar-sh/',
        title: 'No confirma agendar cita desempeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CONFIRMAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_ConfirmReagendarCitaDes_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmReagendarCitaDes',
      page: {
        path: '/calendar-rsh/',
        title: 'Confirmar Reagendar cita desempeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ NO CONFIRMAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_NoConfirmReagendarCitaDes_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmReagendarCitaDes',
      page: {
        path: '/calendar-rsh/',
        title: 'No confirma Reagendar cita desempeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ AGENDAR CITA VENTA VENCIDA =_=_=_=_=_=_=_=_
export const gtmGoTo_AgendarCitaVenVencida_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewAgendarCitaVenVencida',
      page: {
        path: '/calendar-mb/',
        title: 'Agendar cita venta vencida',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ REAGENDAR CITA VENTA VENCIDA =_=_=_=_=_=_=_=_
export const gtmGoTo_ReagendarCitaVenVencida_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewReagendarCitaVenVencida',
      page: {
        path: '/calendar-rmb/',
        title: 'Reagendar cita venta vencida',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CONFIRMAR CITA VENTA VENCIDA =_=_=_=_=_=_=_=_
export const gtmGoTo_ConfirmarAgendarCitaVenVencida_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmAgendarCitaVenVencida',
      page: {
        path: '/calendar-rmb/',
        title: 'Confirmar agendar cita venta vencida',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ NO CONFIRMAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_NoConfirmAgendarCitaVenVencida_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmAgendarCitaVenVencida',
      page: {
        path: '/calendar-conven-mb/',
        title: 'No confirma agendar cita venta vencida',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CONFIRMAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_ConfirmReagendarCitaVenVencida_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmReagendarCitaVenVencida',
      page: {
        path: '/calendar-conven-rmb/',
        title: 'Confirmar Reagendar cita venta vencida',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ NO CONFIRMAR CITA DESEMPEÑO =_=_=_=_=_=_=_=_
export const gtmGoTo_NoConfirmReagendarCitaVenVencida_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConfirmReagendarCitaVenVencida',
      page: {
        path: '/calendar-conven-rmb/',
        title: 'No confirma Reagendar cita venta vencida',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ VENDE O EMPEÑA AQUI =_=_=_=_=_=_=_=_
export const gtmGoTo_EmpeñaAqui_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewVendeEmpena',
      page: {
        path: '/con-emp',
        title: 'Evaluacion Del Articulo',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS VENTAS =_=_=_=_=_=_=_=_
export const tmGoTo_MisVentas_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisVentas',
      page: {
        path: '/consigna-mb-view',
        title: 'Ventas',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS EMPEÑOS =_=_=_=_=_=_=_=_
export const gtmGoTo_MisEmpeños_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisEmpenos',
      page: {
        path: '/empeno-mb-view',
        title: 'Empeños',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS CITAS =_=_=_=_=_=_=_=_
export const gtmGoTo_MisCitas_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisCitas',
      page: {
        path: '/citas-mb',
        title: 'Citas',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ COMPRA EN NUESTRA TIENDA =_=_=_=_=_=_=_=_
export const gtmGoTo_Shopify_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewShopify',
      page: {
        path: 'https://founditpreloved.com',
        title: 'Tienda Shopify Barterluxe',
      },
    },
  })
}
//=_=_=_=_=_=_=_=_ COMPRA EN NUESTRA TIENDA =_=_=_=_=_=_=_=_
export const gtmGoTo_ShopifyCompra_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewShopifyCompra',
      page: {
        path: 'https://founditpreloved.com/collections/recien-llegados',
        title: 'Tienda Shopify Compra Barterluxe',
      },
    },
  })
}
//=_=_=_=_=_=_=_=_ PROCESO DE AUTENTICACIÓN =_=_=_=_=_=_=_=_
export const gtmGoTo_ProcesoAutenticacion_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewAuthenticationProcess',
      page: {
        path: 'https://founditpreloved.com/pages/proceso-de-autentificacion',
        title: 'Proceso de autenticacion Barterluxe',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ PERFIL =_=_=_=_=_=_=_=_
export const gtmGoTo_Profile_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewProfile',
      page: {
        path: '/perfil-mb',
        title: 'Perfil MundoBarter',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CERRAR SESION =_=_=_=_=_=_=_=_
export const gtmGoTo_LogOut_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewLogOut',
      page: {
        path: '/home',
        title: 'Cerrar sesión MundoBarter',
      },
    },
  })
}
//=_=_=_=_=_=_=_=_ COMPLETAR PERFIL  =_=_=_=_=_=_=_=_
export const tmCompletar_Perfil_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewRegister',
      page: {
        path: '/home',
        title: 'CompletarPefil',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS PROPUESTAS  =_=_=_=_=_=_=_=_
export const gtmGoTo_Propuestas_Emp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPropuestasEmpeno',
      page: {
        path: '/empeno_mb_propuestas',
        title: 'Propuestas empeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ REALIZAR PAGO  =_=_=_=_=_=_=_=_
export const gtmGoTo_RealizarPago_Emp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPagoEmpeno',
      page: {
        path: '/empeno-mb/',
        title: 'Realizar Pago',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ LIQUIDAR  =_=_=_=_=_=_=_=_
export const gtmGoTo_Liquidar_Emp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPagoLiqEmpeno',
      page: {
        path: '/pago-liquidar/',
        title: 'Realizar Pago',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ REFRENDAR  =_=_=_=_=_=_=_=_
export const gtmGoTo_Refrendar_Emp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPagoRefEmpeno',
      page: {
        path: '/pago-refrendo/',
        title: 'Realizar Pago',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ PROPUESTA ACEPTADA  =_=_=_=_=_=_=_=_
export const gtmGoTo_AceptarPropuesta_Emp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPropuestasEmpeno',
      page: {
        path: '/empeno_mb_propuestas',
        title: 'Cliente acepta propuesta',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ PROPUESTA RECHAZADA  =_=_=_=_=_=_=_=_
export const gtmGoTo_RechazaPropuesta_Emp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPropuestasEmpeno',
      page: {
        path: '/empeno_mb_propuestas',
        title: 'Cliente rechaza propuesta',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS ARTICULOS EN VENTA  =_=_=_=_=_=_=_=_
export const gtmGoTo_ArticulosEmpenados_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewArticulosEmpenados',
      page: {
        path: '/empeno_mb_productos',
        title: 'Mis articulos empeñados',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS REFRENDOS  =_=_=_=_=_=_=_=_
export const gtmGoTo_MisRefrendos_Emp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewMisRefrendos',
      page: {
        path: '/empeno_mb_refrendo',
        title: 'Refrendos',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS DESEMPEÑOS  =_=_=_=_=_=_=_=_
export const gtmGoTo_Desempenos_Emp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewDesempenos',
      page: {
        path: '/empeno_mb_desempeño',
        title: 'Articulos en desempeño',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ AGENDAR CITA  =_=_=_=_=_=_=_=_
export const gtmGoTo_NuevaCita_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewCalendario',
      page: {
        path: '/calendar/',
        title: 'Agendar cita',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ABRIR POP UP TERMINOS Y CONDICIONES  =_=_=_=_=_=_=_=_
export const gtm_OpenTerminosEmp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewTerminos',
      page: {
        path: '/termininos-condiciones-empeno',
        title: 'Abrir terminos y condiciones empeños',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ABRIR POP UP TERMINOS Y CONDICIONES  =_=_=_=_=_=_=_=_
export const gtm_CloseTerminosEmp_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewTerminos',
      page: {
        path: '/termininos-condiciones-empeno',
        title: 'Cerrar terminos y condiciones empeños',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS PROPUESTAS  =_=_=_=_=_=_=_=_
export const gtmGoTo_Propuestas_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPropuestasConsigna',
      page: {
        path: '/consigna-mb-propuestas',
        title: 'Propuestas consigna',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ PROPUESTA ACEPTADA  =_=_=_=_=_=_=_=_
export const gtmGoTo_AceptarPropuesta_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPropuestasConsigna',
      page: {
        path: '/consigna-mb-propuestas',
        title: 'Cliente acepta propuesta',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ PROPUESTA RECHAZADA  =_=_=_=_=_=_=_=_
export const gtmGoTo_RechazaPropuesta_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPropuestasConsigna',
      page: {
        path: '/consigna-mb-propuestas',
        title: 'Cliente rechaza propuesta',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ MIS ARTICULOS EN VENTA  =_=_=_=_=_=_=_=_
export const gtmGoTo_ArticulosVenta_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewArticulosVenta',
      page: {
        path: '/consigna-mb-piezas',
        title: 'Articulos en venta',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CONSIGNAS VENDIDAS  =_=_=_=_=_=_=_=_
export const gtmGoTo_ConsignasVendidas_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConsignasVendidas',
      page: {
        path: '/consigna-mb-venta',
        title: 'Articulos en venta',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CONSIGNAS VENDIDAS  =_=_=_=_=_=_=_=_
export const gtmGoTo_ConsignasRechazadas_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewConsignasRechazadas',
      page: {
        path: '/consigna-mb-rechazadas',
        title: 'Articulos en venta',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ABRIR POP UP TERMINOS Y CONDICIONES  =_=_=_=_=_=_=_=_
export const gtm_OpenTerminos_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewTerminos',
      page: {
        path: '/termininos-condiciones-ventas',
        title: 'Abrir terminos y condiciones consignas',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ABRIR POP UP TERMINOS Y CONDICIONES  =_=_=_=_=_=_=_=_
export const gtm_CloseTerminos_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewTerminos',
      page: {
        path: '/termininos-condiciones-ventas',
        title: 'Cerrar terminos y condiciones consignas',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ABRIR POP UP APLICAR DESCUENTO  =_=_=_=_=_=_=_=_
export const gtm_OpenDescuento_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewDescuentoConsigna',
      page: {
        path: '/consigna-mb-piezas',
        title: 'Abrir Aplicar Descuento',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ CERRAR POP UP APLICAR DESCUENTO  =_=_=_=_=_=_=_=_
export const gtm_CerrarDescuento_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewDescuentoConsigna',
      page: {
        path: '/consigna-mb-piezas',
        title: 'Cerrar Aplicar Descuento',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ SEND APLICAR DESCUENTO  =_=_=_=_=_=_=_=_
export const gtm_AplicarDescuento_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewDescuentoConsigna',
      page: {
        path: '/consigna-mb-piezas',
        title: 'Send Aplicar Descuento',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ACEPTA APLICAR DESCUENTO  =_=_=_=_=_=_=_=_
export const gtm_AceptaAplicarDescuento_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewDescuentoConsigna',
      page: {
        path: '/consigna-mb-piezas',
        title: 'Acepta Aplicar Descuento',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ RECHAZA APLICAR DESCUENTO  =_=_=_=_=_=_=_=_
export const gtm_RechazaAplicarDescuento_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewDescuentoConsigna',
      page: {
        path: '/consigna-mb-piezas',
        title: 'Rechaza Aplicar Descuento',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ VENDE O EMPEÑA AQUI =_=_=_=_=_=_=_=_
export const gtm_SendPreevaluacion_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sendPreevaluacion',
      page: {
        path: '/con-emp',
        title: 'Envio Preevaluacion Del Articulo',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ ENVIO OTRA PREEVALUACION =_=_=_=_=_=_=_=_
export const gtm_OtraPreevaluacion_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'resetPreevaluacion',
      page: {
        path: '/con-emp',
        title: 'Otra Preevaluacion Del Articulo',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ NO ENVIO OTRA PREEVALUACION =_=_=_=_=_=_=_=_
export const gtm_No_OtraPreevaluacion_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'noResetPreevaluacion',
      page: {
        path: '/con-emp',
        title: 'No Otra Preevaluacion Del Articulo',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ PERFIL  =_=_=_=_=_=_=_=_
export const gtmGoTo_Perfil_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPerfil',
      page: {
        path: '/perfil-mb',
        title: 'Perfil',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ HABILITAR PERFIL  =_=_=_=_=_=_=_=_
export const gtmGoTo_HabilitarEdicionPerfil_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPerfil',
      page: {
        path: '/perfil-mb',
        title: 'Habilitar/Desabilitar Edición Perfil',
      },
    },
  })
}

//=_=_=_=_=_=_=_=_ GUARDAR PERFIL  =_=_=_=_=_=_=_=_
export const gtmGoTo_SendEdicionPerfil_Log = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageViewPerfil',
      page: {
        path: '/perfil-mb',
        title: 'Send Edición Perfil',
      },
    },
  })
}
