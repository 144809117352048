/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Alert from '@mui/material/Alert'

import '../../Styles/App/Alert/alert.css'

const AlertsSuccess = (props) => {
	return (
		<div>
			<Snackbar
				open={props.openAlert}
				autoHideDuration={6000}
				onClose={props.handleCloseAlert}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert
					className='alert-success'
					onClose={props.handleCloseAlert}
					icon={<CalendarMonthIcon fontSize='inherit' sx={{ fontSize: 30 }} />}
				>
					{props.messageAlert}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default AlertsSuccess
