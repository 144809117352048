/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { AppBar, Dialog, Toolbar, Grid, Slide } from "@mui/material";
import BlCard from "../../Util/BlCard";
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import noimg from '../../Images/85488.png'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewImg(props) {


    const [_UrlImage, setUrlImage] = useState(false)
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setUrlImage(false)
        setOpen(false);
    };

    useEffect(() => {

    }, [])


    return (
        <Grid container>
            <Grid item xs={12}>
                <BlCard title="Listado de imagen" color={'primary'}>
                    <Grid container spacing={1}>

                        <Grid item xs={12} md={2}>
                            <ImageListItem >
                                <img
                                    src={`${props.UrlImgFrente}?w=100&h=100&fit=crop&auto=format`}
                                    srcSet={`${props.UrlImgFrente}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                                    alt={"no img"}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = noimg
                                    }}
                                />
                                <ImageListItemBar title={"Imagen Frontal"} subtitle={"BarteLuxe"}
                                    actionIcon={<IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        onClick={function () {
                                            setUrlImage(props.UrlImgFrente)
                                            handleClickOpen()
                                        }}
                                    > <VisibilityIcon /> </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <ImageListItem >
                                <img
                                    src={`${props.UrlImgAtras}?w=100&h=100&fit=crop&auto=format`}
                                    srcSet={`${props.UrlImgAtras}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                                    alt={"no img"}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = noimg
                                    }}
                                />
                                <ImageListItemBar title={"Imagen Trasera"} subtitle={"@BarteLuxe"}
                                    actionIcon={<IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        onClick={function () {
                                            setUrlImage(props.UrlImgAtras)
                                            handleClickOpen()
                                        }}
                                    > <VisibilityIcon /> </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <ImageListItem >
                                <img
                                    src={`${props.UrlImgIzqIzquierda}?w=100&h=100&fit=crop&auto=format`}
                                    srcSet={`${props.UrlImgIzqIzquierda}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                                    alt={"no img"}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = noimg
                                    }}
                                />
                                <ImageListItemBar title={"Imagen Izquierda"} subtitle={"@BarteLuxe"}
                                    actionIcon={<IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        onClick={function () {
                                            setUrlImage(props.UrlImgIzqIzquierda)
                                            handleClickOpen()
                                        }}
                                    > <VisibilityIcon /> </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <ImageListItem >
                                <img
                                    src={`${props.UrlImgDerecha}?w=100&h=100&fit=crop&auto=format`}
                                    srcSet={`${props.UrlImgDerecha}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                                    alt={"no img"}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = noimg
                                    }}
                                />
                                <ImageListItemBar title={"Imagen Derecha"} subtitle={"@BarteLuxe"}
                                    actionIcon={<IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        onClick={function () {
                                            setUrlImage(props.UrlImgDerecha)
                                            handleClickOpen()
                                        }}
                                    > <VisibilityIcon /> </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <ImageListItem >
                                <img
                                    src={`${props.UrlImgDentro}?w=100&h=100&fit=crop&auto=format`}
                                    srcSet={`${props.UrlImgDentro}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                                    alt={"no img"}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = noimg
                                    }}
                                />
                                <ImageListItemBar title={"Imagen Interior"} subtitle={"@BarteLuxe"}
                                    actionIcon={<IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        onClick={function () {
                                            setUrlImage(props.UrlImgDentro)
                                            handleClickOpen()
                                        }}
                                    > <VisibilityIcon /> </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <ImageListItem >
                                <img
                                    src={`${props.UrlImgDetalle}?w=100&h=100&fit=crop&auto=format`}
                                    srcSet={`${props.UrlImgDetalle}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                                    alt={"no img"}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = noimg
                                    }}
                                />
                                <ImageListItemBar title={"Imagen Detalle"} subtitle={"@BarteLuxe"}
                                    actionIcon={<IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        onClick={function () {
                                            setUrlImage(props.UrlImgDetalle)
                                            handleClickOpen()
                                        }}
                                    > <VisibilityIcon /> </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <ImageListItem >
                                <img
                                    src={`${props.UrlImgDetalle2}?w=100&h=100&fit=crop&auto=format`}
                                    srcSet={`${props.UrlImgDetalle2}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                                    alt={"no img"}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = noimg
                                    }}
                                />
                                <ImageListItemBar title={"Imagen Detalle 2"} subtitle={"@BarteLuxe"}
                                    actionIcon={<IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        onClick={function () {
                                            setUrlImage(props.UrlImgDetalle2)
                                            handleClickOpen()
                                        }}
                                    > <VisibilityIcon /> </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={2}>

                            <ImageListItem >
                                <img
                                    src={`${props.UrlImgDetalle3}?w=100&h=100&fit=crop&auto=format`}
                                    srcSet={`${props.UrlImgDetalle3}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                                    alt={"no img"}
                                    loading="lazy"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = noimg
                                    }}
                                />
                                <ImageListItemBar title={"Imagen Detalle 3"} subtitle={"@BarteLuxe"}
                                    actionIcon={<IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        onClick={function () {
                                            setUrlImage(props.UrlImgDetalle3)
                                            handleClickOpen()
                                        }}
                                    > <VisibilityIcon /> </IconButton>
                                    }
                                />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </BlCard>
            </Grid>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }} className='none-shadow back-white'>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon className='font-dark' />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className='center flex'>
                    <img src={_UrlImage} width="600px" height="600px" onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = noimg
                    }} sx={{ objectFit: 'cover' }} />

                </div>
            </Dialog>
        </Grid>
    );
}