/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Alert from '@mui/material/Alert'

import '../../Styles/App/Alert/alert.css'

const AlertInfo = (props) => {
	return (
		<div>
			<Snackbar
				open={props.openAlert}
				autoHideDuration={6000}
				onClose={props.handleCloseAlert}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert
					className='alert-info'
					onClose={props.handleCloseAlert}
					icon={
						<InfoOutlinedIcon
							fontSize='inherit'
							sx={{ fontSize: 30, color: '#0077b6' }}
						/>
					}
				>
					<strong>{props.MensajeTitle}</strong>
					{props.MensajeBody}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default AlertInfo
