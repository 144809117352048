/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { styled, createTheme } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Logout from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { logout } from '../Actions/UserActions'
import { useDispatch, useSelector } from 'react-redux'
import '../Styles/GeneralStyles.css'
// import logo from '../Images/logo.png'
import logo from '../Images/logo-barter-sin-fondo.png'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import requests from '../Components/AxiosCalls/AxiosAuth'

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import {
  CLIENTES_CONSIGNAS_VIEW,
  CLIENTES_EMP_VIEW,
  CLIENTES_INTERCAMBIO_VIEW,
  CLIENTES_RENT_VIEW,
  CLIENTES_SECTION_VIEW,
  CLIENTES_CON_PROPUESTAS_VIEW,
  CLIENTES_CONSIGNAS_PIEZAS,
  CLIENTES_PROFILE,
  CLIENTES_SECTION_WISH,
  CLIENTES_EMP_PRO_VIEW,
  CLIENTES_EMP_PROPUESTAS_VIEW,
  CITAS_MB,
  CITAS_MB_CONSIGNA,
  CITAS_MB_EMPEÑO,
  CUESTIONARIO,
  PAGOS_EXTRAUDINARIOS,
  ENVIO_VIEW,
  PERSONAL_SHOPPER_VIEW,
} from '../Constants/routesConstants'

import {
  CLIENTES_PERFIL_COMPLETO,
  PROPUESTA__LIST_CONFOR_BY_ID_CLIENTE,
  PROPUESTA_LIST_EMPFOR_BY_ID_CLIENTE,
} from '../Constants/ApiConstants'

import {
  gtmGoTo_EmpeñaAqui_Log,
  tmGoTo_MisVentas_Log,
  gtmGoTo_MisEmpeños_Log,
  gtmGoTo_MisCitas_Log,
  gtmGoTo_Shopify_Log,
  gtmGoTo_LogOut_Log,
  gtmGoTo_Perfil_Log,
} from '../Constants/GoogleTagManager/gtmConstants'

import { fontSize, padding } from '@mui/system'
const drawerWidth = 290

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#6a1b9a',
    },
  },
})
const HeaderLayoutCliente = (props) => {
  let navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const [listaCForaneas, setListaCForaneas] = useState([])
  const [listaEForaneas, setListaEForaneas] = useState([])

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const logOut = () => {
    dispatch(logout())
    gtmGoTo_LogOut_Log()
    navigate('/')
    window.location.reload()
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleCloseUserProfile = () => {
    gtmGoTo_Perfil_Log()
    navigate(CLIENTES_PROFILE)
    setAnchorElUser(null)
  }

  //============== CONSIGNAS =========================
  const [anchorEl3, setAnchorEl3] = React.useState(null)
  const open3 = Boolean(anchorEl3)
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget)
  }
  const handleClose3 = () => {
    setAnchorEl3(null)
  }

  const handleCloseConsignas = () => {
    navigate(CLIENTES_CONSIGNAS_VIEW)
    setAnchorEl3(null)
  }

  const handleClosePropuestaConsignas = () => {
    navigate(CLIENTES_CON_PROPUESTAS_VIEW)
    setAnchorEl3(null)
  }

  //============== EMPEÑOS =========================

  const [anchorEl4, setAnchorEl4] = React.useState(null)
  const open4 = Boolean(anchorEl4)
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget)
  }
  const handleClose4 = () => {
    setAnchorEl4(null)
  }

  const handleCloseEmpeños = () => {
    navigate(CLIENTES_EMP_PRO_VIEW)
    setAnchorEl4(null)
  }
  const handleClosePropuestas = () => {
    navigate(CLIENTES_EMP_PROPUESTAS_VIEW)
    setAnchorEl4(null)
  }

  //============== APARTADOS =========================
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open2 = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCloseCompras = () => {
    navigate(CLIENTES_SECTION_VIEW)
    setAnchorEl(null)
  }
  const handleCloseComprasDeseo = () => {
    navigate(CLIENTES_SECTION_WISH)
    setAnchorEl(null)
  }

  //============== CITAS =========================

  const [anchorElCitas, setAnchorElCitas] = React.useState(null)
  const openCitas = Boolean(anchorElCitas)
  const handleClickCitas = (event) => {
    setAnchorElCitas(event.currentTarget)
  }
  const handleClose5 = () => {
    setAnchorElCitas(null)
  }
  const handleCloseCitasConsigna = () => {
    navigate(CITAS_MB_CONSIGNA)
    setAnchorElCitas(null)
  }
  const handleCloseCitasEmpeño = () => {
    navigate(CITAS_MB_EMPEÑO)
    setAnchorElCitas(null)
  }

  //============== ENLACE SHOPIFY =========================
  const goShopify = () => {
    window.open('https://founditpreloved.com/', '_blank')
  }
  const getBool = () => {
    return JSON.parse(localStorage.getItem('profile'))
  }
  const bool = getBool()

  const [_Complet, setComplete] = useState()
  const getProfile = () => {
    //if(bool !== true){
    if (
      userInfo.intIdUsuario !== null &&
      userInfo.intIdUsuario !== '' &&
      userInfo.intIdUsuario !== undefined
    ) {
      requests
        .get(CLIENTES_PERFIL_COMPLETO, userInfo.intIdUsuario)
        .then((response) => {
          setComplete(response)
          localStorage.setItem('profile', JSON.stringify(response))
        })
        .catch((error) => {
          console.log(error)
        })
    }
    //}
  }

  const getListaCForaneas = () => {
    requests
      .get(PROPUESTA__LIST_CONFOR_BY_ID_CLIENTE, userInfo.intIdUsuario)
      .then((response) => {
        setListaCForaneas(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getListaEForaneas = () => {
    requests
      .get(PROPUESTA_LIST_EMPFOR_BY_ID_CLIENTE, userInfo.intIdUsuario)
      .then((response) => {
        setListaEForaneas(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    getListaCForaneas()
    getListaEForaneas()
    getProfile()
  }, [])

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="absolute"
          open={open}
          className="none-shadow back-white"
        >
          <div className="w100">
            {/* {_Complet === false
							? null
							: // <Alert
							  // 	severity='warning'
							  // 	sx={{
							  // 		display: 'flex',
							  // 		justifyContent: 'center',
							  // 		fontWeight: 'bold',
							  // 		fontSize: '16px',
							  // 		mb: 1,
							  // 	}}
							  // 	action={
							  // 		<Button
							  // 			color='inherit'
							  // 			size='small'
							  // 			onClick={handleCloseUserProfile}
							  // 		>
							  // 			Completar Perfil
							  // 		</Button>
							  // 	}
							  // >
							  // 	Es necesario completar tu perfil para realizar acciones en Mundo
							  // 	BarterLuxe.{' '}
							  // </Alert>
							  null} */}

            <Toolbar disableGutters className="widthToolbarHeaderClient center">
              {/* <Typography
								variant='h6'
								noWrap
								component='a'
								href='/'
								sx={{
									// mr: 2,
									display: { xs: 'none', md: 'flex' },
									fontFamily: 'monospace',
									fontWeight: 700,
									letterSpacing: '.3rem',
									color: 'inherit',
									textDecoration: 'none',
								}}
							>
								<Grid
									container
									direction='row'
									justifyContent='center'
									alignItems='center'
									// margin-right='10px'
									// backgroundColor='red'
								>
									<img src={logo} width='85px' />
								</Grid>
							</Typography> */}
              <div style={{ display: 'flex' }}>
                <img
                  onClick={function () {
                    //gtmPageViewHomeUNL()
                    navigate('/')
                  }}
                  src={logo}
                  width="85px"
                  height="35px"
                  // className=' mr2 red'
                  className=" mr2"
                  style={{ cursor: 'pointer' }}
                />

                {/* <Typography
								variant='h5'
								noWrap
								component='a'
								href='/'
								sx={{
									mr: 2,
									display: { xs: 'flex', md: 'none' },
									flexGrow: 1,
									fontFamily: 'monospace',
									fontWeight: 700,
									letterSpacing: '.3rem',
									color: 'inherit',
									textDecoration: 'none',
								}}
							>
								<img src={logo} width='85px' />
							</Typography> */}

                <Box
                  sx={{
                    flexGrow: 1,
                    display: {
                      xs: 'none',
                      md: 'flex',
                      color: '#000',
                    },
                  }}
                >
                  <Button
                    onClick={function () {
                      gtmGoTo_EmpeñaAqui_Log()
                      navigate(CUESTIONARIO)
                    }}
                    className="co-black BtnHeader2"
                  >
                    {/* Evaluaciones */}
                    Vende o empeña aquí
                  </Button>
                  <Button
                    onClick={function () {
                      tmGoTo_MisVentas_Log()
                      navigate(CLIENTES_CONSIGNAS_VIEW)
                      // if (_Complet !== false) {
                      // 	navigate(CLIENTES_CONSIGNAS_VIEW)
                      // } else {
                      // 	props.setTypeSnackBar('warning')
                      // 	props.setMessageAlertWarning(
                      // 		'Debes completar tu perfil para poder acceder a nuestros servicios'
                      // 	)
                      // 	props.setOpenAlertWarning(true)
                      // }
                    }}
                    className="co-black BtnHeader"
                  >
                    Mis ventas
                  </Button>
                  <Button
                    onClick={function () {
                      gtmGoTo_MisEmpeños_Log()
                      navigate(CLIENTES_EMP_VIEW)
                      // if (_Complet !== false) {
                      // 	navigate(CLIENTES_EMP_VIEW)
                      // } else {
                      // 	props.setTypeSnackBar('warning')
                      // 	props.setMessageAlertWarning(
                      // 		'Debes completar tu perfil para poder acceder a nuestros servicios'
                      // 	)
                      // 	props.setOpenAlertWarning(true)
                      // }
                    }}
                    className="co-black BtnHeader"
                  >
                    Mis empeños
                  </Button>
                  <Button
                    onClick={function () {
                      // gtmGoTo_Shopify_Log()
                      // goShopify()
                      navigate(CLIENTES_SECTION_VIEW)
                    }}
                    className="co-black BtnHeader"
                  >
                    {/* Volver a comprar */}
                    Mis apartados
                  </Button>
                  {/* <Button
										onClick={function () {
											// gtmGoTo_Shopify_Log()
											// goShopify()
											navigate(PERSONAL_SHOPPER_VIEW)
										}}
										className='co-black BtnHeader'
									>
										{/* Volver a comprar */}
                  {/* PlanLuxe
									</Button> */}
                  <Button
                    onClick={function () {
                      gtmGoTo_MisCitas_Log()
                      navigate(CITAS_MB)

                      // if (_Complet !== false) {
                      // 	navigate(CITAS_MB)
                      // } else {
                      // 	props.setTypeSnackBar('warning')
                      // 	props.setMessageAlertWarning(
                      // 		'Debes completar tu perfil para poder acceder a nuestros servicios'
                      // 	)
                      // 	props.setOpenAlertWarning(true)
                      // }
                    }}
                    className="co-black BtnHeader"
                  >
                    Mis citas
                  </Button>
                  {listaCForaneas.length > 0 || listaEForaneas.length > 0 ? (
                    <Button
                      onClick={function () {
                        navigate(ENVIO_VIEW)
                      }}
                      className="co-black BtnHeader"
                    >
                      Mis Envios
                    </Button>
                  ) : null}

                  <Button
                    onClick={function () {
                      gtmGoTo_Shopify_Log()
                      goShopify()
                    }}
                    className="co-black BtnHeader"
                  >
                    {/* Volver a comprar */}
                    Tienda en línea
                  </Button>
                </Box>
              </div>
              <div style={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 0, ml: 2 }}>
                  <Button
                    onClick={handleOpenUserMenu}
                    className="Chip-login-success"
                    startIcon={
                      <IconButton sx={{ p: 0 }}>
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            background: '#FFF',
                            color: '#757D65',
                          }}
                        >
                          {userInfo.nombre
                            ? userInfo.nombre.substring(0, 1).toUpperCase()
                            : null}
                        </Avatar>
                      </IconButton>
                    }
                  >
                    <div className="flex-column-start">
                      <span className="p14 bold">Hola</span>
                      <span className="p14">{userInfo.nombre}</span>
                    </div>
                  </Button>

                  <Menu
                    sx={{ mt: '50px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                  >
                    <MenuItem
                      sx={{ minWidth: 250 }}
                      onClick={handleCloseUserProfile}
                    >
                      <ManageAccountsIcon sx={{ mr: 1 }} />
                      Mi perfil
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem sx={{ minWidth: 250 }} onClick={logOut}>
                      <Logout sx={{ mr: 1 }} />
                      Cerrar sesión
                    </MenuItem>
                  </Menu>
                </Box>
                <Box
                  sx={{
                    //backgroundColor: 'red',
                    display: { xs: 'flex', md: 'none' },
                    border: 'solid 1px #adb5bd',
                    borderRadius: '5px',
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    sx={{ color: '#000' }}
                  >
                    <MenuIcon sx={{ color: '#000' }} />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 15,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem
                      onClick={function () {
                        gtmGoTo_EmpeñaAqui_Log()
                        navigate(CUESTIONARIO)
                        handleCloseNavMenu()
                      }}
                      sx={{ width: '300px' }}
                    >
                      <Typography textAlign="center" sx={{ color: '#000' }}>
                        Vende o empeña aquí
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      // onClick={function () {
                      // 	if (_Complet !== false) {
                      // 		tmGoTo_MisVentas_Log()
                      // 		navigate(CLIENTES_CONSIGNAS_VIEW)
                      // 		handleCloseNavMenu()
                      // 	} else {
                      // 		tmCompletar_Perfil_Log()
                      // 		handleCloseNavMenu()
                      // 		props.setTypeSnackBar('warning')
                      // 		props.setMessageAlertWarning(
                      // 			'Debes completar tu perfil para poder acceder a nuestros servicios'
                      // 		)
                      // 		props.setOpenAlertWarning(true)
                      // 	}
                      // }}
                      onClick={function () {
                        tmGoTo_MisVentas_Log()
                        navigate(CLIENTES_CONSIGNAS_VIEW)
                        handleCloseNavMenu()
                      }}
                    >
                      <Typography textAlign="center" sx={{ color: '#000' }}>
                        Mis ventas
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      // onClick={function () {
                      // 	if (_Complet !== false) {
                      // 		gtmGoTo_MisEmpeños_Log()
                      // 		navigate(CLIENTES_EMP_VIEW)
                      // 		handleCloseNavMenu()
                      // 	} else {
                      // 		tmCompletar_Perfil_Log()
                      // 		handleCloseNavMenu()
                      // 		props.setTypeSnackBar('warning')
                      // 		props.setMessageAlertWarning(
                      // 			'Debes completar tu perfil para poder acceder a nuestros servicios'
                      // 		)
                      // 		props.setOpenAlertWarning(true)
                      // 	}
                      // }}

                      onClick={function () {
                        gtmGoTo_MisEmpeños_Log()
                        navigate(CLIENTES_EMP_VIEW)
                        handleCloseNavMenu()
                      }}
                    >
                      <Typography textAlign="center" sx={{ color: '#000' }}>
                        Mis empeños
                      </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={function () {
                        // gtmGoTo_Shopify_Log()
                        // goShopify()
                        navigate(CLIENTES_SECTION_VIEW)
                        handleCloseNavMenu()
                      }}
                    >
                      <Typography textAlign="center" sx={{ color: '#000' }}>
                        Mis apartados
                      </Typography>
                    </MenuItem>
                    <Divider />
                    {/* <MenuItem
											onClick={function () {
												// gtmGoTo_Shopify_Log()
												// goShopify()
												navigate(PERSONAL_SHOPPER_VIEW)
												handleCloseNavMenu()
											}}
										>
											<Typography textAlign='center' sx={{ color: '#000' }}>
												PlanLuxe
											</Typography>
										</MenuItem>
										<Divider /> */}
                    <MenuItem
                      // onClick={function () {
                      // 	if (_Complet !== false) {
                      // 		gtmGoTo_MisCitas_Log()
                      // 		navigate(CITAS_MB)
                      // 		handleCloseNavMenu()
                      // 	} else {
                      // 		tmCompletar_Perfil_Log()
                      // 		handleCloseNavMenu()
                      // 		props.setTypeSnackBar('warning')
                      // 		props.setMessageAlertWarning(
                      // 			'Debes completar tu perfil para poder acceder a nuestros servicios'
                      // 		)
                      // 		props.setOpenAlertWarning(true)
                      // 	}
                      // }}

                      onClick={function () {
                        gtmGoTo_MisCitas_Log()
                        navigate(CITAS_MB)
                        handleCloseNavMenu()
                      }}
                    >
                      <Typography textAlign="center" sx={{ color: '#000' }}>
                        Mis citas
                      </Typography>
                    </MenuItem>
                    <Divider />

                    {listaCForaneas.length > 0 ? (
                      <>
                        <MenuItem
                          onClick={function () {
                            // gtmGoTo_MisCitas_Log()
                            navigate(ENVIO_VIEW)
                            handleCloseNavMenu()
                          }}
                        >
                          <Typography textAlign="center" sx={{ color: '#000' }}>
                            Mis Envios
                          </Typography>
                        </MenuItem>
                        <Divider />
                      </>
                    ) : null}

                    {/* {listaCForaneas.length > 0 ? (
									<Button
										onClick={function () {
											
											navigate(ENVIO_VIEW)
										}}
										className='co-black'
									>
										Mis Envios
									</Button>
								) : null} */}

                    <MenuItem
                      onClick={function () {
                        gtmGoTo_Shopify_Log()
                        goShopify()
                        handleCloseNavMenu()
                      }}
                    >
                      <Typography textAlign="center" sx={{ color: '#000' }}>
                        Tienda en línea
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </div>
            </Toolbar>
          </div>
        </AppBar>
      </Box>

      {/* <Button
									id='basic-button'
									aria-controls={open3 ? 'basic-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={open3 ? 'true' : undefined}
									onClick={handleClick3}
									sx={{ color: '#000' }}
								>
									Consigna
								</Button>
								<Menu
									id='basic-menu'
									anchorEl={anchorEl3}
									open={open3}
									onClose={handleClose3}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									<MenuItem onClick={handleCloseConsignas}>
										Mis consignas
									</MenuItem>
									<MenuItem onClick={handleClosePropuestaConsignas}>Propuestas</MenuItem>
								</Menu>
								<Button
									id='basic-button'
									aria-controls={open4 ? 'basic-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={open4 ? 'true' : undefined}
									onClick={handleClick4}
									sx={{ color: '#000' }}
								>
									Empeño
								</Button>
								<Menu
									id='basic-menu'
									anchorEl={anchorEl4}
									open={open4}
									onClose={handleClose4}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									<MenuItem onClick={handleCloseEmpeños}>Mis empeños</MenuItem>
									<MenuItem onClick={handleClosePropuestas}>Propuestas</MenuItem>
								</Menu>
								<Button
									id='basic-button'
									aria-controls={open2 ? 'basic-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={open2 ? 'true' : undefined}
									onClick={handleClick}
									sx={{ color: '#000' }}
								>
									Apartado
								</Button>
								<Menu
									id='basic-menu'
									anchorEl={anchorEl}
									open={open2}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									<MenuItem onClick={handleCloseCompras}>Mis pedidos</MenuItem>
									<MenuItem onClick={handleCloseComprasDeseo}>
										Lista de deseo
									</MenuItem>
								</Menu>
								<Button
									id='basic-button'
									aria-controls={openCitas ? 'basic-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={openCitas ? 'true' : undefined}
									onClick={handleClickCitas}
									sx={{ color: '#000' }}
								>
									Mis Citas
								</Button>
								<Menu
									id='basic-menu'
									anchorEl={anchorElCitas}
									open={openCitas}
									onClose={handleClickCitas}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
								>
									<MenuItem onClick={handleCloseCitasConsigna}>
										Consignas
									</MenuItem>
									<MenuItem onClick={handleCloseCitasEmpeño}>
										Empeños
									</MenuItem>
								</Menu> */}

      {/* <Button
									onClick={function () {
										navigate(CLIENTES_INTERCAMBIO_VIEW)
									}}
									sx={{ my: 2, color: '#000', display: 'block' }}
								>
									Intercambio
								</Button> */}
      {/* <Button
									onClick={function () {
										navigate(CLIENTES_RENT_VIEW)
									}}
									sx={{ my: 2, color: '#000', display: 'block' }}
								>
									Renta
								</Button> */}

      {/* <Button
									onClick={function () {
										navigate(CLIENTES_SECTION_VIEW)
									}}
									sx={{ my: 2, color: '#000', display: 'block' }}
								>
									Apartado
								</Button> */}
      {/* <Button
									onClick={function () {
										navigate(PAGOS_EXTRAUDINARIOS)
									}}
									sx={{ my: 2, color: '#000', display: 'block' }}
								>
									Cobros Adicionales
								</Button> */}
      {/* <Button
									onClick={function () {
										navigate(ENVIO_VIEW)
									}}
									sx={{ my: 2, color: '#000', display: 'block' }}
								>
									Envios
								</Button> */}
    </div>
  )
}

export default HeaderLayoutCliente
