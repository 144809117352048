/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useNavigate, useParams } from 'react-router-dom'

// import {
// 	EMPEÑO_BY_ID,
// 	EMPEÑO_PAGO_DETALLES,
// } from '../../Constants/ApiConstants'

import AmountPayCertificados from './PaymentsComponentsCertificados/AmountPayCertificados'
import PayCertificados from './PaymentsComponentsCertificados/PayCertificados'
import SuccessfullCertificados from './PaymentsComponentsCertificados/SuccessfullCertificados'
import requests from '../../AxiosCalls/AxiosCall'
import '../../../Styles/PayCodi.css'
import NumberFormat from 'react-number-format'

const PaymentFlowCerticados = (props) => {
	const { id } = useParams()

	const [activeStep, setActiveStep] = useState(0)
	const [skipped, setSkipped] = useState(new Set())
	const [_Empeño, setEmpeño] = useState([])
	const [_Detalles, setDetalles] = useState([])
	let navigate = useNavigate()

	const isStepOptional = (step) => {
		return step === 1
	}

	const isStepSkipped = (step) => {
		return skipped.has(step)
	}

	const handleNext = () => {
		let newSkipped = skipped
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values())
			newSkipped.delete(activeStep)
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1)
		setSkipped(newSkipped)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleReset = () => {
		setActiveStep(0)
	}
	// const getEmpeño = () => {
	// 	props.setOpenLoadingScreen()
	// 	requests
	// 		.get(EMPEÑO_BY_ID, id)
	// 		.then((response) => {
	// 			setEmpeño(response)
	// 			props.setCloseLoadingScreen()
	// 		})
	// 		.catch((error) => {
	// 			console.log('Error: ' + error)
	// 			props.setCloseLoadingScreen()
	// 		})
	// }
	// const getDetalles = () => {
	// 	props.setOpenLoadingScreen()
	// 	requests
	// 		.get(EMPEÑO_PAGO_DETALLES, id)
	// 		.then((response) => {
	// 			setDetalles(response)
	// 			props.setCloseLoadingScreen()
	// 		})
	// 		.catch((error) => {
	// 			console.log('Error: ' + error)
	// 			props.setCloseLoadingScreen()
	// 		})
	// }
	// useEffect(() => {
	// 	getEmpeño()
	// 	getDetalles()
	// }, [])

	//::::::::::::::::::::: Titulos de cada paso ::::::::::::::::::::::::::
	const steps = [
		'Monto a pagar',
		'Pagar mediante CODI',
		'Resumen de pago',
	]
	//::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
	function getSteps(step, handleNext, handleBack) {
		switch (step) {
			case 0:
				return (
					<AmountPayCertificados
						handleNext={handleNext}
						handleBack={handleBack}
						// data={_Empeño}
						// detalles={_Detalles}
					/>
				)
			case 1:
				return (
					<PayCertificados
						handleNext={handleNext}
						handleBack={handleBack}
						setMsjLoadingScreen={props.setMsjLoadingScreen}
						setOpenLoadingScreen={props.setOpenLoadingScreen}
						setCloseLoadingScreen={props.setCloseLoadingScreen}
						setOpenSnackBar={props.setOpenSnackBar}
						setMessageSnackBar={props.setMessageSnackBar}
						setTypeSnackBar={props.setTypeSnackBar}
						// data={_Empeño}
						// detalles={_Detalles}
					/>
				)
			case 2:
				return (
					<SuccessfullCertificados
						handleNext={handleNext}
						handleBack={handleBack}
						// data={_Empeño}
						// detalles={_Detalles}
					/>
				)
			default:
				throw new Error('Unknown step')
		}
	}

	//::::::::::::::::::::: Vistas de cada paso ::::::::::::::::::::::::::
	return (
		<div className='containerViewUser'>
			<div className='w60'>
				<div>
					<div className='box-proceso-producto-pay'>
						<div className='w20 p2'>
							<img className='img-proceso-producto' src='https://www.entrupy.com/wp-content/uploads/2019/04/Entrupy-Saint-Laurent-authentication-certificate.png' />
						</div>
						<div className='flex-column-start w80 line-height-0'>
							<div>
								<div>
								
									<p className='title-proceso-producto'>
										Tipo de envío:  Express Domestic
									</p>
									<div className='flex-start'>
										<p>
											<strong>Costo de envío: </strong>
											<NumberFormat
												value='250'
												displayType={'text'}
												fixedDecimalScale={true}
												decimalScale={2}
												thousandSeparator={true}
												prefix={'$ '}
												suffix={'MXN'}
											/>
										</p>
									</div>
									<div className='flex-start'>
										<p>
										<strong>Saint Laurent</strong> - Leather
										</p>
									</div>
									<div className='flex-start'>
										<p>
											<strong>SKU: </strong>
											CVRTNS01029133
										</p>
									</div>
                                    <div className='flex-start'>
										<p>
											<strong>Número de guía: </strong>
											9950522024
										</p>
									</div>
								</div>

								{/* <p className=''>
									<strong>Saldo Actual:</strong> $ 45,900 MXN
								</p> */}
							</div>
						</div>
					</div>
				</div>

				<Box sx={{ width: '100%', mt: 3 }}>
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
							const stepProps = {}
							const labelProps = {}

							if (isStepSkipped(index)) {
								stepProps.completed = false
							}
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							)
						})}
					</Stepper>
					{activeStep === steps.length ? (
						<div className='box-paycodi-stepper'>
							<Typography sx={{ mt: 2, mb: 1 }}>
								All steps completed - you&apos;re finished
							</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button onClick={handleReset}>Ver historia de pago</Button>
							</Box>
						</div>
					) : (
						<div className='box-paycodi-stepper'>
							{getSteps(activeStep, handleNext, handleBack, navigate)}

							{/* <div className='body-stepper'>Step {activeStep + 1}</div>
							<div className='footer-stepper'>
								<Button
									className='btn-stepper'
									disabled={activeStep === 0}
									onClick={handleBack}
								>
									Regresar
								</Button>
								<Button className='btn-stepper' onClick={handleNext}>
									{activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
								</Button>
							</div> */}
						</div>
					)}
				</Box>
			</div>
		</div>
	)
}
export default PaymentFlowCerticados