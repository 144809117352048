import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { postAbonoCreate } from '../../../../Actions/MundoBarterActions'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import ModalCargoServicio from '../../ModalCargoServicio'

const AmountPayRef = (props) => {
	const dispatch = useDispatch()
	const handleNext = (data) => {
		dispatch(postAbonoCreate(data))
		props.handleNext()
	}

	const [amountPay, setAmountCodi] = useState({
		monto: 0,
		saldo: props.detalles.dblSaldoActual,
		refrendo: 0,
		fee: 0,
		totalPagar: props.detalles.dblTotalEmpeño,
	})
	const getRefrendo = () => {
		var data = JSON.parse(sessionStorage.getItem('refrendo'))
		if (data !== null) {
			setAmountCodi({
				...amountPay,
				saldo: props.detalles.dblSaldoActual,
				monto: data.refrendo,
				fee: data.fee,
				totalPagar: data.totalPagar,
			})
		}
	}
	useEffect(() => {
		getRefrendo()
	}, [])

	const [openModalTerminos, setModalTerminos] = useState(false)
	const handleOpenTerminos = () => {
		setModalTerminos(!openModalTerminos)
	}
	return (
		<div className='CardApartadoDetailPagosCardStepper'>

			<div className='BodyCardApartadoDetail'>
				<div className='ApartadoDetailSection2'>
					<h2>Detalle de pago</h2>
					<hr className='Dvividerhr' />
					<div className='containerFlexBetween'>
						<span className='bold upperCase'>
							<div className='containerSelectDetailPago'>
								<span className='subtitlesdetail'>  </span>{' '}
								{/* <NumberFormat
									value={amountPay.monto + amountPay.fee}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={'MXN'}
								/> */}
							</div>
						</span>
						<span className='bold upperCase'>
							<div className='containerSelectDetailPago'>
								<span className='subtitlesdetail'>Monto a refrendar </span>{' '}
								<NumberFormat
									value={props.detalles.dblSaldoActual === 0 ? 0 : amountPay.totalPagar}
									displayType={'text'}
									fixedDecimalScale={true}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$ '}
									suffix={' MXN'}
								/>
							</div>
						</span>
					</div>
					<Button
					className='btn-pay'
					//disabled={amountPay.length === 0 ? true : false}
					disabled={props.detalles.dblSaldoActual === 0 ? true : false}
					//onClick={handleNext}
					onClick={function () {
						handleNext(amountPay)
					}}
				>
					Realizar pago
				</Button>
				</div>
			</div>
			<ModalCargoServicio
				openModalImgPreview={openModalTerminos}
				onCloseModalImgPreview={handleOpenTerminos}
			/>
		</div>
	)
}
export default AmountPayRef
