import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../../Styles/Theme';
import {Chip, Card, CardContent, CardMedia, IconButton, Typography, Box } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import '../../../Styles/GeneralStyles.css';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {CLIENTES_SECTION_VIEW} from '../../../Constants/routesConstants'

const ApartadoDevuelto = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <ThemeProvider theme={Theme}>
                <Grid>
                    <Grid item xs={4} sm={8} md={12} lg={12} elevation={12}>
                        <Grid Grid item xs={4} sm={4} md={4} lg={12} className='d-flex align-center mb-30'>
                        <Button
                                className='back-gradient-4 bord-radius-10 shadow font-letter font-light'
                                startIcon={<ArrowBackIcon />}
                                onClick={function () {
                                    navigate(CLIENTES_SECTION_VIEW)
                                }}
                            >
                                Regresar
                            </Button>
                            <Chip icon={<ShoppingCartIcon />} label='Apartado / Mis pedidos / Devueltos' color='primary' className='back-transparent font-letter font-barter font-20' />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center' spacing={2} elevation={12}>
                        <Grid item xs={4}>
                            <Card sx={{ display: 'flex', height: 200 }}>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 151 }}
                                    image="https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
                                    alt="Live from space album cover"
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5">
                                            Ralph Lauren
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Devuelto
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Este producto ha sido devuelto con éxito.
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Fecha: 01/11/2022
                                        </Typography>
                                    </CardContent>

                                </Box>

                            </Card>
                        </Grid>

                        <Grid item xs={4}>
                            <Card sx={{ display: 'flex', height: 200 }}>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 151 }}
                                    image="https://images.unsplash.com/photo-1603808033192-082d6919d3e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80"
                                    alt="Live from space album cover"
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5">
                                            Chanel
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Devuelto
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Este producto ha sido devuelto con éxito.
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            Fecha: 03/11/2022
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                           
                        </Grid>
                    </Grid>

                </Grid>
            </ThemeProvider>
        </>
    )
}
export default ApartadoDevuelto