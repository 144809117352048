import React from 'react'
import { Button, Collapse, Grid, Typography } from "@mui/material";
import BlCard from "../../../Util/BlCard";
import BlLabelInfo from "../../../Util/BlLabelInfo";
export default function Informacion (props)  {
    console.log(props.Registro)
    const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Grid container>
    <Grid item xs={12}>
        <BlCard title="Información de general">
            <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                    <Typography variant="h6" gutterBottom>
                        Folio: 
                    </Typography>
                    <Typography>
                        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        {props.Registro.txtFolio}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography variant="h6" gutterBottom>
                    Cliente: 
                    </Typography>
                    <Typography>
                        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        {props.Registro.nombreCliente}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography variant="h6" gutterBottom>
                    Telefóno: 
                    </Typography>
                    <Typography>
                        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        {props.Registro.nombreCliente}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography variant="h6" gutterBottom>
                    Email:
                    </Typography>
                    <Typography>
                        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        {props.Registro.txtTelefono}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                <Typography variant="h6" gutterBottom>
                    Fecha: 
                    </Typography>
                    <Typography gutterBottom>
                    {props.Registro.fecFecha} 
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    
                </Grid>
                
            </Grid>
        </BlCard>
    </Grid>
    {/* <Grid item xs={12}>
        <Collapse in={open}  >
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                    <BlCard title="Datos del prospecto" color={'secondary'}>
                        <BlLabelInfo label="Nombre:" inline>                       
                        {props.Registro.nombreCliente}
                        </BlLabelInfo>
                        <BlLabelInfo label="Email:" inline>                       
                        {props.Registro.txtEmail}                      
                        </BlLabelInfo>                        
                        <BlLabelInfo label="Telefóno:" inline>
                        {props.Registro.txtTelefono}  
                        </BlLabelInfo>
                    </BlCard>
                </Grid>
                              
            </Grid>
        </Collapse>
    </Grid> */}
</Grid>
);
}