import React, { useState } from 'react'
import '../../Styles/StylesMB.css'
import RegisterMb from '../../Components/Login/RegisterMb'
import {
  gtmOpenRegisterUNL,
  gtmCloseRegisterUNL,
} from '../../Constants/GoogleTagManager/gtmConstants'
import ESection1Web from '../../Components/SectionsEmpeña/Section1/ESection1Web'
import ESection2Web from '../../Components/SectionsEmpeña/Section2/Esection2Web'
import ESection3Web from '../../Components/SectionsEmpeña/Section3/ESection3Web'

const MBEmpeñoView = (props) => {
  //============== OPEN REGISTRO=========================
  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    gtmCloseRegisterUNL()
    setOpenSingUp(false)
  }

  return (
    <div className="bg-white-color">
      {/* //? ******************* SECTION 1 ************************* */}
      <ESection1Web props={props} />
      {/* //? ******************* SECTION 2 ************************* */}
      <ESection2Web props={props} />
      {/* //? ******************* SECTION 2 ************************* */}
      <ESection3Web props={props} />
      <RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} />
    </div>
  )
}

export default MBEmpeñoView
