/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { useSelector } from 'react-redux'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'

////-::::::::::::::::::::::::::::::::::::::
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Dialog, TextField } from '@mui/material'
import '../../../Styles/Styles.css'
import { PERSONAL_SHOPPER_ADD } from '../../../Constants/ApiConstants'
import Slide from '@mui/material/Slide'
import imgSingupbarter from '../../../Images/Home/Registrer.jpeg'
import LBL from '../../../Images/logo-barter-sin-fondo.png'
import Compressor from 'compressorjs'
import { styled } from '@mui/material/styles'
import SendIcon from '@mui/icons-material/Send'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Typography, Tooltip, Fade } from '@mui/material'

import {
  gtmOpenLoginUNL,
  gtmCloseLoginUNL,
  gtmCloseRegisterUNL,
  gtmSendRegisterUNL,
} from '../../../Constants/GoogleTagManager/gtmConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const Input = styled('input')({
  display: 'none',
})

const PersonalShopperForm = (props) => {
  const methods = useForm()
  const { handleSubmit } = methods
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  const [_Image, setImage] = useState(null)

  const [_ListTipoServicio, setListTipoServicio] = useState([])
  const [_ListTipoPieza, setListTipoPieza] = useState([])

  const [_DataPersonalShop, setDataPersonalShop] = useState({
    lngIdPersonalShopper: 0,
    txtMarca: '',
    txtColor: '',
    txtTamaño: '',
    txtModelo: '',
    txtUrlImagen: '',
    lngIdCliente: '',
    bolDisponibilidad: false,
    fecRegistro: '',
  })

  const handlePersonalShop = (event) => {
    setDataPersonalShop({
      ..._DataPersonalShop,
      [event.target.name]: event.target.value,
    })
  }

  const LimpiarValores = () => {
    setDataPersonalShop({
      ..._DataPersonalShop,
      txtMarca: '',
      txtColor: '',
      txtTamaño: '',
      txtModelo: '',
      txtUrlImagen: '',
      lngIdCliente: '',
      bolDisponibilidad: '',
      fecRegistro: '',
    })
  }

  const handleClose = () => {
    props.onClose()
    LimpiarValores()
  }

  const guardar = () => {
    props.props.setOpenLoadingScreen()
    const formData = new FormData()
    formData.append('TxtMarca', _DataPersonalShop.txtMarca)
    formData.append('TxtColor', _DataPersonalShop.txtColor)
    formData.append('TxtTamaño', _DataPersonalShop.txtTamaño)
    formData.append('TxtModelo', _DataPersonalShop.txtModelo)
    formData.append('LngIdCliente', userInfo.intIdUsuario)
    formData.append('TxtUrlImagen', _Image)
    requests
      .post(PERSONAL_SHOPPER_ADD, formData)
      .then((response) => {
        props.props.setTypeSnackBar('success')
        props.props.setMessageAlert('Tu solicitud ha sido enviada con éxito.')
        props.props.setOpenAlert(true)
        props.props.setCloseLoadingScreen()
        handleClose()
      })
      .catch((error) => {
        props.props.setTypeSnackBar('warning')
        props.props.setMessageAlertWarning(error.data.mensaje)
        props.props.setOpenAlertWarning(true)
        props.props.setCloseLoadingScreen()
      })
  }

  const handleImage = (e) => {
    props.props.setOpenLoadingScreen()
    const file = e.target.files[0]
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const myFile = new File([result], e.target.files[0].name, {
          type: result.type,
        })
        setImage(myFile)
        props.props.setCloseLoadingScreen()
      },
    })
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose ? props.onClose : null}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            background: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        <div className="ppp">
          <div className="modal-SingUpMb">
            {window.screen.width <= 600 ? (
              <div className="flex h100 bgImageRegisterMobile">
                <div className="bodyLoginMobile">
                  <div className="flex-end">
                    <Button onClick={handleClose}>
                      <CloseIcon
                        fontSize="small"
                        className="btn-close-icon-LoginMobile font-dark"
                      />
                    </Button>
                  </div>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: ' 0px 20px',
                    }}
                  >
                    <div className="flex-column w100">
                      <img className="w40" src={LBL} />
                      <div className="flex-column-start mb1 w100">
                        <span className="h2">¡Hola!</span>
                        <span className="p co-grey">
                          Regístrate y accede al mundo Foundit
                        </span>
                      </div>
                    </div>

                    <Box
                      component="form"
                      onSubmit={handleSubmit(guardar)}
                      sx={{ mt: 3 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="txtMarca"
                            required
                            fullWidth
                            id="txtMarca"
                            label="Marca"
                            color="warning"
                            size="small"
                            value={_DataPersonalShop.txtMarca}
                            onChange={handlePersonalShop}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="txtColor"
                            label="Color"
                            name="txtColor"
                            color="warning"
                            size="small"
                            value={_DataPersonalShop.txtColor}
                            onChange={handlePersonalShop}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="txtTamaño"
                            label="Tamaño"
                            name="txtTamaño"
                            color="warning"
                            //type='email'
                            size="small"
                            value={_DataPersonalShop.txtTamaño}
                            onChange={handlePersonalShop}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="txtModelo"
                            label="Modelo"
                            name="txtModelo"
                            color="warning"
                            size="small"
                            value={_DataPersonalShop.txtModelo}
                            onChange={handlePersonalShop}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <label htmlFor="imagenFrente">
                            <Input
                              id="imagenFrente"
                              type="file"
                              onChange={handleImage}
                              name="imagenFrente"
                              //disabled
                            />
                            <Tooltip
                              title="Cargar imagen frente"
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              arrow
                            >
                              <Button
                                color="secondary"
                                variant="contained"
                                component="span"
                                className="back-gradient-8 bord-radius-10 font-letter mt-10"
                                startIcon={<CloudUploadIcon />}
                                //disabled
                              >
                                Adjuntar imagen
                              </Button>
                            </Tooltip>
                          </label>
                          <Typography
                            variant="caption"
                            gutterBottom
                            component="div"
                          >
                            {_Image !== null ? _Image.name : 'Sin archivo'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="btnLogin"
                      >
                        Enviar
                      </Button>
                    </Box>
                  </Box>
                </div>
              </div>
            ) : (
              <div className="flex h100">
                <div className="w50">
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                    }}
                    src={imgSingupbarter}
                  />
                </div>
                <div className="w50">
                  <div className="flex-end mt1">
                    <Button onClick={handleClose}>
                      <CloseIcon
                        fontSize="small"
                        className="btn-close-icon-modal font-dark"
                      />
                    </Button>
                  </div>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: ' 0px 20px',
                    }}
                  >
                    <div className="flex-column w100">
                      <img className="w30" src={LBL} />
                      <div className="flex-column-start mb1 w100">
                        <span className="h2">Lorem Ipsum!</span>
                        <span className="p co-grey">
                          Neque porro quisquam est qui dolorem ipsum quia dolor
                          sit amet, consectetur, adipisci velit
                        </span>
                      </div>
                    </div>

                    <Box
                      component="form"
                      onSubmit={handleSubmit(guardar)}
                      sx={{ mt: 3 }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="txtMarca"
                            required
                            fullWidth
                            id="txtMarca"
                            label="Marca"
                            color="warning"
                            size="small"
                            value={_DataPersonalShop.txtMarca}
                            onChange={handlePersonalShop}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="txtColor"
                            label="Color"
                            name="txtColor"
                            color="warning"
                            size="small"
                            value={_DataPersonalShop.txtColor}
                            onChange={handlePersonalShop}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            id="txtTamaño"
                            label="Tamaño/Medida"
                            name="txtTamaño"
                            color="warning"
                            //type='email'
                            size="small"
                            value={_DataPersonalShop.txtTamaño}
                            onChange={handlePersonalShop}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            fullWidth
                            id="txtModelo"
                            label="Modelo"
                            name="txtModelo"
                            color="warning"
                            //type='email'
                            size="small"
                            value={_DataPersonalShop.txtModelo}
                            onChange={handlePersonalShop}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="imagenFrente">
                            <Input
                              id="imagenFrente"
                              type="file"
                              onChange={handleImage}
                              name="imagenFrente"
                              //disabled
                            />
                            <Tooltip
                              title="Cargar imagen frente"
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              arrow
                            >
                              <Button
                                color="secondary"
                                variant="contained"
                                component="span"
                                className="back-gradient-8 bord-radius-10 font-letter mt-10"
                                startIcon={<CloudUploadIcon />}
                                //disabled
                              >
                                Adjuntar imagen
                              </Button>
                            </Tooltip>
                          </label>
                          <Typography
                            variant="caption"
                            gutterBottom
                            component="div"
                          >
                            {_Image !== null ? _Image.name : 'Sin archivo'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="btnLogin"
                      >
                        Enviar
                      </Button>
                    </Box>
                  </Box>
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default PersonalShopperForm
