/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { Button } from '@mui/material'
import { MB_CONSIGNA_ACT_DES_MODO_VACACIONES } from '../../Constants/ApiConstants'
import requests from '../../Components/AxiosCalls/AxiosCall'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import AlertsSuccess from './AlertsSuccess'

import '../../Styles/App/Alert/alert.css'

import { gtm_AceptaAplicarDescuento_Log, gtm_RechazaAplicarDescuento_Log } from '../../Constants/GoogleTagManager/gtmConstants'

const AlertConfirmActionDescount = (props) => {
	const [openAlertSuccess, setOpenAlertSuccess] = useState(false)
	const [messageAlertSuccess, setMessageAlertSuccess] = useState(
		'El descuento se ha otogado con éxito.'
	)
	const handleCloseAlertSuccess = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		setOpenAlertSuccess(true)
	}
	const aplicarDescuento = (event) => {
		gtm_AceptaAplicarDescuento_Log()
		props.handleCloseAlert()
		props.closeModal()
		props.save()
		// handleCloseAlertSuccess()
		// requests
		//     .delete(MB_CONSIGNA_ACT_DES_MODO_VACACIONES, props.IdMbConsigna)
		//     .then((response) => {
		//         props.handleCloseAlert()
		//         props.getConsignas()
		//     })
		//     .catch((error) => {
		//         console.log('Error: ' + error)
		//     })
	}
	const noAplicarDescuento = (event) => {
		gtm_RechazaAplicarDescuento_Log()
		props.handleCloseAlert()
		//props.closeModal()
		// requests
		//     .delete(MB_CONSIGNA_ACT_DES_MODO_VACACIONES, props.IdMbConsigna)
		//     .then((response) => {
		//         props.handleCloseAlert()
		//         props.getConsignas()
		//     })
		//     .catch((error) => {
		//         console.log('Error: ' + error)
		//     })
	}

	return (
		<div>
			<Snackbar
				open={props.openAlert}
				autoHideDuration={6000}
				onClose={props.handleCloseAlert}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert
					className='alert-Warning'
					onClose={props.handleCloseAlert}
					icon={
						<WarningAmberIcon
							fontSize='inherit'
							sx={{ fontSize: 30, color: '#f08080' }}
						/>
					}
				>
					<strong>{props.MensajeTitle}</strong>
					{props.MensajeBody}

					<div className='flex-betweene mt1'>
						<Button
							className='btn-success mr1'
							onClick={function () {
								aplicarDescuento()
							}}
						>
							Aceptar
						</Button>
						<Button
							className='btn-fail mr1'
							// onClick={function () {
							//     props.handleCloseAlert()
							//     props.getConsignas()
							// }}
							onClick={function () {
								noAplicarDescuento()
							}}
						>
							Cancelar
						</Button>
					</div>
				</Alert>
			</Snackbar>
			{/* <AlertsSuccess
                openAlert={openAlertSuccess}
                messageAlert={messageAlertSuccess}
                handleCloseAlert={handleCloseAlertSuccess}
            /> */}
		</div>
	)
}

export default AlertConfirmActionDescount
