/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { Chip, Alert, Fab } from '@mui/material'
import { GUIA_ERP_LIST_BY_ID_CLI } from '../../Constants/ApiConstants'
import InfoIcon from '@mui/icons-material/Info'
import '../../Styles/App/Apartado/Apartado.css'
import requests from '../../Components/AxiosCalls/AxiosCall'
import DownloadIcon from '@mui/icons-material/Download'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Tooltip from '@mui/material/Tooltip'

const EnviosDetalles = (props) => {
	
	const [_GuiasEnvio, setGuiasEnvio] = useState([])
	const [_Descarga, setDescarga] = useState()

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)

	const getGuiasEnvio = () => {
		props.setOpenLoadingScreen()
		requests
			.get(GUIA_ERP_LIST_BY_ID_CLI, userInfo.intIdUsuario)
			.then((response) => {
				setGuiasEnvio(response)

				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				props.setCloseLoadingScreen()
				setDescarga(null)
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getGuiasEnvio()
	}, [])

	return (
		<div>
			<div className='containerViewUser'>
				<div className='flex-start w100'>
					<Chip
						icon={<LocalShippingIcon />}
						label='Envios/ Detalles de envio.'
						color='primary'
						className='back-transparent font-letter font-barter font-20'
					/>
				</div>

				{/* 
				{
					"lngIdGuiaBr": 4,
					"fecDia": "19/01/2023",
					"lngIdProducto": "a0d72e9b-535c-48b1-ad83-d277413b3cd8",
					"fecEnvio": "21/01/2023",
					"fecLlegada": "01/01/0001",
					"txtGuiaBr": "EDS1234",
					"txtObservaciones": "Prueba 4",
					"txtUrlGuia": "https://dl.dropbox.com/s/1gvuemicm76ty1r/guia_DHL_4193804274_Bartelux.pdf?dl=0",
					"txtStatusEnvio": "En tránsito",
					"txtSkuReg": "CVRTNS01029133",
					"txtMarca": "CARVELA",
					"txtArticuloH": "TENIS\r\n",
					"txtArticuloM": null,
					"txtImgProducto": "https://dl.dropbox.com/s/6ppv3bfabmkv74k/tenisprueba.jpg?dl=0"
				} */}

				<div className='box-consigna-pro' hidden={_Ocultar}>
					{_GuiasEnvio.map((row, index) => (
						<div className='box-list-consigna-pro'>
							<div className='flex w100' key={row.txtMarca}>
								<div className='w20'>
									<img className='img-consigna-pro' src={row.txtImgProducto} />
								</div>
								<div className='flex-column-start-between w80'>
									<div className='flex-between w100'>
										<span className='p bold upperCase'>
											{row.txtMarca}
											<span className='mx1'> - </span>
											<span className='p bold'>{row.txtStatusEnvio}</span>
										</span>

										<div className=''>
											<span className='p'>
												<strong>Enviado:</strong> {row.fecEnvio}
											</span>
											<span className='mx1'> - </span>
											<span className='p'>
												<strong>llegada: &nbsp;</strong>

												{row.fecLlegada === '01/01/0001'
													? 'Sin fecha'
													: row.fecLlegada}
											</span>
										</div>
									</div>
									{/*  */}
									<div className='flex-column-start w100'>
										<span className='p '>
											<strong>SKU:</strong> {row.txtSkuReg}
										</span>
										<span className='flex-start p '>
											<strong>Guía de envio: &nbsp;</strong>

											{row.txtGuiaBr}

											<Tooltip title='Copiar'>
												<IconButton
													className='ml1'
													onClick={() =>
														navigator.clipboard.writeText(row.txtGuiaBr)
													}
												>
													<ContentCopyIcon sx={{ fontSize: 20 }} />
												</IconButton>
											</Tooltip>
										</span>
									</div>
									<div className='w100 flex-end'>
										<Button
											className='btn-calendar'
											startIcon={<DownloadIcon />}
											onClick={function () {
												window.open(row.txtUrlGuia, '_blank')
											}}
										>
											Descargar guía
										</Button>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<div hidden={_Ocultar2}>
				<div className='containerViewUser'>
					<Alert
						className='alert-no-data'
						icon={
							<InfoIcon fontSize='inherit' className='alert-icon-no-data' />
						}
					>
						Aún no cuentas con propuestas, sobre tus articulos.
					</Alert>
				</div>
			</div>
		</div>
	)
}

export default EnviosDetalles
