import React, { useEffect, useState } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
import '../../../Styles/Foundit/faqsMB.css'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListItemText from '@mui/material/ListItemText'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
const Accordionn = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    border: '2px solid #E5E7E0',
    borderRadius: 4,
  },
  '&::before': {
    display: 'none',
  },
  '& .Mui-expanded': {
    backgroundColor: '#757D65',
  },
}))

const AccordionSummaryy = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    color: '#fff',
  },
  '& .MuiTypography-root': {
    // color: "#000",
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
}))

const FqSectionVentas = (props) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <section className="FaqsSectionVentas">
      <div className="contentFaqs">
        <span className="titlefqs">Ventas</span>
        <Accordionn
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          className="accordion"
        >
          <AccordionSummaryy
            aria-controls="panel1d-content"
            id="panel1d-header"
            className="acordionbackground"
          >
            <Typography> ¿Cómo puedo agendar una cita con ustedes?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Para agendar una cita con nosotros tendrás que ir directamente a
                nuestra web{' '}
                <a href="www.founditmembers.com" target="_blank">
                  www.founditmembers.com
                </a>
                , después de registrarte y de acuerdo al servicio que requieras
                tendrás que llenar un formulario al término del cual se te re
                direccionará al Calendario para que desde ahí puedas agendar tu
                cita dependiendo del servicio y horarios disponibles.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Cómo puedo vender mis artículos?</Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Lleva a cabo los siguientes pasos:
                <br />
                1. Accede a nuestra plataforma: Ingresa a www.founditmembers.com
                <br />
                2. Rellena el formulario: Completa el formulario con facilidad.
                <br />
                3. Prepara tus fotos: Sube de 5 a 8 fotos claras de tu artículo,
                mostrando todos los ángulos y cualquier defecto, sobre fondo
                blanco.
                <br />
                4. Recibe una propuesta: Nuestro valuador te enviará una oferta
                por correo basada en tus fotos
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Qué hacen con mi artículo consignado?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                En nuestras instalaciones se revisará y validará tu artículo,
                posteriormente se te dará la propuesta final y en caso de
                aceptarla se firmará un contrato de mutuo acuerdo.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Dónde resguardan mi artículo durante el tiempo de consigna?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Se reguarda en nuestro almacén bajo los más estrictos estándares
                de seguridad, este proceso se lleva acabo después de que se
                realiza el marketing para su comercialización.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Por qué puede cambiar el pre-avalúo que realizaron a mi artículo?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Recuerda que tu artículo fue pre-evaluado por medio de
                fotografías, en la valuación presencial nuestros expertos
                certifican que efectivamente tu artículo cuenta con las
                características mencionadas, pero si hubiera algún desgaste o
                defecto no apreciado este sufrirá un cambio en el importe de
                avalúo.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Cuánto es el tiempo estimado de consigna?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Como tu artículo se encuentra resguardado en nuestras
                instalaciones el tiempo máximo es de 3 meses, si al término de
                este plazo no se logra vender puedes optar por retirarlo o
                dejarlo 30 días más.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Existe un límite de artículos a consignar?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                No hay un límite de artículos establecido, lo que significa que
                mientras más consignes más puedes ganar por la venta de tus
                artículos.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Puedo retirar mis artículos si aún no se cumplen los 3 meses?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                En caso de que tus artículos ya se hayan publicado y necesites
                retirarlos antes del término del tiempo de consignación, se
                deberá cubrir un costo operativo por artículo de $1,500 pesos,
                los cuales incluyen, almacenaje, trabajo operativo y marketing
                digital.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel9'}
          onChange={handleChange('panel9')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>¿Qué artículos puedo consignar en Foundit?</Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                A través de Foundit podrás consignar artículos de moda de mujer,
                hombre y estilos unisex; en las categorías zapatos, bolsas y
                accesorios. Los requisitos que todo artículo debe cubrir son
                autenticidad (debe ser un artículo original), estado de uso
                (debe contar con un mínimo desgaste y en buenas condiciones),
                limpieza (los artículos que entregues deberán estar limpios,
                según sea el caso, sin humedad, suciedad y con sus respectivos
                accesorios). Por cuestiones de higiene personal Foundit no
                ofrece servicios relacionados con trajes de baño sin etiqueta,
                ropa interior y/o cosméticos.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel10'}
          onChange={handleChange('panel10')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿En dónde aperecerán mis artículos consignados?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Tus artículos en consigna aparecerán anunciados en nuestra
                página web y redes sociales
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel11'}
          onChange={handleChange('panel11')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Puedo agregar un descuento a mis artículos en venta?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Sí, desde tu perfil de Foundit puedes indicar a cuál de tus
                artículos y por cuanto quieres aplicar el descuento.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel12'}
          onChange={handleChange('panel12')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Cómo puedo saber si ya se vendieron mis artículos?
            </Typography>
          </AccordionSummaryy>
          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                Podrás visualizar el estatus de los artículos a través de tu
                perfil en nuestra página de{' '}
                <a href="www.founditpreloved.com" target="_blank">
                  www.founditpreloved.com
                </a>{' '}
                igualmente, se te enviará un correo electrónico confirmando la
                compra de alguno de tus artículos. <br />
                El pago por tu artículo lo recibirás 6 días hábiles después de
                efectuada la venta y recibirás un correo donde se confirmará tu
                depósito.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <Accordionn
          expanded={expanded === 'panel13'}
          onChange={handleChange('panel13')}
          className="accordion"
        >
          <AccordionSummaryy
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordionbackground"
          >
            <Typography>
              ¿Puedo recuperar alguno de mis artículos si ya se ha vendido?
            </Typography>
          </AccordionSummaryy>

          <AccordionDetails className="acordiondetails">
            <ListItemText className="bodyacordion">
              <p>
                ¡Ups! En caso de que el artículo ya haya sido vendido, no
                podremos hacerte la devolución.
              </p>
            </ListItemText>
          </AccordionDetails>
        </Accordionn>
        <span></span>
      </div>
    </section>
  )
}

export default FqSectionVentas
