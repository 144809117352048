/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
	Card,
	Grid,
	CardContent,
	CardMedia,
	CardActions,
	Button,
	Alert,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { Chip } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import NumberFormat from 'react-number-format'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PreviewImg from '../../../Components/PreviewImg'
import IconButton from '@mui/material/IconButton'
import EventIcon from '@mui/icons-material/Event'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import { CITA_LIST_VM_EMP_BY_ID_CLIENT } from '../../../Constants/ApiConstants'
import '../../../Styles/App/Apartado/Apartado.css'
import CitasEdit from '../../../Components/Preevaluaciones/Citas/CitasEdit'

import { useNavigate } from 'react-router-dom'
import { CALENDAR_VIEW_RESCHEDULE } from '../../../Constants/routesConstants'

import { TablePagination } from '@mui/material'
import * as locales from '@mui/material/locale'
import { createTheme, useTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'

const CitasEmpeño = (props) => {
	const [registroId, setRegistroId] = useState(null)
	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin
	const navigate = useNavigate()

	const [_ListCitas, setListCitas] = useState([])
	const getListCitas = () => {
		props.setOpenLoadingScreen()
		requests
			.get(CITA_LIST_VM_EMP_BY_ID_CLIENT, userInfo.intIdUsuario)
			.then((response) => {
				setListCitas(response)
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				setRegistroId(null)
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getListCitas()
	}, [])

	const fechaActual = new Date()
	function diferencia(date1, date2) {
		const fechaCita = new Date(date2)
		const dia1UTC = Date.UTC(
			date1.getFullYear(),
			date1.getMonth(),
			date1.getDate()
		)
		const dia2UTC = Date.UTC(
			fechaCita.getFullYear(),
			fechaCita.getMonth(),
			fechaCita.getDate()
		)
		var dia = 1000 * 60 * 60 * 24
		var total = (dia2UTC - dia1UTC) / dia
		if (total === 4 || total === 3 || total === 2 || total === 1) {
			return (
				<div className='card-media-box-citas-1'>
					<span> {format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
		if (total > 5) {
			return (
				<div className='card-media-box-citas-2'>
					<span> {format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
		if (total <= 0) {
			return (
				<div className='card-media-box-citas-3'>
					<span> {format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
	}
	function btReAgendar(date1, date2, lngIdCitas) {
		const fechaCita = new Date(date2)
		const dia1UTC = Date.UTC(
			date1.getFullYear(),
			date1.getMonth(),
			date1.getDate()
		)
		const dia2UTC = Date.UTC(
			fechaCita.getFullYear(),
			fechaCita.getMonth(),
			fechaCita.getDate()
		)
		var dia = 1000 * 60 * 60 * 24
		var total = (dia2UTC - dia1UTC) / dia
		if (total === 4 || total === 3 || total === 2 || total === 1) {
			return (
				<Button
					className='btn-calendar mr1'
					startIcon={<EventIcon />}
					onClick={function () {
						setRegistroId(lngIdCitas)
						handleOpen()
						//navigate(CLIENTES_SALES + row.lngIdProducto)
					}}
				>
					Reagendar cita
				</Button>
			)
		}
		if (total > 5) {
			return (
				<Button
					className='btn-calendar mr1'
					startIcon={<EventIcon />}
					onClick={function () {
						setRegistroId(lngIdCitas)
						handleOpen()
						//navigate(CLIENTES_SALES + row.lngIdProducto)
					}}
				>
					Reagendar cita
				</Button>
			)
		}
		if (total <= 0) {
			return (
				<div></div>
				// 	<Button
				// 	className='btn-calendar mr1'
				// 	startIcon={<EventIcon />}
				// 	onClick={function () {
				// 		setRegistroId(lngIdCitas)
				// 		handleOpen()
				// 		//navigate(CLIENTES_SALES + row.lngIdProducto)
				// 	}}
				// >
				// 	Reagendar cita
				// </Button>
			)
		}
	}

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	//abrir modal img
	const [IdImgPreview, setIdImgPreview] = useState()
	const [openModalImgPreview, setModalImgPreview] = useState(false)
	const handleOpenImgPreview = () => {
		setModalImgPreview(!openModalImgPreview)
	}
	/*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION INICIO	::::::::::::::::::::::::::::::::::::::::::::::: */
	const [locale, setLocale] = React.useState('esES')
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}
	const theme = useTheme()
	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales[locale]),
		[locale, theme]
	)
	/*:::::::::::::::::::::::::::::::::::::::::::::::	PAGINACION FIN	::::::::::::::::::::::::::::::::::::::::::::::: */
	return (
		<div>
			<div className='containerViewUser my4 paddingTablets' hidden={_Ocultar}>
				{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
				<div className='flex-start w100 pb2 pl1'>
					<div className='chip-breadcrumbs'>
						<span className='letter-breadcrumbs'>C</span>
					</div>

					<div role='presentation' className='ml2'>
						<Breadcrumbs aria-label='breadcrumb' separator='›'>
							<Link
								underline='hover'
								className='breadcrumb-url '
								onClick={() => {
									navigate(-1)
								}}
							>
								Citas
							</Link>
							<Link underline='hover' className='breadcrumb-url bold'>
								Empeño
							</Link>
						</Breadcrumbs>
					</div>
				</div>

				{_ListCitas.length === 0 ? null : (
					<div className='box-consigna-pro'>
						{_ListCitas
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => (
								<div className='box-list-consigna-pro'>
									<div className='w20 relative'>
										<img
											className='img-consigna-pro'
											src={row.txtUrlImgFrente}
										/>
										<div className='box-view-absolute'>
											<IconButton
												className='btn-blur'
												onClick={function () {
													setIdImgPreview(row.txtUrlImgFrente)
													handleOpenImgPreview()
												}}
											>
												<RemoveRedEyeIcon
													className='RemoveEyeIconConsigna'
													sx={{ m: 0, p: 0, color: '#000000' }}
												/>
											</IconButton>
										</div>
									</div>
									<div className='flex-column-start-between w80 pl1'>
										<div className='flex-column-start w100'>
											<div className='flex-between'>
												<span className='titleConsignaPropuestas upperCase ProductTitleMarca'>
													{row.txtArticulo} {row.txtMarca} de {row.txtMaterial}
													<span className='mx1'> - </span>
													<span className='p titleConsignaPropuestas ProductTitleMarca'>
														{row.txtTipoServicio}
													</span>
												</span>

												<span className='p textFechaConsignaPropuestas co-grey'>
													{diferencia(fechaActual, row.fecCita)}
												</span>
											</div>

											<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
												<strong>Cliente: &nbsp;</strong>
												{row.txtNombreCliente}
											</span>

											<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
												<strong>Estatus Cita: &nbsp;</strong>
												{row.txtStatusCita === null
													? 'Procesando'
													: row.txtStatusCita}
											</span>
											<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
												<strong>Folio: &nbsp;</strong>
												{row.txtFolio}
											</span>
										</div>

										<div className='flex-start'>
											<Button
												className='btn-calendar mr1'
												startIcon={<EventIcon />}
												onClick={function () {
													sessionStorage.setItem('service', 'Empeños')
													navigate(
														`${CALENDAR_VIEW_RESCHEDULE}${row.lngIdCitas}`
													)
												}}
											>
												Reagendar cita
											</Button>
										</div>
									</div>
								</div>
							))}
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'
							spacing={2}
						>
							<Grid item xl={12} className='flex'>
								<ThemeProvider theme={themeWithLocale}>
									<TablePagination
										rowsPerPageOptions={[10, 25, 100]}
										component='div'
										count={_ListCitas.length}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
									/>
								</ThemeProvider>
							</Grid>
						</Grid>
					</div>
				)}
			</div>
			<div hidden={_Ocultar2}>
				<div className='containerViewUser'>
					<Alert
						className='alert-no-data'
						icon={
							<InfoIcon fontSize='inherit' className='alert-icon-no-data' />
						}
					>
						Aún no cuentas con citas agendadas.
					</Alert>
				</div>
			</div>
			<CitasEdit
				registroId={registroId}
				getListCitas={getListCitas}
				open={open}
				onClose={handleOpen}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
				setMessageAlert={props.setMessageAlert}
				setOpenAlert={props.setOpenAlert}
				setMessageAlertWarning={props.setMessageAlertWarning}
				setOpenAlertWarning={props.setOpenAlertWarning}
			/>
			<PreviewImg
				IdImgPreview={IdImgPreview}
				openModalImgPreview={openModalImgPreview}
				onCloseModalImgPreview={handleOpenImgPreview}
			/>
		</div>
	)
}
export default CitasEmpeño
