/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { ThemeProvider } from '@mui/material/styles'
import Theme from '../../../Styles/Theme'
import {
	Chip,
	Card,
	CardContent,
	CardMedia,
	IconButton,
	Typography,
	Box,
} from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { CLIENTES_SECTION_VIEW } from '../../../Constants/routesConstants'
import PreviewImg from '../../../Components/PreviewImg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

const ApartadoEntregado = (props) => {
	const navigate = useNavigate()

	//abrir modal img
	const [IdImgPreview, setIdImgPreview] = useState()
	const [openModalImgPreview, setModalImgPreview] = useState(false)
	const handleOpenImgPreview = () => {
		setModalImgPreview(!openModalImgPreview)
	}

	return (
		<ThemeProvider theme={Theme}>
			<div className='containerViewUser'>
				<div className='flex-start w100'>
					{/*  <Button
                                className='back-gradient-4 bord-radius-10 shadow font-letter font-light'
                                startIcon={<ArrowBackIcon />}
                                onClick={function () {
                                    navigate(CLIENTES_SECTION_VIEW)
                                }}
                            >
                                Regresar
                            </Button> */}
					<Chip
						icon={<ShoppingCartIcon />}
						label='Apartado / Entregados'
						color='primary'
						className='back-transparent font-letter font-barter font-20'
					/>
				</div>

				<div className='box-consigna-pro mt3'>
					{/* {_Propuestas.map((row, index) => ( */}
					<div className='box-list-consigna-pro'>
						<div className='w20 relative'>
							<img
								className='img-consigna-pro'
								src='https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
							/>
							<div className='box-view-absolute'>
								<IconButton
									className='btn-blur'
									onClick={function () {
										setIdImgPreview(
											'https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
										)
										handleOpenImgPreview()
									}}
								>
									<RemoveRedEyeIcon sx={{ m: 0, p: 0, color: '#000000' }} />
								</IconButton>
							</div>
						</div>
						<div className='flex-column-start-between w80 pl1'>
							<div className='flex-column-start w100'>
								<div className='flex-between'>
									<span className='p20 bold upperCase'>
										Ralph Lauren
										{/* <span className='mx1'> - </span>
											<span className='p'>{row.txtTipoServicio}</span> */}
									</span>

									<span className='p co-grey'> Fecha: 01/11/2022</span>
								</div>
								{/* <span className='p '>
										<strong>Cuenta con Cita: &nbsp;</strong>
										{row.bolCitaAgendada === true ? 'si' : 'no'}
									</span> */}
								{/* <span className='p '>
										<strong>Propuesta: &nbsp;</strong>

										<NumberFormat
											value={row.dblMontoPropuesta}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={'$ '}
											suffix={'MXN'}
										/>
									</span> */}
								<span className='p '>
									<strong>Estatus de envio:</strong> Entregado
								</span>
								<span className='p '>
									<strong>Detalles de envio:</strong> Este producto ya se
									encuentra en tu guardarropa.
								</span>
							</div>

							<div className='flex-start w30'>
								{/* {row.bolUpdateMb === false ? (
										<CardActions>
											<Button
												className='btn-success mr1'
												startIcon={<CheckCircleOutlineOutlinedIcon />}
												onClick={function () {
													setIdPropuesta(row.lngIdPropuestas)
													handleChangeSendStatusCita(row.lngIdRegistro)
												}}
											>
												Aceptar
											</Button>
											<Button
												className='btn-fail mr1'
												startIcon={<CancelOutlinedIcon />}
												onClick={function () {
													handleChangeSendStatusRechazado(row.lngIdRegistro)
												}}
											>
												Rechazar
											</Button>
										</CardActions>
									) : null} */}
							</div>
						</div>
					</div>
					{/* ))} */}

					{/* {_Propuestas.map((row, index) => ( */}
					<div className='box-list-consigna-pro'>
						<div className='w20 relative'>
							<img
								className='img-consigna-pro'
								src='https://images.unsplash.com/photo-1603808033192-082d6919d3e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80'
							/>
							<div className='box-view-absolute'>
								<IconButton
									className='btn-blur'
									onClick={function () {
										setIdImgPreview(
											'https://images.unsplash.com/photo-1603808033192-082d6919d3e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80'
										)
										handleOpenImgPreview()
									}}
								>
									<RemoveRedEyeIcon sx={{ m: 0, p: 0, color: '#000000' }} />
								</IconButton>
							</div>
						</div>
						<div className='flex-column-start-between w80 pl1'>
							<div className='flex-column-start w100'>
								<div className='flex-between'>
									<span className='p20 bold upperCase'>
										Gucci
										{/* <span className='mx1'> - </span>
											<span className='p'>{row.txtTipoServicio}</span> */}
									</span>

									<span className='p co-grey'> Fecha: 03/11/2022</span>
								</div>
								{/* <span className='p '>
										<strong>Cuenta con Cita: &nbsp;</strong>
										{row.bolCitaAgendada === true ? 'si' : 'no'}
									</span> */}
								{/* <span className='p '>
										<strong>Propuesta: &nbsp;</strong>

										<NumberFormat
											value={row.dblMontoPropuesta}
											displayType={'text'}
											fixedDecimalScale={true}
											decimalScale={2}
											thousandSeparator={true}
											prefix={'$ '}
											suffix={'MXN'}
										/>
									</span> */}
								<span className='p '>
									<strong>Estatus de envio:</strong> Entregado
								</span>
								<span className='p '>
									<strong>Detalles de envio:</strong> Este producto ya se
									encuentra en tu guardarropa.
								</span>
							</div>

							<div className='flex-start w30'>
								{/* {row.bolUpdateMb === false ? (
										<CardActions>
											<Button
												className='btn-success mr1'
												startIcon={<CheckCircleOutlineOutlinedIcon />}
												onClick={function () {
													setIdPropuesta(row.lngIdPropuestas)
													handleChangeSendStatusCita(row.lngIdRegistro)
												}}
											>
												Aceptar
											</Button>
											<Button
												className='btn-fail mr1'
												startIcon={<CancelOutlinedIcon />}
												onClick={function () {
													handleChangeSendStatusRechazado(row.lngIdRegistro)
												}}
											>
												Rechazar
											</Button>
										</CardActions>
									) : null} */}
							</div>
						</div>
					</div>
					{/* ))} */}
				</div>

				{/* <Grid>
					<Grid
						item
						xs={12}
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						spacing={2}
						elevation={12}
					>
						<Grid item xs={4}>
							<Card sx={{ display: 'flex', height: 200 }}>
								<CardMedia
									component='img'
									sx={{ width: 151 }}
									image='https://images.unsplash.com/photo-1599643478518-a784e5dc4c8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
									alt='Live from space album cover'
								/>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<CardContent sx={{ flex: '1 0 auto' }}>
										<Typography component='div' variant='h5'>
											Ralph Lauren
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Entregado
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Este producto ya se encuentra en tu guardarropa.
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Fecha: 01/11/2022
										</Typography>
									</CardContent>
								</Box>
							</Card>
						</Grid>

						<Grid item xs={4}>
							<Card sx={{ display: 'flex', height: 200 }}>
								<CardMedia
									component='img'
									sx={{ width: 151 }}
									image='https://images.unsplash.com/photo-1603808033192-082d6919d3e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=715&q=80'
									alt='Live from space album cover'
								/>
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<CardContent sx={{ flex: '1 0 auto' }}>
										<Typography component='div' variant='h5'>
											Gucci
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Entregado
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Este producto ya se encuentra en tu guardarropa.
										</Typography>
										<Typography
											variant='subtitle1'
											color='text.secondary'
											component='div'
										>
											Fecha: 03/11/2022
										</Typography>
									</CardContent>
								</Box>
							</Card>
						</Grid>
						<Grid item xs={4}></Grid>
					</Grid>
				</Grid> */}
			</div>

			<PreviewImg
				IdImgPreview={IdImgPreview}
				openModalImgPreview={openModalImgPreview}
				onCloseModalImgPreview={handleOpenImgPreview}
			/>
		</ThemeProvider>
	)
}
export default ApartadoEntregado
