import React, { useEffect, useState } from "react";
import "../../../Styles/App/HomeMB2.css";
import { Fade } from "react-awesome-reveal";
import ServicioApartado from "../../../Images/HomeMB2/ServicioApartado.png";
import Button from "@mui/material/Button";
const Section6HomeWeb = (props) => {
  return (
    <section className="ContainerSection6MB">
      <div className="cardSecttion6MB">
        <div className="section6MB-1">
          <p>Las opiniones de quienes nos eligen:</p>
          {/* *************************** */}
          <Fade direction="right" triggerOnce>
            <div className="cardOpinones">
              <img
                src={
                  "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Corbata+hermes.png"
                }
              />
              {/* <img src={'https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/estrellas2.png'} /> */}
              <div className="optionesBody">
                <div className="HeadBody">
                  <div className="nombreProduct">
                    <img
                      src={
                        "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/Like.svg"
                      }
                    />
                    <span>Corbata Hermes</span>
                  </div>
                  <img
                    src={
                      "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/estrellas1.png"
                    }
                  />
                </div>
                <span className="opinion">
                  Espero que alguien disfrute de mi corbata tanto como yo
                </span>
                <span className="nombreOpinion">Frank |</span>
              </div>
            </div>
          </Fade>
          {/* *************************** */}
          <Fade style={{alignSelf: 'end'}} direction="left" triggerOnce>
            <div className="cardOpinones self">
              <div className="optionesBody">
                <div className="HeadBody">
                  <div className="nombreProduct">
                    <img
                      src={
                        "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/Like.svg"
                      }
                    />
                    <span>Bolsa Celine</span>
                  </div>
                  <img
                    src={
                      "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/estrellas2.png"
                    }
                  />
                </div>
                <span className="opinion">
                  Una bolsa que no puede faltar en tu colección
                </span>
                <span className="nombreOpinion">Paulina |</span>
              </div>
              <img
                src={
                  "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/Bolsa+Celine.png"
                }
              />
            </div>
          </Fade>
          {/* *************************** */}
          <Fade direction="right" triggerOnce>

          <div className="cardOpinones">
            <img
              src={
                "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/b7366604b432aab9dc04d67cd0a4f046.png"
              }
            />

            <div className="optionesBody">
              <div className="HeadBody">
                <div className="nombreProduct">
                  <img
                    src={
                      "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/Like.svg"
                    }
                  />
                  <span>Zapatos Chanel</span>
                </div>
                <img
                  src={
                    "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Iconos/estrellas2.png"
                  }
                />
              </div>
              <span className="opinion">
                Mis zapatos favoritos sin duda alguna
              </span>
              <span className="nombreOpinion">Luisa |</span>
            </div>
          </div>
          </Fade>
        </div>
        <div className="section6MB-2">
          <img
            src={
              "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/Home/DSC01461.png"
            }
          />
        </div>
      </div>
    </section>
  );
};

export default Section6HomeWeb;
