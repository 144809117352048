/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

const PreviewImg = (props) => {
	const handleClose = () => {
		props.onCloseModalImgPreview()
	}

	return (
		<div>
			<Dialog
				fullScreen
				open={props.openModalImgPreview}
				onClose={
					props.onCloseModalImgPreview ? props.onCloseModalImgPreview : null
				}
				TransitionComponent={Transition}
				PaperProps={{
					style: {
						background: 'rgba(255, 255, 255, 0.2)',
						backdropFilter: 'blur(10px)',
					},
				}}
				classN
			>
				<div className='relative modal-view-event'>
					<img className='img-preview-img' src={props.IdImgPreview} />
					<div className='box-modal-absolute'>
						<IconButton onClick={handleClose} className='btn-blur-close'>
							<CloseIcon sx={{ fontSize: 35 }} />
						</IconButton>
					</div>
				</div>
			</Dialog>
		</div>
	)
}

export default PreviewImg
