/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
	Card,
	Grid,
	CardContent,
	CardMedia,
	CardActions,
	Button,
	Alert,
} from '@mui/material'
import { Chip } from '@mui/material'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import NumberFormat from 'react-number-format'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PreviewImg from '../../../Components/PreviewImg'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import EventIcon from '@mui/icons-material/Event'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { format } from 'date-fns'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import { useSelector } from 'react-redux'
import { CITA_LIST_VM_DESEMP_BY_ID_CLIENT } from '../../../Constants/ApiConstants'
import '../../../Styles/App/Apartado/Apartado.css'
import CitasEdit from '../../../Components/Preevaluaciones/Citas/CitasEdit'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'

import Pagination from '@mui/material/Pagination'
import usePagination from '../../../Util/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'
import {
	CALENDAR_VIEW_RMB,
	CALENDAR_VIEW_MB,
} from '../../../Constants/routesConstants'

import {
	gtmGoTo_AgendarCitaDes_Log,
	gtmGoTo_ReagendarCitaDes_Log,
} from '../../../Constants/GoogleTagManager/gtmConstants'

const CitasDesempeño = (props) => {
	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin
	const navigate = useNavigate()
	// userInfo.intIdUsuario

	const [_ListCitas, setListCitas] = useState([])
	const getListCitasDesempeños = () => {
		props.setOpenLoadingScreen()
		requests
			.get(CITA_LIST_VM_DESEMP_BY_ID_CLIENT, userInfo.intIdUsuario)
			.then((response) => {
				// console.log(response)
				setListCitas(response)
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log(error)
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getListCitasDesempeños()
	}, [])

	const fechaActual = new Date()
	function diferencia(date1, date2) {
		const fechaCita = new Date(date2)
		const dia1UTC = Date.UTC(
			date1.getFullYear(),
			date1.getMonth(),
			date1.getDate()
		)
		const dia2UTC = Date.UTC(
			fechaCita.getFullYear(),
			fechaCita.getMonth(),
			fechaCita.getDate()
		)
		var dia = 1000 * 60 * 60 * 24
		var total = (dia2UTC - dia1UTC) / dia
		// setOcultarAgendar(true)
		if (total === 4 || total === 3 || total === 2 || total === 1) {
			return (
				<div className='card-media-box-citas-1'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
		if (total > 5) {
			return (
				<div className='card-media-box-citas-2'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
		if (total <= 0) {
			return (
				<div className='card-media-box-citas-3'>
					<span className=''>{format(new Date(fechaCita), 'dd/MM/yyyy')}</span>
				</div>
			)
		}
	}
	//abrir modal img
	const [IdImgPreview, setIdImgPreview] = useState()
	const [openModalImgPreview, setModalImgPreview] = useState(false)
	const handleOpenImgPreview = () => {
		setModalImgPreview(!openModalImgPreview)
	}
	return (
		<div>
			<div className='containerViewUser my4 paddingTablets' hidden={_Ocultar}>
				{/* :::::::::::::::::::::::::::::::::::::::::::::: Head breadcrumbs ::::::::::::::::::::::::::::::::::::::::::::::*/}
				<div className='flex-start w100 pb2 pl1'>
					<div className='chip-breadcrumbs'>
						<span className='letter-breadcrumbs'>C</span>
					</div>

					<div role='presentation' className='ml2'>
						<Breadcrumbs aria-label='breadcrumb' separator='›'>
							<Link
								underline='hover'
								className='breadcrumb-url '
								onClick={() => {
									navigate(-1)
								}}
							>
								Citas
							</Link>
							<Link underline='hover' className='breadcrumb-url bold'>
								Desempeño
							</Link>
						</Breadcrumbs>
					</div>
				</div>
				{_ListCitas.length === 0 ? null : (
					<div className='box-consigna-pro'>
						{_ListCitas.map((row, index) => (
							<div key={row.lngIdEmpeno} className='box-list-consigna-pro'>
								<div className='w20 relative'>
									<img className='img-consigna-pro' src={row.txtUrl} />
									<div className='box-view-absolute'>
										<IconButton
											className='btn-blur'
											onClick={function () {
												setIdImgPreview(row.txtUrl)
												handleOpenImgPreview()
											}}
										>
											<RemoveRedEyeIcon
												className='RemoveEyeIconConsigna'
												sx={{ m: 0, p: 0, color: '#000000' }}
											/>
										</IconButton>
									</div>
								</div>
								<div className='flex-column-start-between w80 pl1'>
									<div className='flex-column-start w100'>
										<div className='flex-between'>
											<span className='titleConsignaPropuestas upperCase ProductTitleMarca'>
												{row.txtCategoria} {row.txtMarca} {row.txtMaterial}
												{/* <span className='mx1'> - </span>
											<span className='p titleConsignaPropuestas ProductTitleMarca'>
												{row.txtTipoServicio}
											</span> */}
											</span>

											{/* <span className='p textFechaConsignaPropuestas co-grey'>
											{diferencia(fechaActual, row.fecEmpeno)}
										</span> */}
										</div>

										{/* <span className='p textFechaConsignaPropuestas ProductTitleDescription'>
										<strong>Cliente:&nbsp;</strong>
										{row.cliente}
									</span> */}
										<span className='p textFechaConsignaPropuestas ProductTitleDescription mt1'>
											<strong>Folio: &nbsp;</strong>
											{row.txtFolio}
										</span>
										<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
											<strong>Estatus Cita: &nbsp;</strong>
											{row.txtEstatusEmpeno === null
												? 'Procesando'
												: 'EMPEÑO ' + row.txtEstatusEmpeno}
										</span>
										<span className='p textFechaConsignaPropuestas ProductTitleDescription'>
											<strong>SKU: &nbsp;</strong>
											{row.txtSkuReg}
										</span>
										<span className='p textFechaConsignaPropuestas ProductTitleDescription '>
											{row.bolAgendado === false ? (
												<strong>Agenda tu cita aquí</strong>
											) : (
												<strong>Reagenda tu cita aquí</strong>
											)}
										</span>
									</div>

									<div className='flex-start'>
										{row.bolAgendado === false ? (
											<Button
												className='btn-calendar mr1'
												startIcon={<EventIcon />}
												onClick={function () {
													gtmGoTo_AgendarCitaDes_Log()
													navigate(`${CALENDAR_VIEW_MB}${row.lngIdProducto}`)
												}}
											>
												Agendar Cita
											</Button>
										) : (
											<Button
												className='btn-calendar mr1'
												startIcon={<EventIcon />}
												onClick={function () {
													gtmGoTo_ReagendarCitaDes_Log()
													navigate(`${CALENDAR_VIEW_RMB}${row.lngIdProducto}`)
												}}
											>
												Reagendar cita
											</Button>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				)}
			</div>

			<div hidden={_Ocultar2}>
				<div className='containerViewUser'>
					<Alert
						className='alert-no-data'
						icon={
							<InfoIcon fontSize='inherit' className='alert-icon-no-data' />
						}
					>
						Aún no cuentas con citas para desempeños.
					</Alert>
				</div>
			</div>
			<PreviewImg
				IdImgPreview={IdImgPreview}
				openModalImgPreview={openModalImgPreview}
				onCloseModalImgPreview={handleOpenImgPreview}
			/>
		</div>
	)
}

export default CitasDesempeño
