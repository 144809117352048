import React, { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import {
	Card,
	CardActions,
	CardContent,
	Button,
	CardMedia,
	Alert,
	Zoom,
	Fab,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import { CLIENTES_EMP } from '../../../Constants/routesConstants'
import { INTERCAMBIO_LIST_BY_ID_CLIENTE } from '../../../Constants/ApiConstants'
import InfoIcon from '@mui/icons-material/Info'
import HelpIcon from '@mui/icons-material/Help'
import '../../../Styles/App/Apartado/Apartado.css'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { Chip } from '@mui/material'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

import EmpeñoHelp from '../Empeños/EmpeñoHelp'

const ClienteRentas = (props) => {
	const [_Intercambios, setIntercambios] = useState([])
	const navigate = useNavigate()
	const theme = useTheme()
	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin
	const [_Ocultar, setOcultar] = useState(true)
	const [_Ocultar2, setOcultar2] = useState(true)

	const getEmpeños = () => {
		props.setOpenLoadingScreen()
		requests
			.get(INTERCAMBIO_LIST_BY_ID_CLIENTE, userInfo.intIdUsuario)
			.then((response) => {
				setIntercambios(response)
				if (response.length > 0) {
					setOcultar(false)
					setOcultar2(true)
				} else {
					setOcultar(true)
					setOcultar2(false)
				}
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}
	useEffect(() => {
		getEmpeños()
	}, [])

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setOpen(!open)
	}

	return (
		<div>
			<div className='containerViewUser' hidden={_Ocultar}>
				<div className='flex-start w100'>
					<Chip
						icon={<ChangeCircleIcon />}
						label='Rentas / Mis rentas'
						color='primary'
						className='back-transparent font-letter font-barter font-20'
					/>
				</div>

				<Grid container spacing={2}>
					{_Intercambios.map((row, index) => (
						<Grid item xs={16} xl={3} key={index}>
							<Card elevation={9}>
								<div className='card-media-box'>
									<div className='card-media-box1'>
										<p className='mt1'> {row.fecIngreso}</p>
									</div>
									<CardMedia
										className='card-media-box2'
										component='img'
										image={row.txtUrl}
										alt='Live from space album cover'
									/>
								</div>

								<CardContent>
									<p className='title-card-content'>
										{' '}
										{row.intIdProductos} {row.intIdMarca}
									</p>
									{/* <p>
										El producto se encuentra en proceso, esto puede durar 2 hrs.
									</p> */}
								</CardContent>
								<CardActions>
									<Button
										className='btn-abonar-apartado'
										onClick={function () {
											navigate(CLIENTES_EMP + row.lngIdProducto)
										}}
									>
										Abonar
									</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
				</Grid>
				<Fab
					sx={{ position: 'absolute', bottom: 16, right: 16 }}
					aria-label='Add'
					color='primary'
					onClick={function () {
						handleOpen()
					}}
				>
					<HelpIcon />
				</Fab>
			</div>
			<div hidden={_Ocultar2}>
				<Alert
					className='alert-no-data'
					icon={<InfoIcon fontSize='inherit' className='alert-icon-no-data' />}
				>
					Aún no cuentas con artículos rentados.
				</Alert>
			</div>
			<EmpeñoHelp
				open={open}
				onClose={handleOpen}
				setOpenLoadingScreen={props.setOpenLoadingScreen}
				setCloseLoadingScreen={props.setCloseLoadingScreen}
				setOpenSnackBar={props.setOpenSnackBar}
				setMessageSnackBar={props.setMessageSnackBar}
				setTypeSnackBar={props.setTypeSnackBar}
			/>
		</div>
	)
}
export default ClienteRentas
