/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Grid from '@mui/material/Grid'
import { Chip } from '@mui/material'
import PaidIcon from '@mui/icons-material/Paid'
import '../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

import {
	PAGOS_EXTRAUDINARIOS_GUIAS,
	PAGOS_EXTRAUDINARIOS_CERTIFICADOS,
} from '../../Constants/routesConstants'

const PagosExtraudinarios = (props) => {
	const navigate = useNavigate()
	return (
		<div className='containerViewUser p2'>
			<Grid container spacing={2}>
				<Grid item lg={12}>
					<Chip
						icon={<PaidIcon />}
						// label='Pagos Extraudinarios /'
						label='Cobros Adicionales /'
						color='primary'
						className='back-transparent font-letter font-barter font-20'
					/>
				</Grid>
				{/* <Grid item lg={6} className='flex mt2'>
					<div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://portalinnova.cl/wp-content/uploads/2022/08/MBE-Chile-LRM-Comunicaciones-696x464.jpg'
							/>
						</div>

						<div className='card-center'>
							<p className='bold'>Pagos De Guias</p>
							<p className='w80 center'>
								Aquí podrás dar seguimiento al estatus del articulo que haz
								apartado y poder realizar los abonos correspondientes.
								-Información del articulo
							</p>
						</div>

						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(PAGOS_EXTRAUDINARIOS_GUIAS)
								}}
							>
								Pagar Guias
							</Button>
						</div>
					</div>
				</Grid> */}

				<Grid item lg={6} className='flex mt2'>
					<div className='card-container'>
						<div className='w100'>
							<img
								className='img-size'
								src='https://rebloomofficial.com/wp-content/uploads/2020/06/que-es1.jpg'
							/>
						</div>

						<div className='card-center'>
							<p className='bold'>Pago De Certificados</p>
							<p className='w80 center'>
								Aquí podrás dar seguimiento al estatus del articulo que haz
								apartado y poder realizar los abonos correspondientes.
								-Información del articulo
							</p>
						</div>

						<div className=''>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								onClick={function () {
									navigate(PAGOS_EXTRAUDINARIOS_CERTIFICADOS)
								}}
							>
								Pagar Certificados
							</Button>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}

export default PagosExtraudinarios
