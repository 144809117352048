import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Theme from '../../../Styles/Theme';
import requests from '../../AxiosCalls/AxiosCall';
import { PROPUESTA_CREATE } from '../../../Constants/ApiConstants';
import { ThemeProvider } from '@mui/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import '../../../Styles/Styles.css';
import NumberFormat from 'react-number-format';

const PropuestaAdd = (props) => {

	const methods = useForm()
	const { handleSubmit } = methods

	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [_Propuesta, setPropuesta] = useState({
		//lngIdPropuestas: 0,
		lngIdRegistro: 0,
		fecPropuestas: "",
		dblMontoPropuesta: 0,
		bolAprobacion: true
	})
	const LimpiarValores = () => {
		setPropuesta({
			..._Propuesta,
			lngIdRegistro: "",
			fecPropuestas: "",
			dblMontoPropuesta: "",
			bolAprobacion: ""
		})
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		_Propuesta.lngIdRegistro = props.idRegistro;
		requests
			.post(PROPUESTA_CREATE, _Propuesta)
			.then((response) => {
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.mensaje)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.mensaje)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<Dialog
					open={props.open}
					onClose={props.onClose ? props.onClose : null}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<Box component="form" onSubmit={handleSubmit(guardar)} autoComplete='off'>
						<Box className='containerHeaderModal'>
							<DialogTitle
								id='alert-dialog-title'
								className='containerHeaderModal font-letter font-barter font-20'
							>
								Propuesta Folio: <strong>{props.folio}</strong>
							</DialogTitle>
							<Box>
								<Button onClick={handleClose}>
									<CloseIcon
										fontSize='small'
										className='btn-close-icon-modal font-dark'
									/>
								</Button>
							</Box>
						</Box>
						<DialogContent className='dialog-content-modal'>
							<DialogContentText id='alert-dialog-description'>
								<NumberFormat
									label="Monto de la propuesta"
									required
									value={_Propuesta.dblMontoPropuesta}
									customInput={TextField}
									thousandSeparator={true}
									fixedDecimalScale={true}
									prefix={'$'}
									decimalScale={2}
									onValueChange={values => {
										setPropuesta({ ..._Propuesta, dblMontoPropuesta: values.floatValue })
									}}
									name="dblMontoPropuesta"
									color='warning'
									variant="standard"
									size='small'
									fullWidth
								/>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} className='font-dark font-letter'>
								Cerrar
							</Button>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								variant='contained'
								type='submit'
								startIcon={<AddIcon />}
							>
								Agregar
							</Button>
						</DialogActions>
					</Box>
				</Dialog>
			</Dialog>
		</ThemeProvider>
	)
}

export default PropuestaAdd