import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import SaveIcon from '@mui/icons-material/Save'
import Theme from '../../../Styles/Theme'
import requests from '../../AxiosCalls/AxiosCall'
import { RECHAZO_PREEVALUACION } from '../../../Constants/ApiConstants'
import { ThemeProvider } from '@mui/styles'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@mui/material'
import '../../../Styles/Styles.css'

const MotivoRechazo = (props) => {
	const methods = useForm()
	const { handleSubmit } = methods
	const handleClose = () => {
		props.onClose()
		props.getRegistros()
		LimpiarValores()
	}
	const [_Motivo, setMotivo] = useState({
		//lngIdCitas: 0,
		idRegistro: '',
		txtMotivoRechazo: '',
	})
	const handleMotivo = (event) => {
		setMotivo({ ..._Motivo, [event.target.name]: event.target.value })
	}
	const LimpiarValores = () => {
		setMotivo({
			..._Motivo,
			idRegistro: '',
			txtMotivoRechazo: '',
		})
	}

	const guardar = () => {
		props.setOpenLoadingScreen()
		_Motivo.idRegistro = props.idRegistro
		requests
			.put(
				RECHAZO_PREEVALUACION +
					'?idRegistro=' +
					_Motivo.idRegistro +
					'&txtMotivoRechazo=' +
					_Motivo.txtMotivoRechazo
			)
			.then((response) => {
				console.log(response)
				props.setTypeSnackBar('success')
				props.setMessageSnackBar(response.mensaje)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
				handleClose()
			})
			.catch((error) => {
				props.setTypeSnackBar('warning')
				props.setMessageSnackBar(error.mensaje)
				props.setOpenSnackBar(true)
				props.setCloseLoadingScreen()
			})
	}

	return (
		<ThemeProvider theme={Theme}>
			<Dialog
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<Dialog
					open={props.open}
					onClose={props.onClose ? props.onClose : null}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<Box
						component='form'
						onSubmit={handleSubmit(guardar)}
						autoComplete='off'
					>
						<Box className='containerHeaderModal'>
							<DialogTitle
								id='alert-dialog-title'
								className='containerHeaderModal font-letter font-barter font-20'
							>
								Agregar motivo de rechazo
							</DialogTitle>							
						</Box>
						<DialogContent className='dialog-content-modal'>
							<DialogContentText id='alert-dialog-description'>
								<TextField
									required
									name='txtMotivoRechazo'
									value={_Motivo.txtMotivoRechazo}
									onChange={handleMotivo}
									variant='standard'
									color='warning'
									fullWidth
									size='small'
								/>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
								variant='contained'
								type='submit'
								startIcon={<SaveIcon />}
							>
								Guardar
							</Button>
						</DialogActions>
					</Box>
				</Dialog>
			</Dialog>
		</ThemeProvider>
	)
}

export default MotivoRechazo
