import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import CircleIcon from '@mui/icons-material/Circle'
import { makeStyles } from '@mui/styles'
import bolsoPlateado from '../Images/HomeMB2/BolsoPlateado.png'
import zapatos from '../Images/HomeMB2/Zapatos.png'
import bolsos from '../Images/HomeMB2/Bolsos.png'
import accesorios from '../Images/HomeMB2/Accesorios.png'
import flecha from '../Images/HomeMB2/FlechaDorada.png'
import flecha2 from '../Images/HomeMB2/FlechaDorada2.png'
import iconpaso1 from '../Images/HomeMB2/Iconpaso1.png'
import iconpaso2 from '../Images/HomeMB2/Iconpaso2.png'
import iconpaso3 from '../Images/HomeMB2/Iconpaso3.png'
import Comming from '../Images/coming.png'
import ServicioConsigna from '../Images/HomeMB2/ServicioConsigna.png'
import ServicioEmpeño from '../Images/HomeMB2/ServicioEmpeño.png'
import ServicioApartado from '../Images/HomeMB2/ServicioApartado.png'
import tabsArrowLeft from '../Images/HomeMB2/TabsArrowLeft.png'
import tabsArrowRight from '../Images/HomeMB2/TabsArrowRight.png'
import opinion1 from '../Images/HomeMB2/opinion1.png'
import opinion2 from '../Images/HomeMB2/opinion2.png'
import opinion3 from '../Images/HomeMB2/opinion3.png'
import section8 from '../Images/HomeMB2/ImageSection8.png'
import systrac from '../Images/HomeMB2/systrack.png'
import '../Styles/App/HomeMB2.css'
import SliderHome2M from './Slider/SliderHome2M'
import RegisterMb from './Login/RegisterMb'
import { CUESTIONARIO, CITAS_MB } from '../Constants/routesConstants'
import SliderHome2MServicios from './Slider/SliderHome2MServicios'
import {
  gtmOpenRegisterUNL,
  gtmGoTo_EmpeñaAqui_Log,
  gtmGoTo_MisCitas_Log,
  gtmGoTo_ProcesoAutenticacion_Log,
  gtmGoTo_Shopify_Log,
  gtmGoTo_ShopifyCompra_Log,
} from '../Constants/GoogleTagManager/gtmConstants'
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp'
import EastIcon from '@mui/icons-material/East'
import Section2HomeWeb from './SectionsHomeMB2/Section2/Section2Web'
import Section2HomeMobile from './SectionsHomeMB2/Section2/Section2Mobile'
import { Fade } from 'react-awesome-reveal'
import Section3HomeWeb from './SectionsHomeMB2/Section3/Section3Web'
import Section4HomeWeb from './SectionsHomeMB2/Section4/Section4Web'
import Section5HomeWeb from './SectionsHomeMB2/Section5/Section5Web'
import Section5HomeMobile from './SectionsHomeMB2/Section5/Section5Mobile'
import Section6HomeWeb from './SectionsHomeMB2/Section6/Section6Web'
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBarTransparent: {
    display: 'block',
    // position: 'fixed',
    // color: '#cbb878',
    // bottom: '30px',
    // fontSize: '120px !important',
    // backgroundColor: '#0000001a !important',
    transition: 'all .9s ease-in-out !important',
    // backdropFilter: 'blur(3px)',
    opacity: 1,
  },
  appBarSolid: {
    // display: 'none',
    // backgroundColor: '#183076 !important',
    transition: 'all .9s ease-in-out !important',
    opacity: 0,
  },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cfb76d',
    color: '#000',
    textTransform: 'uppercase',
    fontWeight: '600',
    // fontSize: window.screen.width <= 600 ? 9 : null,
    border: 0,
    paddingTop: 5,
    paddingBottom: 7,
    // borderRadius: '20px',
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#545454',
    // padding: window.screen.width <= 600 ? 13 : null,
    // fontSize: window.screen.width <= 600 ? 8 : null,
    border: 0,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
    fontWeight: 'bold',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const HomeMundoBarter2 = (props) => {
  const [navBackground, setNavBackground] = useState('appBarTransparent')
  const navRef = React.useRef()
  navRef.current = navBackground
  const classes = useStyles()
  const navigate = useNavigate()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const [openSingUp, setOpenSingUp] = useState(false)
  const handleOpenSingUp = () => {
    gtmOpenRegisterUNL()
    setOpenSingUp(!openSingUp)
  }

  const handleCloseSingUp = () => {
    setOpenSingUp(false)
  }
  const handleChange = (event, newValue) => {
    console.log(newValue)
    // setValue(newValue)
  }
  function createData(name, calories, fat) {
    return { name, calories, fat }
  }
  const rows = [
    createData('-', '$ 10,000.00 MXN', '70%'),
    createData('$ 10,001.00 MXN', '$ 20,000.00 MXN', '75%'),
    createData('$ 20,001.00 MXN', '$ 35,000.00 MXN', '80%'),
    createData('$ 35,001.00 MXN', '$ 100,000.00 MXN', '82%'),
    createData('$ 100,001.00 MXN', '+', '85%'),
  ]
  function createDataa(name, calories) {
    return { name, calories }
  }
  const rowsMovil = [
    createDataa('-', '70%'),
    createDataa('$ 10,001.00', '75%'),
    createDataa('$ 20,001.00', '80%'),
    createDataa('$ 35,001.00', '82%'),
    createDataa('$ 100,001.00', '85%'),
  ]
  const columns = [
    {
      id: 'CostoMinimo',
      label: 'Costo Mínimo',
      radius: '30px 0px 0px 30px',
    },
    {
      id: 'CostoMaximo',
      label: 'Costo Máximo',
    },
    {
      id: 'GananciaFinal',
      label: 'Ganancia Final',
      radius: '0px 30px 30px 0px',
    },
  ]
  const columnsMovil = [
    {
      id: 'Costo',
      label: 'Costo',
      // radius: '30px 0px 0px 30px',
    },
    {
      id: 'Ganancia',
      label: 'Ganancia',
      // radius: '0px 30px 30px 0px',
    },
  ]
  const goShopify = () => {
    window.open('https://founditpreloved.com/', '_blank')
  }
  const goLuxury = () => {
    window.open(
      'https://founditpreloved.com/collections/disenadores#tab-luxury',
      '_blank'
    )
  }

  const goShopifyCompra = () => {
    window.open(
      'https://founditpreloved.com/collections/recien-llegados',
      '_blank'
    )
  }
  const goAuthenticationProcess = () => {
    window.open(
      'https://founditpreloved.com/pages/proceso-de-autentificacion',
      '_blank'
    )
  }

  useEffect(() => {
    const handScroll = () => {
      var data = 0
      if (window.screen.width <= 600) {
        data = 4900
      } else {
        data = 3800
      }
      const show = window.scrollY > data
      if (show) {
        setNavBackground('appBarSolid')
      } else {
        setNavBackground('appBarTransparent')
      }
    }
    document.addEventListener('scroll', handScroll)
    return () => {
      document.removeEventListener('scroll', handScroll)
    }
  }, [])
  return (
    <div className="bg-white-color">
      {/* //? ******************* SECTION 1 ************************* */}
      <section className="ContainerSection1MB">
        <div className="contentSection1MB">
          <p className="titlesection1MB">love</p>
          <p className="titlesection1MB">at First</p>
          <p className="titlesection1MB">Find</p>
          <p className="subtitlesection1MB">
            Vende o empeña tu preciada pieza preloved, zapatos, bolsos o
            accesorios de manera rápida, segura y confiable
          </p>
        </div>
      </section>
      {/* //? ******************* SECTION 2 ************************* */}
      {window.screen.width <= 600 ? (
        <Section2HomeMobile props={props} />
      ) : (
        <Section2HomeWeb props={props} />
      )}
      {/* //? ******************* SECTION 3 ************************* */}
      <Section3HomeWeb props={props} />
      {/* //? ******************* SECTION 4 ************************* */}
      <Section4HomeWeb props={props} />
      {/* //? ******************* SECTION 5 ************************* */}

      {window.screen.width <= 600 ? (
        <Section5HomeMobile props={props} />
      ) : (
        <Section5HomeWeb props={props} />
      )}
      {/* //? ******************* SECTION 6 ************************* */}
      <Section6HomeWeb props={props} />
      {/* //TODO SECCIONES ANTIGUAS APARTIR DE AQUÍ */}
      {/* <section className="ContainerSection6MB">
        <div className="CardSection6MB">
          <div className="Img"></div>
          <div className="PiezasOriginales">
            <p className="TitlePiezas">Todas nuestras piezas son originales.</p>
            <p className="SubtitlePiezas">
              Antes de aceptar los artículos nuestros expertos los autentifican,
              se revisan cuidadosamente y se hace uso de la tecnología{" "}
              <span>Entrupy</span> para estar 100% seguros de su originalidad.{" "}
            </p>

            <Button
              onClick={function () {
                gtmGoTo_ProcesoAutenticacion_Log();
                goAuthenticationProcess();
              }}
              variant="contained"
            >
              Conocer Más
            </Button>
          </div>
        </div>
      </section>
      <section>
        <div className="ContainerSection7MB">
          <p className="TitleSection">
            Opiniones del <span>Mundo Barter</span>
          </p>
          {window.screen.width <= 600 ? (
            <SliderHome2M />
          ) : (
            <div className="ContainerOpiniones">
              <fieldset className="fielsetOpiniones">
                <legend>
                  <img src={opinion1} />
                </legend>

                <div className="mensaje">
                  <p>Zapatos Yves Saint Laurent</p>
                  <span>
                    Los amo, pero con mi embarazo tuve que dejar de usar muchos
                    de mis zapatos, los dejé para venta y fue súper rápido.
                  </span>
                </div>
                <div className="autor">
                  <span>Ana Laura</span>
                  <span>@analauracassis</span>
                </div>
              </fieldset>
              <fieldset className="fielsetOpiniones">
                <legend>
                  <img src={opinion2} />
                </legend>

                <div className="mensaje">
                  <p>Bolsa Burberry</p>
                  <span>
                    Es una bolsa clásica, cualquiera debería tenerla entre sus
                    básicos, ojalá alguien la disfrute tanto como yo.
                  </span>
                </div>
                <div className="autor">
                  <span>Karla A</span>
                </div>
              </fieldset>
              <fieldset className="fielsetOpiniones">
                <legend>
                  <img src={opinion3} />
                </legend>

                <div className="mensaje">
                  <p>Accesorio Hermes</p>
                  <span>
                    Después de la pandemia deje de usar traje para ir a la
                    oficina, son piezas básicas pero ahora tengo demasiadas.
                  </span>
                </div>
                <div className="autor">
                  <span>Alberto Valdez S</span>
                </div>
              </fieldset>
            </div>
          )}
        </div>
      </section>
      <section>
        <div className="ContainerSection8MB">
          <p>
            Únete al mundo <span>Barterluxe</span>
          </p>

          {window.screen.width <= 600 ? (
            <div className="ContentValuarPiezas">
              <img src={section8} />
              <div className="ContentActionButtons">
                <p>
                  Sin salir de casa, registra <br /> tus piezas en línea
                </p>
                <Button
                  variant="contained"
                  onClick={
                    userInfo
                      ? function () {
                          navigate(CUESTIONARIO);
                          gtmGoTo_EmpeñaAqui_Log();
                        }
                      : function () {
                          handleOpenSingUp();
                        }
                  }
                >
                  Valuar mis piezas
                </Button>
              </div>
              <div className="ContentActionButtons">
                <p>
                  Visita nuestro ShowRoom y <br /> trae tus piezas a consignar.
                </p>
                <Button
                  variant="contained"
                  onClick={
                    userInfo
                      ? function () {
                          navigate(CITAS_MB);
                          gtmGoTo_MisCitas_Log();
                        }
                      : function () {
                          handleOpenSingUp();
                        }
                  }
                >
                  Generar cita
                </Button>
              </div>
            </div>
          ) : (
            <div className="ContentValuarPiezas">
              <div className="ContentActionButtons">
                <p>
                  Sin salir de casa, registra <br /> tus piezas en línea
                </p>
                <Button
                  variant="contained"
                  onClick={
                    userInfo
                      ? function () {
                          navigate(CUESTIONARIO);
                          gtmGoTo_EmpeñaAqui_Log();
                        }
                      : function () {
                          handleOpenSingUp();
                        }
                  }
                >
                  Valuar mis piezas
                </Button>
              </div>
              <img src={section8} />
              <div className="ContentActionButtons">
                <p>
                  Visita nuestro ShowRoom y <br /> trae tus piezas a consignar.
                </p>
                <Button
                  variant="contained"
                  onClick={
                    userInfo
                      ? function () {
                          navigate(CITAS_MB);
                          gtmGoTo_MisCitas_Log();
                        }
                      : function () {
                          handleOpenSingUp();
                        }
                  }
                >
                  Generar cita
                </Button>
              </div>
            </div>
          )}

          <div className="ContentReutilizaRenueva">
            <p className="txt1">
              ¡Sácale provecho a las piezas que ya no usas!
            </p>
            <div className="ReutilizaRenueva">
              <span>Reutiliza</span>
              <span className="dividerS8">|</span>
              <span>Reinvéntate</span>
              <span className="dividerS8">|</span>
              <span>Renueva</span>
            </div>
            <p className="txt2">
              En <span>BarterLuxe</span> reinventamos el lujo.
            </p>
          </div>
        </div>
      </section> */}
      <RegisterMb open={openSingUp} onClose={handleCloseSingUp} props={props} />
    </div>
  )
}

export default HomeMundoBarter2
