import React, { useEffect, useState } from "react";
import "../../../Styles/Foundit/facturacion.css";

import Button from "@mui/material/Button";
const FactSection2Web = (props) => {
  return (
    <section className="FacSection2MB">
      <div className="cardVSection3">
        <div className="cardSection1">
          <img
            className="Logo"
            src={
              "https://barterluxe-mx.s3.us-east-2.amazonaws.com/FounditWeb/Desktop/FoundIT+Logos/Foundit+Logo+BLACK.png"
            }
          />
          <span className="text">
          Confía en nuestro proceso y asegura que tus artículos Preloved reciban la atención y el valor que merecen
          </span>
        </div>
      </div>
    </section>
  );
};

export default FactSection2Web;
