/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../Styles/TerminosConditions/TermsConditions.css";
import "../Styles/Foundit/faqsMB.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import FqSection1Web from "../Components/SectionFaqs/Section1/FqSection1Web";
import FqSectionVentas from "../Components/SectionFaqs/Section2/FqSectionVentas";
import FqSectionEmpeños from "../Components/SectionFaqs/Section3/FqSectionEmpeños";
import FqSectionCompras from "../Components/SectionFaqs/Section4/FqSectionCompras";
import FactSection1Web from "../Components/SectionFacturacion/Section1/FactSection1Web";
import FactSection2Web from "../Components/SectionFacturacion/Section2/FactSection2Web";
const FacturacionFoundit = () => {
  return (
    <div className="bg-white-color">
      {/* //? ******************* SECTION 1 ************************* */}
      <FactSection1Web />
      {/* //? ******************* SECTION 2 ************************* */}
     <FactSection2Web />
    </div>
  );
};

export default FacturacionFoundit;
