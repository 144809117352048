/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import requests from '../../../Components/AxiosCalls/AxiosCall'
import { CANCELACIONES_INFO } from '../../../Constants/ApiConstants'
import Theme from '../../../Styles/Theme'
import { ThemeProvider } from '@mui/styles'
import { Chip, Grid, Button, Dialog, AppBar, Toolbar, Slide } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router-dom'

import { CANCELACIONES_VIEW } from '../../../Constants/routesConstants'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CancelacionInfo = (props) => {
	const { id } = useParams()
	const navigate = useNavigate()
	const [_Registro, setRegistro] = useState([])
    const [_UrlImage, setUrlImage] = useState(false)    
	const getRegistros = () => {
		props.setOpenLoadingScreen()
		requests
			.get(CANCELACIONES_INFO, id)
			.then((response) => {
				setRegistro(response)				
				props.setCloseLoadingScreen()
			})
			.catch((error) => {
				console.log('Error: ' + error)
				props.setCloseLoadingScreen()
			})
	}

	useEffect(() => {
		getRegistros()
	}, [])

	const [open, setOpen] = useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<ThemeProvider theme={Theme}>
			<Grid>
				<Grid item xs={4} sm={8} md={12} lg={12} elevation={12} sx={{ mb: 2 }}>

					<Grid
						Grid
						item
						xs={4}
						sm={4}
						md={4}
						lg={12}
						style={{ display: "flex", alignItems: "center" }}
					>
						<Button
							className='back-gradient-4 bord-radius-10 shadow font-letter font-light'
							startIcon={<ArrowBackIosIcon />}
							onClick={function () {
								navigate(CANCELACIONES_VIEW)
							}}
						>
							Regresar
						</Button>
						<Chip icon={<CancelPresentationIcon />} label=' Cancelaciones / Detalles' color='primary' className='back-transparent font-letter font-barter font-20 ml-10' />

					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3}>
					<Grid item xs={12} md={4} xl={4}>
						<strong>Folio: </strong> {_Registro.txtFolio}
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<strong>Fecha: </strong> {_Registro.fecRegistro}
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<strong>No. pedido: </strong> {_Registro.txtNumPedido}
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<strong>Nombre: </strong> {_Registro.txtNombre}
					</Grid>

					<Grid item xs={12} md={4} xl={4}>
						<strong>Email: </strong> {_Registro.txtEmail}
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<strong>Tipo: </strong> {_Registro.bolTipoSolicitud === true ? 'Cancelacion ' : 'Devolucion'}
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<strong>Motivo: </strong>
						{_Registro.txtMotivo}
					</Grid>

					<Grid item xs={12} md={4} xl={4}>
						<strong>Titular de la cuenta: </strong>
						{_Registro.txtTitularCuenta}
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<strong>Banco: </strong>
						{_Registro.txtBanco}
					</Grid>

					<Grid item xs={12} md={4} xl={4}>
						<strong>Clabe interbancaria: </strong>
						{_Registro.txtClabeInterbancaria}
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<strong>No. tarjeta: </strong>
						{_Registro.txtNumTarjeta}
					</Grid>
					<Grid item xs={12} md={4} xl={4}>
						<strong>Ubicación del articulo: </strong> {_Registro.txtDomicilio}
					</Grid>

					<Grid item xs={12} md={4} xl={4}>
						<strong>tipo de cancelación: </strong>
						{_Registro.bolTipoCancelacion === true ? 'Parcial' : 'Total'}
					</Grid>

					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Grid item xs={12} md={4} xl={4}>
							<div className='flex-column'>
								<img src={_Registro.txtUrlComprobantePago} width="100px" height="100px" />
								<Button className='back-gradient-2 bord-radius-10 shadow font-letter font-light mt-10 w50' onClick={function () {
									setUrlImage(_Registro.txtUrlComprobantePago)
									handleClickOpen()
								}} >
									Ver imagen
								</Button>
							</div>
						</Grid>
						<Grid item xs={12} md={4} xl={4}>
							<div className='flex-column'>
								<img src={_Registro.txtUrlIdentificacion} width="100px" height="100px"></img>
								<Button className='back-gradient-2 bord-radius-10 shadow font-letter font-light mt-10 w50' onClick={function () {
									setUrlImage(_Registro.txtUrlIdentificacion)
									handleClickOpen()
								}} >
									Ver imagen
								</Button>
							</div>
						</Grid>
						<Grid item xs={12} md={4} xl={4}>
							<div className='flex-column'>
								<img src={_Registro.txtUrlProducto} width="100px" height="100px"></img>
								<Button className='back-gradient-2 bord-radius-10 shadow font-letter font-light mt-10 w50' onClick={function () {
									setUrlImage(_Registro.txtUrlProducto)
									handleClickOpen()
								}} >
									Ver imagen
								</Button>
							</div>
						</Grid>
						<Grid item xs={12} md={4} xl={4}>
							<div className='flex-column'>
								<img src={_Registro.txtUrlComprobantePagoCostos} width="100px" height="100px"></img>
								<Button className='back-gradient-2 bord-radius-10 shadow font-letter font-light mt-10 w50' onClick={function () {
									setUrlImage(_Registro.txtUrlComprobantePagoCostos)
									handleClickOpen()
								}} >
									Ver imagen
								</Button>
							</div>
						</Grid>						
					</Grid>
					{/* </div> */}

				</Grid>
			</Grid>

			{/* Dialogo para ver imagen en grande */}

			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative' }} className='none-shadow back-white'>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon className='font-dark'/>
						</IconButton>
				
					</Toolbar>
				</AppBar>
				<div className='center flex'>
					<img src={_UrlImage} width="40%" />
				</div>
			</Dialog>
		</ThemeProvider>
	)
}
export default CancelacionInfo