import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Button from '@mui/material/Button'
import '../../Styles/App/User/Profile.css'
import requests from '../../Components/AxiosCalls/AxiosAuth'
import { CONTRATOS_DOCUMENTS_MB } from '../../Constants/ApiConstants'
import CloseIcon from '@mui/icons-material/Close'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import PreviewIcon from '@mui/icons-material/Preview';
import EjectIcon from '@mui/icons-material/Eject';
import AbrirPdf from '../Clientes/AbrirPdf'
import ModalFirmaDesempeño from './Registro/FirmaDesempeño'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#D7D1C4',
		color: '#545454',
		textTransform: 'uppercase',
		fontWeight: '800',
		fontSize: window.screen.width <= 600 ? 13 : 15,
		border: 0,
	},
	[`&.${tableCellClasses.body}`]: {
		color: '#545454',
		padding: window.screen.width <= 600 ? 13 : null,
		fontSize: window.screen.width <= 600 ? 13 : 18,
		border: 0,
	},
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})
const DetailsContratos = (props) => {
	const [_Contratos, setContratos] = React.useState([])
	const [_Desempeño, setDesempeño] = React.useState(null)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(5)
	const [openPdf, setOpenPdf] = React.useState(false)
	const [_OpenModalDesempeño, setOpenModalDesempeño] = React.useState(false)

	const [_idDocContrato, setIdDocContrato] = React.useState(null)
	const [IdTipoDoc, setIdTipoDoc] = React.useState(null)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleCloseModalDesempeño = () => {
		setOpenModalDesempeño(false)
	}

	const handleOpenModalDesempeño = () => {
		setOpenModalDesempeño(true)
	}

	const handleOpenPdf = (id) => {
		if (openPdf === false) {
			setOpenPdf(!openPdf)
		} else {
			setOpenPdf(!openPdf)
			setIdDocContrato(null)
		}
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}
	const handleClose = () => {
		props.onClose()
	}

	const columns = [
		{
			id: 'Tipo',
			label: 'Tipo',
			minWidth: window.screen.width <= 600 ? 45 : 80,
		},
		{
			id: 'descargar',
			label: 'Contrato',
			minWidth: window.screen.width <= 600 ? 65 : 100,
		},
		_Desempeño !== null && _Desempeño !== true ?({
			id: 'desempreñar',
			label: 'Desempeño',
			minWidth: window.screen.width <= 600 ? 65 : 100,
		}):({})
			
	]

	const getDetailContrato = () => {
		requests
			.get(CONTRATOS_DOCUMENTS_MB, props.idContrato)
			.then((response) => {
				setContratos(response)
			})
			.catch((error) => {
				console.log(error)
			})
	}
	
	const getContrato = () =>{
		requests
			.get("Contratos/BuscarPorId/" + props.idContrato)
			.then((response) => {
				debugger
				setDesempeño(response.bolDesempeno)
			})
			.catch((error) => {	
				debugger
				console.log(error)
			})
	}

	useEffect(() => {
		getDetailContrato()
		getContrato()
	}, [])
	return (
		<>
			<Dialog
				fullScreen
				open={props.open}
				onClose={props.onClose ? props.onClose : null}
				TransitionComponent={Transition}
				PaperProps={{
					style: {
						background:
							window.screen.width <= 600
								? 'rgba(255, 255, 255, 0.2)'
								: 'rgba(255, 255, 255, 0.2)',
					},
				}}
				className={'backdropfilter'}
			>
				<div className='container-modal-FacturasDetail'>
					<div className='header-modal-purchase flex-between'>
						<p className='text-TitleDacturasDetail'>Detalles Contratos</p>
						<IconButton aria-label='delete' onClick={handleClose}>
							<CloseIcon sx={{ color: 'black' }} />
						</IconButton>
					</div>

					<div className='container-body-modal'>
						<div className=''>
							{_Contratos.length === 0 ? (
								<span className='NohayContratos'>Sin información</span>
							) : (
								<>
									<Paper
										sx={{
											width: '100%',
											overflow: 'hidden',
											boxShadow: 'none',
										}}
									>
										<TableContainer sx={{ maxHeight: 500 }}>
											<Table stickyHeader aria-label='sticky table'>
												<TableHead>
													<TableRow>
														{columns.map((column) => (
															<StyledTableCell
																key={column.id}
																align={column.align}
																style={{ minWidth: column.minWidth }}
															>
																<span>{column.label}</span>
															</StyledTableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{_Contratos
														.slice(
															page * rowsPerPage,
															page * rowsPerPage + rowsPerPage
														)
														.map((row) => {
															console.log(row)
															return (
																<StyledTableRow
																	hover
																	role='checkbox'
																	tabIndex={-1}
																	key={row.lngIdDocContrato}
																	className='font-weight6'
																>
																	<StyledTableCell>
																		{row.tipoDocContrato}
																	</StyledTableCell>

																	<StyledTableCell>
																	{row.bolFirmado ? ( 
																		<Button
																			onClick={function () {
																				window.open(row.txtUrlDoc, '_blank')
																			}}
																			size='small'
																			className='btn-table-Contratos-Download'
																			startIcon={<CloudDownloadIcon />}
																		>
																			Descargar
																		</Button>
																		):(
																		<Button
																			onClick={function () {
																				
																				handleOpenPdf()
																				setIdDocContrato(row.lngIdDocContrato)
																				setIdTipoDoc(row.intIdTipoDocContrato)
																			}}
																			size='small'
																			className='btn-table-Contratos-Download'
																			startIcon={<PreviewIcon />}
																		>
																			Ver
																		</Button>
																		)}
																	</StyledTableCell>
																	<StyledTableCell>
																	{_Desempeño !== null && _Desempeño !== true ? ( 
																		<Button
																			onClick={function () {
																				handleOpenModalDesempeño()
																				setIdDocContrato(row.lngIdDocContrato)
																			}}
																			size='small'
																			className='btn-table-Contratos-Download'
																			startIcon={<EjectIcon />}
																		>
																			Firmar Desempeño
																		</Button>																		
																		):(null)}
																	</StyledTableCell>

																</StyledTableRow>
															)
														})}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[10, 25, 100]}
											component='div'
											count={_Contratos.length}
											rowsPerPage={rowsPerPage}
											page={page}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
										/>
									</Paper>
								</>
							)}
						</div>
					</div>
				</div>
				{_idDocContrato !== null ? (
					<AbrirPdf
						open={openPdf}
						onClose={handleOpenPdf}
						IdDocContrato = {_idDocContrato}
						setOpenLoadingScreen={props.setOpenLoadingScreen}
						setCloseLoadingScreen={props.setCloseLoadingScreen}
						setOpenSnackBar={props.setOpenSnackBar}
						setMessageSnackBar={props.setMessageSnackBar}
						setTypeSnackBar={props.setTypeSnackBar}
					/>					
				): null}
				<ModalFirmaDesempeño
					open={_OpenModalDesempeño}
					onClose={handleCloseModalDesempeño}
					IdDocContrato = {_idDocContrato}
					setOpenLoadingScreen={props.setOpenLoadingScreen}
					setCloseLoadingScreen={props.setCloseLoadingScreen}
					setOpenSnackBar={props.setOpenSnackBar}
					setMessageSnackBar={props.setMessageSnackBar}
					setTypeSnackBar={props.setTypeSnackBar}
					/>
			</Dialog>
		</>
	)
}

export default DetailsContratos
