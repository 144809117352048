/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import Grid from '@mui/material/Grid'
import { Chip } from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import '../../../Styles/GeneralStyles.css'
import Button from '@mui/material/Button'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import imgPropuestas from '../../../Images/MB-Empena/Propuestas_Recibidas.jpg'

import {
	CLIENTES_SECTION_PRO,
	CLIENTES_SECTION_ENT,
	CLIENTES_SECTION_GUI,
	CLIENTES_SECTION_TRA,



} from '../../../Constants/routesConstants'

const ClientesApartadoView = (props) => {
	const navigate = useNavigate()

	return (

		<div className='containerViewUser py4 paddingTablets'>
			<div className='flex-start w90 pb2 pl1'>
				<div className='chip-breadcrumbs'>
					<span className='letter-breadcrumbs'>A</span>
				</div>

				<div role='presentation' className='ml2'>
					<Breadcrumbs aria-label='breadcrumb' separator='›'>
						<Link underline='hover' className='breadcrumb-url bold' href='/'>
							Apartados
						</Link>
					</Breadcrumbs>
				</div>
			</div>
			<Grid container spacing={2}>
			<Grid item lg={12} className='flex-column text-center'>
					<div
						style={{
							backgroundColor: '#9EB5AE',
							width: '95%',
							borderRadius: '10px',
						}}
					>
						<p className='pl3 pr3 co-black '>
							<span className='bold'>Apartados:</span> Gestiona los pagos de tu apartado, 
							podrás pagar todo o hacer abonos extraordinarios al saldo pendiente en cualquier momento.
						</p>
					</div>
				</Grid>
			<Grid item xs={12} md={6} lg={6} className='flex mt2'>
					<div
						className='card'
						style={{
							backgroundImage: `url(${imgPropuestas})`,
							// backgroundImage: `url("https://images.unsplash.com/photo-1525103504173-8dc1582c7430?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80")`,
						}}
					>
						<div className='card-content'>
							<h2 className='card-title'>Piezas apartadas</h2>
							<p className='card-body'>
								En este módulo podrás darle seguimiento a tus articulos apartados.
							</p>
							<Button
								className='buttonn'
								onClick={function () {
									navigate(CLIENTES_SECTION_PRO)

									// if (_Complet !== false) {
									// 	gtmGoTo_Propuestas_Emp_Log()
									// 	navigate(CLIENTES_EMP_PROPUESTAS_VIEW)
									// } else {
									// 	props.setTypeSnackBar('warning')
									// 	props.setMessageAlertWarning(
									// 		'Debes completar tu perfil, para administrar tus ventas y tus empeños.'
									// 	)
									// 	props.setOpenAlertWarning(true)
									// }
								}}
							>
								Ver artículos
							</Button>
						</div>
					</div>
				</Grid>
			</Grid>


		</div>

		// <div className='containerViewUser p2'>
		// 	<Grid container spacing={2}>
		// 		<Grid item lg={12}>
		// 			<Chip
		// 				icon={<ShoppingCartIcon />}
		// 				label='Apartado /'
		// 				color='primary'
		// 				className='back-transparent font-letter font-barter font-20'
		// 			/>
		// 		</Grid>
		// 		<Grid item lg={6} className='flex mt2'>
		// 			<div className='card-container'>
		// 				<div className='w100'>
		// 					<img
		// 						className='img-size'
		// 						src='https://images.unsplash.com/photo-1523381210434-271e8be1f52b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
		// 					/>
		// 				</div>

		// 				<div className='card-center'>
		// 					<p className='bold'>ARTICULO APARTADO</p>
		// 					<p className='w80 center'>
		// 						Aquí podrás dar seguimiento al estatus del articulo que haz
		// 						apartado y poder realizar los abonos correspondientes.
		// 						-Información del articulo
		// 					</p>
		// 				</div>

		// 				<div className=''>
		// 					<Button
		// 						className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
		// 						onClick={function () {
		// 							navigate(CLIENTES_SECTION_PRO)
		// 						}}
		// 					>
		// 						Ver Articulos Apartados
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</Grid>
		// 		<Grid item lg={6} className='flex mt2'>
		// 			<div className='card-container'>
		// 				<div className='w100'>
		// 					<img
		// 						className='img-size'
		// 						src='https://images.unsplash.com/photo-1603808033587-935942847de4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1112&q=80'
		// 					/>
		// 				</div>

		// 				<div className='card-center'>
		// 					<p className='bold'>EN TRÁNSITO</p>
		// 					<p className='w80 center'>
		// 						Aquí podrás dar seguimiento a los artículos que van camino a tu
		// 						guardarropa una vez que se confirme el pago total.
		// 					</p>
		// 				</div>

		// 				<div className=''>
		// 					<Button
		// 						className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
		// 						onClick={function () {
		// 							navigate(CLIENTES_SECTION_TRA)
		// 						}}
		// 					>
		// 						Ver Detalle
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</Grid>
		// 		<Grid item lg={6} className='flex mt2'>
		// 			<div className='card-container'>
		// 				<div className='w100'>
		// 					<img
		// 						className='img-size'
		// 						src='https://images.unsplash.com/photo-1566150905968-62f0de3d6df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
		// 					/>
		// 				</div>

		// 				<div className='card-center'>
		// 					<p className='bold'>ENTREGADOS</p>
		// 					<p className='w80 center'>
		// 						Aquí podrás ver los artículos que ya se encuentran en tu
		// 						guardarropa.
		// 					</p>
		// 				</div>

		// 				<div className=''>
		// 					<Button
		// 						className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
		// 						onClick={function () {
		// 							navigate(CLIENTES_SECTION_ENT)
		// 						}}
		// 					>
		// 						Ver pedidos
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</Grid>

		// 		{/* <Grid item lg={6} className='flex mt2'>
		// 			<div className='card-container'>
		// 				<div className='w100'>
		// 					<img
		// 						className='img-size'
		// 						src='https://images.unsplash.com/photo-1631728370215-9440df2e29e3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
		// 					/>
		// 				</div>

		// 				<div className='card-center'>
		// 					<p className='bold'>PAGOS EXTRAORDINARIOS</p>
		// 					<p className='w80 center'>
		// 						Aquí podrás realizar los pagos de guías y certificaciones.
		// 					</p>
		// 				</div>

		// 				<div className=''>
		// 					<Button
		// 						className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
		// 						onClick={function () {
		// 							navigate(CLIENTES_SECTION_GUI)
		// 						}}
		// 					>
		// 						Ver Detalles
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</Grid>

		// 		<Grid item lg={6} className='flex mt2'>
		// 			<div className='card-container'>
		// 				<div className='w100'>
		// 					<img
		// 						className='img-size'
		// 						src='https://images.unsplash.com/photo-1631728370215-9440df2e29e3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
		// 					/>
		// 				</div>

		// 				<div className='card-center'>
		// 					<p className='bold'>ENVIOS</p>
		// 					<p className='w90 center'>
		// 						Aquí podrás ver el estatus de tu guía en el dado caso que tu
		// 						tengas tu articulo y se haya vendido, aquí podrás descargar la
		// 						guía para que realices tu envió.
		// 						<p className='fs14'>
		// 							** Recuerda que entre mas rápido lo envíes más rápido
		// 							recibirás tu pago.
		// 						</p>
		// 					</p>
		// 				</div>

		// 				<div className=''>
		// 					<Button
		// 						className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
		// 						onClick={function () {
		// 							navigate(CLIENTES_SECTION_GUI)
		// 						}}
		// 					>
		// 						Ver Detalles
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</Grid>

		// 		<Grid item lg={6} className='flex mt2'>
		// 			<div className='card-container'>
		// 				<div className='w100'>
		// 					<img
		// 						className='img-size'
		// 						src='https://images.unsplash.com/photo-1631728370215-9440df2e29e3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
		// 					/>
		// 				</div>

		// 				<div className='card-center'>
		// 					<p className='bold'>EN TRÁNSITO</p>
		// 					<p className='w90 center'>
		// 						Aquí podrás dar seguimiento a los envíos que están en trayecto.
		// 					</p>
		// 				</div>

		// 				<div className=''>
		// 					<Button
		// 						className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
		// 						onClick={function () {
		// 							navigate(CLIENTES_SECTION_GUI)
		// 						}}
		// 					>
		// 						Ver Detalles
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</Grid>
		// 		<Grid item lg={6} className='flex mt2'>
		// 			<div className='card-container'>
		// 				<div className='w100'>
		// 					<img
		// 						className='img-size'
		// 						src='https://images.unsplash.com/photo-1631728370215-9440df2e29e3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
		// 					/>
		// 				</div>

		// 				<div className='card-center'>
		// 					<p className='bold'>RECIBIDOS</p>
		// 					<p className='w90 center'>
		// 						Aquí podrás realizar los envíos que ya llegaron a su destino.
		// 					</p>
		// 				</div>

		// 				<div className=''>
		// 					<Button
		// 						className='back-gradient-2 bord-radius-10 shadow font-letter font-light ml-10'
		// 						onClick={function () {
		// 							navigate(CLIENTES_SECTION_GUI)
		// 						}}
		// 					>
		// 						Ver Detalles
		// 					</Button>
		// 				</div>
		// 			</div>
		// 		</Grid> */}
		// 	</Grid>
		// </div>
	)
}

export default ClientesApartadoView
