import React from 'react'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})
const InfoBancario = (props) => {
    const handleClose = () => {
		props.onClose()
	}
  return (
    <Dialog
    fullScreen
    //disableEscapeKeyDown
    open={props.open}
    onClose={props.onClose ? props.onClose : null}
    TransitionComponent={Transition}
    PaperProps={{
        style: {
            background:
                window.screen.width <= 600
                    ? 'rgba(255, 255, 255, 0.2)'
                    : 'rgba(255, 255, 255, 0.2)',
        },
    }}
    className={'backdropfilter'}
>
    <div className='container-modal-Agradecimiento2'>
        <div className='header-modal-Agradecimiento flex-between'>
            <p className='text-TitleAgradecimiento'>
                Datos bancarios
            </p>
            <IconButton aria-label='delete' onClick={handleClose}>
                <CloseIcon sx={{ color: 'black' }} />
            </IconButton>
        </div>
        <div className='container-body-modal-Agradecimiento'>
            
            <div className='section-Msg-Agradecimiento'>
                <p>
                    Son requeridos para realizar transferencias por Empeño o cuando tu pieza se haya vendido. Asegúrate de que los datos sean correctos.
                </p>
            </div>
        </div>
    </div>
</Dialog>
  )
}

export default InfoBancario