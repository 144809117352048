import React from 'react'
import { Collapse, Grid, Typography } from "@mui/material";
import BlCard from "../../Util/BlCard";
import BlLabelInfo from "../../Util/BlLabelInfo";
export default function InformacionGeneral(props) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <BlCard title="Información de general" color={'primary'}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" gutterBottom className='f-weight-bold'>
                                Folio:
                            </Typography>
                            <Typography>
                                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                    {props.Registro.txtFolio}
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" gutterBottom className='f-weight-bold'>
                                Servicio:
                            </Typography>
                            <Typography>
                                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                    {props.Registro.txtTipoServicio}
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" gutterBottom className='f-weight-bold'>
                                Score:
                            </Typography>
                            <Typography gutterBottom>
                                {props.Registro.numValScore}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" gutterBottom className='f-weight-bold'>
                                Fecha:
                            </Typography>
                            <Typography gutterBottom>
                                {props.Registro.fecFecha}
                            </Typography>
                        </Grid>

                    </Grid>
                </BlCard>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Collapse in={open}  >
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6}>
                            <BlCard title="Datos del prospecto" color={'secondary'}>
                                <BlLabelInfo label="Nombre:" inline>
                                    {props.Registro.txtNombre}
                                </BlLabelInfo>
                                <BlLabelInfo label="Email:" inline>
                                    {props.Registro.txtEmail}
                                </BlLabelInfo>
                                <BlLabelInfo label="Telefóno:" inline>
                                    {props.Registro.txtTelefono}
                                </BlLabelInfo>
                                <BlLabelInfo label="Dirección:" inline>

                                </BlLabelInfo>
                                <BlLabelInfo label="Ubicación:" inline>

                                </BlLabelInfo>
                            </BlCard>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <BlCard title="Datos del artículo" color={'secondary'}>
                                <BlLabelInfo label="Articulo:" inline>
                                    {props.Registro.txtArticulo}
                                </BlLabelInfo>
                                <BlLabelInfo label="Marca:" inline>
                                    {props.Registro.txtMarca}
                                </BlLabelInfo>
                                <BlLabelInfo label="Otra Marca:" inline>
                                    {props.Registro.txtOtraMarca}
                                </BlLabelInfo>
                                <BlLabelInfo label="Material:" inline>
                                    {props.Registro.txtMaterial}
                                </BlLabelInfo>
                                <BlLabelInfo label="Precio:" inline >
                                    {props.Registro.txtPrecio}
                                </BlLabelInfo>
                            </BlCard>
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
}