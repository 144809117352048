import { createTheme } from '@mui/material/styles'
const Theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			light: '#fff',
			main: '#fff',
			dark: '#fff',
			contrastText: '#000',
		},
		secondary: {
			light: '#CBB878',
			main: '#CBB878',
			dark: '#CBB878',
			contrastText: '##000',
		},
		info: {
			light: '#D35400',
			main: '#D35400',
			dark: '#D35400',
			contrastText: '##000',
		},
		//mode: 'dark',
	},
	components: {
		MuiTextField: {
			defaultProps: {
				margin: 'dense',
			},
		},
	},
	typography: {
		fontFamily: ['DM Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
			','
		),
	},
})
export default Theme
